<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="0"
        :audit_status="audit_status"
      ></audit-tab>

      <!--      <router-link class="item on" :to="'/admin/audit/maintain/tab1?id=' + param_id">评审信息</router-link>-->
      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab2?id=' + param_id">评审指标</router-link>-->

      <!--      <span id="more-btn">-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab3?id=' + param_id">评审分组</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab4?id=' + param_id">评审详情</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab5?id=' + param_id">会评分组</router-link>-->
      <!--      </span>-->
      <!--add by sunyj audit_status === 0 只有准备阶段才可以进行评审信息编辑 -->
      <span
        v-if="audit_status===0"
        class="edit sprite_1"
        @click="querySingle"
      ><i class="iconfont"></i>编辑</span>
    </div>
    <div
      class="audit_content ac_1_0 seendtime audit_info"
      style="display: block;"
    >
      <div class="info">
        <ul class="info_ul">
          <li class="item">项目名称：{{reviewInfo.title}}</li>
          <li class="item">评分方式：{{reviewInfo.calType=='1'?'算术平均分':'截尾平均分'}}</li>
          <li class="item">同校规避：{{reviewInfo.diffSchool?'是':'否'}}</li>
          <li class="item">评审包含否决性指标：{{reviewInfo.isVeto?'是':'否'}}</li>
          <li class="item">一级评审指标评价必填：{{reviewInfo.isPriEv?'是':'否'}}</li>
          <li class="item">二级评审指标评价必填：{{reviewInfo.isSubEv?'是':'否'}}</li>
          <li class="item">主办单位：{{reviewInfo.units?reviewInfo.units:'暂无'}}</li>
          <li class="item ">评审说明：<span
              class="item-des"
              @click="showDes(reviewInfo.des)"
            >{{formatDes(reviewInfo.des)}}</span>
            <div
              class="des-info"
              v-if="isShowDes"
            >
              <div>
                <span class="des-title">评审说明</span>
                <span
                  class="des-close"
                  @click="isShowDes=false"
                ></span>
              </div>
              <p>{{reviewInfo.des}}</p>
            </div>
          </li>
          <li class="item">当前阶段：准备阶段</li>
        </ul>
      </div>
      <div class="satus_time">
        <span class="time time1">{{formatDate(reviewInfo.created)}}</span>
        <span class="time time2">{{reviewInfo.time2?formatDate(reviewInfo.time2):'暂无'}}</span>
        <span class="time time3">{{reviewInfo.time3?formatDate(reviewInfo.time3):'暂无'}}</span>
        <span class="time time4">{{reviewInfo.time4?formatDate(reviewInfo.time4):'暂无'}}</span>
        <span class="time time5">{{reviewInfo.time5?formatDate(reviewInfo.time5):'暂无'}}</span>
        <span class="time time6">{{reviewInfo.time6?formatDate(reviewInfo.time6):'暂无'}}</span>
      </div>
      <div class="satus_title">
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==0?'on':''"
        >准备阶段</span>
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==1?'on':''"
        >通知阶段</span>
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==2?'on':''"
        >作品提交阶段</span>
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==3?'on':''"
        >网评阶段</span>
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==4?'on':''"
        >终审阶段</span>
        <span class="arrow">↑</span>
        <span
          class="item"
          :class="audit_status==5?'on':''"
        >公示阶段</span>
        <p class="line"></p>
      </div>
      <div class="handbook">
        <h3>专家手册</h3>

        <div
          class="cont"
          v-if="reviewInfo.handbook"
        >
          <p
            v-for="(item,index) in handbookList"
            :key="index"
          ><span
              class="del sprite_1"
              title="删除"
              @click="delHandbook(index)"
            ><i class="iconfont"></i></span><a
              :href="item.path"
              :download="item.name"
            >{{item.name}}</a></p>
        </div>
        <span
          class="import-btn sprite_1"
          id="handbookUpload"
          @click="showFileWindow(1)"
        >添加手册</span>
        <div class="clear"></div>
        <p class="imgMsg sprite_1">允许上传文件类型：.txt, .png, .jpg, .pdf, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .pptx</p>
      </div>
      <div class="attachment">
        <h3>附件</h3>

        <div
          class="cont"
          v-if="reviewInfo.attachment"
        >
          <p
            v-for="(item,index) in attachmentList"
            :key="index"
          ><span
              class="del sprite_1"
              title="删除"
              @click="delAttachment(index)"
            ><i class="iconfont"></i></span><a
              :href="item.path"
              :download="item.name"
            >{{item.name}}</a></p>
        </div>
        <span
          class="import-btn sprite_1"
          id="fileUpload"
          @click="showFileWindow(2)"
        >添加附件</span>
        <div class="clear"></div>
        <p class="imgMsg sprite_1">允许上传文件类型：.txt, .png, .jpg, .pdf, .jpeg, .rar, .zip, .doc, .docx, .xls, .xlsx, .ppt,
          .pptx</p>
      </div>
    </div>

    <div
      class="form_overlay"
      id="form_overlay_info"
      :style="showEditWindow ? 'visibility: visible' : 'visibility: hidden'"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">评审管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="showEditWindow = false"
        >
          <i class="iconfont"></i>
        </a>
        <div
          class="form_content"
          style=""
        >
          <form
            class="itemForm"
            id="itemForm_info"
            style=""
          >
            <div class="item">
              <span class="label"><i class="require">*</i>项目名称:</span>
              <input
                v-model="formInfo.title"
                name="title"
                type="text"
                :disabled="audit_status>2"
              >
            </div>
            <div class="item">
              <span class="check-label"><i class="require"></i>同校规避:</span>
              <label class="check-radio">
                <input
                  v-model="formInfo.diffSchool"
                  type="radio"
                  name="diffSchool"
                  value="1"
                  :disabled="audit_status>2"
                >是
              </label>
              <label class="check-radio">
                <input
                  v-model="formInfo.diffSchool"
                  type="radio"
                  name="diffSchool"
                  value="0"
                  :disabled="audit_status>2"
                >否
              </label>
            </div>
            <div class="item">
              <span class="check-label"><i class="require">*</i>评分方式:</span>
              <label class="check-radio">
                <input
                  v-model="formInfo.calType"
                  type="radio"
                  name="calType"
                  value="1"
                  :disabled="audit_status>2"
                >算术平均分
                <div class="tooltip label-tip">
                  <div class="tooltip-icon">?</div>
                  <span class="tooltiptext">数据中所有数据之和再除以数据个数。</span>
                </div>
              </label>
              <label class="check-radio">
                <input
                  v-model="formInfo.calType"
                  type="radio"
                  name="calType"
                  value="2"
                  :disabled="audit_status>2"
                >截尾平均分
                <div class="tooltip label-tip">
                  <div class="tooltip-icon">?</div>
                  <span class="tooltiptext">去掉最高分和最低分求平均数。</span>
                </div>
              </label>
            </div>
            <div class="item subev-item">
              <span class="label"><i class="require"></i>评审包含否决性指标:</span>
              <input
                v-model="formInfo.isVeto"
                type="checkbox"
                name="isVeto"
                :disabled="audit_status>2"
              >
            </div>
            <div class="item subev-item">
              <span class="label"><i class="require"></i>一级评审指标评价必填:</span>
              <input
                v-model="formInfo.isPriEv"
                type="checkbox"
                name="isPriEv"
                :disabled="audit_status>2"
              >
            </div>
            <div class="item subev-item">
              <span class="label"><i class="require"></i>二级评审指标评价必填:</span>
              <input
                v-model="formInfo.isSubEv"
                type="checkbox"
                name="isSubEv"
                :disabled="audit_status>2"
              >
            </div>
            <div class="item subev-item">
              <span class="label"><i class="require">*</i>打分数值类型:</span>
              <select
                v-model="formInfo.scoreType"
                name="scoreType"
                :disabled="audit_status>2"
              >
                <option value="">选择打分数值类型</option>
                <option value="1">整数</option>
                <option value="2">保留一位小数点</option>
                <option value="3">保留二位小数点</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>主办单位:</span>
              <input
                v-model="formInfo.units"
                name="units"
                type="text"
                value=""
                :disabled="audit_status>2"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：所有时间计算到23时59分59秒</p>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>准备阶段结束时间:</span>
              <input
                v-model="formInfo.time2"
                name="time2"
                type="date"
                value=""
                :max="formInfo.time3"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：本阶段可以进行<b>评审信息</b>和<b>评审指标</b>的编辑。一旦项目进入下一阶段，将不能再编辑<b>评审信息</b>和<b>评审指标</b>。</p>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>通知阶段结束时间:</span>
              <input
                v-model="formInfo.time3"
                name="time3"
                class="laydate-icon"
                type="date"
                value=""
                :min="formInfo.time2"
                :max="formInfo.time4"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：本阶段不可以进行评审信息编辑，只提供查阅。</p>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>网评阶段开始时间:</span>
              <input
                v-model="formInfo.time4"
                name="time4"
                class="laydate-icon"
                type="date"
                value=""
                :min="formInfo.time3"
                :max="formInfo.time5"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：本阶段可以进行查看参评的作品。</p>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>网评阶段结束时间:</span>
              <input
                v-model="formInfo.time5"
                name="time5"
                class="laydate-icon"
                type="date"
                value=""
                :min="formInfo.time4"
                :max="formInfo.time6"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：本阶段可以进行查看参评作品的专家打分情况和维护评审分组。</p>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>终审阶段结束时间:</span>
              <input
                v-model="formInfo.time6"
                name="time6"
                class="laydate-icon"
                type="date"
                value=""
                :min="formInfo.time5"
              >
            </div>
            <div class="item">
              <p class="imgMsg">注：本阶段可以进行维护评审结果和批量导出参评作品的在线评审纪录以及查看当时的评审分组情况。</p>
            </div>
            <div class="item">
              <span class="label"><i class="require"></i>评审说明:</span>
              <textarea
                v-model="formInfo.des"
                name="desc"
                class="simple"
                :disabled="audit_status>2"
              ></textarea>
            </div>
            <div class="item auth-item">
              <span class="label"><i class="require"></i>评审须知:</span>
              <input
                v-model="formInfo.isauth"
                type="checkbox"
                name="isauth"
                :disabled="audit_status>2"
              >
            </div>
            <div
              class="item position-relitive"
              v-show="formInfo.isauth"
            >
              <span class="label"><i class="require">*</i>阅读时间:</span>
              <div class="k-container">
                <input
                  v-model="formInfo.agreeTime"
                  name="agreeTime"
                  type="number"
                  value=""
                  :disabled="audit_status>2"
                >
                <span>秒</span>
              </div>
            </div>
            <div
              class="item"
              v-show="formInfo.isauth"
            >
              <vue-ueditor-wrap
                v-model="formInfo.authtxt"
                :config="editorConfig"
                editor-id="editor-demo-01"
              ></vue-ueditor-wrap>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button info_close close"
            @click="showEditWindow = false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveAudit"
          >提交</a>
        </div>
      </div>
    </div>
    <div
      id="file-upload-overlay"
      :style="showWindow.bigFile?'display:block':''"
    >
      <div class="file-upload-bg"></div>
      <div class="file-upload">
        <div
          class="file-upload-type"
          v-if="showWindow.handbookFile"
        >允许上传的文件扩展名：txt,png,jpg,pdf,jpeg,.doc,.docx,.xls,.xlsx,.ppt,.pptx</div>
        <div
          class="file-upload-type"
          v-if="showWindow.attachmentFile"
        >允许上传的文件扩展名：txt,png,jpg,pdf,jpeg,rar,zip,.doc,.docx,.xls,.xlsx,.ppt,.pptx</div>
        <div
          class="file-upload-close"
          @click="hideFileWindow"
        >×</div><span
          class="file_uploadBtn"
          id="file_uploadBtn"
          style="display:block;"
        >选择文件<input
            type="file"
            id="file_fileInput"
            accept=".txt, .png, .jpg, .pdf, .jpeg, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
            style="display:block;"
            ref="imageFile"
            @change="imageFileChange"
          ></span>
        <div
          class="fileBox"
          id="file_uploadBox"
        ><span
            class="pauseBtn"
            id="file_pauseBtn"
            style="display:none;"
          >暂停</span>
          <div
            class="filename"
            id="file_filename"
          ></div>
          <div
            class="pbar"
            style="display: none;"
          >
            <div
              class="progress"
              id="file_progress"
              style="width: 0px;"
            ></div>
            <div id="file_percent">0%</div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VueUeditorWrap from 'vue-ueditor-wrap'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab1',
  components: {
    VueUeditorWrap,
    AuditTab
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      showEditWindow: false,
      auditInfo: null,
      formInfo: {
        id: 0,
        title: '',
        diffSchool: 0,
        calType: 1,
        isVeto: 0,
        isPriEv: 0,
        isSubEv: 0,
        scoreType: 0,
        units: '',
        time2: '',
        time3: '',
        time4: '',
        time5: '',
        time6: '',
        des: '',
        isauth: 0,
        agreeTime: 0,
        authtxt: ''
      },
      reviewInfo: {},
      handbookList: [],
      attachmentList: [],
      notificationSystem: {
        options: {
          error: {
            position: 'topRight',
            messageColor: 'white',
            titleColor: 'white',
            iconColor: '#fff'
          },
          success: {
            position: 'topRight',
            messageColor: 'white',
            titleColor: 'white',
            iconColor: '#fff'
          }

        }
      },
      showWindow: {
        handbookFile: false,
        attachmentFile: false,
        bigFile: false,
        bigFileOption: {
          showProgress: false,
          progressValue: 0
        }
      },
      isShowDes: false
    }
  },
  computed: {
    editorConfig () {
      return this.$store.state.editorConfig
    }
  },
  methods: {
    // 格式化评审说明
    formatDate (value) {
      if (value) {
        return value.substring(0, value.indexOf('T'))
      } else {
        return '暂无'
      }
    },
    showDes (val) {
      if (val.length > 20) {
        this.isShowDes = true
      } else {
        this.isShowDes = false
      }
    },
    formatDes (val) {
      if (val) {
        if (val.length > 20) {
          return val.substring(0, 20) + '...'
        } else {
          return val
        }
      } else {
        return '暂无'
      }
    },

    showFileWindow (val) {
      this.showWindow.bigFile = true
      if (val === 1) {
        this.showWindow.handbookFile = true
        this.showWindow.attachmentFile = false
      } else {
        this.showWindow.handbookFile = false
        this.showWindow.attachmentFile = true
      }
    },
    hideFileWindow () {
      this.showWindow.bigFile = false
      this.showWindow.handbookFile = false
      this.showWindow.attachmentFile = false
    },
    async imageFileChange () {
      const fileObj = {}
      const uploadUrl = '/files/' + this.$refs.imageFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.imageFile.files[0]).then(async (data) => {
        fileObj.name = this.$refs.imageFile.files[0].name
        fileObj.path = data
        if (this.showWindow.handbookFile) {
          this.handbookList.push(fileObj)
          this.formInfo.handbook = JSON.stringify(this.handbookList)
        } else if (this.showWindow.attachmentFile) {
          this.attachmentList.push(fileObj)
          this.formInfo.attachment = JSON.stringify(this.attachmentList)
        }
        await this.fileUpload()
        this.hideFileWindow()
      }).catch(() => {
        this.$toast.error('上传失败！', '', null)
      })
    },
    async fileUpload () {
      const url = '/audit/' + this.formInfo.id + '/'
      var params
      if (this.showWindow.handbookFile) {
        params = {
          handbook: this.formInfo.handbook
        }
      } else if (this.showWindow.attachmentFile) {
        params = {
          attachment: this.formInfo.attachment
        }
      }
      await this.$http.put(url, params).then((data) => {
        if (data.id) {
          this.getAuditInfo()
        } else {
          this.$toast.error('修改失败', '', this.notificationSystem.options.error)
        }
      })
    },
    delHandbook (index) {
      this.handbookList.splice(index, 1)
      this.formInfo.handbook = JSON.stringify(this.handbookList)
      this.showWindow.handbookFile = true
      this.showWindow.attachmentFile = false
      this.fileUpload()
    },
    delAttachment (index) {
      this.attachmentList.splice(index, 1)
      this.formInfo.attachment = JSON.stringify(this.attachmentList)
      this.showWindow.handbookFile = false
      this.showWindow.attachmentFile = true
      this.fileUpload()
    },
    async getAuditInfo () {
      // const url = '/audit/' + this.formInfo.id + '/'
      // 优化使用返回快的接口，防止页面加载慢
      const url = '/audit_search/' + this.formInfo.id + '/'
      await this.$http.get(url).then((data) => {
        this.reviewInfo = { ...data }
        this.handbookList = data.handbook ? JSON.parse(data.handbook) : []
        this.attachmentList = data.attachment ? JSON.parse(data.attachment) : []
      })
    },
    async querySingle () {
      this.showEditWindow = true

      this.formInfo = { ...this.reviewInfo }
      this.formInfo.time2 = this.formatDate(this.formInfo.time2)
      this.formInfo.time3 = this.formatDate(this.formInfo.time3)
      this.formInfo.time4 = this.formatDate(this.formInfo.time4)
      this.formInfo.time5 = this.formatDate(this.formInfo.time5)
      this.formInfo.time6 = this.formatDate(this.formInfo.time6)
    },
    async saveAudit () {
      if (!this.formInfo.title) {
        this.$toast.error('项目名称不能为空', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.scoreType) {
        this.$toast.error('请选择打分数值类型', '', this.notificationSystem.options.error)
        return false
      }

      if (!this.formInfo.units) {
        this.$toast.error('主办单位不能为空', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.time2) {
        this.$toast.error('请选择准备阶段结束时间', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.time3) {
        this.$toast.error('请选择通知阶段结束时间', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.time4) {
        this.$toast.error('请选择网评阶段开始时间', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.time5) {
        this.$toast.error('请选择网评阶段结束时间', '', this.notificationSystem.options.error)
        return false
      }
      if (!this.formInfo.time6) {
        this.$toast.error('请选择终审阶段结束时间', '', this.notificationSystem.options.error)
        return false
      }
      this.formInfo.isVeto = this.formInfo.isVeto ? '1' : '0'
      this.formInfo.isauth = this.formInfo.isauth ? '1' : '0'
      this.formInfo.isPriEv = this.formInfo.isPriEv ? '1' : '0'
      this.formInfo.isSubEv = this.formInfo.isSubEv ? '1' : '0'
      this.formInfo.time2 = this.formInfo.time2 + 'T23:59:59'
      this.formInfo.time3 = this.formInfo.time3 + 'T23:59:59'
      this.formInfo.time4 = this.formInfo.time4 + 'T23:59:59'
      this.formInfo.time5 = this.formInfo.time5 + 'T23:59:59'
      this.formInfo.time6 = this.formInfo.time6 + 'T23:59:59'
      // const url = '/audit/' + this.formInfo.id + '/'
      // 使用轻接口 优化慢接口sunyj
      const url = '/audit_search/' + this.formInfo.id + '/'
      await this.$http.put(url, this.formInfo).then((data) => {
        if (data.id) {
          this.$toast.success('修改成功', '', this.notificationSystem.options.success)
          this.showEditWindow = false
          this.getAuditInfo()
        } else {
          this.$toast.error('修改失败', '', this.notificationSystem.options.error)
        }
      })
    }
  },
  created () {
    this.formInfo.id = this.param_id
    this.getAuditInfo()
  }

}
</script>

<style scoped>
.add-btn,
.add-veto-btn,
.add-veto-desc-btn,
.import-btn {
  float: unset;
}

.des-info {
  width: 500px;
  min-height: 300px;
  background: #fff;
  margin: 10px 0;
  padding: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%);
  z-index: 10;
}
.des-info > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.des-info .des-title {
  display: inline-block;
  font-weight: bold;
  color: #666;
}
.des-info .des-close {
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
  background: url(../../../assets/images/close.jpeg) no-repeat;
  background-size: 100% 100%;
}

.des-info p {
  min-height: 280px;
  border: 1px solid #efefef;
  padding: 10px;
  box-sizing: border-box;
}
.item-des {
  cursor: pointer;
}

.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
