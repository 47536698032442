<template>
  <div>
    <router-link
      :class="activeClass(0)"
      :to="'/admin/audit/maintain/tab1?id=' + param_id"
    >评审信息</router-link>
    <router-link
      :class="activeClass(1)"
      :to="'/admin/audit/maintain/tab2?id=' + param_id"
    >评审指标</router-link>

    <span id="more-btn">
      <router-link
        v-if="audit_status==2 || audit_status==3 || audit_status==4 || audit_status==5"
        :class="activeClass(2)"
        :to="'/admin/audit/maintain/tab3?id=' + param_id"
      >评审分组</router-link>
      <router-link
        v-if="audit_status==2"
        :class="activeClass(3)"
        :to="'/admin/audit/maintain/tab4?id=' + param_id"
      >参评作品</router-link>
      <router-link
        :class="activeClass(4)"
        v-if="audit_status==3  || audit_status==4 || audit_status==5"
        :to="'/admin/audit/maintain/tab5?id=' + param_id"
      >评审详情</router-link>
      <router-link
        v-if="audit_status==4  || audit_status==5"
        :class="activeClass(5)"
        :to="'/admin/audit/maintain/tab6?id=' + param_id"
      >会评分组</router-link>
    </span>
  </div>
</template>

<script>
export default {
  name: 'AuditTab',
  props: ['param_id', 'active_index', 'audit_status'],
  methods: {
    activeClass (index) {
      if (index === this.active_index) {
        return 'item on'
      }
      return 'item'
    }
  }
}
</script>

<style scoped>
</style>
