<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">待评审作品</span>
      <div class="btns">
        <span
          class="btn add"
          id="view-book"
          @click="showAuthText = true"
        ><i class="iconfont"></i>评审须知</span>
        <ul id="bookList">
          <li v-for="item in handbooks" :key="item.path">
              <a :href="item.path" download>{{item.name}}</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="search-box">
      <form
        class="form-inline"
        id="filterForm"
        :style="showSearch ? 'display: block; height: auto;': ''"
      >
        <div class="form-group">
          <input
            type="text"
            v-model="queryInfo.item_name"
            class="form-control input-sm"
            name="title"
            placeholder="作品名称"
            value=""
          >
        </div>
        <div class="form-group">
          <select
            v-model="queryInfo.item_isZhen"
            class="form-control input-sm"
            name="isZhen"
          >
            <option value="">一流专业</option>
            <option value="1">是</option>
            <option value="2">否</option>
          </select>
        </div>
        <div class="form-group">
          <button
            @click="pageCallback(1)"
            type="button"
            class="btn btn-primary btn-rounded btn-search"
          >
            <i class="fa fa-search text-xs m-r-xs"></i>搜索
          </button>
        </div>
      </form>
      <span
        class="btn btn-danger btn-open"
        data-reset=""
        @click="showSearch=!showSearch"
      >
        {{ showSearch ? '关闭' : '展开' }}
      </span>
    </div>
    <div
      id="list"
      class="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th width="">作品名称</th>
            <th width="120">专业类</th>
            <th width="160">学校</th>
            <th width="120">负责人</th>
            <th width="120">一流专业建设点</th>
            <th width="60">得分</th>
            <th width="220">操作</th>
          </tr>
          <tr
            v-for="(item, index) in items"
            :key="index"
          >
            <td>
              <a
                href="javascript:void(0)"
                class="a-link"
                @click="jumpToIlabx(item)"
              >
                <span :title="item.projTitle">{{ item.projTitle }}</span>
              </a>
            </td>
            <td>{{ item.category02 }}</td>
            <td>{{ item.projSchoolTitle ? item.projSchoolTitle : '-' }}</td>
            <td>{{ item.incharge }}</td>
            <td>{{ item.isZhen == 1 ? '是' : '否' }}</td>
            <td
              id="score_18630"
              cid="18630"
            >
              <span v-if="item.score">{{ item.score }}</span>
              <span
                v-else
                class="status_1"
              >未评分</span>
            </td>
            <td>
              <a
                @click="jumpToIlabx(item)"
                class="a-link btn"
                target="_blank"
              >预览</a>
              <a
                href="javascript:void(0)"
                class="btn manage"
                @click="getAudititemCourse(item.auditItem_id)"
              >相关课程</a>
              <router-link
                :to="'/admin/my_audit_score?audit_id=' + item.auditId +'&group_id=' + item.groupId + '&item_id=' + item.auditItem_id"
                class="other1 btn edit"
              >
                {{ item.status == 1 ? '继续评分' : item.status == 2 ? '查看评分' : item.status == 0 ? '评分' : '评分' }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination
      v-if="items.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="form_overlay_info"
      :style="showAuthText ? 'visibility: visible' : 'visibility: hidden'"
    >
      <div class="form_overlay_bg"></div>
      <div
        class="form_panel editor"
      >
        <div class="form_title">评审须知</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="showAuthText = false"
        >
          <i class="iconfont"></i>
        </a>
        <div
          class="form_content"
          v-html="audit_auth_txt"
        ></div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button info_close close"
            @click="showAuthText = false"
          >关闭</a>
        </div>
      </div>
    </div>
    <!-- 相关课程列表弹窗 -->
    <div
      class="form_overlay"
      id="course_detail_overlay"
      :style="classShowCourseListWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">相关课程</div>
        <div class="form_content">
          <div class="list">
<!--            <table>-->
<!--              <tbody>-->
<!--                <tr>-->
<!--                  <td colspan="6"><strong>负责人往年认定课程，供专家比对</strong></td>-->
<!--                </tr>-->
<!--                <tr class="l_header">-->
<!--                  <th>课程名称</th>-->
<!--                  <th width="90">负责人</th>-->
<!--                  <th width="120">团队主要成员</th>-->
<!--                  <th width="120">学校</th>-->
<!--                  <th width="70">认定年份</th>-->
<!--                  <th width="60">操作</th>-->
<!--                </tr>-->
<!--                <tr-->
<!--                  v-for="item in courseData"-->
<!--                  :key="item.id"-->
<!--                >-->
<!--                  <template v-if="item.type==1">-->
<!--                    <td>{{item.title}}</td>-->
<!--                    <td>{{item.incharge}}</td>-->
<!--                    <td>{{item.mainTeam}}</td>-->
<!--                    <td>{{item.schoolTitle}}</td>-->
<!--                    <td>{{item.declareYear}}</td>-->
<!--                    <td><a :href="item.url" class="btn preview">查看</a></td>-->
<!--                  </template>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td-->
<!--                    colspan="6"-->
<!--                    v-if="!courseData.length"-->
<!--                  >-->
<!--                    <div class="noitems">无相关课程</div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--              </tbody>-->
<!--            </table>-->
            <table>
              <tbody>
                <tr>
                  <td colspan="6"><strong>同专业认定课程，供考虑课程结构布局时参考</strong></td>
                </tr>
                <tr class="l_header">
                  <th>课程名称</th>
                  <th width="90">负责人</th>
                  <th width="120">团队主要成员</th>
                  <th width="120">学校</th>
                  <th width="70">认定年份</th>
                  <th width="60">操作</th>
                </tr>
                <tr
                  v-for="item in courseData"
                  :key="item.id"
                >
                  <template v-if="item.type==2">
                    <td>{{item.title}}</td>
                    <td>{{item.incharge}}</td>
                    <td>{{item.mainTeam}}</td>
                    <td>{{item.schoolTitle}}</td>
                    <td>{{item.declareYear}}</td>
                    <!--     新打开标签页               -->
                    <td><a :href="item.url" target="_blank" class="btn preview">查看</a></td>
                  </template>
                </tr>
                <tr>
                  <td
                    colspan="6"
                    v-if="!courseData.length"
                  >
                    <div class="noitems">无相关课程</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hideCourseListWindow"
          >关闭</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'

function getUrlParams (url) {
  const queryString = {}
  if (!url) {
    return queryString
  }
  url = url.split('?')
  if (url.length <= 1) {
    return queryString
  }
  var query = url[1]
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    // If first entry with this name
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[pair[0]] = pair[1]
      // If second entry with this name
    } else if (typeof queryString[pair[0]] === 'string') {
      queryString[pair[0]] = [queryString[pair[0]], pair[1]]
      // If third or later entry with this name
    } else {
      queryString[pair[0]].push(pair[1])
    }
  }
  return queryString
}

function openPage (url, method, params, target) {
  target = target || '_self'
  var dynamicForm = document.createElement('form')
  dynamicForm.setAttribute('method', method)
  dynamicForm.setAttribute('action', url)
  dynamicForm.setAttribute('target', target)
  dynamicForm.innerHTML = ''
  document.body.appendChild(dynamicForm)
  for (var i = 0; i < params.length; i++) {
    var input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', params[i].paramName)
    input.setAttribute('value', params[i].paramValue)
    dynamicForm.appendChild(input)
  }
  dynamicForm.submit()
}

export default {
  name: 'MyAuditGroup',
  components: {
    Pagination
  },
  data () {
    return {

      showSearch: false,
      showAuthText: false,
      audit_id: this.$route.query.audit_id,
      audit_auth_txt: '',
      diff_school: 0,
      queryInfo: {
        page: 1,
        size: 10,
        item_name: '',
        item_isZhen: '',
        audit_id: this.$route.query.audit_id
        // group_id: this.$route.query.group_id
        // 专家不能传递id 会横向越权
        // expert_id: this.$store.state.userInfo.profile_id
      },
      items: [],
      items_count: 0,
      classShowCourseListWindow: {},
      courseData: [],
      handbooks: []
    }
  },
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.items_count / this.queryInfo.size),
        totalData: this.items_count
      }
    }
  },
  methods: {
    async jumpToIlabx (item) {
      // target="_blank"
      // 获取token
      console.log(item)
      await this.$http.post('/xjwt/', {}).then((res) => {
        console.log(res)
        if (res.token) {
          // 获取token成功 拼接url进行跳转
          const url = item.projUrl
          const ps = getUrlParams(url)
          const data = []
          for (const k in ps) {
            data.push({
              paramName: k,
              paramValue: ps[k]
            })
          }
          data.push({
            paramName: 'token',
            paramValue: res.token
          })
          debugger
          openPage(url.split('?')[0], 'GET', data, '_blank')
        } else {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        }
      })
    },
    async getItems () {
      // console.log(this.diff_school)
      // if (this.diff_school === 1) {
      //   this.queryInfo.exclude_school_id = this.$store.state.userInfo.school_id
      // }
      // this.$http.get('/auditgroup_itemscore/', {
      //   params: this.queryInfo
      // }).then((data) => {
      //   this.items = data.results
      //   this.items_count = data.count
      // })
      // 优化查询 上个接口返回的数据太多，并且耗时
      // 查询当前专家 在当前评审项目中需要评审哪些作品，所以不需要用组来进行参数传递，用audit_id来过滤
      this.$http.get('/auditgroup_itemscore_expert/', {
        params: this.queryInfo
      }).then((data) => {
        this.items = data.results
        this.items_count = data.count
      })
    },
    pageCallback (data) {
      this.queryInfo.page = data
      this.getItems()
    },
    toMaintain (id) {
      this.$router.push('/admin/audit/maintain?id=' + id)
    },
    async getAudit () {
      // sunyj 由audit切换为auditSearch,audit接口太重了
      await this.$http.get('/audit_search/' + this.audit_id + '/').then((data) => {
        console.log(data)
        this.audit_auth_txt = data.authtxt
        this.diff_school = data.diffSchool
        const handbook = data.handbook
        if (handbook !== null && handbook !== '' && handbook !== undefined) {
          this.handbooks = JSON.parse(handbook)
        }
      })
    },
    // 相关课程列表
    async getAudititemCourse (id) {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.$route.query.audit_id,
        auditItem_id: id
      }
      await this.$http.get('/audititem_course/', { params }).then(async (data) => {
        this.courseData = data.results
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
      this.showCourseListWindow()
    },
    // 打开相关课程列表弹窗
    showCourseListWindow () {
      this.classShowCourseListWindow = {
        visibility: 'visible'
      }
    },
    // 关闭相关课程列表弹窗
    hideCourseListWindow () {
      this.classShowCourseListWindow = {}
    },
    computeScore (scores) {
      if (!scores) {
        return ''
      }
      const arrScore = scores.split(',')
      let intScore = []
      for (let i = 0; i < arrScore.length; i++) {
        intScore.push(parseFloat(arrScore[i]))
      }
      intScore = intScore.sort()
      // 去头去尾逻辑 待补充
      let result = 0
      for (let i = 0; i < intScore.length; i++) {
        result += intScore[i]
      }
      return result / intScore.length
    },
    toProj (projUrl) {
      if (projUrl) {
        const ticket = 'V2RCwpfNPoTfNK1RLkNekDSBklnAFCe35%2Bi8aSw1sCKu8sUtaUZBdhVtM6i%2FLjR%2BKeejVR91K4fFIbC0GREY%2Fw%3D%3D'
        if (projUrl.indexOf('?') !== -1) {
          window.open(projUrl + '&ticket=' + ticket)
        } else {
          window.open(projUrl + '?ticket=' + ticket)
        }
      } else {
        this.$layer.msg('实验路径配置错误，请联系管理员！')
      }
    }
  },
  mounted () {
  },
  created () {
    // 优化去除await 防止阻塞
    this.getAudit()
    this.getItems()
  }
}
</script>

<style scoped>
/*@import "../../assets/css/items.css";*/

.content .container > .set-wrap{
  height:auto;
  min-height:64px;
}
/*下载列表样式*/
.content .container .set-wrap .btns {
  width: 80%;
}

.content .container .set-wrap .btns .btn {
  float: right;
}
/*下载列表样式*/
#bookList{
  overflow:hidden;
  float: right;
  max-width: 80%;
}

#bookList>li{
  float:right;
  margin: 2px 0 0 12px;
  line-height: 36px;
}

#bookList>li>a{
  color: #df3d3e;
  text-decoration: underline;
}
/*相关课程弹窗样式*/
#course_detail_overlay .form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}

#form_overlay_info .form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
