<template>
  <div
    class="container"
    style="min-height: 1216px;"
  >
    <div class="cont_wrap audit">
      <h3
        class="title auditnone"
        style="display: block;"
      >{{audits_info.title}}</h3>

      <button class="btn btn-primary initStatus" @click="initialStage(0)">初始化评审阶段</button>
      <button class="btn btn-primary hide preStatus">回到上一阶段</button>
      <div class="clearfix"></div>
      <div
        class="audit_satus auditnone"
        style="display: block;"
      >
        <span
          class="item"
          :class="statusButtonArr[0]"
          @click="askChangeStatus(0)"
        >准备阶段</span>
        <span class="arrow">→</span>
        <span
          class="item"
          :class="statusButtonArr[1]"
          @click="askChangeStatus(1)"
        >通知阶段</span>
        <span class="arrow">→</span>
        <span
          class="item"
          :class="statusButtonArr[2]"
          @click="askChangeStatus(2)"
        >作品提交阶段</span>
        <span class="arrow">→</span>
        <span
          class="item"
          :class="statusButtonArr[3]"
          @click="askChangeStatus(3)"
        >网评阶段</span>
        <span class="arrow">→</span>
        <span
          class="item"
          :class="statusButtonArr[4]"
          @click="askChangeStatus(4)"
        >终审阶段</span>
        <span class="arrow">→</span>
        <span
          class="item"
          :class="statusButtonArr[5]"
          @click="askChangeStatus(5)"
        >公示阶段</span>
      </div>
      <div
        v-if="audits_info.status == 0"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>准备阶段</b>，可以进行<b>评审信息</b>和<b>评审指标</b>的编辑。一旦项目进入下一阶段，将不能再编辑<b>评审信息</b>和<b>评审指标</b>。</p>
        <p v-if="audits_info.time2">距<b>通知阶段</b>还有：{{ timeDeal(audits_info.time2) }}</p>
      </div>
      <div
        v-if="audits_info.status == 1"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>通知阶段</b>，不可以进行评审信息编辑，只提供查阅。</p>
        <p v-if="audits_info.time3">距<b>作品提交阶段</b>还有：{{ timeDeal(audits_info.time3) }}</p>
      </div>
      <div
        v-if="audits_info.status == 2"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>作品提交阶段</b>，可以进行查看参评的作品。</p>
        <p v-if="audits_info.time4">距<b>网评阶段</b>还有：{{ timeDeal(audits_info.time4) }}</p>
        <p class="audit-item-num">截至目前为止，<b class="num">{{auditItemCount}}</b>件作品参与评审。</p>
      </div>
      <div
        v-if="audits_info.status == 3"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>网评阶段</b>，可以进行 查看参评作品的专家打分情况和维护评审分组。</p>
        <p v-if="audits_info.time5">距<b>终审阶段</b>还有：{{ timeDeal(audits_info.time5) }}</p>
      </div>
      <div
        v-if="audits_info.status == 4"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>终审阶段</b>，可以进行维护评审结果和批量导出参评作品的在线评审纪录以及查看当时的评审分组情况。</p>
        <p v-if="audits_info.time6">距<b>公示阶段</b>还有：{{ timeDeal(audits_info.time6) }}</p>
      </div>
      <div
        v-if="audits_info.status == 5"
        class="audit_msg auditnone"
        style="display: block;"
      >
        <p>本评审项目目前在<b>公示阶段</b>，可以进行对评审结果的查看和导出。</p>
      </div>
      <router-view
        v-if="audits_info"
        :param_id="param_id"
        :audit_status="audits_info.status"
        :mode="audits_info.auditMode"
      ></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Maintain',
  data () {
    return {
      param_id: 0,
      audits_info: {
        id: 0,
        title: '',
        status: null,
        time2: null,
        time3: null,
        time4: null,
        time5: null,
        time6: null,
        auditMode: null
      },
      normvetoInfo: [],
      normInfo: [],
      // 参评作品数量
      auditItemCount: 0
    }
  },
  computed: {
    statusButtonArr () {
      const arr = ['', '', '', '', '', '']
      arr[this.audits_info.status] = 'on'
      if (this.audits_info.status + 1 < arr.length) {
        arr[this.audits_info.status + 1] = 'next'
      }
      return arr
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  methods: {
    async getInfo () {
      // const url = '/audit/' + this.param_id + '/'
      // 优化使用返回快的接口，防止页面加载慢
      const url = '/audit_search/' + this.param_id + '/'
      await this.$http.get(url).then((data) => {
        if (data.id) {
          this.audits_info.title = data.title
          this.audits_info.id = data.id
          this.audits_info.status = data.status
          this.audits_info.time2 = data.time2
          this.audits_info.time3 = data.time3
          this.audits_info.time4 = data.time4
          this.audits_info.time5 = data.time5
          this.audits_info.time6 = data.time6
          this.audits_info.isVeto = data.isVeto
          this.audits_info.auditMode = data.auditMode
        }
      })
    },
    timeDeal (t) {
      if (!t) {
        return ''
      }
      const diff = new Date(t) - new Date()

      if (diff < 0) {
        return '已过'
      } else {
        // 分钟数
        const m = parseInt(diff / 60000)
        return '' + Math.floor(m / 60) + '小时' + (m % 60) + '分钟'
      }
    },
    async getNorm () {
      const params = {
        page: 1,
        size: 1,
        audit_id: this.param_id
      }
      await this.$http.get('/norm/', { params }).then((data) => {
        this.normInfo = data.results
      })
    },
    async getNormveto () {
      const params = {
        page: 1,
        size: 1,
        audit_id: this.param_id
      }
      await this.$http.get('/normveto/', { params }).then((data) => {
        this.normvetoInfo = data.results
      })
    },
    async initialStage (status) {
      const that = this
      this.$toast.show('确定要初始化到准备阶段吗？', '', {
        color: 'green',
        position: 'topCenter',
        buttons: [
          ['<button>确定</button>', function (instance, toast) {
            that.changeStatus(status)
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>取消</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }]
        ]
      })
    },
    async askChangeStatus (status) {
      if (Number(status) === 1) {
        await this.getInfo()
        await this.getNorm()
        await this.getNormveto()
        if (this.audits_info.isVeto === 0 && this.normInfo.length === 0) {
          this.$toast.error('信息填写不完整', '', this.error_tips)
          return
        }
        if (this.audits_info.isVeto === 1 && (this.normInfo.length === 0 || this.normvetoInfo.length === 0)) {
          this.$toast.error('信息填写不完整', '', this.error_tips)
          return
        }
      }
      if (Number(status) === 3) {
        console.log(123)
        if (this.audits_info.auditMode === 2) {
          const data = await this.$http.get('/auditgroup/', {
            params: {
              page: 1,
              size: 10000,
              audit_id: this.param_id
            }
          })
          console.log(data)
          if (data.count === 0) {
            this.$toast.error('请创建评审分组', '', this.error_tips)
            return
          }
          for (let i = 0; i < data.results.length; i++) {
            if (data.results[i].max_time > data.results[i].expert_count) {
              this.$toast.error('【' + data.results[i].title + '】专家数不能小于每个项目最低被评次数', '', this.error_tips)
              return
            }
          }
        }
      }
      if (this.statusButtonArr[status] !== 'next') {
        return
      }
      const that = this
      this.$toast.show('确定要改变状态吗？一旦更改不可回退！', '', {
        color: 'green',
        position: 'topCenter',
        buttons: [
          ['<button>确定</button>', function (instance, toast) {
            that.changeStatus(status)
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>取消</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }]
        ]
      })
    },
    async changeStatus (status) {
      // if (status === 3) {
      //   console.log(status)
      //   await this.$http.post('/generate_score/', {
      //     audit_id: this.param_id
      //   }).then((data) => {
      //     if (data.code === '200') {
      //       this.doChange(status)
      //     } else {
      //       this.$toast.error('操作失败', '', null)
      //     }
      //   })
      // } else {
      //   await this.doChange(status)
      // }
      // 孙英吉注释了上面的代码 即使是网评阶段也不进行generate_score操作，因为系统会崩掉如果一直占用线程，采取离线脚本的方式人工进行分配
      await this.doChange(status)
    },
    async doChange (status) {
      // const url = '/audit/' + this.param_id + '/'
      // 原版的接口基于modelviewset 里面的更新接口又会自动调用查询，但是查询接口比较慢 所以进行优化用audit_search
      const url = '/audit_search/' + this.param_id + '/'
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        if (data.id) {
          this.$toast.success('操作成功', '', this.success_tips)
        } else {
          this.$toast.error('操作失败', '', this.error_tips)
        }
        if (status === 3) {
          this.$router.push({ path: '/admin/audit/maintain/tab5', query: { id: this.param_id } })
        }
        console.log(status)
        console.log(status === 4)
        this.getInfo()
      })
    },
    async getAuditItem () {
      await this.$http.get('/audit-items/count/' + this.param_id + '/').then((data) => {
        this.auditItemCount = data.audit_item_count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    }
  },
  async created () {
    this.param_id = this.$route.query.id
    if (!this.param_id) {
      this.$router.push('/admin/audit')
    } else {
      await this.getInfo()
    }
  },
  mounted () {
    this.getNorm()
    this.getNormveto()
    // add by sunyj 查询评审项目 用来展示截至目前为止，多少件作品参与评审
    this.getAuditItem()
  }
}
</script>

<style src="../../../assets/css/seedtime.css"></style>
<style scoped>
</style>
