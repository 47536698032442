<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="4"
        :audit_status="audit_status"
      ></audit-tab>

      <span
        class="export-btn btn-export sprite_7"
        id="save-storage-all"
        @click="submitSaveData"
      >提交所有保存数据</span>
    </div>
    <div
      class="audit_content seendtime audit_cour clearfix"
      style="display: block;"
    >
      <div class="analisys-box clearfix"></div>
      <div class="search-box"></div>
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th width="200">账号</th>
              <th>作品名称</th>
              <th>作品编码</th>
              <th width="180">保存时间</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td>-</td>
              <td>{{item.title}}</td>
              <td>{{item.projId}}</td>
              <td>{{formatDate(item.result_time)}}</td>
            </tr>

          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        >暂无数据</div>
      </div>
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>

  </div>
</template>

<script>
// import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab5',
  components: {
    AuditTab
    // Pagination,
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        audit_id: this.param_id,
        result_status: 0
      },

      tableData: [],
      tableDataCount: 0

    }
  },
  methods: {
    // 格式化评审说明
    formatDate (value) {
      if (value) {
        return value.substring(0, value.indexOf('T'))
      } else {
        return '暂无'
      }
    },
    // 提交所有保存数据
    async submitSaveData () {
      const params = {
        audit_id: this.param_id,
        result_status: 1
      }
      await this.$http.post('/audit_result/', params).then(async (data) => {
        this.$toast.success('数据保存成功！', '', this.success_tips)
        await this.search()
        // this.$router.push({ path: '/admin/audit/maintain/tab5_maintain', query: { id: this.param_id } })
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据保存失败！', '', this.error_tips)
      })
    },
    // 搜索
    async search () {
      await this.$http.get('/audititem/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },

    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    },
    dict_result () {
      return this.$store.state.dict_result
    }
  },
  mounted () {
    this.search()
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
#save-storage-all {
  background: #1a61ab;
  border-color: #1a61ab;
}
</style>
