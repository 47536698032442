<template>
  <div class="form_content clearfix">
    <div
      class="list"
      id="auditList"
    >
      <table style="width:98%">
        <tbody>
          <tr class="l_header">
            <th>专家名称</th>
            <th>否决性指标编码</th>
            <th>否决性指标名称</th>
            <th>专家评分</th>
          </tr>
          <template v-for="(expert, index) in detailInfo">
            <tr :key="'' + expert.id + '_' + index + '_1'">
              <td :rowspan="expert.vetos ? expert.vetos.length + 2 : 2">{{ expert.name }}</td>
            </tr>
            <template v-for="(veto, index) in expert.vetos">
              <tr :key="'' + expert.id + '_' + veto.id + '_' + index + '_2'">
                <td>{{ veto.id }}</td>
                <td>{{ veto.title }}</td>
                <td>
                  <input
                    type="checkbox"
                    disabled="disabled"
                    :checked="veto.checked"
                  >
                </td>
              </tr>
            </template>
            <tr :key="'' + expert.id + '_' + index + '_7'">
              <td>评语</td>
              <td
                colspan="2"
                align="left"
              >{{ expert.vetoText }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!detailInfo.length"
      >没有用户</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuditNormVeto',
  props: ['item_id'],
  data () {
    return {
      detailInfo: []
    }
  },
  watch: {
    item_id (val, oldVal) {
      this.getData()
    }
  },
  methods: {
    async getData () {
      if (!this.item_id) {
        return
      }
      await this.$http.get('/norm_veto_detail/', {
        params: {
          item_id: this.item_id
        }
      }).then((data) => {
        debugger
        console.log(data)
        this.detailInfo = data.data
      })
    },
    sum (arr) {
      const len = arr.length
      if (len === 0) {
        return 0
      } else if (len === 1) {
        return arr[0]
      } else {
        return arr[0] + this.sum(arr.slice(1))
      }
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
</style>
