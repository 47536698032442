<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="1"
        :audit_status="audit_status"
      ></audit-tab>

      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab1?id=' + param_id">评审信息</router-link>-->
      <!--      <router-link class="item on" :to="'/admin/audit/maintain/tab2?id=' + param_id">评审指标</router-link>-->

      <!--      <span id="more-btn">-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab3?id=' + param_id">评审分组</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab4?id=' + param_id">评审详情</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab5?id=' + param_id">会评分组</router-link>-->
      <!--      </span>-->
    </div>
    <div
      class="audit_content seendtime audit_norm clearfix"
      style="display: block;"
    >
      <div
        class="norm-container ct-one hide"
        style="display: block;"
        v-show="auditIsShow"
      >
        <div class="norm-container-title clearfix">
          <span class="text">否决性指标</span>
          <span
            class="add-veto-btn sprite_2"
            @click="addFun"
          >添加否决性指标</span>
          <span
            class="add-veto-desc-btn"
            @click="handleFun"
          >否决性指标简介</span>
        </div>
        <!-- 否决性表 -->
        <div
          id="list-veto"
          class="list"
        >
          <table>
            <tbody>
              <tr class="l_header">
                <th>编码</th>
                <th>指标名称</th>
                <th width="100">一票否决</th>
                <th width="100">专家可更改</th>
                <th
                  width="120"
                  v-if="audit_status==0"
                >操作</th>
              </tr>
              <tr
                v-for="item in listArr1"
                :key="item.id"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.title }}</td>
                <td>{{ item.isVeto ? "是" :  "否"}}</td>
                <td>{{ item.isChange ? "是" : "否" }}</td>
                <td v-if="audit_status==0">
                  <a
                    class="btn edit"
                    href="javascript:void(0)"
                    @click="readAudit(item.id)"
                  >编辑</a>
                  <a
                    class="btn delete"
                    href="javascript:void(0)"
                    @click="deleteAudit(item.id)"
                  >删除</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!listArr1.length"
          >没有数据</div>
        </div>
      </div>
      <!-- 实质表 -->
      <div class="norm-container ct-two">
        <div class="norm-container-title clearfix">
          <span class="text">实质性审查指标及评分</span>
          <span
            class="add-btn sprite_2"
            @click="firstAddSub"
            v-if="audit_status==0"
          >添加一级指标</span>
        </div>
        <div id="list">
          <table>
            <tbody>
              <tr class="l_header">
                <th width="140">一级指标</th>
                <th width="140">二级指标</th>
                <th>观测点及描述</th>
                <th width="100">分数</th>
                <th
                  width="220"
                  v-if="audit_status==0"
                >操作</th>
              </tr>
              <tr
                v-for="item in listArr2"
                :key="item.id"
              >
                <td>{{item.level===1?item.title:''}}</td>
                <td>{{item.level===2?item.title:''}}</td>
                <td>{{item.desc}}</td>
                <td>
                  <span v-if="item.level === 2">{{item.score}}分</span>
                </td>
                <td
                  class="align-right"
                  v-if="audit_status==0"
                >
                  <a
                    v-if="item.level===1 && audit_status==0"
                    class="btn import"
                    href="javascript:void(0)"
                    @click="addSub(item.id)"
                  >添加二级指标</a><a
                    class="btn edit"
                    href="javascript:void(0)"
                    @click="editSub(item.id,item.level)"
                  >编辑</a>
                  <a
                    class="btn delete"
                    href="javascript:void(0)"
                    @click="deleteSub(item.id)"
                  >删除</a>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!listArr2.length"
          >没有数据</div>
        </div>
      </div>
    </div>
    <!-- 添加否决性指标 -->
    <div
      class="form_overlay"
      id="veto_overlay"
      :style="showVetoWindow ? 'visibility: visible' : 'visibility: hidden'"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">否决性指标管理</div>
        <a
          href="javascript:void(0)"
          @click="showVetoWindow = false"
          class="overlay-close"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="vetoForm"
          >
            <div class="item">
              <span class="label"><i class="require">*</i>指标名称:</span>
              <input
                name="title"
                type="text"
                v-model="vetoInfo.indexName"
              />
            </div>
            <div class="item auth-item">
              <span class="label"><i class="require"></i>一票否决:</span>
              <input
                type="checkbox"
                name="isVeto"
                v-model="vetoInfo.oneVote"
              />
            </div>
            <div class="item auth-item">
              <span class="label"><i class="require"></i>专家可修改:</span>
              <input
                type="checkbox"
                name="isChange"
                v-model="vetoInfo.isChange"
              />
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="showVetoWindow = false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="veto_submit"
            @click="createAudit"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 添加否决性指标否决性指标简介 -->
    <div
      class="form_overlay"
      id="veto_desc_overlay"
      style="visibility: visible;"
      v-show="isPannel"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">否决性指标简介管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="isPannel = false"
        ><i class="iconfont"></i>
        </a>
        <vue-ueditor-wrap
          v-model="msg"
          :config="editorConfig"
        ></vue-ueditor-wrap>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="isPannel = false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="veto_desc_submit"
            @click="createIndex"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 添加一级指标 -->
    <div
      class="form_overlay"
      style="visibility: visible;"
      v-show="isPannel2"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">指标管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="isPannel2 = false"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form class="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>指标名称:</span>
              <input
                name="title"
                type="text"
                v-model="subInfo.firstName"
              />
            </div>
            <div
              class="item l2 hide"
              style="display: none;"
            >
              <span class="label"><i class="require">*</i>分数:</span>
              <input
                name="score"
                type="text"
                value=""
                class="error"
              />
            </div>
            <div
              class="item l2 hide"
              style="display: none;"
            >
              <span class="label"><i class="require">*</i>观测点及描述:</span>
              <textarea
                name="desc"
                class="simple"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="isPannel2 = false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="createSub"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 添加二级指标 -->
    <div
      class="form_overlay"
      style="visibility: visible;"
      v-show="isShow1"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">指标管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="isShow1 = false"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form class="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>指标名称:</span>
              <input
                name="title"
                type="text"
                v-model="subInfo.secondName"
              />
            </div>
            <div
              class="item l2 hide"
              style="display: block;"
            >
              <span class="label"><i class="require">*</i>分数:</span>
              <input
                name="score"
                type="text"
                v-model="subInfo.secondScore"
                class="error"
              />
            </div>
            <div
              class="item l2 hide"
              style="display: block;"
            >
              <span class="label"><i class="require">*</i>观测点及描述:</span>
              <textarea
                name="desc"
                class="simple"
                v-model="subInfo.secondDesc"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="isShow1 = false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="createSub"
          >提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuditTab from '../../../components/AuditTab'
import VueUeditorWrap from 'vue-ueditor-wrap'

export default {
  name: 'MaintainTab2',
  data () {
    return {
      msg: '',
      auditIsShow: null,
      showVetoWindow: false,
      isPannel: false,
      isPannel2: false,
      isShow1: false,
      listArr1: [], // 否决性指标列表
      vetoInfo: {
        isChange: '', // 专家可修改
        oneVote: '', // 一票否决
        editId: '',
        isEdit: '',
        indexName: '' // 指标名称
      },
      subInfo: {
        secondScore: '', // 二级分数
        secondName: '', // 二级指标名称
        secondDesc: '', // 二级观点描述
        firstId: '', // 一级id
        firstName: '', // 一级指标名称
        isEdit: '',
        level: '',
        id: ''
      },
      auditInfo: {
        size: 10000,
        audit_id: ''
      },
      normInfo: {
        audit_id: '',
        size: 10000
      },
      listArr2: [], // 实质列表
      notificationSystem: {
        options: {
          error: {
            position: 'topRight',
            messageColor: 'white',
            titleColor: 'white',
            iconColor: '#fff'
          },
          success: {
            position: 'topRight',
            messageColor: 'white',
            titleColor: 'white',
            iconColor: '#fff'
          }

        }
      },
      scoreCount: 0
    }
  },
  computed: {
    editorConfig () {
      return this.$store.state.editorConfig
    }
  },
  mounted () {
    this.auditList() // 否决性指标列表
    this.normList() // 实质列表
    this.readIndex()
  },
  methods: {
    // 否决性指标管理
    handleFun () {
      this.isPannel = true
      this.readIndex()
    },
    firstAddSub () {
      this.subInfo.level = 1
      this.isPannel2 = true
    },
    addSub (id) {
      this.isShow1 = true
      this.subInfo = {
        level: 2,
        firstId: id,
        id: 0,
        secondDesc: '',
        secondName: '',
        secondScore: ''
      }
    },
    // 编辑实质表
    async editSub (id, level) {
      this.subInfo.id = id
      this.subInfo.level = level
      this.subInfo.isEdit = true
      if (level === 1) {
        try {
          const { title } = await this.$http.get('/norm/' + id + '/')
          this.subInfo.firstName = title
        } catch (error) {
          console.log(error)
        }
        this.isPannel2 = true
      } else {
        try {
          const { title, desc, score } = await this.$http.get('/norm/' + id + '/')
          this.subInfo.secondName = title
          this.subInfo.secondDesc = desc
          this.subInfo.secondScore = score
        } catch (error) {
          console.log(error)
        }
        this.isShow1 = true
      }
    },
    // 验证分数之和
    async checkScoreCount () {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id
      }

      await this.$http.get('/norm/', { params }).then((data) => {
        this.scoreCount = 0
        if (data.results.length) {
          for (let i = 0; i < data.results.length; i++) {
            if (String(this.subInfo.id) === String(data.results[i].id)) {
              continue
            }
            this.scoreCount = this.scoreCount + Number(data.results[i].score)
          }
        } else {
          this.scoreCount = 0
        }
      })
    },
    // 实质表提交
    async createSub () {
      await this.checkScoreCount()
      var score = Number(this.subInfo.secondScore) + Number(this.scoreCount)
      if (score > 100) {
        this.$toast.error('各项指标分数之和不能大于100分', '', this.notificationSystem.options.error)
        score = 0
        this.scoreCount = 0
        return
      }
      if (this.subInfo.isEdit) {
        // 编辑
        this.editSun()
      } else {
        // 添加
        if (this.subInfo.level === 1) { // 添加一级指标
          this.firstNorm()
        } else { // 添加二级指标
          this.secondNorm()
        }
      }
    },
    // 实质表编辑
    async editSun () {
      try {
        if (this.subInfo.level === 1) { // 一级编辑
          const { id } = await this.$http.put('/norm/' + this.subInfo.id + '/', {
            title: this.subInfo.firstName
          })
          id && this.$toast.success('编辑成功！', '', this.notificationSystem.options.success)
          this.normList()
          this.isPannel2 = false
        } else { // 二级编辑
          const { id } = await this.$http.put('/norm/' + this.subInfo.id + '/', {
            title: this.subInfo.secondName,
            score: this.subInfo.secondScore,
            desc: this.subInfo.secondDesc
          })
          id && this.$toast.success('编辑成功！', '', this.notificationSystem.options.success)
          this.normList()
          this.isShow1 = false
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 删除实质性审查指标
    async deleteSub (delId) {
      try {
        const { id } = await this.$http.put('/norm/' + delId + '/', {
          isdel: 1
        })
        id && this.$toast.success('删除成功！', '', this.notificationSystem.options.success)
        this.normList()
      } catch (error) {
        console.log(error)
      }
    },
    addSubFun (id) {
      this.subInfo.firstId = id
      this.isShow1 = true
    },
    // 读取否决性简介
    async readIndex () {
      try {
        // const { vetoDesc, isVeto } = await this.$http.get('/audit/' + this.param_id + '/')
        // 优化慢接口排除sunyj
        const { vetoDesc, isVeto } = await this.$http.get('/audit_search/' + this.param_id + '/')
        this.msg = vetoDesc
        this.auditIsShow = isVeto
      } catch (error) {
        console.log(error)
      }
    },
    // 修改否决性指标简介
    async createIndex () {
      try {
        // 优化慢接口排除sunyj 修改否决性指标简介
        const { id } = await this.$http.put('/audit_search/' + this.param_id + '/', {
          vetoDesc: this.msg
        })
        id && this.$toast.success('操作成功！', '', this.notificationSystem.options.success)
        this.isPannel = false
      } catch (error) {
        console.log(error)
      }
    },
    // 实质列表
    async normList () {
      try {
        this.normInfo.audit_id = this.param_id
        const { results } = await this.$http.get('/norm/', {
          params: this.normInfo
        })
        const tempNorm = JSON.parse(JSON.stringify(results))
        const nt = []
        for (let i = 0; i < tempNorm.length; i++) {
          if (tempNorm[i].level === 1) {
            tempNorm[i].children = []
            tempNorm[i].appraiseText = ''
            nt.push(tempNorm[i])
          }
        }
        for (let i = 0; i < tempNorm.length; i++) {
          if (tempNorm[i].level === 2) {
            for (let j = 0; j < nt.length; j++) {
              if (nt[j].id === tempNorm[i].parent) {
                tempNorm[i].score_level = null
                tempNorm[i].appraiseText = ''
                nt[j].children.push(tempNorm[i])
              }
            }
          }
        }

        const newArr = []
        nt.forEach((item, index) => {
          const arr = []
          if (item.children) {
            nt[index].children.sort(this.mysort)
            arr.push(item)
            item.children.forEach((item2, index2) => {
              arr.push(item2)
            })
            newArr.push(...arr)
          }
        })
        this.listArr2 = newArr
      } catch (error) {
        console.log(error)
      }
    },
    // 添加一级指标
    async firstNorm () {
      try {
        const { id } = await this.$http.post('/norm/', {
          level: 1,
          title: this.subInfo.firstName,
          audit: this.param_id
        })
        id && this.$toast.success('添加成功！', '', this.notificationSystem.options.success)
        this.isPannel2 = false
        this.subInfo.firstName = ''
        this.normList()
      } catch (error) {
        console.log(error)
      }
    },
    // 添加二级指标
    async secondNorm () {
      try {
        const { id } = await this.$http.post('/norm/', {
          parent: this.subInfo.firstId,
          level: 2,
          title: this.subInfo.secondName,
          score: this.subInfo.secondScore,
          desc: this.subInfo.secondDesc,
          audit: this.param_id
        })
        id && this.$toast.success('添加成功！', '', this.notificationSystem.options.success)
        this.isShow1 = false
        this.subInfo.secondName = this.subInfo.secondDesc = this.subInfo.secondScore = ''
        this.normList()
      } catch (error) {
        console.log(error)
      }
    },
    addFun () {
      this.showVetoWindow = true
      this.vetoInfo.isEdit = false
    },
    async readAudit (id) {
      try {
        this.vetoInfo.isEdit = true
        this.vetoInfo.editId = id
        const { title, isVeto, isChange } = await this.$http.put(
          '/normveto/' + id + '/'
        )
        this.vetoInfo.indexName = title
        this.vetoInfo.oneVote = isVeto
        this.vetoInfo.isChange = isChange
        this.showVetoWindow = true
      } catch (error) {
        console.log(error)
      }
    },
    // 编辑否决指标
    async editAudit () {
      try {
        const isVeto = this.vetoInfo.oneVote ? 1 : 0
        const isChange = this.vetoInfo.isChange ? 1 : 0
        const { id } = await this.$http.put('/normveto/' + this.vetoInfo.editId + '/', {
          title: this.vetoInfo.indexName, // 指标名称
          isVeto, // 一票否决
          isChange // 专家可修改
        })
        id && this.$toast.success('编辑成功！', '', this.notificationSystem.options.success)
        this.showVetoWindow = false
        this.auditList()
        this.vetoInfo.oneVote = this.vetoInfo.isChange = this.vetoInfo.indexName = ''
      } catch (error) {
        console.log(error)
      }
    },
    // 删除否决指标
    async deleteAudit (delId) {
      try {
        const { id } = await this.$http.put('/normveto/' + delId + '/', {
          isdel: 1
        })
        id && this.$toast.success('删除成功！', '', this.notificationSystem.options.success)
        this.auditList()
      } catch (error) {
        console.log(error)
      }
    },
    // 添加否决指标
    async createAudit () {
      if (this.vetoInfo.isEdit) {
        // 编辑
        this.editAudit()
      } else {
        // 创建
        try {
          const isVeto = this.vetoInfo.oneVote ? 1 : 0
          const isChange = this.vetoInfo.isChange ? 1 : 0
          const { id } = await this.$http.post('/normveto/', {
            title: this.vetoInfo.indexName, // 指标名称
            isVeto, // 一票否决
            isChange, // 专家可修改
            audit: this.param_id
          })
          id && this.auditList()
          this.showVetoWindow = false
          this.vetoInfo.indexName = this.vetoInfo.oneVote = this.vetoInfo.isChange = ''
        } catch (error) {
          console.log(error)
        }
      }
    },
    // 否决性指标列表
    async auditList () {
      try {
        this.auditInfo.audit_id = this.param_id
        const { results } = await this.$http.get('/normveto/', {
          params: this.auditInfo
        })
        this.listArr1 = results
      } catch (error) {
        console.log(error)
      }
    }
  },
  components: {
    VueUeditorWrap,
    AuditTab
  },
  props: ['param_id', 'audit_status']
}
</script>

<style src="../../../assets/css/norm.css" scoped></style>

<style scoped>
#list {
  margin-bottom: 30px;
}
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
