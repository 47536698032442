<template>
  <div
    class="container"
    style="min-height: 818px;"
  >
    <div class="set-wrap clearfix">
      <span
        class="title-text"
        id="auditTitle"
      >评审</span>
      <a
        class="title-text proTitle"
        :href="'javascript:void(0)'"
        @click="jumpToIlabx(itemInfo)"
      >
        {{ itemInfo ? itemInfo.title : '' }}
      </a>
      <a
        class="proj-declare"
        :href="'javascript:void(0)'"
        @click="jumpToIlabx(itemInfo)"
      >查看申报书</a>
      <div class="btns">
        <router-link
          class="btn import"
          :to="'/admin/my_audit_group?audit_id=' + audit_id + '&group_id=' + group_id"
        >
          <i class="iconfont"></i>返回上一级
        </router-link>
      </div>
    </div>

    <div
      class="content-wrap veto-wrap hide clearfix"
      style="display: block;"
    >
      <div class="veto-desc"></div>
      <div
        class="list"
        id="veto-info"
      ></div>
      <div
        id="veto-list"
        class="list"
      >
        <table>
          <tbody>
            <tr class="l_header p10">
              <th colspan="10">评审专家否决性指标及结论</th>
            </tr>
            <tr class="">
              <th colspan="10">
                <div
                  class="veto-desc"
                  v-html="auditInfo ? auditInfo.vetoDesc : ''"
                ></div>
              </th>
            </tr>
            <tr class="l_header">
              <th width="100">序号</th>
              <th>否决项主要观测点</th>
              <th>形式审查情况</th>
              <th width="90">专家意见</th>
            </tr>
            <template v-if="fixVeto && fixVeto.length > 0">
              <tr
                v-for="(item, index) in fixVeto"
                :key="index"
              >
                <td>{{ index + 1 }}</td>
                <td
                  class="td-left"
                  :class="item.markNum === 1 ? 'markNum_1' : 'markNum_2'"
                >{{ item.title }}</td>
                <td>
                <!--         如果没有问题就没有查看详情按钮         -->
                  <a
                    v-if="item.desc"
                    href="javascript:void(0);"
                    @click="showVeto(item.desc)"
                  >查看详情</a>
                  <span v-else>-</span>
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="veto_item_check"
                    v-model="postData.veto"
                    :value="item.id"
                    :disabled="item.isChange === 1 && showButtons? false : true"
                    :checked="item.isVeto === 1 ? true : false"
                    @change="changeVeto"
                  >
                </td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td colspan="4" style="text-align: center">
                  --
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="veto-comment">
          <textarea
            :disabled="!showButtons"
            v-model="postData.vetoText"
            class="veto-comment-box"
            placeholder="如提出否决性意见，请说明存在的具体问题及出处"
            maxlength="1000"
          ></textarea>
          <div
            class="veto-word-tip"
            id="veto-word-tip"
          >还可以输入{{ 1000 - postData.vetoText.length }}个字</div>
        </div>
      </div>
    </div>

    <div class="content-wrap formal-wrap hide clearfix"></div>

    <div
      class="content-wrap norm-wrap clearfix"
      style="display: block;"
    >
      <div
        class="list"
        id="other-info"
      ></div>
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header p10">
              <th colspan="10">实质性审查指标及评分</th>
            </tr>
            <tr class="l_header">
              <th
                rowspan="3"
                width="12.5%"
              >一级指标</th>
              <th
                rowspan="3"
                width="12.5%"
              >二级指标</th>
              <th
                width=""
                rowspan="3"
              >观测点及描述</th>
              <th
                width="60"
                rowspan="3"
              >分值</th>
              <th
                colspan="5"
                width="180"
              >评价等级</th>
            </tr>
            <tr class="l_header">
              <th width="36">A</th>
              <th width="36">B</th>
              <th width="36">C</th>
              <th width="36">D</th>
              <th width="36">E</th>
            </tr>
            <tr class="l_header">
              <th>1.0</th>
              <th>0.75</th>
              <th>0.5</th>
              <th>0.25</th>
              <th>0</th>
            </tr>
            <template v-for="item in normTree">
              <tr :key="'' + item.id + '_1'">
                <td :rowspan="2 * item.children.length + 1">{{ item.title }}</td>
                <td
                  rowspan="2"
                  v-if="item.children.length > 0"
                >{{ item.children[0].title }}</td>
                <td
                  align="left"
                  v-if="item.children.length > 0"
                >
                  <pre>{{ item.children[0].desc }}</pre>
                </td>
                <td v-if="item.children.length > 0">{{ item.children[0].score }}分</td>
                <td v-if="item.children.length > 0"><input
                    v-model="item.children[0].score_level"
                    type="radio"
                    class="score"
                    value="1"
                    :disabled="pageDisable"
                  ></td>
                <td v-if="item.children.length > 0"><input
                    v-model="item.children[0].score_level"
                    type="radio"
                    class="score"
                    value="0.75"
                    :disabled="pageDisable"
                  ></td>
                <td v-if="item.children.length > 0"><input
                    v-model="item.children[0].score_level"
                    type="radio"
                    class="score"
                    value="0.5"
                    :disabled="pageDisable"
                  ></td>
                <td v-if="item.children.length > 0"><input
                    v-model="item.children[0].score_level"
                    type="radio"
                    class="score"
                    value="0.25"
                    :disabled="pageDisable"
                  ></td>
                <td v-if="item.children.length > 0"><input
                    v-model="item.children[0].score_level"
                    type="radio"
                    class="score"
                    value="0"
                    :disabled="pageDisable"
                  ></td>
              </tr>
              <tr
                :key="'' + item.id + '_2'"
                v-if="item.children.length > 0"
              >
                <td colspan="7">
                  <textarea
                    class="item-comment sub-item"
                    :class="{plstyle:auditInfo.isPriEv === 1}"
                    rows="2"
                    data-level="2"
                    v-model="item.children[0].appraiseText"
                    :placeholder="'请填写本二级指标专家评语' + (auditInfo.isSubEv == 1 ? '，必填' : '，非必填')"
                    :disabled="pageDisable"
                    maxlength="100"
                  >
                </textarea>
                  <div class="subev-tip">还可以输入{{ 100 - item.children[0].appraiseText.length }}个字</div>
                </td>
              </tr>
              <template v-for="(child, index) in item.children">
                <tr
                  :key="'' + item.id + '_' + child.id + '_1'"
                  v-if="index != 0"
                >
                  <td rowspan="2">{{ child.title }}</td>
                  <td align="left">
                    <pre>{{ child.desc }}</pre>
                  </td>
                  <td>{{ child.score }}分</td>
                  <td><input
                      type="radio"
                      class="score"
                      v-model="child.score_level"
                      value="1"
                      :disabled="pageDisable"
                    ></td>
                  <td><input
                      type="radio"
                      class="score"
                      v-model="child.score_level"
                      value="0.75"
                      :disabled="pageDisable"
                    ></td>
                  <td><input
                      type="radio"
                      class="score"
                      v-model="child.score_level"
                      value="0.5"
                      :disabled="pageDisable"
                    ></td>
                  <td><input
                      type="radio"
                      class="score"
                      v-model="child.score_level"
                      value="0.25"
                      :disabled="pageDisable"
                    ></td>
                  <td><input
                      type="radio"
                      class="score"
                      v-model="child.score_level"
                      value="0"
                      :disabled="pageDisable"
                    ></td>
                </tr>
                <tr
                  :key="'' + item.id + '_' + child.id + '_2'"
                  v-if="index != 0"
                >
                  <td colspan="7">
                    <textarea
                      class="item-comment sub-item"
                      :class="{plstyle:auditInfo.isPriEv === 1}"
                      rows="2"
                      data-level="2"
                      v-model="child.appraiseText"
                      :placeholder="'请填写本二级指标专家评语' + (auditInfo.isSubEv == 1 ? '，必填' : '，非必填')"
                      :disabled="pageDisable"
                      maxlength="100"
                    >
                  </textarea>
                    <div class="subev-tip">还可以输入{{ 100 - child.appraiseText.length }}个字</div>
                  </td>
                </tr>
              </template>
              <tr :key="'' + item.id + '_3'">
                <td colspan="8">
                  <textarea
                    class="item-comment pri-item"
                    :class="{plstyle:auditInfo.isPriEv === 1}"
                    rows="2"
                    v-model="item.appraiseText"
                    :placeholder="'请填写本一级指标专家评语，评语不可超过100字' + (auditInfo.isPriEv == 1 ? '，必填' : '，非必填')"
                    :disabled="pageDisable"
                    maxlength="100"
                  >
                </textarea>
                  <div class="subev-tip">还可以输入{{ 100 - item.appraiseText.length }}个字</div>
                </td>
              </tr>
            </template>
            <tr class="p10">
              <td
                colspan="13"
                id="total"
              >合计得分：{{ computeScore }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="comment-box"
        style="display: block;"
      >
        <span class="comment_title">专家评价意见</span>
        <textarea
          id="comment"
          rows="5"
          placeholder="请对课程进行简要评价"
          v-model="postData.appraiseText"
          :disabled="pageDisable"
          maxlength="200"
        >
        </textarea>
        <div id="comment-tip">还可以输入{{ 200 - postData.appraiseText.length }}个字</div>
      </div>
      <div
        class="sub-wrap hide"
        style="display: block;"
        v-if="showButtons"
      >
        <span
          class="ret"
          @click="$router.push('/admin/my_audit_group?audit_id=' + audit_id + '&group_id=' + group_id)"
        >取消</span>
        <span
          class="sub"
          id="save"
          @click="saveScore(1)"
        >保存</span>
        <span
          class="sub tj"
          id="submit"
          @click="saveScore(2)"
        >立即提交</span>
      </div>
    </div>

    <div
      class="form_overlay"
      id="form_overlay"
      :style="showVetoWindow ? 'visibility: visible' : ''"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">否决性指标详情</div>
        <a
          @click="showVetoWindow = false"
          href="javascript:void(0)"
          class="overlay-close"
        ><i class="iconfont"></i></a>
        <div class="form_content">
          <div
            class="f_list"
            id="auditList"
            v-html="showVetoHtml"
          ></div>
        </div>
        <div
          class="form_control"
          style="padding-left:20px"
        >
          <a
            href="javascript:void(0)"
            @click="showVetoWindow = false"
            class="button hide close"
          >取消</a>
          <a
            href="javascript:void(0)"
            @click="showVetoWindow = false"
            class="button"
            id="form_submit"
          >确定</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUrlParams, openPage } from '@/util/utils'

export default {
  name: 'MyAuditScore',
  data () {
    return {
      showVetoWindow: false,
      showVetoHtml: '',
      audit_id: parseInt(this.$route.query.audit_id),
      group_id: parseInt(this.$route.query.group_id),
      item_id: parseInt(this.$route.query.item_id),
      auditInfo: null,
      groupInfo: null,
      itemInfo: null,
      vetoData: [],
      itemVetoData: [],
      fixVeto: [],
      normData: [],
      normTree: [],
      postData: {
        veto: [],
        vetoText: '',
        appraiseText: ''
      },
      showButtons: false
    }
  },
  computed: {
    pageDisable () {
      // 除了否决性指标被勾选不能编辑 另外如果已经提交审核了也不能够编辑
      return this.postData.veto.length > 0 || !this.showButtons
    },
    computeScore () {
      let score = 0
      for (let i = 0; i < this.normTree.length; i++) {
        for (let j = 0; j < this.normTree[i].children.length; j++) {
          if (this.normTree[i].children[j].score_level) {
            score += this.normTree[i].children[j].score * this.normTree[i].children[j].score_level
          }
        }
      }
      return score
    },
    notificationSystem () {
      return this.$store.state.notificationSystem
    }
  },
  methods: {
    async jumpToIlabx (item) {
      // target="_blank"
      // 获取token
      console.log(item)
      await this.$http.post('/xjwt/', {}).then((res) => {
        console.log(res)
        if (res.token) {
          // 获取token成功 拼接url进行跳转
          const url = item.projUrl
          const ps = getUrlParams(url)
          const data = []
          for (const k in ps) {
            data.push({
              paramName: k,
              paramValue: ps[k]
            })
          }
          data.push({
            paramName: 'token',
            paramValue: res.token
          })
          debugger
          openPage(url.split('?')[0], 'GET', data, '_blank')
        } else {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        }
      })
    },
    async getAudit () {
      // 优化使用返回快的接口，防止页面加载慢
      await this.$http.get('/audit_search/' + this.audit_id + '/').then((data) => {
        this.auditInfo = data
      })
    },
    async getGroup () {
      await this.$http.get('/auditgroup/' + this.group_id + '/').then((data) => {
        this.groupInfo = data
      })
    },
    async getItem () {
      // 优化使用返回快的接口，防止页面加载慢
      await this.$http.get('/audititem_search/' + this.item_id + '/').then((data) => {
        this.itemInfo = data
      })
    },
    async getVeto () {
      // 首先获取的是评审项目的整体否决指标
      await this.$http.get('/normveto/', {
        params: {
          page: 1,
          size: 10000,
          audit_id: this.audit_id
        }
      }).then((data) => {
        this.vetoData = data.results
      })
      // 接下来获取的是该作品导入的否决性指标
      await this.$http.get('/audititem_veto/', {
        params: {
          page: 1,
          size: 10000,
          auditItem_id: this.item_id
        }
      }).then((data) => {
        this.itemVetoData = data.results
      })

      const fix = JSON.parse(JSON.stringify(this.vetoData))
      for (let i = 0; i < fix.length; i++) {
        for (let j = 0; j < this.itemVetoData.length; j++) {
          if (fix[i].id === this.itemVetoData[j].normVeto) {
            fix[i].isVeto = this.itemVetoData[j].isVeto
            fix[i].markNum = this.itemVetoData[j].markNum
            fix[i].desc = this.itemVetoData[j].desc
          }
        }
      }
      this.fixVeto = fix
    },
    async getNorm () {
      await this.$http.get('/norm/', {
        params: {
          page: 1,
          size: 10000,
          audit_id: this.audit_id
        }
      }).then((data) => {
        this.normData = data.results
      })

      const tempNorm = JSON.parse(JSON.stringify(this.normData))
      const nt = []
      for (let i = 0; i < tempNorm.length; i++) {
        if (tempNorm[i].level === 1) {
          tempNorm[i].children = []
          tempNorm[i].appraiseText = ''
          nt.push(tempNorm[i])
        }
      }
      for (let i = 0; i < tempNorm.length; i++) {
        if (tempNorm[i].level === 2) {
          for (let j = 0; j < nt.length; j++) {
            if (nt[j].id === tempNorm[i].parent) {
              tempNorm[i].score_level = null
              tempNorm[i].appraiseText = ''
              nt[j].children.push(tempNorm[i])
            }
          }
        }
      }
      this.normTree = nt
    },
    async getScore () {
      await this.$http.get('/audit_score/', {
        params: {
          page: 1,
          size: 10000,
          item_id: this.item_id,
          group_id: this.group_id
        }
      }).then((data) => {
        if (data.code === '200') {
          this.postData.veto = data.data.veto
          this.postData.vetoText = data.data.vetoText ? data.data.vetoText : ''
          this.postData.appraiseText = data.data.appraiseText ? data.data.appraiseText : ''
          for (let i = 0; i < this.normTree.length; i++) {
            for (let j = 0; j < data.data.normData.length; j++) {
              if (this.normTree[i].id === data.data.normData[j].norm_id) {
                this.normTree[i].appraiseText = data.data.normData[j].appraiseText
              }
            }
            for (let j = 0; j < this.normTree[i].children.length; j++) {
              this.normTree[i].children[j].score_level = null
              this.normTree[i].children[j].appraiseText = ''
              for (let k = 0; k < data.data.normData.length; k++) {
                if (this.normTree[i].children[j].id === data.data.normData[k].norm_id) {
                  this.normTree[i].children[j].score_level = data.data.normData[k].score_level
                  this.normTree[i].children[j].appraiseText = data.data.normData[k].appraiseText
                }
              }
            }
          }
          if (data.data.status !== 2) {
            this.showButtons = true
          }
        } else {
          this.showButtons = true
        }
      })
    },
    showVeto (text) {
      this.showVetoHtml = text
      this.showVetoWindow = true
    },
    changeVeto () {
      if (this.postData.veto.length > 0) {
        for (let i = 0; i < this.normTree.length; i++) {
          this.normTree[i].appraiseText = ''
          for (let j = 0; j < this.normTree[i].children.length; j++) {
            this.normTree[i].children[j].score_level = null
            this.normTree[i].children[j].appraiseText = ''
          }
        }
        this.postData.appraiseText = ''
      }
    },
    async saveScore (status) {
      const normData = []
      for (let i = 0; i < this.normTree.length; i++) {
        normData.push({
          norm_id: this.normTree[i].id,
          score_level: null,
          appraiseText: this.normTree[i].appraiseText
        })

        if (this.auditInfo.isPriEv === 1 && this.postData.veto.length === 0 && this.normTree[i].appraiseText.trim().length === 0 && status === 2) {
          this.$toast.error('请填写一级指标专家评价意见！', '', this.notificationSystem.options.error)
          return false
        }

        if (this.normTree[i].children && this.normTree[i].children.length > 0) {
          for (let j = 0; j < this.normTree[i].children.length; j++) {
            normData.push({
              norm_id: this.normTree[i].children[j].id,
              score_level: this.normTree[i].children[j].score_level,
              appraiseText: this.normTree[i].children[j].appraiseText
            })
            console.log(this.normTree[i].children[j].score_level)
            if (this.postData.veto.length === 0 && !this.normTree[i].children[j].score_level && status === 2) {
              this.$toast.error('请完成所有实质性审查指标及评分！', '', this.notificationSystem.options.error)
              return false
            }

            if (this.auditInfo.isSubEv === 1 && this.postData.veto.length === 0 && this.normTree[i].children[j].appraiseText.trim().length === 0 && status === 2) {
              this.$toast.error('请填写二级指标专家评价意见！', '', this.notificationSystem.options.error)
              return false
            }
          }
        }
      }
      const reqData = {
        audit_id: this.audit_id,
        group_id: this.group_id,
        item_id: this.item_id,
        score: this.computeScore,
        veto: this.postData.veto,
        vetoText: this.postData.vetoText,
        appraiseText: this.postData.appraiseText,
        normData: normData,
        status: status
      }

      if (reqData.veto.length > 0 && reqData.vetoText.trim().length === 0 && status === 2) {
        this.$toast.error('如提出否决性意见，请说明存在的具体问题及出处！', '', this.notificationSystem.options.error)
        return false
      }

      if (this.postData.veto.length === 0 && reqData.appraiseText.trim().length === 0 && status === 2) {
        this.$toast.error('请填写专家评价意见！', '', this.notificationSystem.options.error)
        return false
      }

      await this.$http.post('/audit_score/', {
        data: JSON.stringify(reqData)
      }).then((data) => {
        const saveType = status === 1 ? '保存' : '提交'
        if (data.code === '200') {
          this.$toast.success(saveType + '成功', '', this.notificationSystem.options.success)
          if (status === 2) {
            this.$router.push('/admin/my_audit_group?audit_id=' + this.audit_id + '&group_id=' + this.group_id)
          }
        } else {
          this.$toast.error(saveType + '失败', '', this.notificationSystem.options.error)
        }
      })
    }
  },
  async created () {
    await this.getAudit()
    // 优化不需要查询组信息 没有使用的地方
    // await this.getGroup()
    await this.getItem()
    await this.getVeto()
    await this.getNorm()
    await this.getScore()
  }
}
</script>

<style scoped src="../../assets/css/result.css"></style>
<style scoped>
.plstyle::placeholder {
  color: #FF8080;
}
</style>
