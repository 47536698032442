<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="5"
        :audit_status="audit_status"
      ></audit-tab>
    </div>
    <div
      class="audit_content seendtime audit_group clearfix"
      style="display: block;"
    >
      <div class="group_head">
        <h3>{{auditgroupInfo.title}}</h3>

        <span>目前本组有专家<b>{{auditgroupInfo.expert_count}}</b>名、参评作品有<b>{{auditgroupInfo.item_count}}</b>个。</span>
        <router-link :to="'/admin/audit/maintain/tab6?id=' + param_id">返回分组列表</router-link>
      </div>
      <div class="group_title">
        <span class="item">会评专家</span>
        <span
          class="add-btn add-expert sprite_4"
          @click="getGroupLeaderInfo"
        >添加会评专家</span>
      </div>
      <div
        class="search-box"
        id="expert-search"
      >
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm"
            v-if="isOpen"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="username"
                placeholder="专家账号"
                v-model="expertQueryInfo.username"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="name"
                placeholder="专家姓名"
                v-model="expertQueryInfo.name"
              ></div>
            <div class="form-group"><button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          @click="isOpen=!isOpen"
        >{{isOpen?'关闭':'展开'}}</span>
      </div>
      <div
        class="list f_list"
        id="expert_list"
      >
        <table>
          <tbody>
            <tr class="l_header">
              <th>专家账号</th>
              <th>专家手机号</th>
              <th>专家姓名</th>
              <th>性别</th>
              <th>所属学校</th>
              <th>一级学科</th>
              <th>二级学科</th>
              <th width="160">操作</th>
            </tr>
            <tr
              class="item"
              v-for="item in expertData"
              :key="item.id"
            >
              <td class="itemTitle">{{item.expert?item.expert.username:'-'}}</td>
              <td class="itemTitle">{{item.expert?item.expert.mobile:'-'}}</td>
              <td class="itemTitle">{{item.expert?item.expert.name:'-'}}</td>
              <td class="itemTitle">{{item.expert?item.expert.gender:'-'}}</td>
              <td class="itemTitle">{{item.expert?item.expert.school.title:'-'}}</td>
              <td class="itemTitle">{{item.expert.subject01?item.expert.subject01.title:'-'}}</td>
              <td class="itemTitle">{{item.expert.subject02?item.expert.subject02.title:'-'}}</td>
              <td class=""><a
                  class="btn delete"
                  href="javascript:void(0)"
                  @click="delExperItem(item.id)"
                >移除</a></td>
            </tr>
          </tbody>

        </table>
        <div
          class="noitems"
          v-if="!expertData.length"
        >没有数据</div>
        <!-- 分页 -->
        <pagination
          v-if="expertData.length"
          :cur='pageInfo.page'
          :all='pageInfo.all'
          :totalData="pageInfo.totalData"
          :callback="pageCallback"
        ></pagination>
      </div>

      <div class="group_title">
        <span class="item">参评作品</span>
        <span
          class="add-btn add-works sprite_4"
          @click="showWorksWindow"
        >添加会评作品</span>
        <!-- <span
          class="import-btn import-works sprite_5"
          @click="showUploadWindow"
        >导入作品补充信息</span> -->
      </div>
      <div
        class="search-box"
        id="work-search"
      >
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm2"
            v-if="isShow"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="projId"
                placeholder="作品编码"
                v-model="queryJoinWorksInfo.projId"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="title"
                placeholder="项目名称"
                value=""
                v-model="queryJoinWorksInfo.title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="school"
                placeholder="学校名称"
                v-model="queryJoinWorksInfo.school_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="specialtySubject"
                placeholder="所属专业大类"
                v-model="queryJoinWorksInfo.category01"
              ></div>
            <div class="form-group"><button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageJoinWorksCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          data-reset=""
          @click="isShow=!isShow"
        >{{isShow?'关闭':'展开'}}</span>
      </div>
      <div
        class="list f_list"
        id="works_list"
      >
        <table>
          <tbody>
            <tr class="l_header">
              <th>作品名称</th>
              <th>学校</th>
              <th>负责人</th>
              <th>所属专业类</th>
              <th width="120">操作</th>
            </tr>
            <tr
              v-for="item in joinWorksTableData"
              :key="item.id"
            >
              <td><a
                  href="javascript:void(0)"
                  class="a-link"
                ><span>{{item.title}}</span></a></td>
              <td>{{item.school?item.school.title:'-'}}</td>
              <td>{{item.incharge}}</td>
              <td>{{item.category01}}</td>
              <td class="action"><a
                  class="btn delete"
                  href="javascript:void(0)"
                  @click="delItem(item.id)"
                >移除</a></td>
            </tr>

          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!joinWorksTableData.length"
        >没有数据</div>
        <!-- 分页 -->
        <pagination
          v-if="joinWorksTableData.length"
          :cur='pageJoinWorksInfo.page'
          :all='pageJoinWorksInfo.all'
          :totalData="pageJoinWorksInfo.totalData"
          :callback="pageJoinWorksCallback"
        ></pagination>
      </div>

    </div>
    <!-- 选择组长弹窗 -->
    <div
      id="c-panel"
      :style="classShowGroupleaderWindow"
    >
      <div id="c-panel-bg"></div>
      <div class="c-content">
        <form
          id="search"
          class="search"
          onsubmit="return false;"
        >
          <select
            name="subject01"
            class="s-text searchSelect short"
            id="subject01"
            v-model="queryGroupLeaderInfo.subject01_id"
            @change="getSsecondLevelInfo(queryGroupLeaderInfo.subject01_id)"
          >
            <option value="">一级学科</option>
            <option
              v-for="item in categoryData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <select
            name="subject02"
            class="s-text searchSelect short"
            id="subject02"
            v-model="queryGroupLeaderInfo.subject02_id"
          >
            <option value="">二级学科</option>
            <option
              v-for="item in secondLevelData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <input
            type="text"
            name="name"
            class="s-text short"
            placeholder="姓名"
            v-model="queryGroupLeaderInfo.name"
          >
          <input
            type="text"
            name="referral"
            class="s-text short"
            placeholder="推荐来源"
            v-model="queryGroupLeaderInfo.source"
          >
          <input
            type="button"
            class="s-btn1"
            value="查询"
            @click="pageGroupLeaderCallback(1)"
          >
          <span
            class="close-cpanel"
            @click="hideGroupLeaderWindow"
          ><i class="iconfont"></i>

          </span>
        </form>
        <div id="uList">
          <table>
            <tbody>
              <tr class="l_header">
                <th class="itemTitle">用户名</th>
                <th class="itemTitle">手机号</th>
                <th class="itemCategory">姓名</th>
                <th class="itemCategory">性别</th>
                <th class="itemCategory">学校</th>
                <th class="itemCategory">一级学科</th>
                <th class="itemCategory">二级学科</th>
                <th class="itemCategory">推荐来源</th>
                <th class="action">操作</th>
              </tr>
              <tr
                v-for="item in groupLeaderData"
                :key="item.id"
              >
                <td>{{item.username}}</td>
                <td>{{item.mobile?item.mobile:'-'}}</td>
                <td>{{item.name}}</td>
                <td>{{formatGender(item.gender)}}</td>
                <td>{{item.school?item.school.title:'-'}}</td>
                <td>{{item.subject01?item.subject01.title:'-'}}</td>
                <td>{{item.subject02?item.subject02.title:'-'}}</td>
                <td>{{item.source}}</td>

                <td v-if="item.isSelect">-</td>
                <td v-else><a
                    href="javascript:void(0)"
                    @click="selectItem(item)"
                  >选择</a></td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!groupLeaderData.length"
          >没有数据</div>
        </div>
        <!-- 分页 -->
        <pagination
          v-if="groupLeaderData.length"
          :cur='pageGroupLeaderInfo.page'
          :all='pageGroupLeaderInfo.all'
          :totalData="pageGroupLeaderInfo.totalData"
          :callback="pageGroupLeaderCallback"
        ></pagination>
      </div>
    </div>
    <!-- 添加作品弹窗 -->
    <div
      id="w-panel"
      :style="classShowWorksWindow"
    >
      <div id="w-panel-bg"></div>
      <div
        class="c-content"
        style="width:900px;"
      >
        <form
          id="wSearch"
          onsubmit="return false;"
          style="width:900px;"
        >
          <input
            type="text"
            name="specialtySubject"
            class="s-text short"
            placeholder="所属专业"
            v-model="queryWorksInfo.category01"
          >
          <input
            type="text"
            name="name"
            class="s-text"
            placeholder="作品名称"
            v-model="queryWorksInfo.title"
          >
          <input
            type="text"
            name="remarks"
            class="s-text short"
            placeholder="备注"
            v-model="queryWorksInfo.remarks"
          >
          <input
            type="button"
            class="s-btn1"
            value="查询"
            @click="pageWorksCallback(1)"
          >
          <span class="close-cpanel"><i
              class="iconfont"
              @click="hideWorksWindow"
            ></i></span>
        </form>
        <div id="wList">
          <table>
            <tbody>
              <tr class="l_header">
                <th
                  width="30"
                  style="texta-align:center"
                ><a
                    href="javascript:;"
                    class="selectAllPro"
                    @click="ckAll"
                  >全选</a></th>
                <th>作品名称</th>
                <th>隶属学校</th>
                <th>负责人</th>
                <th>所属专业类</th>
                <th>备注</th>
                <th width="60">操作</th>
              </tr>
              <tr
                v-for="item in worksTableData"
                :key="item.id"
              >
                <td><input
                    type="checkbox"
                    name="chose"
                    :value="item.id"
                    v-model="ckUser"
                  ></td>
                <td><span title="测试">{{item.title}}</span></td>
                <td>{{item.school?item.school.title:'-'}}</td>
                <td>{{item.incharge}}</td>
                <td>{{item.category01}}</td>
                <td>{{item.remarks}}</td>
                <td><a
                    class="a-button"
                    href="javascript:void(0)"
                    @click="changeGroup(item.id)"
                  >选择</a></td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!worksTableData.length"
          >没有数据</div>
        </div>
        <input
          type="button"
          class="add-btn"
          id="batchSelectPro"
          value="批量选择"
          @click="batchSelection"
        >
        <!-- 分页 -->
        <pagination
          v-if="worksTableData.length"
          :cur='pageWorksInfo.page'
          :all='pageWorksInfo.all'
          :totalData="pageWorksInfo.totalData"
          :callback="pageWorksCallback"
        ></pagination>
      </div>
    </div>
    <!-- 上传弹窗 -->
    <div
      class="form_overlay"
      id="form_upload_overlay"
      :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">导入作品补充信息</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideUploadWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label"><i class="require">*</i>模板创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：projId（作品编码）、seq（排序）、mshow（是否显示：1-显示，2-不显示）、mexplain（说明，100字以内）、平均分；</p>
                  <p>3. 其中所有选项均为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_meeting_iteminfo_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>

            <div class="item">
              <span class="label"><i class="require">*</i>导入文件:</span>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="fileUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="fileUpload"
                    ref="xlsFile"
                    accept=".csv,.xls,.xlsx"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideUploadWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab3Maintain',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      tableData: [],
      tableDataCount: 0,
      groupLeaderData: [],
      groupLeaderDataCount: 0,
      expertData: [],
      expertDataCount: 0,
      worksTableData: [],
      worksTableDataCount: 0,
      joinWorksTableData: [],
      joinWorksTableDataCount: 0,
      expertQueryInfo: {
        page: 1,
        size: 10,
        username: '',
        name: '',
        auditMeetingGroup_id: this.$route.query.groupMeetingId
      },
      queryGroupLeaderInfo: {
        page: 1,
        size: 10,
        subject01_id: '',
        subject02_id: '',
        name: '',
        source: '',
        role: 1
      },
      queryWorksInfo: {
        page: 1,
        size: 10,
        category01: '',
        title: '',
        remarks: '',
        auditMeetingGroup_id: '',
        audit_id: this.param_id
      },
      queryJoinWorksInfo: {
        page: 1,
        size: 10,
        auditMeetingGroup_id: this.$route.query.groupMeetingId,
        projId: '',
        title: '',
        school_title: '',
        category01: ''
      },
      classShowGroupleaderWindow: {},
      classShowWorksWindow: {},
      ckUser: [],
      isOpen: false,
      isShow: false,
      auditgroupInfo: {},
      categoryData: [],
      secondLevelData: [],
      classShowProcessWindow: {},
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: []
    }
  },
  methods: {
    // 全选
    ckAll () {
      if (this.ckUser.length === this.worksTableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.worksTableData.length; i++) {
          this.ckUser.push(this.worksTableData[i].id)
        }
      }
    },
    // 批量选择
    async  batchSelection () {
      if (this.ckUser.length === 0) {
        this.$toast.error('请先选择记录！', '', this.error_tips)
      } else {
        for (let i = 0; i < this.worksTableData.length; i++) {
          if (this.ckUser.indexOf(this.worksTableData[i].id) >= 0) {
            await this.changeGroup(this.worksTableData[i].id)
          }
        }
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
        this.hideWorksWindow()
        this.ckUser = []
      }
    },
    formatGender (val) {
      if (val !== '') {
        return val === '1' ? '男' : '女'
      } else {
        return '-'
      }
    },
    async getAuditgroupInfo () {
      const url = '/auditmetinggroup/' + this.$route.query.groupMeetingId + '/'
      await this.$http.get(url).then((data) => {
        this.auditgroupInfo = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async selectItem (item) {
      item.isSelect = true
      await this.$http.post('/auditmetinggroup_expert/', {
        expert_id: item.id,
        auditMeetingGroup_id: this.$route.query.groupMeetingId
      }).then((data) => {
        this.search()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async changeGroup (id) {
      const url = '/audititem/' + id + '/'

      await this.$http.put(url, {
        auditMeetingGroup_id: this.$route.query.groupMeetingId
      }).then((data) => {
        this.getWorksInfo()
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取一级专业
    async getCategoryInfo () {
      const params = {
        page: 1,
        size: 10000,
        level: 1
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取二级专业
    async getSsecondLevelInfo (id) {
      const params = {
        page: 1,
        size: 10000,
        level: 2,
        parent_id: id
      }
      this.secondLevelData = []
      this.queryGroupLeaderInfo.subject02_id = ''
      if (id) {
        await this.$http.get('/subject/', { params }).then((data) => {
          this.secondLevelData = data.results
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        this.secondLevelData = []
      }
    },
    async search () {
      await this.$http.get('/auditmetinggroup_expert/', {
        params: this.expertQueryInfo
      }).then((data) => {
        this.expertData = data.results
        this.expertDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getGroupLeaderInfo () {
      await this.$http.get('/userprofile/', {
        params: this.queryGroupLeaderInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].isSelect = false
          for (let j = 0; j < this.expertData.length; j++) {
            if (this.expertData[j].expert_id === data.results[i].id) {
              data.results[i].isSelect = true
            }
          }
        }
        this.groupLeaderData = data.results
        this.groupLeaderDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
      this.showGroupLeaderWindow()
    },
    async getWorksInfo () {
      await this.$http.get('/audititem/', {
        params: this.queryWorksInfo
      }).then((data) => {
        this.worksTableData = data.results
        this.worksTableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getJoinGroupWorks () {
      await this.$http.get('/audititem/', {
        params: this.queryJoinWorksInfo
      }).then((data) => {
        this.joinWorksTableData = data.results
        this.joinWorksTableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    delExperItem (id) {
      const url = '/auditmetinggroup_expert/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        this.search()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    delItem (id) {
      const url = '/audititem/' + id + '/'
      this.$http.put(url, {
        auditMeetingGroup_id: null
      }).then((data) => {
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 打开分组管理弹窗
    showGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {
        display: 'block'
      }
    },
    // 关闭分组管理弹窗
    hideGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {}
    },
    // 打开添加作品弹窗
    showWorksWindow () {
      this.classShowWorksWindow = {
        display: 'block'
      }
      this.queryWorksInfo = {
        page: 1,
        size: 10,
        category01: '',
        title: '',
        remarks: '',
        auditMeetingGroup_id: '',
        audit_id: this.param_id
      }
      this.getWorksInfo()
    },
    // 关闭添加作品弹窗
    hideWorksWindow () {
      this.classShowWorksWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.expertQueryInfo.page = data
      await this.search()
    },
    // 分页
    async pageJoinWorksCallback (data) {
      this.queryJoinWorksInfo.page = data
      await this.getJoinGroupWorks()
    },
    // 分页
    async pageWorksCallback (data) {
      this.queryWorksInfo.page = data
      await this.getWorksInfo()
    },
    // 分页
    async pageGroupLeaderCallback (data) {
      this.queryGroupLeaderInfo.page = data
      await this.getGroupLeaderInfo()
    },
    // 打开上传弹窗
    showUploadWindow () {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传弹窗
    hideUploadWindow () {
      this.classShowUploadWindow = {}
      this.uploadedCsv = ''
      this.uploadedCsvName = ''
    },
    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$toast.error('上传失败！', '', this.error_tips)
      })
    },
    async submitCsv () {
      const uploadUrl = '/get_xls/'
      await this.$http.get(uploadUrl, {
        params: {
          file_name: this.uploadedCsv
        }
      }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }
        data.splice(0, 1)
        this.uploadedCscData = data
        this.hideUploadWindow()
        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }
      const row = this.uploadedCscData[this.curCsvIndex]
      // region 数据检查开始
      const uploadForm = {
        projId: row[0],
        m_seq: row[1],
        m_show: row[2],
        m_explain: row[3],
        m_avg: row[4]
      }
      if (String(row[0]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入作品编码！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      let itemId = ''
      await this.$http.get('/audititem/', {
        params: {
          page: 1,
          size: 100000,
          eq_projId: row[0],
          audit_id: this.param_id
        }
      }).then((data) => {
        console.log(data)
        if (data.results.length) {
          itemId = data.results[0].id
        } else {
          itemId = ''
        }
      })
      if (!itemId) {
        this.errorList.push({ code: 0, text: '作品编码不存在！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[1]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '排序不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      console.log(parseInt(row[1]))
      if (isNaN(Number(row[1]))) {
        this.errorList.push({ code: 0, text: '排序的值必须为数值！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (parseInt(row[1]) < 1) {
        this.errorList.push({ code: 0, text: '排序的值大于0！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[2]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '是否显示不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (Number(row[2]) === 2) {
        uploadForm.mshow = 0
      }
      if (String(row[3]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '说明不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[3]).trim().length > 100) {
        this.errorList.push({ code: 0, text: '说明长度不能超过100个字符！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[4]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '平均分不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (isNaN(Number(row[4]))) {
        this.errorList.push({ code: 0, text: '平均分必须为数值！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      } else {
        uploadForm.m_avg = row[4].toFixed(2)
      }

      console.log(uploadForm)
      const url = '/audititem/' + itemId + '/'
      await this.$http.put(url, uploadForm).then((profileData) => {
        this.errorList.push({ code: 1, text: '[' + uploadForm.projId + ']' + '导入成功！' })
        this.curCsvIndex++
        this.insertOne()
      }).catch((error) => {
        console.log(error)
        this.errorList.push({ code: 0, text: '[' + uploadForm.projId + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    }
  },
  mounted () {
    this.getAuditgroupInfo()
    this.search()
    this.getJoinGroupWorks()
    this.getCategoryInfo()
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.expertQueryInfo.page,
        all: Math.ceil(this.expertDataCount / this.expertQueryInfo.size),
        totalData: this.expertDataCount
      }
    },
    pageJoinWorksInfo () {
      return {
        page: this.queryJoinWorksInfo.page,
        all: Math.ceil(this.joinWorksTableDataCount / this.queryJoinWorksInfo.size),
        totalData: this.joinWorksTableDataCount
      }
    },
    pageWorksInfo () {
      return {
        page: this.queryWorksInfo.page,
        all: Math.ceil(this.worksTableDataCount / this.queryWorksInfo.size),
        totalData: this.worksTableDataCount
      }
    },
    pageGroupLeaderInfo () {
      return {
        page: this.queryGroupLeaderInfo.page,
        all: Math.ceil(this.groupLeaderDataCount / this.queryGroupLeaderInfo.size),
        totalData: this.groupLeaderDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.list {
  overflow: hidden;
}
#wList table {
  table-layout: fixed;
}
#wList td {
  padding: 5px;
  line-height: normal;
  vertical-align: middle;
}
.audit .attachment,
.audit .handbook {
  padding-bottom: 0;
  border-top: none;
}
</style>
