<template>
  <div class="form_content clearfix">
    <div
      class="list"
      id="auditList"
    >
      <table style="width:98%">
        <tbody>
          <tr class="l_header">
            <th width="100">专家名称</th>
            <th width="12.5%">一级指标</th>
            <th width="12.5%">二级指标</th>
            <th width="">观测点及描述</th>
            <th width="60">分数</th>
            <th width="60">专家评分</th>
          </tr>
          <template v-for="(expert, index) in detailInfo">
            <tr :key="'' + expert.id + '_' + index + '_1'">
              <td :rowspan="expert.norms ? sum(expert.norms.map(n => n.children.length)) * 2 + expert.norms.length + 1 : 3">
                {{ expert.name }}
                <br>
                （{{ expert.computed_score }}分）
                <br>
                <a
                  href="javascript:void(0)"
                  class="btn delete"
                  @click="clearScore(expert.score_id)"
                >清除成绩</a>
              </td>
            </tr>
            <template v-for="(norm, index) in expert.norms">
              <tr :key="'' + expert.id + '_' + norm.id + '_' + index + '_2'">
                <td :rowspan="norm.children.length * 2 + 1">{{ norm.title }}<br>{{ norm.computed_score }}分</td>
                <td
                  align="left"
                  rowspan="2"
                >{{ norm.children[0].title }}</td>
                <td align="left">
                  <pre>{{ norm.children[0].desc }}</pre>
                </td>
                <td align="left">{{ norm.children[0].score }}分</td>
                <td>{{ norm.children[0].computed_score }}分</td>
              </tr>
              <tr :key="'' + expert.id + '_' + norm.id + '_' + index + '_3'">
                <td
                  colspan="3"
                  align="left"
                >{{ norm.children[0].appraiseText }}</td>
              </tr>
              <template v-for="(children, index) in norm.children">
                <tr
                  :key="'' + expert.id + '_' + norm.id + '_' + children.id + '_' + index + '_4'"
                  v-if="index != 0"
                >
                  <td
                    align="left"
                    rowspan="2"
                  >{{ children.title }}</td>
                  <td align="left">
                    <pre>{{ children.desc }}</pre>
                  </td>
                  <td align="left">{{ children.score }}分</td>
                  <td>{{ children.computed_score }}分</td>
                </tr>
                <tr
                  :key="'' + expert.id + '_' + norm.id + '_' + children.id + '_' + index + '_5'"
                  v-if="index != 0"
                >
                  <td
                    colspan="3"
                    align="left"
                  >{{ children.appraiseText }}</td>
                </tr>
              </template>
              <tr :key="'' + expert.id + '_' + index + '_6'">
                <td
                  colspan="5"
                  align="left"
                >{{ norm.appraiseText }}</td>
              </tr>
            </template>
            <tr :key="'' + expert.id + '_' + index + '_7'">
              <td>评语</td>
              <td
                colspan="5"
                align="left"
              >{{ expert.appraiseText }}</td>
            </tr>
          </template>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!detailInfo.length"
      >没有用户</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuditNormInfo',
  props: ['item_id'],
  data () {
    return {
      detailInfo: []
    }
  },
  computed: {
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  watch: {
    item_id (val, oldVal) {
      this.getData()
    }
  },
  methods: {
    async getData () {
      if (!this.item_id) {
        return
      }
      await this.$http.get('/norm_detail/', {
        params: {
          item_id: this.item_id
        }
      }).then((data) => {
        console.log(data)
        this.detailInfo = data.data
      })
    },
    sum (arr) {
      const len = arr.length
      if (len === 0) {
        return 0
      } else if (len === 1) {
        return arr[0]
      } else {
        return arr[0] + this.sum(arr.slice(1))
      }
    },
    async clearScore (id) {
      await this.$http.post('/audit_score_clear/', {
        score_id: id
      }).then((data) => {
        console.log(data)
        if (data.code === '200') {
          this.getData()
        } else {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        }
      })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
</style>
