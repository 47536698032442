<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="4"
        :audit_status="audit_status"
      ></audit-tab>

      <span
        class="import-veto-btn btn-import hide sprite_4"
        style="display: none;"
      >导入否决性指标</span>
      <span
        class="import-proj-btn btn-import sprite_4"
        style="display: none;"
      >导入作品</span>
      <span
        class="add-proj-btn btn-add sprite_4"
        style="display: none;"
      >新建作品</span>
      <span
        class="export-btn btn-export sprite_6"
        id="download"
        style="display: inline-block;"
        @click="exportMemberInfo()"
        v-if="audit_status==4"
      >导出评审详情</span>
      <span
        class="export-btn btn-export sprite_7"
        id="download2"
        @click="exportMemberListV2()"
        v-if="audit_status==3 || audit_status==4"
        style="display: inline-block;"
        :disabled="isLoading"
      >导出专家评审记录</span>
      <span
        class="export-btn btn-export sprite_7"
        @click="exportItemResultV2()"
        v-if="audit_status==3 || audit_status==4 || audit_status==5"
        style="display: inline-block;"
        :disabled="isLoading"
      >导出评审结果</span>
      <span
        class="export-btn btn-import sprite_6"
        id="save-storage"
        @click="saveDetail"
        v-if="audit_status == 4"
        style="display: inline-block;"
      >保存数据详情</span>
      <span
        class="export-btn btn-export sprite_7"
        id="showDetail"
        v-if="audit_status==3"
        style="display: inline-block;"
        @click="toDetail"
      >查看评审详情
      </span>
    </div>
    <div
      class="audit_content seendtime audit_cour clearfix"
      style="display: block;"
    >
      <div class="search-box">
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm"
            v-if="isOpen"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="projId"
                placeholder="作品编码"
                v-model="queryInfo.projId"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="title"
                placeholder="项目名称"
                v-model="queryInfo.title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="school"
                placeholder="学校名称"
                v-model="queryInfo.school_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="specialtySubject"
                placeholder="所属专业类"
                v-model="queryInfo.category02"
              ></div>
            <div class="form-group"><select
                class="form-control input-sm"
                name="isZhen"
                v-model="queryInfo.isZhen"
              >
                <option value="">一流专业</option>
                <option value="1">是</option>
                <option value="2">否</option>
              </select></div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索
              </button>
            </div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          @click="toggleQueryForm"
        >{{ isOpen ? '关闭' : '展开' }}</span>
      </div>
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th>作品名称</th>
              <th>作品编码</th>
              <th>所属专业类</th>
              <th>学校</th>
              <th>负责人</th>
              <th>一流专业建设点</th>
              <th>分组</th>
              <th width="220">操作</th>
              <th>得分</th>
              <th>专家数(已评/总)</th>
              <th width="180">评审详情</th>
              <th v-if="audit_status > 3">结果</th>
              <th v-if="audit_status == 4">结果维护</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td><a
                  class="a-link"
                  href="javascript:void(0)"
                  @click="jumpToIlabx(item)"
                ><span title="">{{ item.title }}</span></a></td>
              <td>{{ item.projId }}</td>
              <td>{{ item.category02 }}</td>
              <td>{{ item.schoolTitle ? item.schoolTitle : '-' }}</td>
              <td>{{ item.incharge }}</td>
              <td>{{ item.isZhen == 1 ? '是' : '否' }}</td>
              <td>{{ item.groupTitle ? item.groupTitle : '-' }}</td>
              <td>
                <a
                  href="javascript:void(0)"
                  class="btn edit"
                  @click="showExpertWindow(item)"
                  v-if="audit_status == 3"
                >添加专家</a>
                <a
                  href="javascript:void(0)"
                  class="btn manage"
                  @click="showVetoWindow(item.id)"
                >否决性指标</a>
                <a
                  href="javascript:void(0)"
                  class="btn preview"
                  @click="getAudititemCourse(item.id)"
                >相关课程</a>
              </td>
<!--              <td>{{ scoreAvg(item) }}</td>-->
<!--              优化直接取得分，不要返回客户端，由客户端算也消耗时间-->
              <td>{{item.score}}</td>
              <td>
                <a
                  href="javascript:void(0);"
                  @click="showSpecialistWindow(item)"
                >
                  <b>{{ item.score_count ? item.score_count : 0 }}</b>/<b>{{ item.expert_count }}</b>
                </a>
              </td>
              <td>
                <a
                  href="javascript:void(0)"
                  class="btn manage"
                  @click="showAuditDetaiWindow(item.id)"
                >评审详情</a>
                <a
                  href="javascript:void(0)"
                  class="btn edit"
                  @click="showVetoDetaiWindow(item.id)"
                >否决性详情</a>
              </td>
              <td v-if="audit_status > 3">{{ formatResult(item.result) }}</td>
              <td v-if="audit_status == 4">
                <a
                  v-if="item.result_status !== 1"
                  href="javascript:void(0)"
                  class="other1"
                  @click="getResultInfo(item.id)"
                >维护结果</a>
              </td>
            </tr>

          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        ></div>
      </div>
      <!-- 分页 -->
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>
    <div
      class="form_overlay"
      id="form_overlay_status"
      :style="classShowResultWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">维护评审结果</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideResultWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="itemFormStatus"
          >
            <div class="item">
              <span class="label"><i class="require">*</i>评审结果:</span>
              <select
                name="status"
                id="status"
                v-model="resultFormInfo.result"
              >
                <option value="1">通过</option>
                <option value="2">待审</option>
                <option value="3">不通过</option>
              </select>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideResultWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit_status"
            @click="saveResult"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 否决性指标列表弹窗 -->
    <div
      class="form_overlay"
      :style="classShowVetoWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">否决性指标</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideVetoWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content clearfix">
          <div
            class="list"
            id="auditList"
          >
            <table>
              <tbody>
                <tr class="l_header">
                  <th width="100">指标编码</th>
                  <th width="100">指标名称</th>
                  <th>详情</th>
                  <th width="100">否决</th>
                  <th width="90">操作</th>
                </tr>

                <tr
                  class="veto_item_14808"
                  v-for="item in vetoData"
                  :key="item.id"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.title }}</td>
                  <td :style="item.markNum==1?'color:red':''">{{ item.desc ? item.desc : '-' }}</td>
                  <td>{{ item.isVeto == 1 ? '是' : '否' }}</td>
                  <td><a
                      href="javascript:void(0)"
                      class="btn delete"
                      v-if="item.delType"
                      @click="delAudititemVeto(item)"
                    >删除</a>
                    <a
                      href="javascript:void(0)"
                      class="btn delete"
                      v-else
                    >-</a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="noitems"
              v-if="!vetoData.length"
            >无相关课程
            </div>
          </div>

        </div>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            @click="hideVetoWindow"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 相关课程列表弹窗 -->
    <div
      class="form_overlay"
      id="course_detail_overlay"
      :style="classShowCourseListWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">相关课程</div>
        <div class="form_content">
          <div class="list">
            <table>
              <tbody>
                <tr>
                  <td colspan="6"><strong>负责人往年认定课程，供专家比对</strong></td>
                </tr>
                <tr class="l_header">
                  <th>课程名称</th>
                  <th width="90">负责人</th>
                  <th width="120">团队主要成员</th>
                  <th width="120">学校</th>
                  <th width="70">认定年份</th>
                  <th width="60">操作</th>
                </tr>
                <tr
                  v-for="item in courseData"
                  :key="item.id"
                >
                  <template v-if="item.type==1">
                    <td>{{ item.title }}</td>
                    <td>{{ item.incharge }}</td>
                    <td>{{ item.mainTeam }}</td>
                    <td>{{ item.schoolTitle }}</td>
                    <td>{{ item.declareYear }}</td>
                    <td><a
                        :href="item.url"
                        target="_blank"
                        class="btn preview"
                      >查看</a></td>
                  </template>
                </tr>
                <tr>
                  <td
                    colspan="6"
                    v-if="!courseData.length"
                  >
                    <div class="noitems">无相关课程</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td colspan="6"><strong>同专业类认定课程，供考虑课程结构布局时参考</strong></td>
                </tr>
                <tr class="l_header">
                  <th>课程名称</th>
                  <th width="90">负责人</th>
                  <th width="120">团队主要成员</th>
                  <th width="120">学校</th>
                  <th width="70">认定年份</th>
                  <th width="60">操作</th>
                </tr>
                <tr
                  v-for="item in courseData"
                  :key="item.id"
                >
                  <template v-if="item.type==2">
                    <td>{{ item.title }}</td>
                    <td>{{ item.incharge }}</td>
                    <td>{{ item.mainTeam }}</td>
                    <td>{{ item.schoolTitle }}</td>
                    <td>{{ item.declareYear }}</td>
                    <td><a
                        :href="item.url"
                        target="_blank"
                        class="btn preview"
                      >查看</a></td>
                  </template>
                </tr>
                <tr>
                  <td
                    colspan="6"
                    v-if="!courseData.length"
                  >
                    <div class="noitems">无相关课程</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hideCourseListWindow"
          >关闭</a></div>
      </div>
    </div>
    <!-- 评审详情弹窗 -->
    <div
      class="form_overlay"
      :style="classAuditDetailWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">评审详情</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideAuditDetaiWindow"
        ><i class="iconfont"></i>

        </a>
        <auditNormInfo :item_id="audit_detail_id"></auditNormInfo>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            @click="hideAuditDetaiWindow"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 否决性详情弹窗 -->
    <div
      class="form_overlay"
      :style="classVetoDetailWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">否决性详情</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideVetoDetaiWindow"
        ><i class="iconfont"></i>

        </a>
        <audit-norm-veto :item_id="veto_detail_id"></audit-norm-veto>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            @click="hideVetoDetaiWindow"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 添加专家弹窗 -->
    <div
      class="form_overlay"
      id="expert_overlay"
      :style="classShowExpertWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor"><span
          class="overlay-close"
          @click="hideExpertWindow"
        ><i class="iconfont"></i></span>
        <div class="form_content">
          <form
            id="expert-search"
            class="search"
            onsubmit="return false;"
          ><select
              name="subject01"
              class="s-text searchSelect short"
              id="subject01"
              v-model="queryGroupLeaderInfo.subject01_id"
              @change="getSsecondLevelInfo(queryGroupLeaderInfo.subject01_id)"
            >
              <option value="">一级学科</option>
              <option
                v-for="item in categoryData"
                :key="item.id"
                :value="item.id"
              >{{ item.title }}
              </option>
            </select><select
              name="subject02"
              class="s-text searchSelect short"
              id="subject02"
              v-model="queryGroupLeaderInfo.subject02_id"
            >
              <option value="">二级学科</option>
              <option
                v-for="item in secondLevelData"
                :key="item.id"
                :value="item.id"
              >{{ item.title }}
              </option>
            </select><input
              type="text"
              name="name"
              class="s-text short"
              placeholder="姓名"
              v-model="queryGroupLeaderInfo.name"
            ><input
              type="text"
              name="referral"
              class="s-text short"
              placeholder="推荐来源"
              v-model="queryGroupLeaderInfo.source"
            ><input
              type="button"
              class="s-btn1"
              value="查询"
              @click="pageExpertCallback(1)"
            ></form>
          <div class="clearfix">
            <div
              id="expert-list"
              class="list"
            >
              <table>
                <tbody>
                  <tr class="l_header">
                    <th class="itemTitle">用户名</th>
                    <th class="itemTitle">手机号</th>
                    <th class="itemCategory">姓名</th>
                    <th class="itemCategory">性别</th>
                    <th class="itemCategory">学校</th>
                    <th class="itemCategory">一级学科</th>
                    <th class="itemCategory">二级学科</th>
                    <th class="itemCategory">推荐来源</th>
                    <th width="80">操作</th>
                  </tr>
                  <tr
                    v-for="item in ExpertData"
                    :key="item.id"
                  >
                    <td>{{ item.username }}</td>
                    <td>{{ item.mobile ? item.mobile : '-' }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.gender == 1 ? '男' : '女' }}</td>
                    <td>{{ item.school ? item.school.title : '-' }}</td>
                    <td>{{ item.subject01 ? item.subject01.title : '-' }}</td>
                    <td>{{ item.subject02 ? item.subject02.title : '-' }}</td>
                    <td>{{ item.source }}</td>
                    <td class="a-button"><a
                        class="a-button"
                        href="javascript:void(0)"
                        v-if="item.isSelect"
                      >-</a>
                      <a
                        class="a-button"
                        href="javascript:void(0)"
                        v-else
                        @click="choiceExpert(item)"
                      >选择</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="noitems"
                v-if="!ExpertData.length"
              >没有用户
              </div>
            </div>
            <!-- 分页 -->
            <pagination
              v-if="ExpertData.length"
              :cur='pageExpertInfo.page'
              :all='pageExpertInfo.all'
              :totalData="pageExpertInfo.totalData"
              :callback="pageExpertCallback"
            ></pagination>
          </div>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hideExpertWindow"
          >关闭</a></div>
      </div>
    </div>
    <!-- 专家数弹窗 -->
    <div
      id="c-panel"
      :style="classShowSpecialistWindow"
    >
      <div id="c-panel-bg"></div>
      <div class="c-content">
        <form
          id="search"
          class="search audit"
          onsubmit="return false;"
        >
          <div
            class="tab1 auditnone showScoredExp"
            style="display: block; float: left; width: auto; padding: 0px;"
          >
            <a
              class="item"
              :class="active==1?'on':''"
              @click="active=1"
            >已评专家</a>
            <a
              class="item"
              :class="active==2?'on':''"
              @click="active=2"
            >未评专家</a>
          </div>
          <span
            class="close-cpanel"
            @click="hideSpecialistWindow"
          ><i class="iconfont"></i>

          </span>
        </form>
        <div
          id="uList"
          class="list"
        >
          <table>
            <tbody>
              <tr class="l_header">
                <th class="itemTitle">用户名</th>
                <th class="itemCategory">姓名</th>
                <th class="itemCategory">性别</th>
                <th class="itemCategory">手机号</th>
              </tr>
              <template v-if="active==1">
                <tr
                  v-for="item in commentedExperts"
                  :key="item.id"
                >
                  <td>{{ item.expert ? item.expert.username : '-' }}</td>
                  <td>{{ item.expert ? item.expert.name : '-' }}</td>
                  <td>{{ item.expert ? formatGender(item.expert.gender) : '-' }}</td>
                  <td>{{ item.expert ? item.expert.mobile : '-' }}</td>
                </tr>

              </template>
              <template v-if="active==2">
                <tr
                  v-for="item in notEvaluated"
                  :key="item.id"
                >
                  <td>{{ item.expert ? item.expert.username : '-' }}</td>
                  <td>{{ item.expert ? item.expert.name : '-' }}</td>
                  <td>{{ item.expert ? formatGender(item.expert.gender) : '-' }}</td>
                  <td>{{ item.expert ? item.expert.mobile : '-' }}</td>
                </tr>
              </template>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if='active==1 && !commentedExperts.length'
          >没有数据
          </div>
          <div
            class="noitems"
            v-else-if='active==2 && !notEvaluated.length'
          >没有数据
          </div>
        </div>

        <ul
          class="cN-pagination"
          id="pagination1"
        >
          <li class="selected"></li>
        </ul>
      </div>
    </div>
    <!-- 进度条 -->
    <div v-if="isLoading" class="xoverlay">
      <div class="xmodal">
        <h3>下载中... 请稍候</h3>
        <div class="progress-bar">
          <div class="progress-bar-inner" :style="{ width: progress + '%' }"></div>
        </div>
        <p>进度: {{ progress }}%</p>
        <button @click="()=>{cancelDownload()}">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import outputTable from '../../../assets/js/outputTable.js'
import AuditNormInfo from '../../../components/AuditNormInfo'
import AuditNormVeto from '../../../components/AuditNormVeto'
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'
import { getUrlParams, openPage } from '@/util/utils'

export default {
  name: 'MaintainTab5',
  components: {
    AuditTab,
    Pagination,
    AuditNormInfo,
    AuditNormVeto
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      active: 1,
      queryInfo: {
        page: 1,
        size: 10,
        audit_id: this.param_id,
        projId: '',
        title: '',
        school_title: '',
        category02: '',
        isZhen: ''
      },
      resultFormInfo: {
        id: 0,
        result: ''
      },
      tableData: [],
      tableDataCount: 0,
      ExpertData: [],
      expertListData: [],
      ExpertDataCount: 0,
      isOpen: false,
      classShowResultWindow: {},
      classShowCourseListWindow: {},
      classAuditDetailWindow: {},
      classVetoDetailWindow: {},
      classShowVetoWindow: {},
      classShowExpertWindow: {},
      vetoData: [],
      courseData: [],
      audit_detail_id: '',
      veto_detail_id: '',
      exportData: [],
      queryGroupLeaderInfo: {
        page: 1,
        size: 10,
        subject01_id: '',
        subject02_id: '',
        name: '',
        source: '',
        role: 1
      },
      groupId: '',
      expertList: [],
      specialist: [],
      classShowSpecialistWindow: {},
      importVetoInfo: [],
      commentedList: [],
      notEvaluated: [],
      commentedExperts: [],
      allExperts: [],
      allExpertsList: [],
      categoryData: [],
      secondLevelData: [],
      // 用来控制评审作品 导出进度条
      isLoading: false,
      progress: 0,
      error: null,
      cancelRequested: false
    }
  },
  methods: {
    async jumpToIlabx (item) {
      // target="_blank"
      // 获取token
      console.log(item)
      await this.$http.post('/xjwt/', {}).then((res) => {
        console.log(res)
        if (res.token) {
          // 获取token成功 拼接url进行跳转
          const url = item.projUrl
          const ps = getUrlParams(url)
          const data = []
          for (const k in ps) {
            data.push({
              paramName: k,
              paramValue: ps[k]
            })
          }
          data.push({
            paramName: 'token',
            paramValue: res.token
          })
          debugger
          openPage(url.split('?')[0], 'GET', data, '_blank')
        } else {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        }
      })
    },
    formatGender (val) {
      if (Number(val) === 1) {
        return '男'
      } else if (Number(val) === 0) {
        return '女'
      } else {
        return '-'
      }
    },
    unique (arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i] === arr[j]) { // 第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1)
            j--
          }
        }
      }
      return arr
    },
    // 数组求和
    sum (arr) {
      var len = arr.length
      if (len === 0) {
        return 0
      } else if (len === 1) {
        return arr[0]
      } else {
        return arr[0] + this.sum(arr.slice(1))
      }
    },
    scoreAvg (item) {
      const calType = item.audit.calType
      const scores = item.scores
      const ids = item.score_ids
      if (!scores || !ids) {
        return ''
      }

      const arrScore = scores.split(',').map(i => parseFloat(i))
      const arrId = ids.split(',').map(i => parseInt(i))
      const fixArr = {}
      for (let i = 0; i < arrId.length && i < arrScore.length; i++) {
        fixArr[arrId[i]] = arrScore[i]
      }
      let floatScore = []
      for (const key in fixArr) {
        floatScore.push(fixArr[key])
      }
      if (calType === 1) {
        console.log('calType=1')
        return (this.sum(floatScore) / floatScore.length).toFixed(2)
      } else {
        console.log('calType=2')
        floatScore = floatScore.sort()
        console.log('============')
        console.log(floatScore)
        console.log('============')
        if (floatScore.length >= 3) {
          floatScore.pop()
          floatScore.shift()
        }
        return (this.sum(floatScore) / floatScore.length).toFixed(2)
      }
      // return '' + calType + ' ' + scores
    },
    formatResult (val) {
      if (val) {
        return this.dict_result[val]
      } else {
        return '-'
      }
    },
    // 获取一级专业
    async getCategoryInfo () {
      const params = {
        page: 1,
        size: 10000,
        level: 1
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取二级专业
    async getSsecondLevelInfo (id) {
      const params = {
        page: 1,
        size: 10000,
        level: 2,
        parent_id: id
      }
      this.secondLevelData = []
      this.queryGroupLeaderInfo.subject02_id = ''
      if (id) {
        await this.$http.get('/subject/', { params }).then((data) => {
          this.secondLevelData = data.results
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        this.secondLevelData = []
      }
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    // 搜索优化 不使用audititem 使用audititem_search
    async search () {
      await this.$http.get('/audititem_search/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getResultInfo (id) {
      const url = '/audititem/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.resultFormInfo.id = id
        if (data.result) {
          this.resultFormInfo.result = data.result
        } else {
          this.resultFormInfo.result = 1
        }
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
      this.showResultWindow()
    },
    // 获取当前时间
    getCurrentTime () {
      var _this = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.gettime = yy + '-' + mm + '-' + dd + 'T' + hh + ':' + mf + ':' + ss
      return _this.gettime
    },
    // 结果维护保存
    async saveResult () {
      var nowDateTime = this.getCurrentTime()
      const url = '/audititem/' + this.resultFormInfo.id + '/'
      await this.$http.put(url, {
        result: this.resultFormInfo.result,
        result_time: nowDateTime
      }).then((data) => {
        this.search()
        this.hideResultWindow()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getVetoInfo () {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id
      }
      await this.$http.get('/normveto/', { params }).then(async (data) => {
        this.vetoData = data.results
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 否决性指标列表
    async getAudititemVeto (id) {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        auditItem_id: id
      }
      await this.$http.get('/audititem_veto/', { params }).then(async (data) => {
        this.importVetoInfo = data.results
        if (this.importVetoInfo.length) {
          const fix = JSON.parse(JSON.stringify(this.vetoData))
          for (let j = 0; j < this.importVetoInfo.length; j++) {
            for (let i = 0; i < fix.length; i++) {
              if (fix[i].id === this.importVetoInfo[j].normVeto) {
                fix[i].isVeto = this.importVetoInfo[j].isVeto
                fix[i].desc = this.importVetoInfo[j].desc
                fix[i].markNum = this.importVetoInfo[j].markNum
                fix[i].audititemId = this.importVetoInfo[j].id
                fix[i].delType = true
              }
            }
          }
          this.vetoData = fix
        } else {
          this.getVetoInfo()
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    async delAudititemVeto (item) {
      const url = '/audititem_veto/' + item.audititemId + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then(async (data) => {
        await this.getVetoInfo()
        await this.getAudititemVeto(data.auditItem)
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 相关课程列表
    async getAudititemCourse (id) {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        auditItem_id: id
      }
      await this.$http.get('/audititem_course/', { params }).then(async (data) => {
        this.courseData = data.results
        this.courseDataCount = data.count
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
      this.showCourseListWindow()
    },
    // 导出查询列表
    async exportMemberList () {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        status: 2
      }
      var exportArray, fileName
      fileName = '专家评审记录.csv'
      exportArray = [{
        projId: '作品编码',
        title: '作品',
        scores: '评分',
        created: '评判时间'
      }]

      await this.$http.get('/auditgroup_itemscore/', { params }).then((data) => {
        this.exportData = data.results
        if (!this.exportData.length) {
          this.$toast.error('暂无信息可导出！', '', this.error_tips)
        } else {
          this.exportData.forEach(item => {
            exportArray.push({
              projId: item.auditItem.projId,
              title: item.auditItem.title,
              scores: item.score,
              created: item.saveDate ? item.saveDate.substr(0, 10) : '-'
            })
          })

          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        }
      }).catch(() => {
        this.$toast.error('获取信息失败！', '', this.error_tips)
      })
    },
    // 导出专家评审记录 优化
    async exportMemberListV2 () {
      if (this.isLoading) return // 防止多次点击
      this.progress = 0
      // this.error = null;
      this.cancelRequested = false
      this.isLoading = true
      let page = 1
      const pageSize = 20
      let hasMoreData = true
      let loaded = 0
      const exportArray = [{
        projId: '作品编码',
        title: '作品',
        scores: '评分',
        created: '评判时间'
      }]

      try {
        while (hasMoreData && !this.cancelRequested) {
          const data = await this.$http.get('/auditgroup_itemscoreg/', {
            // 每次循环后page++
            params: {
              page: page,
              size: pageSize,
              audit_id: this.param_id,
              status: 2
            }
          })
          const results = data.results
          const next = data.next
          const count = data.count
          if (results.length) {
            results.forEach(item => {
              exportArray.push({
                projId: item.projId,
                title: item.projTitle,
                scores: item.score,
                created: item.saveDate ? item.saveDate.substr(0, 10) : '-'
              })
            })
            loaded += results.length // 更新已加载记录数
            this.progress = Math.round((loaded / count) * 100)
            hasMoreData = !!next
            page++
          } else {
            hasMoreData = false // No more data to fetch
          }
        }
      } catch (e) {
      } finally {
        this.isLoading = false
      }
      this.progress = 100
      if (exportArray.length > 1) {
        if (!this.cancelRequested) {
          const fileName = '专家评审记录.csv'
          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        } else {
          this.$toast.success('已取消', '', this.success_tips)
        }
      } else {
        this.$toast.error('暂无信息可导出！', '', this.error_tips)
      }
    },
    // 导出查询列表
    async exportMemberInfo () {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        status: 2
      }
      var exportArray, fileName

      fileName = '评审详情.csv'
      exportArray = [{
        audit_title: '评审项目',
        id: '评审项目ID',
        title: '作品名称',
        projId: '作品编码',
        school_title: '学校',
        school_id: '学校ID',
        incharge: '负责人',
        auditGroup_title: '分组',
        scores: '得分',
        result: '结果'
      }]
      await this.$http.get('/auditgroup_itemscore/', { params }).then((data) => {
        this.exportData = data.results
        if (!this.exportData.length) {
          this.$toast.error('暂无信息可导出！', '', this.error_tips)
        } else {
          console.log(this.exportData)
          this.exportData.forEach(item => {
            var result = this.formatResult(item.auditItem.result)
            exportArray.push({
              audit_title: item.auditItem.audit ? item.auditItem.audit.title : '-',
              id: item.auditItem ? item.auditItem.audit_id : '-',
              title: item.auditItem ? item.auditItem.title : '-',
              projId: item.auditItem ? item.auditItem.projId : '-',
              school_title: item.auditItem.school ? item.auditItem.school.title : '-',
              school_id: item.auditItem.school_id,
              incharge: item.auditItem.incharge,
              auditGroup_title: item.auditItem.auditGroup ? item.auditItem.auditGroup.title : '-',
              scores: item.score,
              result: result
            })
          })

          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        }
      }).catch(() => {
        this.$toast.error('获取信息失败！', '', this.error_tips)
      })
    },
    // 导出评审结果进行优化和修改
    async exportItemResult () {
      const fileName = '评审项目评审结果.csv'
      const exportArray = [{
        projId: '实验项目编号',
        title: '实验项目名称',
        incharge: '实验负责人',
        school_title: '学校',
        group_title: '作品分组',
        score: '最终得分',
        score_count: '专家评审'
      }]
      await this.$http.get('/audititem/', {
        params: {
          page: 1,
          size: 10000,
          audit_id: this.param_id
        }
      }).then((data) => {
        this.exportData = data.results
        if (!data.results.length) {
          this.$toast.error('暂无信息可导出！', '', this.error_tips)
        } else {
          this.exportData.forEach(item => {
            exportArray.push({
              projId: item.projId,
              title: item.title,
              incharge: item.incharge,
              school_title: item.school ? item.school.title : '-',
              group_title: item.auditGroup ? item.auditGroup.title : '-',
              score: this.scoreAvg(item),
              score_count: '' + (item.score_count ? item.score_count : 0) + '\\' + (item.expert_count)
            })
          })
        }

        this.$toast.success('导出成功', '', this.success_tips)
        outputTable(exportArray, fileName)
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // v2版本的导出 支持分页导出防止后台长时间占用线程
    async exportItemResultV2 () {
      if (this.isLoading) return // 防止多次点击
      this.progress = 0
      // this.error = null;
      this.cancelRequested = false
      this.isLoading = true
      let page = 1
      const pageSize = 20
      let hasMoreData = true
      let loaded = 0
      const exportArray = [{
        projId: '实验项目编号',
        title: '实验项目名称',
        incharge: '实验负责人',
        school_title: '学校',
        group_title: '作品分组',
        score: '最终得分',
        score_count: '专家评审'
      }]
      try {
        while (hasMoreData && !this.cancelRequested) {
          const data = await this.$http.get('/audititem_search/', {
            params: {
              page: page,
              size: pageSize,
              audit_id: this.param_id
            }
          })
          const results = data.results
          const next = data.next
          const count = data.count
          if (results.length) {
            results.forEach(item => {
              exportArray.push({
                projId: item.projId,
                title: item.title,
                incharge: item.incharge,
                school_title: item.schoolTitle ? item.schoolTitle : '-',
                group_title: item.groupTitle ? item.groupTitle : '-',
                score: item.score,
                score_count: '' + (item.score_count ? item.score_count : 0) + '\\' + (item.expert_count)
              })
            })
            // Increment page for the next iteration
            // Check if the number of results is less than the pageSize to determine if there's no more data
            // hasMoreData = results.length === pageSize
            loaded += results.length // 更新已加载记录数
            this.progress = Math.round((loaded / count) * 100)
            hasMoreData = !!next
            page++
          } else {
            hasMoreData = false // No more data to fetch
          }
        }
        this.progress = 100
      } catch (e) {
      } finally {
        this.isLoading = false
      }
      if (exportArray.length > 1) {
        if (!this.cancelRequested) {
          const fileName = '评审项目评审结果.csv'
          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        } else {
          this.$toast.success('已取消', '', this.success_tips)
        }
      } else {
        this.$toast.error('暂无信息可导出！', '', this.error_tips)
      }
    },
    // 取消下载
    cancelDownload () {
      this.cancelRequested = true
    },
    async saveDetail () {
      // const params = {
      //   audit_id: this.param_id,
      //   result_status: 1
      // }
      // await this.$http.post('/audit_result/', params).then(async (data) => {
      //   console.log(data)
      //   // this.$toast.success('数据保存成功！', '', this.success_tips)
      //   this.$router.push({ path: '/admin/audit/maintain/tab5_maintain', query: { id: this.param_id } })
      // }).catch((error) => {
      //   console.log(error)
      //   this.$toast.error('数据保存失败！', '', this.error_tips)
      // })
      this.$router.push({
        path: '/admin/audit/maintain/tab5_maintain',
        query: { id: this.param_id }
      })
    },
    async getExpertList () {
      const params = {
        page: 1,
        size: 10000,
        auditGroup_id: this.groupId
      }
      await this.$http.get('/auditgroup_expert/', { params }).then((data) => {
        this.expertListData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async addExpert () {
      await this.$http.get('/userprofile/', {
        params: this.queryGroupLeaderInfo
      }).then((data) => {
        for (let i = 0; i < data.results.length; i++) {
          data.results[i].isSelect = false
          for (let j = 0; j < this.expertListData.length; j++) {
            if (this.expertListData[j].expert_id === data.results[i].id) {
              data.results[i].isSelect = true
            }
          }
        }
        this.ExpertData = data.results
        this.ExpertDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async generateScore () {
      await this.$http.post('/generate_score/', {
        audit_id: this.param_id
      }).then((data) => {
        console.log(data)
      })
    },
    async choiceExpert (item) {
      const params = {
        expert_id: item.id,
        auditGroup_id: this.groupId
      }
      await this.$http.post('/auditgroup_expert/', params).then(async (data) => {
        await this.generateScore()
        await this.getExpertList()
        await this.addExpert()
        await this.search()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 打开否决性指标列表弹窗
    showVetoWindow (id) {
      this.classShowVetoWindow = {
        visibility: 'visible'
      }
      debugger
      this.getAudititemVeto(id)
    },
    // 关闭否决性指标列表弹窗
    hideVetoWindow () {
      this.classShowVetoWindow = {}
      this.vetoData = []
      this.getVetoInfo()
    },
    // 打开结果维护弹窗
    showResultWindow () {
      this.classShowResultWindow = {
        visibility: 'visible'
      }
    },
    // 关闭结果维护弹窗
    hideResultWindow () {
      this.classShowResultWindow = {}
    },
    // 打开相关课程列表弹窗
    showCourseListWindow () {
      this.classShowCourseListWindow = {
        visibility: 'visible'
      }
    },
    // 关闭相关课程列表弹窗
    hideCourseListWindow () {
      this.classShowCourseListWindow = {}
    },
    // 打开评审详情弹窗
    showAuditDetaiWindow (id) {
      this.classAuditDetailWindow = {
        visibility: 'visible'
      }
      console.log(id)
      this.audit_detail_id = id
    },
    // 关闭评审详情弹窗
    hideAuditDetaiWindow () {
      this.classAuditDetailWindow = {}
    },
    // 打开评审详情弹窗
    showVetoDetaiWindow (id) {
      this.classVetoDetailWindow = {
        visibility: 'visible'
      }
      console.log(id)
      this.veto_detail_id = id
    },
    // 关闭评审详情弹窗
    hideVetoDetaiWindow () {
      this.classVetoDetailWindow = {}
    },
    // 打开分组管理弹窗
    async showExpertWindow (item) {
      this.classShowExpertWindow = {
        visibility: 'visible'
      }
      this.groupId = item.auditGroup_id
      await this.getCategoryInfo()
      await this.getExpertList()
      await this.addExpert()
    },
    // 关闭分组管理弹窗
    hideExpertWindow () {
      this.classShowExpertWindow = {}
    },
    // 打开专家数弹窗
    async showSpecialistWindow (item) {
      this.classShowSpecialistWindow = {
        display: 'block'
      }
      this.groupId = item.auditGroup_id
      this.active = 1
      await this.getExpertList()
      var expertListData = this.expertListData
      var allExperts = item.experts ? item.experts.split(',') : ''
      var commented = item.score_experts ? item.score_experts.split(',') : ''
      var commentedList = this.unique(commented)
      this.notEvaluated = []
      this.commentedExperts = []
      this.allExpertsList = []
      for (let i = 0; i < allExperts.length; i++) {
        for (let j = 0; j < expertListData.length; j++) {
          if (allExperts[i] === String(expertListData[j].expert_id)) {
            this.allExpertsList.push(expertListData[j])
          }
        }
      }
      if (commentedList.length) {
        for (let i = 0; i < commentedList.length; i++) {
          for (let j = 0; j < expertListData.length; j++) {
            if (commentedList[i] === String(expertListData[j].expert_id)) {
              this.commentedExperts.push(expertListData[j])
            }
          }
        }
      } else {
        this.notEvaluated = this.allExpertsList
      }

      if (this.commentedExperts.length) {
        for (let i = 0; i < this.commentedExperts.length; i++) {
          for (let j = 0; j < this.allExpertsList.length; j++) {
            if (this.allExpertsList[j].expert_id === this.commentedExperts[i].expert_id) {
              this.allExpertsList.splice(j, 1)
            }
          }
        }

        this.notEvaluated = this.allExpertsList
      }
      console.log(this.commentedExperts)
      console.log(this.notEvaluated)
    },
    // 关闭专家数弹窗
    hideSpecialistWindow () {
      this.classShowSpecialistWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    },
    toDetail () {
      this.$router.push('/admin/audit/maintain/tab5_detail?id=' + this.param_id)
    },
    // 分页
    async pageExpertCallback (data) {
      this.queryGroupLeaderInfo.page = data
      await this.addExpert()
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    pageExpertInfo () {
      return {
        page: this.queryGroupLeaderInfo.page,
        all: Math.ceil(this.ExpertDataCount / this.queryGroupLeaderInfo.size),
        totalData: this.ExpertDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    },
    dict_result () {
      return this.$store.state.dict_result
    }
  },
  mounted () {
    this.search()
    this.getVetoInfo()
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}

.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}

.xoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.xmodal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar-inner {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
}
</style>
