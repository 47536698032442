<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">评审管理</span>
      <div class="btns">
        <span
          class="btn add"
          @click="openAddAudit"
        ><i class="iconfont"></i>添加评审项目</span>
      </div>
    </div>
    <div class="search-box">
      <transition name="fade">
        <form
          class="form-inline"
          id="filterForm"
          v-if="showSearch"
          style="display: block; height: auto;"
        >
          <div class="form-group">
            <input
              v-model="queryInfo.title"
              type="text"
              class="form-control input-sm"
              name="title"
              placeholder="评审名称"
              value=""
            >
          </div>
          <div class="form-group">
            <select
              v-model="queryInfo.level"
              class="form-control input-sm"
              name="level"
            >
              <option value="">评审级别</option>
              <option value="1">国家级</option>
              <option value="2">省级</option>
              <option value="3">校级</option>
            </select>
          </div>
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-rounded btn-search"
              @click="pageCallback(1)"
            >
              <i class="fa fa-search text-xs m-r-xs"></i>搜索
            </button>
          </div>
        </form>
      </transition>
      <span
        class="btn btn-danger btn-open"
        data-reset=""
        @click="showSearch=!showSearch"
      >{{
          showSearch ? '关闭' : '展开'
        }}</span>
    </div>
    <div
      class="list"
      id="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th width="100">ID</th>
            <th>使用平台</th>
            <th>评审项目</th>
            <th>评审级别</th>
            <th>评审模式</th>
            <th>评审进程</th>
            <th width="180">操作</th>
          </tr>
          <tr
            v-for="item in audits"
            :key="item.id"
          >
            <td>{{ item.id }}</td>
            <td>{{ item.sites ? item.sites.title : '' }}</td>
            <td>{{ item.title }}</td>
            <td>{{ dict_audit_level[item.level] }}</td>
            <td>{{ dict_audit_mode[item.auditMode] }}</td>
            <td><span :class="'status_' + item.status">{{ dict_audit_status[item.status] }}</span></td>
            <td><a
                class="btn edit"
                href="javascript:void(0)"
                @click="toMaintain(item.id)"
              >评审项目维护</a></td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!audits.length"
      >没有数据</div>
    </div>
    <pagination
      v-if="audits.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="form_overlay"
      :style="showAddWindow ? 'visibility: visible;' : 'visibility: hidden;'"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">评审管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="showAddWindow=false"
        >
          <i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="itemForm"
          >
            <div class="item">
              <span class="label">
                <i class="require">*</i>使用平台:</span>
              <div class="choose">
                <span class="choice">
                  <select
                    name="site"
                    v-model="auditAddForm.sites_id"
                  >
                    <option value="">请选择平台</option>
                    <option
                      :value="item.id"
                      v-for="item in sites"
                      :key="item.id"
                    >{{ item.title }}</option>
                  </select>
                </span>
              </div>
            </div>
            <div class="item">
              <span class="label">
                <i class="require">*</i>评审级别:</span>
              <div class="choose">
                <span class="choice">
                  <select
                    name="site"
                    v-model="auditAddForm.level"
                  >
                    <option value="">请选择评审级别</option>
                    <option
                      :value="item.id"
                      v-for="item in levels"
                      :key="item.id"
                    >{{ item.title }}</option>
                  </select>
                </span>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>项目名称:</span>
              <input
                name="title"
                type="text"
                value=""
                v-model="auditAddForm.title"
              >
            </div>
            <div class="item">
              <span class="check-label"><i class="require">*</i>评审模式:</span>
              <label class="check-label">
                <input
                  class="elevel"
                  name="auditMode"
                  type="radio"
                  value="1"
                  v-model="auditAddForm.auditMode"
                >传统模式
              </label>
              <label class="check-label">
                <input
                  class="elevel"
                  name="auditMode"
                  type="radio"
                  value="2"
                  v-model="auditAddForm.auditMode"
                >随机分配模式
              </label>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="showAddWindow=false"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveAudit"
          >提交</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'

export default {
  name: 'Audit',
  components: {
    Pagination
  },
  data () {
    return {
      showSearch: false,
      showAddWindow: false,
      queryInfo: {
        page: 1,
        size: 10,
        title: '',
        level: '',
        isdel: 0
      },
      sites: [],
      audits: [],
      audits_count: 0,
      auditAddForm: {
        sites_id: '',
        auditMode: 1,
        level: 1,
        title: ''
      },
      // 评审级别下拉列表
      levels: [
        { id: 1, title: '国家级' },
        { id: 2, title: '省级' },
        { id: 3, title: '校级' }
      ]
    }
  },
  computed: {
    dict_audit_level () {
      return this.$store.state.dict_audit_level
    },
    dict_audit_status () {
      return this.$store.state.dict_audit_status
    },
    dict_audit_mode () {
      return this.$store.state.dict_audit_mode
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    },
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.audits_count / this.queryInfo.size),
        totalData: this.audits_count
      }
    }
  },
  methods: {
    async getSites () {
      await this.$http.get('/sites/', {
        params: {
          page: 1,
          size: 10000,
          isdel: 0
        }
      }).then((data) => {
        console.log(data)
        this.sites = data.results
      })
    },
    async getAudits () {
      await this.$http.get('/audit_search/', {
        params: this.queryInfo
      }).then((data) => {
        console.log(data)
        this.audits = data.results
        this.audits_count = data.count
      })
    },
    pageCallback (data) {
      this.queryInfo.page = data
      this.getAudits()
    },
    openAddAudit () {
      this.showAddWindow = true
      this.auditAddForm = {
        sites_id: '',
        auditMode: 1,
        level: 1,
        title: ''
      }
    },
    async saveAudit () {
      if (!this.auditAddForm.sites_id) {
        this.$toast.error('请选择平台', '', this.error_tips)
        return
      }
      if (!this.auditAddForm.level) {
        this.$toast.error('评审级别不能为空', '', this.error_tips)
        return
      }
      if (!this.auditAddForm.title) {
        this.$toast.error('项目名称不能为空', '', this.error_tips)
        return
      }
      await this.$http.post('/audit/', this.auditAddForm).then((data) => {
        if (data.id) {
          this.$toast.success('添加成功', '', this.success_tips)
        } else {
          this.$toast.error('添加失败', '', this.error_tips)
        }
        this.showAddWindow = false
        this.getAudits()
      })
    },
    toMaintain (id) {
      this.$router.push('/admin/audit/maintain?id=' + id)
    }
  },
  async created () {
    await this.getSites()
  },
  mounted () {
    this.getAudits()
  }
}
</script>

<style scoped src="../../assets/css/styles.css"></style>
<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
