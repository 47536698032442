<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="5"
        :audit_status="audit_status"
      ></audit-tab>
      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab1?id=' + param_id">评审信息</router-link>-->
      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab2?id=' + param_id">评审指标</router-link>-->

      <!--      <span id="more-btn">-->
      <!--        <router-link class="item on" :to="'/admin/audit/maintain/tab3?id=' + param_id">评审分组</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab4?id=' + param_id">评审详情</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab5?id=' + param_id">会评分组</router-link>-->
      <!--      </span>-->

      <span
        v-if="audit_status=4"
        class="add-btn sprite_4"
        @click="showGroupWindow('add')"
      >添加会评分组</span>
      <span
        v-if="audit_status=4"
        class="btn-export sprite_5 import-works"
        @click="showUploadWindow"
      >导入作品补充信息</span>
    </div>
    <div
      class="audit_content seendtime audit_group clearfix"
      style="display: block;"
    >
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th>分组名称</th>
              <th>专家数</th>
              <th>作品数</th>
              <th width="180">操作</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td>{{item.title}}</td>
              <td id="e_num_150">{{item.expert_count}}</td>
              <td id="w_num_150">{{item.item_count}}</td>
              <td>
                <router-link
                  class="btn import"
                  :to="'/admin/audit/maintain/tab6_maintain?id=' + param_id+'&groupMeetingId='+item.id"
                >维护</router-link>
                <a
                  class="btn edit"
                  href="javascript:void(0)"
                  @click="querySingle(item.id)"
                >编辑</a>
                <a
                  class="btn delete hide"
                  href="javascript:void(0)"
                  @click="delItem(item.id)"
                >删除</a>
              </td>

            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        >没有数据</div>
      </div>
      <!-- 分页 -->
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      >
      </pagination>
    </div>
    <!-- 分组管理弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowGroupWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">分组管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideGroupWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="itemForm"
          >
            <div class="item">
              <span class="label"><i class="require">*</i>组名称:</span>
              <input
                name="title"
                type="text"
                v-model="formInfo.title"
              >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideGroupWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 上传弹窗 -->
    <div
      class="form_overlay"
      id="form_upload_overlay"
      :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">导入作品补充信息</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideUploadWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label"><i class="require">*</i>模板创建规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：projId（作品编码）、seq（排序）、mshow（是否显示：1-显示，2-不显示）、mexplain（说明，100字以内）、平均分；</p>
                  <p>3. 其中所有选项均为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_meeting_iteminfo_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>

            <div class="item">
              <span class="label"><i class="require">*</i>导入文件:</span>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="fileUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="fileUpload"
                    ref="xlsFile"
                    accept=".csv,.xls,.xlsx"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideUploadWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab3',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      tableData: [],
      tableDataCount: 0,
      queryInfo: {
        page: 1,
        size: 10,
        audit_id: this.param_id,
        expert_id: ''
      },
      formInfo: {
        id: 0,
        audit_id: '',
        title: ''
      },
      classShowGroupWindow: {},
      classShowProcessWindow: {},
      classShowUploadWindow: {},
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: []
    }
  },
  methods: {
    formatGender (val) {
      if (val !== '') {
        return val === '1' ? '男' : '女'
      } else {
        return '-'
      }
    },
    async search () {
      await this.$http.get('/auditmetinggroup/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async querySingle (id) {
      const url = '/auditmetinggroup/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo.id = id
        this.formInfo.audit_id = this.param_id
        this.formInfo.title = data.title
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
      this.showGroupWindow('edit')
    },
    async saveInfo () {
      const params = {
        audit_id: this.param_id,
        title: this.formInfo.title
      }
      if (!this.formInfo.title) {
        this.$toast.error('组名称不能为空！', '', this.error_tips)
        return
      }
      if (this.formInfo.id) {
        const url = '/auditmetinggroup/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideGroupWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/auditmetinggroup/', params).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideGroupWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      }
    },

    delItem (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/auditmetinggroup/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },
    // 打开分组管理弹窗
    showGroupWindow (val) {
      this.classShowGroupWindow = {
        visibility: 'visible'
      }
      if (val === 'add') {
        this.formInfo.title = ''
        this.formInfo.id = 0
      }
    },
    // 关闭分组管理弹窗
    hideGroupWindow () {
      this.classShowGroupWindow = {}
    },

    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 打开上传弹窗
    showUploadWindow () {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传弹窗
    hideUploadWindow () {
      this.classShowUploadWindow = {}
      this.uploadedCsv = ''
      this.uploadedCsvName = ''
    },
    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$toast.error('上传失败！', '', this.error_tips)
      })
    },
    async submitCsv () {
      const uploadUrl = '/get_xls/'
      await this.$http.get(uploadUrl, {
        params: {
          file_name: this.uploadedCsv
        }
      }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }
        data.splice(0, 1)
        this.uploadedCscData = data
        this.hideUploadWindow()
        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }
      const row = this.uploadedCscData[this.curCsvIndex]
      // region 数据检查开始
      const uploadForm = {
        projId: row[0],
        m_seq: row[1],
        m_show: row[2],
        m_explain: row[3],
        m_avg: row[4]
      }
      if (String(row[0]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入作品编码！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      let itemId = ''
      await this.$http.get('/audititem/', {
        params: {
          page: 1,
          size: 100000,
          eq_projId: row[0],
          audit_id: this.param_id
        }
      }).then((data) => {
        console.log(data)
        if (data.results.length) {
          itemId = data.results[0].id
        } else {
          itemId = ''
        }
      })
      if (!itemId) {
        this.errorList.push({ code: 0, text: '作品编码不存在！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[1]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '排序不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      console.log(parseInt(row[1]))
      if (isNaN(Number(row[1]))) {
        this.errorList.push({ code: 0, text: '排序的值必须为数值！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (parseInt(row[1]) < 1) {
        this.errorList.push({ code: 0, text: '排序的值大于0！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[2]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '是否显示不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (Number(row[2]) === 2) {
        uploadForm.mshow = 0
      }
      if (String(row[3]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '说明不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[3]).trim().length > 100) {
        this.errorList.push({ code: 0, text: '说明长度不能超过100个字符！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (String(row[4]).trim().length === 0) {
        this.errorList.push({ code: 0, text: '平均分不能为空！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (isNaN(Number(row[4]))) {
        this.errorList.push({ code: 0, text: '平均分必须为数值！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      } else {
        uploadForm.m_avg = row[4].toFixed(2)
      }

      console.log(uploadForm)
      const url = '/audititem/' + itemId + '/'
      await this.$http.put(url, uploadForm).then((profileData) => {
        this.errorList.push({ code: 1, text: '[' + uploadForm.projId + ']' + '导入成功！' })
        this.curCsvIndex++
        this.insertOne()
      }).catch((error) => {
        console.log(error)
        this.errorList.push({ code: 0, text: '[' + uploadForm.projId + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    }

  },
  mounted () {
    this.search()
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },

    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.audit .attachment,
.audit .handbook {
  padding-bottom: 0;
  border-top: none;
}
</style>
