<template>
  <div
    class="container"
    style="min-height: 668px;"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">评审详情</span>
      <div class="btns">
        <span
          class="btn import"
          @click="$router.go(-1)"
        ><i class="iconfont"></i>返回上一级</span>
      </div>
    </div>
    <div class="search-box"></div>
    <div
      id="list"
      class="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>作品名称</th>
            <th>所属专业大类</th>
            <th>学校</th>
            <th>负责人</th>
            <th>分组</th>
            <th>得分</th>
            <th>专家数(已评/总)</th>
            <th width="180">评审详情</th>
          </tr>
          <tr
            v-for="item in audits"
            :key="item.id"
          >
            <td><a
                class="a-link"
                href="javascript:void(0)"
              ><span title="作品001">{{item.title}}</span></a></td>
            <td>{{item.category01}}</td>
            <td>{{item.school.title}}</td>
            <td>{{item.incharge}}</td>
            <td>{{item.auditGroup.title}}</td>
            <td>{{avg(item.audit.calType, item.scores, item.score_ids)}}</td>
            <td><span id="num_18637_105"><b>{{item.score_count ? item.score_count : 0}}</b>/<b>{{item.expert_count}}</b></span></td>
            <td>
              <span class="btn manage" @click="showAuditDetaiWindow(item.id)">评审详情</span>
              <span class="btn edit" @click="showVetoDetaiWindow(item.id)">否决性详情</span>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="audits.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>
    <!-- 评审详情弹窗 -->
    <div
      class="form_overlay"
      :style="classAuditDetailWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">评审详情</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideAuditDetaiWindow"
        ><i class="iconfont"></i>

        </a>
        <auditNormInfo :item_id="audit_detail_id"></auditNormInfo>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            @click="hideAuditDetaiWindow"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 否决性详情弹窗 -->
    <div
      class="form_overlay"
      :style="classVetoDetailWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">否决性详情</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideVetoDetaiWindow"
        ><i class="iconfont"></i>

        </a>
        <audit-norm-veto :item_id="veto_detail_id"></audit-norm-veto>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            @click="hideVetoDetaiWindow"
          >确定</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'
import AuditNormInfo from '../../components/AuditNormInfo'
import AuditNormVeto from '../../components/AuditNormVeto'

export default {
  name: 'MyAuditItemIndex',
  components: {
    Pagination,
    AuditNormInfo,
    AuditNormVeto
  },
  data () {
    return {
      audits_count: 0,
      audits: [],
      queryInfo: {
        page: 1,
        size: 10
      },
      classAuditDetailWindow: {},
      classVetoDetailWindow: {},
      audit_detail_id: '',
      veto_detail_id: ''
    }
  },
  mounted () {
    this.getAudits()
  },
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.audits_count / this.queryInfo.size),
        totalData: this.audits_count
      }
    }
  },
  methods: {
    pageCallback (data) {
      this.queryInfo.page = data
      this.getAudits()
    },
    // 数组求和
    sum (arr) {
      var len = arr.length
      if (len === 0) {
        return 0
      } else if (len === 1) {
        return arr[0]
      } else {
        return arr[0] + this.sum(arr.slice(1))
      }
    },
    avg (calType, scores, ids) {
      if (!scores || !ids) {
        return ''
      }

      const arrScore = scores.split(',').map(i => parseFloat(i))
      const arrId = ids.split(',').map(i => parseInt(i))
      const fixArr = {}
      for (let i = 0; i < arrId.length; i++) {
        fixArr[arrId[i]] = arrScore[i]
      }

      let floatScore = []
      for (const key in fixArr) {
        floatScore.push(fixArr[key])
      }
      if (calType === 1) {
        return this.sum(floatScore) / floatScore.length
      } else {
        floatScore = floatScore.sort()
        if (floatScore.length >= 3) {
          floatScore.pop()
          floatScore.shift()
        }
        return this.sum(floatScore) / floatScore.length
      }
      // return '' + calType + ' ' + scores
    },
    async getAudits () {
      try {
        this.queryInfo.auditGroup_id = this.$route.query.id
        const { results } = await this.$http.get('/audititem/', { params: this.queryInfo })
        this.audits = results
        // for (let i = 0; i < this.audits.length; i++) {
        //   this.audits[i].scoresArr = this.audits[i].scores.split(',').map(item => parseFloat(item))
        //   if (this.audits[i].audit.calType === 1 || this.audits[i].scoresArr.length <= 2) {
        //     // 算数平均分
        //     this.audits[i].newScores = this.sum(this.audits[i].scoresArr) / this.audits[i].scoresArr.length
        //   } else {
        //     // 结尾平均分
        //     this.audits[i].scoresArr.sort((a, b) => a - b)
        //     if (this.audits[i].scoresArr.length >= 3) {
        //       this.audits[i].scoresArr.pop()
        //       this.audits[i].scoresArr.shift()
        //     }
        //     this.audits[i].newScores = this.sum(this.audits[i].scoresArr) / this.audits[i].scoresArr.length
        //   }
        // }
        console.log(this.audits)
        this.audits_count = results.length
      } catch (error) {
        console.log(error)
      }
    },
    showAuditDetaiWindow (id) {
      this.classAuditDetailWindow = {
        visibility: 'visible'
      }
      console.log(id)
      this.audit_detail_id = id
    },
    // 关闭评审详情弹窗
    hideAuditDetaiWindow () {
      this.classAuditDetailWindow = {}
    },
    // 打开评审详情弹窗
    showVetoDetaiWindow (id) {
      this.classVetoDetailWindow = {
        visibility: 'visible'
      }
      console.log(id)
      this.veto_detail_id = id
    },
    // 关闭评审详情弹窗
    hideVetoDetaiWindow () {
      this.classVetoDetailWindow = {}
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
