<template>
  <div id="layout">
    <div
      id="north"
      class="north"
    >
      <Header></Header>
    </div>
    <div id="center" style="min-height: 742px;">
      <div class="list" style="min-height: 702px;">
        <div class="title clearfix">
          <p>修改密码</p>
        </div>
        <div class="listbox clearfix" id="list">
          <div class="pull-left">
            <form id="itemForm">
              <div class="item">
                <span class="title long">旧密码</span>
                <input type="password" v-model="pwdForm.oldPassword" value="" name="oldPassword" class="t-input" placeholder="输入旧密码">
              </div>
              <div class="item">
                <span class="title long">新密码</span>
                <input type="password" v-model="pwdForm.password" value="" name="password" class="t-input" placeholder="输入新密码">
              </div>
              <div class="item">
                <span class="title">确认密码</span>
                <input type="password" v-model="pwdForm.password2" value="" name="password2" class="t-input" placeholder="确认新密码">
              </div>
            </form>
            <span class="submitBtn" @click="changePassword">确认修改</span>
          </div>
          <div class="tip">
            <p>1、密码长度8-64位</p>
            <p>2、须同时包含大写字母、小写字母、数字、特殊字符中的任意三种</p>
            <p>3、密码不能包含邮箱</p>
          </div>
        </div>
      </div>
    </div>
    <div id="south">
      <div class="footer">
        <p class="center-p">京ICP备18004542号-1</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../../components/Header'

export default {
  name: 'ChangePwd',
  components: {
    Header
  },
  data () {
    return {
      pwdForm: {
        oldPassword: '',
        password: '',
        password2: ''
      }
    }
  },
  methods: {
    async changePassword () {
      if (this.pwdForm.oldPassword.trim() === '') {
        alert('请输入原始密码')
        return false
      }

      if (this.pwdForm.password.trim() === '') {
        alert('请输入新密码')
        return false
      }
      var testPassword = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,64}$/
      if (testPassword.test(this.pwdForm.password) === false) {
        alert('密码须同时包含大写字母、小写字母、数字、特殊字符中的任意三种')
        return false
      }
      var Email = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (Email.test(this.pwdForm.password) === true) {
        alert('密码不能是邮箱')
        return false
      }
      if (this.pwdForm.password2.trim() === '') {
        alert('请输入确认密码')
        return false
      }
      if (this.pwdForm.password.length < 8) {
        alert('密码至少8位')
        return false
      }

      if (this.pwdForm.password.length > 64) {
        alert('密码最多64位')
        return false
      }

      if (this.pwdForm.password !== this.pwdForm.password2) {
        alert('新密码和确认密码不一致')
        return false
      }
      const url = '/change_password/'
      await this.$http.put(url, this.pwdForm).then((data) => {
        alert('密码修改成功，请重新登陆')
        window.localStorage.clear()
        this.$router.push('/login')
      }).catch((error) => {
        console.log(error)
        alert('旧密码输入错误！')
      })
    }
  }
}
</script>

<style scoped>
.list {
  width: 1140px;
  margin:30px auto;
  position:relative;
  background:#fff;
  padding: 0 30px 30px;
}

.list>.title {
  height:64px;
  border-bottom:1px solid #eee;
}

.list .title p {
  float:left;
  font-size:18px;
  line-height: 64px;
  height:64px;
  padding-left: 18px;
  position: relative;
}

.list .title p:before {
  content:" ";
  border: 3px solid #d22800;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 27px;
  left: 0;
  border-radius: 2px;
}

.pull-left {
  float:left;
}

#itemForm {
  padding:30px 0;
}

.item {
  margin-bottom:20px;
}

.t-input {
  border: 1px solid #dbdbdb;
  font-size: 14px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  width: 300px;
  border-radius: 2px;
}

#itemForm .title {
  color: #444;
  margin-right: 20px;
  display: inline-block;
  width: 80px;
}

.t-select {
  border: 1px solid #dbdbdb;
  font-size: 14px;
  height: 32px;
  line-height: 32px;
  min-width: 120px;
}

.submitBtn,.submitBtn-card {
  background: #2948ff;
  color: #fff;
  font-size: 15px;
  height: 42px;
  line-height: 42px;
  margin-left: 103px;
  padding: 0 40px;
  cursor:pointer;
  display: inline-block;
  border-radius: 2px;
}

.submitBtn:hover {
  background: #3451ff;
}

.h-title {
  font-size: 18px;
  color: #666;
  padding-left: 14px;
  border-left: 4px solid #666;
  line-height: 18px;
  height: 20px;
  margin-bottom: 20px;
}

.tip {
  padding:40px 0 0;
  float: right;
}

.tip p {
  color:#666;
  font-size:14px;
  line-height:24px;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .pull-left, .pull-right, .tip {
    float: none;
  }
  .list {
    width: 94%;
    padding: 0 3% 30px;
  }
  .list>.title {
    display: none;
  }
  #itemForm .title {
    display: none;
  }
  .t-input {
    width: 94%;
    padding: 0 3%;
    height: 42px;
    line-height: 42px;
  }
  .submitBtn, .submitBtn-card {
    margin-left: 0;
    width: 100%;
    padding: 0;
    text-align: center;
  }
}
</style>
