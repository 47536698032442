<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">专业管理</span>
      <div class="btns">
        <span
          class="btn import"
          @click="showUploadWindow"
        ><i class="iconfont"></i>导入专业</span>
        <span
          class="btn add"
          @click="showWindow('add')"
        ><i class="iconfont"></i>新增专业</span>
      </div>
    </div>
    <div class="search-box">
      <transition name="fade">
        <form
          class="form-inline open"
          id="filterForm"
          v-if="isOpen"
          style="display: block; height: auto;"
        >
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="title"
              placeholder="专业名称"
              v-model="queryInfo.title"
            ></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="status"
              v-model="queryInfo.status"
            >
              <option value="">全部状态</option>
              <option value="1">已发布</option>
              <option value="0">未发布</option>
            </select></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="grade"
              v-model="queryInfo.level"
            >
              <option value="">全部级别</option>
              <option
                v-for="(val,keys) in dict_subject_level"
                :key="keys"
                :value="keys"
              >{{val}}</option>
            </select></div>
          <div class="form-group"><button
              type="button"
              class="btn btn-primary btn-rounded btn-search"
              @click="pageCallback(1)"
            ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
        </form>
      </transition>
      <span
        class="btn btn-danger btn-open"
        @click="toggleQueryForm"
      >{{isOpen?'关闭':'展开'}}</span>
    </div>
    <div
      class="list"
      id="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>专业编号</th>
            <th>级别</th>
            <th>父级专业</th>
            <th>名称</th>
            <th>状态</th>
            <th width="180">操作</th>
          </tr>

          <tr
            v-for="item in tableData"
            :key="item.id"
          >
            <td>{{item.code}}</td>
            <td>{{dict_subject_level[item.level]}}</td>
            <td>{{formatParent(item.parent)}}</td>
            <td>{{item.title}}</td>
            <td :style="item.status?'color:green':'color:red'">{{item.status?'已发布':'未发布'}}</td>
            <td>
              <a
                href="javascript:void(0)"
                class="btn nopublish"
                @click="isPublish(item)"
              >{{item.status?'取消发布':'发布'}}</a>
              <a
                href="javascript:void(0)"
                class="btn edit"
                @click="querySingle(item.id)"
              >编辑</a>
              <a
                href="javascript:void(0)"
                class="btn delete"
                @click="delInfo(item.id)"
              >删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!tableData.length"
      >没有数据</div>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="tableData.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>
    <!-- 添加/编辑弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">专业管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>级别:</span>
              <select
                name="grade"
                v-model="formInfo.level"
                @change="toggleLevel(formInfo.level)"
              >
                <option value="">选择级别</option>
                <option
                  v-for="(val,keys) in dict_subject_level"
                  :key="keys"
                  :value="keys"
                >{{val}}</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专业编号:</span>
              <input
                type="text"
                name="num"
                v-model="formInfo.code"
                @blur="checkCode(formInfo.code)"
              >
            </div>
            <div
              class="item"
              v-if="formInfo.level==2"
            >
              <span class="label"><i class="require">*</i>专业大类:</span>
              <select
                name="parentTitle"
                v-model="formInfo.parent"
              >
                <option value="">请选择专业大类</option>
                <option
                  v-for="item in categoryData"
                  :key="item.id"
                  :value="item.id"
                >{{item.title}}</option>
              </select>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>名称:</span>
              <input
                type="text"
                name="title"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>英文名称:</span>
              <input
                type="text"
                name="en_title"
                v-model="formInfo.en_title"
              >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 上传弹窗 -->
    <div
      class="form_overlay"
      id="form_upload_overlay"
      :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideUploadWindow"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <label
                for="name"
                class="control-label col-xs-3 font-normal text-muted"
              ><i class="require">*</i>模板创建规则:</label>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为常用的Excel文件（*.xlsx或*.xls）；</p>
                  <p>2. 表格头说明：level（级别[pri_subject(一级)、sub_subject(二级)、num（编码）、title（名称）、parentNum（父级编号）、en_title（英文名称）；</p>
                  <p>3. 其中level、num、title为必填项；</p>
                  <p>4. 导入的数据总条数不得超过2000条，如超过，则需分多次导入；</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_subject_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div class="item">
              <label
                for="name"
                class="label font-normal text-muted"
              ><i class="require">*</i>导入文件:</label>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="fileUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="fileUpload"
                    ref="xlsFile"
                    accept=".csv,.xls,.xlsx"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideUploadWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'
export default {
  name: 'Subject',
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        title: '',
        code: '',
        level: '',
        status: ''
      },
      tableData: [],
      tableDataCount: 0,
      classShowWindow: {},
      classShowUploadWindow: {},
      formInfo: {
        id: 0,
        title: '',
        code: '',
        en_title: '',
        level: '',
        parent: '',
        status: 1
      },
      isOpen: false,
      categoryData: [],
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
      classShowProcessWindow: {},
      secondLevelData: [],
      checkRes: false
    }
  },
  methods: {
    toggleLevel (val) {
      if (val === '2') {
        this.formInfo.parent = ''
      }
    },
    formatParent (val) {
      if (val) {
        for (let i = 0; i < this.categoryData.length; i++) {
          if (this.categoryData[i].id === val) {
            return this.categoryData[i].title
          }
        }
      } else {
        return '-'
      }
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    isPublish (item) {
      var that = this
      this.$toast.question(`确认${item.status ? '取消发布' : '发布'}此条数据吗?`, ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.publish(item)
          }
        }

      })
    },
    async publish (item) {
      const url = '/subject/' + item.id + '/'
      var status = item.status ? 0 : 1
      await this.$http.put(url, {
        status: status
      }).then((data) => {
        if (data.id) {
          this.$toast.success('操作成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('操作失败！', '', this.error_tips)
        }
      }).catch(() => {
        this.$toast.error('操作失败！', '', this.error_tips)
      })
    },
    // 搜索
    async search () {
      await this.$http.get('/subject/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取一级专业
    async getCategoryInfo (val) {
      const params = {
        page: 1,
        size: 10000,
        level: 1,
        code: val
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },

    // 编辑时查询
    async querySingle (id) {
      const url = '/subject/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        this.formInfo.id = id
        this.formInfo.title = data.title
        this.formInfo.code = data.code
        this.formInfo.en_title = data.en_title
        this.formInfo.level = data.level
        this.formInfo.parent = data.parent
        this.formInfo.status = data.status

        this.showWindow('edit')
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    async checkCode (val) {
      const params = {
        page: 1,
        size: 100000,
        code: val
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        if (data.results.length) {
          this.checkRes = true
        } else {
          this.checkRes = false
        }
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 保存
    async saveInfo () {
      console.log(this.formInfo)
      if (!this.formInfo.level) {
        this.$toast.error('请选择专业级别', '', this.error_tips)
        return false
      }
      if (!this.formInfo.code) {
        this.$toast.error('专业编号不能为空', '', this.error_tips)
        return false
      }
      if (this.checkRes) {
        this.$toast.error('专业编号不能重复', '', this.error_tips)
        return false
      }
      if (this.formInfo.level === 2 && !this.formInfo.parent) {
        this.$toast.error('请选择专业大类', '', this.error_tips)
        return false
      }
      if (!this.formInfo.title) {
        this.$toast.error('名称不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.en_title) {
        this.$toast.error('英文名称不能为空', '', this.error_tips)
        return false
      }
      if (this.formInfo.level === '1') {
        this.formInfo.parent = ''
      }

      if (this.formInfo.id) {
        const url = '/subject/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideWindow()
            this.getCategoryInfo()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('修改失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/subject/', this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideWindow()
            this.getCategoryInfo()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('添加失败！', '', this.error_tips)
        })
      }
    },
    delInfo (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/subject/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.getCategoryInfo()
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },

    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$toast.error('上传失败！', '', this.error_tips)
      })
    },
    async submitCsv () {
      const uploadUrl = '/get_xls/'
      await this.$http.get(uploadUrl, {
        params: {
          file_name: this.uploadedCsv
        }
      }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }

        data.splice(0, 1)

        this.uploadedCscData = data
        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }
      debugger
      const row = this.uploadedCscData[this.curCsvIndex]
      let checkOneSubject, categoryData
      // region 数据检查开始
      const uploadForm = {
        title: row[2],
        code: row[1],
        en_title: row[4],
        parent: row[3],
        status: 1
      }
      if (row[0].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入专业级别！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (row[0] === '一级' || Number(row[0]) === 1 || row[0] === 'pri_subject') {
        uploadForm.level = '1'
      } else if (row[0] === '二级' || Number(row[0]) === 2 || row[0] === 'sub_subject') {
        uploadForm.level = '2'
      }
      if (row[1].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入专业编号！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }

      if (row[0] === '一级' || Number(row[0]) === 1 || row[0] === 'pri_subject') {
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 10000,
            level: 1,
            code: row[1]
          }
        }).then((data) => {
          checkOneSubject = data.results
        })
        if (checkOneSubject.length) {
          this.errorList.push({ code: 0, text: '[' + row[1].trim() + ']' + '一级专业编号重复！' })
          this.curCsvIndex++
          await this.insertOne()
          return
        }
      }

      if (row[2].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入专业名称！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if ((row[0] === '二级' || Number(row[0]) === 2 || row[0] === 'sub_subject') && row[3].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入专业大类编号！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }

      if (row[0] === '二级' || Number(row[0]) === 2 || row[0] === 'sub_subject') {
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 10000,
            level: 1
          }
        }).then((data) => {
          categoryData = data.results
        })
        for (let i = 0; i < categoryData.length; i++) {
          if (Number(categoryData[i].code) === Number(row[3])) {
            uploadForm.parent = categoryData[i].id
            break
          } else {
            uploadForm.parent = null
          }
        }
        if (!uploadForm.parent) {
          this.errorList.push({ code: 0, text: '找不到对应的专业大类！' })
          this.curCsvIndex++
          await this.insertOne()
          return
        }
        let secondLevelData
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 10000,
            level: 2,
            parent_id: uploadForm.parent,
            code: row[1]
          }
        }).then((data) => {
          secondLevelData = data.results
        })
        if (secondLevelData.length) {
          this.errorList.push({ code: 0, text: '[' + row[1].trim() + ']' + '二级专业编号重复！' })
          this.curCsvIndex++
          await this.insertOne()
          return
        }
      }
      console.log(uploadForm)
      await this.$http.post('/subject/', uploadForm).then(async (profileData) => {
        this.errorList.push({ code: 1, text: '[' + uploadForm.title + ']' + '导入成功！' })
        this.curCsvIndex++
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 10000,
            level: 1
          }
        }).then((data) => {
          categoryData = data.results
        })
        this.insertOne()
      }).catch(async (error) => {
        console.log(error)
        this.errorList.push({ code: 0, text: '[' + uploadForm.title + ']' + error.response.data[0] })
        this.curCsvIndex++
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 10000,
            level: 1
          }
        }).then((data) => {
          categoryData = data.results
        })
        this.insertOne()
      })
    },
    // 打开添加/编辑弹窗
    showWindow (value) {
      if (value === 'add') {
        this.formInfo = {
          id: 0,
          title: '',
          code: '',
          en_title: '',
          level: '',
          parent: '',
          status: 1
        }
      }
      this.classShowWindow = {
        visibility: 'visible'
      }
      this.getCategoryInfo()
    },
    // 关闭添加/编辑弹窗
    hideWindow () {
      this.classShowWindow = {}
      this.cityData = []
    },
    // 打开上传弹窗
    showUploadWindow () {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传弹窗
    hideUploadWindow () {
      this.classShowUploadWindow = {}
      this.uploadedCsvName = ''
      this.uploadedCsv = []
      this.$refs.xlsFile = []
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    dict_subject_level () {
      return this.$store.state.dict_subject_level
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }

  },
  mounted () {
    this.search()
    this.getCategoryInfo()
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
