import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'

import VueIziToast from 'vue-izitoast'
import 'izitoast/dist/css/iziToast.css'
import 'izitoast/dist/css/iziToast.min.css'

Vue.use(VueIziToast)

axios.defaults.baseURL = store.state.rootUrl + 'api'

axios.interceptors.request.use(config => {
  if (config.url !== '/api-token-auth/') {
    const token = store.state.token
    config.headers.common.Authorization = 'Token ' + token
  }
  return config
})

axios.interceptors.response.use(function (response) {
  return response.data
})

Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
