<template>
  <div class="header">
    <div class="header-bg"></div>
    <div class="header_container">
      <div class="logo">
        <span>虚拟仿真实验教学一流课程评审平台</span>
      </div>
      <div class="nav clearfix">
        <div class="m-menu-icon"><i class="iconfont"></i>
        </div>
        <ul
          class="clearfix"
          id="menu"
        >

        </ul>
        <div class="nav-right">
          <div class="user-btns hide">
            <a
              href="/sign/on"
              class="login-btn"
            >登录</a>
            <a
              href="/sign/up"
              class="reg-btn"
            >注册</a>
          </div>
          <div
            id="user-info"
            class="hide"
            style="display: block;"
          >
            <div class="user-box"><img src="../assets/images/photo.jpg"><span class="uname">{{localUser.name}}</span> <i class="iconfont"></i>
              <div class="login-wrap hide">
                <ul>
                  <li>
                    <a @click="toAdmin"><i class="iconfont icon1"></i>后台管理
                    </a>
                  </li>

                  <li>
                    <router-link to="/change_pwd"><i class="iconfont icon1"></i>修改密码
                    </router-link>
                  </li>
                  <li
                    id="signOff"
                    @click="doLogout"
                  ><a href="javascript:void(0)"><i class="iconfont icon1"></i>退出登录</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  computed: {
    localUser () {
      return this.$store.state.userInfo
    }
  },
  methods: {
    doLogout () {
      this.$store.commit('setToken', '')
      this.$store.commit('setUserInfo', {
        profile_id: 0,
        user_name: '',
        name: '',
        avatar: '',
        gender: '',
        mobile: '',
        role: '',
        province: '',
        school_id: '',
        school_name: '',
        group_leader: ''
      })

      this.$router.push('/login')
    },
    toAdmin () {
      if (this.localUser.role === 1) {
        window.location.href = '/admin/my_audit'
      } else {
        window.location.href = '/admin/user'
      }
    }
  }
}
</script>

<style scoped src="../assets/css/header.css"></style>
<style scoped>
.header_container .logo {
  width: auto;
}
.logo span {
  margin-left: 30px;
  line-height: 60px;
  font-weight: bold;
  font-size: 20px;
}
</style>
