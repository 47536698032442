<template>
  <div class="login">
    <div>
      <div class="container">
        <div class="login-ctn">
          <div class="login-wrap"></div>
          <div class="login-cont">
            <div class="login-title">

              登录</div>
            <form
              class="login-form"
              id="loginForm"
            >
              <div class="login-item"> <i class="iconfont"></i>

                <input
                  type="text"
                  name="username"
                  placeholder="输入账号"
                  required
                  autocomplete="off"
                  v-model="loginForm.username"
                  @keydown.enter="login"
                >
              </div>
              <div class="login-item"> <i class="iconfont"></i>

                <input
                  type="password"
                  name="password"
                  placeholder="输入密码"
                  required
                  autocomplete="off"
                  v-model="loginForm.password"
                  @keydown.enter="login"
                >
              </div>
            </form>
            <div class="login-toolbar clearfix">
              <!-- <router-link to="/www/find/password">找回密码</router-link> -->
            </div>
            <div
              class="login-btn"
              @click="login"
            >登录</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>

          <span>京ICP备18004542号-1</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      // 登录表单
      loginForm: {
        username: '',
        password: ''
      },
      notificationSystem: {
        options: {
          error: {
            position: 'topRight',
            backgroundColor: 'red',
            messageColor: 'white',
            titleColor: 'white',
            iconColor: '#fff'
          }
        }
      }
    }
  },
  methods: {
    async login () {
      if (!this.loginForm.username) {
        this.$toast.error('请输入用户名', '', this.notificationSystem.options.error)
        return
      }
      if (!this.loginForm.password) {
        this.$toast.error('请输入密码！', '', this.notificationSystem.options.error)
        return
      }

      // 登录方法
      await this.$http.post('/api-token-auth/', this.loginForm).then(async (data) => {
        console.log(data)
        if (!data.token) {
          return this.$toast.error('账号或密码输入错误！', '', this.notificationSystem.options.error)
        }
        window.localStorage.clear()
        // 登录后添加token到vuex中
        this.$store.commit('setToken', data.token)
        await this.getUserInfo()
      }).catch(() => {
        this.$toast.error('账号或密码输入错误！', '', this.notificationSystem.options.error)
      })
    },
    async getUserInfo () {
      await this.$http.post('/login_user/', {}).then(async (data) => {
        this.$store.commit('setUserInfo', data)
        console.log(data)
        if (data.role === 0) {
          this.$router.push('/')
        } else {
          this.$router.push('/admin/my_audit')
        }
      }).catch(() => {
        return this.$toast.error('登陆失败！', '', this.notificationSystem.options.error)
      })
    }
  }
}
</script>
<style scoped>
.login {
  height: 100vh;
  min-height: 580px;
  width: 100%;
  position: relative;
}
.login:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("../../assets/images/login-bg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
}
.copyright {
  position: absolute;
  width: 100%;
  color: #fff;
  bottom: 30px;
  text-align: center;
  z-index: 1;
}

.copyright span {
  margin: 0 15px;
}

.container .login-ctn {
  position: absolute;
  z-index: 999;
  margin: auto;
  width: 430px;
  top: 50%;
  margin-top: -200px;
  left: 50%;
  margin-left: -215px;
  height: 400px;
}

.login-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}

.login-cont {
  position: relative;
  z-index: 1;
}

.login-title {
  width: 100%;
  text-align: center;
  padding-top: 30px;
  font-size: 24px;
  color: #fff;
}

.login-title .logo {
  display: inline-block;
  width: 320px;
  height: 53px;
}

.login-title .logo img {
  width: 70%;
}

.login-form {
  width: 320px;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0;
  box-sizing: border-box;
}

.login-toolbar {
  width: 320px;
  box-sizing: border-box;
  padding-bottom: 15px;
}

.login-item {
  width: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 20px;
  padding-left: 50px;
  position: relative;
}

.login-item .iconfont {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -8px;
}

.login-item + .login-item {
  margin-top: 30px;
}

.login-item input {
  width: 100%;
  height: 44px;
  line-height: 42px;
  box-sizing: border-box;
  border: none;
  background: none;
}

.login-item:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.login-btn {
  width: 320px;
  margin: 0 auto;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #fff;
  background-color: #2948ff;
  cursor: pointer;
  font-size: 18px;
}

.login-btn:hover {
  background-color: #3451ff;
}

.login-toolbar {
  margin: 20px auto;
}

.login-toolbar a {
  color: #fff;
  text-decoration: underline;
}

.login-toolbar #forgetpw {
  float: left;
}

.login-toolbar .login-remember {
  float: left;
  color: #999;
}

.login-toolbar .login-remember input {
  position: relative;
  opacity: 0;
  margin-left: 10px;
}

.login-toolbar .login-remember i {
  display: inline-block;
  position: absolute;
  background: #e8ecf5;
  border: 1px solid #d4dae9;
  color: #d4dae9;
  border-radius: 3px;
  margin-top: 2px;
  width: 13px;
  height: 13px;
}

@media screen and (min-width: 320px) and (max-width: 640px) {
  .copyright span {
    display: block;
  }

  #layout {
    min-width: 0;
    background-size: auto 100%;
    min-height: auto;
  }

  .container .login-ctn {
    width: 90%;
    height: auto;
    position: relative;
    margin: 0 auto;
    left: 0;
    top: 20px;
    padding-top: 90px;
    z-index: 2;
    padding: 20px 0;
  }

  .login-title .logo {
    width: 100%;
    height: auto;
  }

  .login-title .logo img {
    width: 100%;
    height: auto;
  }

  .login-title {
    padding-top: 0;
    width: 80%;
    margin: 0 auto;
  }

  .login-toolbar {
    padding: 0;
    width: 90%;
    margin: 15px auto;
  }

  .login-form {
    margin: 0 auto;
    margin-top: 15px;
    padding: 0;
    width: 90%;
  }

  .login-btn,
  .expert-btn {
    width: 90%;
  }

  .login-item {
    width: 100%;
  }

  .login-item input {
    width: 70%;
  }
}
</style>
