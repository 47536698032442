import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const rootUrl = ''
// dev
// const rootUrl = 'http://127.0.0.1:8080'

export default new Vuex.Store({
  state: {
    api: rootUrl,
    rootUrl: rootUrl + '/',
    userInfo: {
      profile_id: 0,
      user_name: '',
      name: '',
      avatar: '',
      gender: '',
      mobile: '',
      role: '',
      province: '',
      school_id: '',
      school_name: '',
      group_leader: ''
    },
    token: '',
    dict_role: {
      0: '系统管理员',
      1: '专家',
      2: '省级管理员'
    },
    dict_province: [
      {
        id: 2,
        code: '110000',
        title: '北京市',
        p_code: '0'
      },
      {
        id: 19,
        code: '120000',
        title: '天津市',
        p_code: '0'
      },
      {
        id: 36,
        code: '130000',
        title: '河北省',
        p_code: '0'
      },
      {
        id: 230,
        code: '140000',
        title: '山西省',
        p_code: '0'
      },
      {
        id: 371,
        code: '150000',
        title: '内蒙古自治区',
        p_code: '0'
      },
      {
        id: 493,
        code: '210000',
        title: '辽宁省',
        p_code: '0'
      },
      {
        id: 623,
        code: '220000',
        title: '吉林省',
        p_code: '0'
      },
      {
        id: 701,
        code: '230000',
        title: '黑龙江省',
        p_code: '0'
      },
      {
        id: 859,
        code: '310000',
        title: '上海市',
        p_code: '0'
      },
      {
        id: 879,
        code: '320000',
        title: '江苏省',
        p_code: '0'
      },
      {
        id: 1011,
        code: '330000',
        title: '浙江省',
        p_code: '0'
      },
      {
        id: 1124,
        code: '340000',
        title: '安徽省',
        p_code: '0'
      },
      {
        id: 1260,
        code: '350000',
        title: '福建省',
        p_code: '0'
      },
      {
        id: 1364,
        code: '360000',
        title: '江西省',
        p_code: '0'
      },
      {
        id: 1487,
        code: '370000',
        title: '山东省',
        p_code: '0'
      },
      {
        id: 1661,
        code: '410000',
        title: '河南省',
        p_code: '0'
      },
      {
        id: 1855,
        code: '420000',
        title: '湖北省',
        p_code: '0'
      },
      {
        id: 1984,
        code: '430000',
        title: '湖南省',
        p_code: '0'
      },
      {
        id: 2134,
        code: '440000',
        title: '广东省',
        p_code: '0'
      },
      {
        id: 2296,
        code: '450000',
        title: '广西壮族自治区',
        p_code: '0'
      },
      {
        id: 2434,
        code: '460000',
        title: '海南省',
        p_code: '0'
      },
      {
        id: 2463,
        code: '500000',
        title: '重庆市',
        p_code: '0'
      },
      {
        id: 2544,
        code: '510000',
        title: '四川省',
        p_code: '0'
      },
      {
        id: 2766,
        code: '520000',
        title: '贵州省',
        p_code: '0'
      },
      {
        id: 2867,
        code: '530000',
        title: '云南省',
        p_code: '0'
      },
      {
        id: 3021,
        code: '540000',
        title: '西藏自治区',
        p_code: '0'
      },
      {
        id: 3103,
        code: '610000',
        title: '陕西省',
        p_code: '0'
      },
      {
        id: 3231,
        code: '620000',
        title: '甘肃省',
        p_code: '0'
      },
      {
        id: 3341,
        code: '630000',
        title: '青海省',
        p_code: '0'
      },
      {
        id: 3394,
        code: '640000',
        title: '宁夏回族自治区',
        p_code: '0'
      },
      {
        id: 3424,
        code: '650000',
        title: '新疆维吾尔自治区',
        p_code: '0'
      },
      {
        id: 3541,
        code: '990000',
        title: '新疆建设兵团',
        p_code: '0'
      }
    ],
    dict_city: [

      {
        id: 3,
        code: '110100',
        title: '东城区',
        p_code: '110000'
      },
      {
        id: 4,
        code: '110200',
        title: '西城区',
        p_code: '110000'
      },
      {
        id: 5,
        code: '110500',
        title: '朝阳区',
        p_code: '110000'
      },
      {
        id: 6,
        code: '110600',
        title: '丰台区',
        p_code: '110000'
      },
      {
        id: 7,
        code: '110700',
        title: '石景山区',
        p_code: '110000'
      },
      {
        id: 8,
        code: '110800',
        title: '海淀区',
        p_code: '110000'
      },
      {
        id: 9,
        code: '110900',
        title: '门头沟区',
        p_code: '110000'
      },
      {
        id: 10,
        code: '111100',
        title: '房山区',
        p_code: '110000'
      },
      {
        id: 11,
        code: '111200',
        title: '通州区',
        p_code: '110000'
      },
      {
        id: 12,
        code: '111300',
        title: '顺义区',
        p_code: '110000'
      },
      {
        id: 13,
        code: '111400',
        title: '昌平区',
        p_code: '110000'
      },
      {
        id: 14,
        code: '111500',
        title: '大兴区',
        p_code: '110000'
      },
      {
        id: 15,
        code: '111600',
        title: '怀柔区',
        p_code: '110000'
      },
      {
        id: 16,
        code: '111700',
        title: '平谷区',
        p_code: '110000'
      },
      {
        id: 17,
        code: '112800',
        title: '密云县',
        p_code: '110000'
      },
      {
        id: 18,
        code: '112900',
        title: '延庆县',
        p_code: '110000'
      },

      {
        id: 20,
        code: '120100',
        title: '和平区',
        p_code: '120000'
      },
      {
        id: 21,
        code: '120200',
        title: '河东区',
        p_code: '120000'
      },
      {
        id: 22,
        code: '120300',
        title: '河西区',
        p_code: '120000'
      },
      {
        id: 23,
        code: '120400',
        title: '南开区',
        p_code: '120000'
      },
      {
        id: 24,
        code: '120500',
        title: '河北区',
        p_code: '120000'
      },
      {
        id: 25,
        code: '120600',
        title: '红桥区',
        p_code: '120000'
      },
      {
        id: 26,
        code: '120900',
        title: '滨海新区',
        p_code: '120000'
      },
      {
        id: 27,
        code: '121000',
        title: '东丽区',
        p_code: '120000'
      },
      {
        id: 28,
        code: '121100',
        title: '西青区',
        p_code: '120000'
      },
      {
        id: 29,
        code: '121200',
        title: '津南区',
        p_code: '120000'
      },
      {
        id: 30,
        code: '121300',
        title: '北辰区',
        p_code: '120000'
      },
      {
        id: 31,
        code: '121400',
        title: '武清区',
        p_code: '120000'
      },
      {
        id: 32,
        code: '121500',
        title: '宝坻区',
        p_code: '120000'
      },
      {
        id: 33,
        code: '122100',
        title: '宁河县',
        p_code: '120000'
      },
      {
        id: 34,
        code: '122300',
        title: '静海县',
        p_code: '120000'
      },
      {
        id: 35,
        code: '122500',
        title: '蓟县',
        p_code: '120000'
      },

      {
        id: 37,
        code: '130100',
        title: '石家庄市',
        p_code: '130000'
      },
      {
        id: 38,
        code: '130101',
        title: '市辖区',
        p_code: '130100'
      },
      {
        id: 39,
        code: '130102',
        title: '长安区',
        p_code: '130101'
      },
      {
        id: 40,
        code: '130103',
        title: '桥东区',
        p_code: '130101'
      },
      {
        id: 41,
        code: '130104',
        title: '桥西区',
        p_code: '130101'
      },
      {
        id: 42,
        code: '130105',
        title: '新华区',
        p_code: '130101'
      },
      {
        id: 43,
        code: '130107',
        title: '井陉矿区',
        p_code: '130101'
      },
      {
        id: 44,
        code: '130108',
        title: '裕华区',
        p_code: '130101'
      },
      {
        id: 45,
        code: '130121',
        title: '井陉县',
        p_code: '130100'
      },
      {
        id: 46,
        code: '130123',
        title: '正定县',
        p_code: '130100'
      },
      {
        id: 47,
        code: '130124',
        title: '栾城县',
        p_code: '130100'
      },
      {
        id: 48,
        code: '130125',
        title: '行唐县',
        p_code: '130100'
      },
      {
        id: 49,
        code: '130126',
        title: '灵寿县',
        p_code: '130100'
      },
      {
        id: 50,
        code: '130127',
        title: '高邑县',
        p_code: '130100'
      },
      {
        id: 51,
        code: '130128',
        title: '深泽县',
        p_code: '130100'
      },
      {
        id: 52,
        code: '130129',
        title: '赞皇县',
        p_code: '130100'
      },
      {
        id: 53,
        code: '130130',
        title: '无极县',
        p_code: '130100'
      },
      {
        id: 54,
        code: '130131',
        title: '平山县',
        p_code: '130100'
      },
      {
        id: 55,
        code: '130132',
        title: '元氏县',
        p_code: '130100'
      },
      {
        id: 56,
        code: '130133',
        title: '赵县',
        p_code: '130100'
      },
      {
        id: 57,
        code: '130181',
        title: '辛集市',
        p_code: '130100'
      },
      {
        id: 58,
        code: '130182',
        title: '藁城市',
        p_code: '130100'
      },
      {
        id: 59,
        code: '130183',
        title: '晋州市',
        p_code: '130100'
      },
      {
        id: 60,
        code: '130184',
        title: '新乐市',
        p_code: '130100'
      },
      {
        id: 61,
        code: '130185',
        title: '鹿泉市',
        p_code: '130100'
      },
      {
        id: 62,
        code: '130200',
        title: '唐山市',
        p_code: '130000'
      },
      {
        id: 63,
        code: '130201',
        title: '市辖区',
        p_code: '130200'
      },
      {
        id: 64,
        code: '130202',
        title: '路南区',
        p_code: '130201'
      },
      {
        id: 65,
        code: '130203',
        title: '路北区',
        p_code: '130201'
      },
      {
        id: 66,
        code: '130204',
        title: '古冶区',
        p_code: '130201'
      },
      {
        id: 67,
        code: '130205',
        title: '开平区',
        p_code: '130201'
      },
      {
        id: 68,
        code: '130207',
        title: '丰南区',
        p_code: '130201'
      },
      {
        id: 69,
        code: '130208',
        title: '丰润区',
        p_code: '130201'
      },
      {
        id: 70,
        code: '130223',
        title: '滦县',
        p_code: '130200'
      },
      {
        id: 71,
        code: '130224',
        title: '滦南县',
        p_code: '130200'
      },
      {
        id: 72,
        code: '130225',
        title: '乐亭县',
        p_code: '130200'
      },
      {
        id: 73,
        code: '130227',
        title: '迁西县',
        p_code: '130200'
      },
      {
        id: 74,
        code: '130229',
        title: '玉田县',
        p_code: '130200'
      },
      {
        id: 75,
        code: '130230',
        title: '唐海县',
        p_code: '130200'
      },
      {
        id: 76,
        code: '130281',
        title: '遵化市',
        p_code: '130200'
      },
      {
        id: 77,
        code: '130283',
        title: '迁安市',
        p_code: '130200'
      },
      {
        id: 78,
        code: '130300',
        title: '秦皇岛市',
        p_code: '130000'
      },
      {
        id: 79,
        code: '130301',
        title: '海港区',
        p_code: '130300'
      },
      {
        id: 80,
        code: '130303',
        title: '山海关区',
        p_code: '130300'
      },
      {
        id: 81,
        code: '130304',
        title: '北戴河区',
        p_code: '130300'
      },
      {
        id: 82,
        code: '130321',
        title: '青龙满族自治县',
        p_code: '130300'
      },
      {
        id: 83,
        code: '130322',
        title: '昌黎县',
        p_code: '130300'
      },
      {
        id: 84,
        code: '130323',
        title: '抚宁县',
        p_code: '130300'
      },
      {
        id: 85,
        code: '130324',
        title: '卢龙县',
        p_code: '130300'
      },
      {
        id: 86,
        code: '130400',
        title: '邯郸市',
        p_code: '130000'
      },
      {
        id: 87,
        code: '130401',
        title: '市辖区',
        p_code: '130400'
      },
      {
        id: 88,
        code: '130402',
        title: '邯山区',
        p_code: '130401'
      },
      {
        id: 89,
        code: '130403',
        title: '丛台区',
        p_code: '130401'
      },
      {
        id: 90,
        code: '130404',
        title: '复兴区',
        p_code: '130401'
      },
      {
        id: 91,
        code: '130406',
        title: '峰峰矿区',
        p_code: '130401'
      },
      {
        id: 92,
        code: '130421',
        title: '邯郸县',
        p_code: '130400'
      },
      {
        id: 93,
        code: '130423',
        title: '临漳县',
        p_code: '130400'
      },
      {
        id: 94,
        code: '130424',
        title: '成安县',
        p_code: '130400'
      },
      {
        id: 95,
        code: '130425',
        title: '大名县',
        p_code: '130400'
      },
      {
        id: 96,
        code: '130426',
        title: '涉县',
        p_code: '130400'
      },
      {
        id: 97,
        code: '130427',
        title: '磁县',
        p_code: '130400'
      },
      {
        id: 98,
        code: '130428',
        title: '肥乡县',
        p_code: '130400'
      },
      {
        id: 99,
        code: '130429',
        title: '永年县',
        p_code: '130400'
      },
      {
        id: 100,
        code: '130430',
        title: '邱县',
        p_code: '130400'
      },
      {
        id: 101,
        code: '130431',
        title: '鸡泽县',
        p_code: '130400'
      },
      {
        id: 102,
        code: '130432',
        title: '广平县',
        p_code: '130400'
      },
      {
        id: 103,
        code: '130433',
        title: '馆陶县',
        p_code: '130400'
      },
      {
        id: 104,
        code: '130434',
        title: '魏县',
        p_code: '130400'
      },
      {
        id: 105,
        code: '130435',
        title: '曲周县',
        p_code: '130400'
      },
      {
        id: 106,
        code: '130481',
        title: '武安市',
        p_code: '130400'
      },
      {
        id: 107,
        code: '130500',
        title: '邢台市',
        p_code: '130000'
      },
      {
        id: 108,
        code: '130501',
        title: '市辖区',
        p_code: '130500'
      },
      {
        id: 109,
        code: '130502',
        title: '桥东区',
        p_code: '130501'
      },
      {
        id: 110,
        code: '130503',
        title: '桥西区',
        p_code: '130501'
      },
      {
        id: 111,
        code: '130521',
        title: '邢台县',
        p_code: '130500'
      },
      {
        id: 112,
        code: '130522',
        title: '临城县',
        p_code: '130500'
      },
      {
        id: 113,
        code: '130523',
        title: '内丘县',
        p_code: '130500'
      },
      {
        id: 114,
        code: '130524',
        title: '柏乡县',
        p_code: '130500'
      },
      {
        id: 115,
        code: '130525',
        title: '隆尧县',
        p_code: '130500'
      },
      {
        id: 116,
        code: '130526',
        title: '任县',
        p_code: '130500'
      },
      {
        id: 117,
        code: '130527',
        title: '南和县',
        p_code: '130500'
      },
      {
        id: 118,
        code: '130528',
        title: '宁晋县',
        p_code: '130500'
      },
      {
        id: 119,
        code: '130529',
        title: '巨鹿县',
        p_code: '130529'
      },
      {
        id: 120,
        code: '130530',
        title: '新河县',
        p_code: '130500'
      },
      {
        id: 121,
        code: '130531',
        title: '广宗县',
        p_code: '130500'
      },
      {
        id: 122,
        code: '130532',
        title: '平乡县',
        p_code: '130500'
      },
      {
        id: 123,
        code: '130533',
        title: '威县',
        p_code: '130500'
      },
      {
        id: 124,
        code: '130534',
        title: '清河县',
        p_code: '130500'
      },
      {
        id: 125,
        code: '130535',
        title: '临西县',
        p_code: '130500'
      },
      {
        id: 126,
        code: '130581',
        title: '南宫市',
        p_code: '130500'
      },
      {
        id: 127,
        code: '130582',
        title: '沙河市',
        p_code: '130500'
      },
      {
        id: 128,
        code: '130600',
        title: '保定市',
        p_code: '130000'
      },
      {
        id: 129,
        code: '130601',
        title: '新市区',
        p_code: '130600'
      },
      {
        id: 130,
        code: '130603',
        title: '北市区',
        p_code: '130600'
      },
      {
        id: 131,
        code: '130604',
        title: '南市区',
        p_code: '130600'
      },
      {
        id: 132,
        code: '130621',
        title: '满城县',
        p_code: '130600'
      },
      {
        id: 133,
        code: '130622',
        title: '清苑县',
        p_code: '130600'
      },
      {
        id: 134,
        code: '130623',
        title: '涞水县',
        p_code: '130600'
      },
      {
        id: 135,
        code: '130624',
        title: '阜平县',
        p_code: '130600'
      },
      {
        id: 136,
        code: '130625',
        title: '徐水县',
        p_code: '130600'
      },
      {
        id: 137,
        code: '130626',
        title: '定兴县',
        p_code: '130600'
      },
      {
        id: 138,
        code: '130627',
        title: '唐县',
        p_code: '130600'
      },
      {
        id: 139,
        code: '130628',
        title: '高阳县',
        p_code: '130600'
      },
      {
        id: 140,
        code: '130629',
        title: '容城县',
        p_code: '130600'
      },
      {
        id: 141,
        code: '130630',
        title: '涞源县',
        p_code: '130600'
      },
      {
        id: 142,
        code: '130631',
        title: '望都县',
        p_code: '130600'
      },
      {
        id: 143,
        code: '130632',
        title: '安新县',
        p_code: '130600'
      },
      {
        id: 144,
        code: '130633',
        title: '易县',
        p_code: '130600'
      },
      {
        id: 145,
        code: '130634',
        title: '曲阳县',
        p_code: '130600'
      },
      {
        id: 146,
        code: '130635',
        title: '蠡县',
        p_code: '130600'
      },
      {
        id: 147,
        code: '130636',
        title: '顺平县',
        p_code: '130600'
      },
      {
        id: 148,
        code: '130637',
        title: '博野县',
        p_code: '130600'
      },
      {
        id: 149,
        code: '130638',
        title: '雄县',
        p_code: '130600'
      },
      {
        id: 150,
        code: '130681',
        title: '涿州市',
        p_code: '130600'
      },
      {
        id: 151,
        code: '130682',
        title: '定州市',
        p_code: '130600'
      },
      {
        id: 152,
        code: '130683',
        title: '安国市',
        p_code: '130600'
      },
      {
        id: 153,
        code: '130684',
        title: '高碑店市',
        p_code: '130600'
      },
      {
        id: 154,
        code: '130685',
        title: '白沟新城县',
        p_code: '130600'
      },
      {
        id: 155,
        code: '130700',
        title: '张家口市',
        p_code: '130000'
      },
      {
        id: 156,
        code: '130701',
        title: '市辖区',
        p_code: '130700'
      },
      {
        id: 157,
        code: '130702',
        title: '桥东区',
        p_code: '130701'
      },
      {
        id: 158,
        code: '130703',
        title: '桥西区',
        p_code: '130701'
      },
      {
        id: 159,
        code: '130705',
        title: '宣化区',
        p_code: '130701'
      },
      {
        id: 160,
        code: '130706',
        title: '下花园区',
        p_code: '130701'
      },
      {
        id: 161,
        code: '130721',
        title: '宣化县',
        p_code: '130700'
      },
      {
        id: 162,
        code: '130722',
        title: '张北县',
        p_code: '130700'
      },
      {
        id: 163,
        code: '130723',
        title: '康保县',
        p_code: '130700'
      },
      {
        id: 164,
        code: '130724',
        title: '沽源县',
        p_code: '130700'
      },
      {
        id: 165,
        code: '130725',
        title: '尚义县',
        p_code: '130700'
      },
      {
        id: 166,
        code: '130726',
        title: '蔚县',
        p_code: '130700'
      },
      {
        id: 167,
        code: '130727',
        title: '阳原县',
        p_code: '130700'
      },
      {
        id: 168,
        code: '130728',
        title: '怀安县',
        p_code: '130700'
      },
      {
        id: 169,
        code: '130729',
        title: '万全县',
        p_code: '130700'
      },
      {
        id: 170,
        code: '130730',
        title: '怀来县',
        p_code: '130700'
      },
      {
        id: 171,
        code: '130731',
        title: '涿鹿县',
        p_code: '130700'
      },
      {
        id: 172,
        code: '130732',
        title: '赤城县',
        p_code: '130700'
      },
      {
        id: 173,
        code: '130733',
        title: '崇礼县',
        p_code: '130700'
      },
      {
        id: 174,
        code: '130800',
        title: '承德市',
        p_code: '130000'
      },
      {
        id: 175,
        code: '130801',
        title: '市辖区',
        p_code: '130800'
      },
      {
        id: 176,
        code: '130802',
        title: '双桥区',
        p_code: '130801'
      },
      {
        id: 177,
        code: '130803',
        title: '双滦区',
        p_code: '130801'
      },
      {
        id: 178,
        code: '130804',
        title: '鹰手营子矿区',
        p_code: '130801'
      },
      {
        id: 179,
        code: '130821',
        title: '承德县',
        p_code: '130800'
      },
      {
        id: 180,
        code: '130822',
        title: '兴隆县',
        p_code: '130800'
      },
      {
        id: 181,
        code: '130823',
        title: '平泉县',
        p_code: '130800'
      },
      {
        id: 182,
        code: '130824',
        title: '滦平县',
        p_code: '130800'
      },
      {
        id: 183,
        code: '130825',
        title: '隆化县',
        p_code: '130800'
      },
      {
        id: 184,
        code: '130826',
        title: '丰宁满族自治县',
        p_code: '130800'
      },
      {
        id: 185,
        code: '130827',
        title: '宽城满族自治县',
        p_code: '130800'
      },
      {
        id: 186,
        code: '130828',
        title: '围场满族蒙古族自治县',
        p_code: '130800'
      },
      {
        id: 187,
        code: '130900',
        title: '沧州市',
        p_code: '130000'
      },
      {
        id: 188,
        code: '130901',
        title: '市辖区',
        p_code: '130900'
      },
      {
        id: 189,
        code: '130902',
        title: '新华区',
        p_code: '130901'
      },
      {
        id: 190,
        code: '130903',
        title: '运河区',
        p_code: '130901'
      },
      {
        id: 191,
        code: '130921',
        title: '沧县',
        p_code: '130900'
      },
      {
        id: 192,
        code: '130922',
        title: '青县',
        p_code: '130900'
      },
      {
        id: 193,
        code: '130923',
        title: '东光县',
        p_code: '130900'
      },
      {
        id: 194,
        code: '130924',
        title: '海兴县',
        p_code: '130900'
      },
      {
        id: 195,
        code: '130925',
        title: '盐山县',
        p_code: '130900'
      },
      {
        id: 196,
        code: '130926',
        title: '肃宁县',
        p_code: '130900'
      },
      {
        id: 197,
        code: '130927',
        title: '南皮县',
        p_code: '130900'
      },
      {
        id: 198,
        code: '130928',
        title: '吴桥县',
        p_code: '130900'
      },
      {
        id: 199,
        code: '130929',
        title: '献县',
        p_code: '130900'
      },
      {
        id: 200,
        code: '130930',
        title: '孟村回族自治县',
        p_code: '130900'
      },
      {
        id: 201,
        code: '130981',
        title: '泊头市',
        p_code: '130900'
      },
      {
        id: 202,
        code: '130982',
        title: '任丘市',
        p_code: '130900'
      },
      {
        id: 203,
        code: '130983',
        title: '黄骅市',
        p_code: '130900'
      },
      {
        id: 204,
        code: '130984',
        title: '河间市',
        p_code: '130900'
      },
      {
        id: 205,
        code: '131000',
        title: '廊坊市',
        p_code: '130000'
      },
      {
        id: 206,
        code: '131001',
        title: '市辖区',
        p_code: '131000'
      },
      {
        id: 207,
        code: '131002',
        title: '安次区',
        p_code: '131001'
      },
      {
        id: 208,
        code: '131003',
        title: '广阳区',
        p_code: '131001'
      },
      {
        id: 209,
        code: '131022',
        title: '固安县',
        p_code: '131000'
      },
      {
        id: 210,
        code: '131023',
        title: '永清县',
        p_code: '131000'
      },
      {
        id: 211,
        code: '131024',
        title: '香河县',
        p_code: '131000'
      },
      {
        id: 212,
        code: '131025',
        title: '大城县',
        p_code: '131000'
      },
      {
        id: 213,
        code: '131026',
        title: '文安县',
        p_code: '131000'
      },
      {
        id: 214,
        code: '131028',
        title: '大厂回族自治县',
        p_code: '131000'
      },
      {
        id: 215,
        code: '131081',
        title: '霸州市',
        p_code: '131000'
      },
      {
        id: 216,
        code: '131082',
        title: '三河市',
        p_code: '131000'
      },
      {
        id: 217,
        code: '131100',
        title: '衡水市',
        p_code: '130000'
      },
      {
        id: 218,
        code: '131101',
        title: '市辖区',
        p_code: '131100'
      },
      {
        id: 219,
        code: '131102',
        title: '桃城区',
        p_code: '131101'
      },
      {
        id: 220,
        code: '131121',
        title: '枣强县',
        p_code: '131100'
      },
      {
        id: 221,
        code: '131122',
        title: '武邑县',
        p_code: '131100'
      },
      {
        id: 222,
        code: '131123',
        title: '武强县',
        p_code: '131100'
      },
      {
        id: 223,
        code: '131124',
        title: '饶阳县',
        p_code: '131100'
      },
      {
        id: 224,
        code: '131125',
        title: '安平县',
        p_code: '131100'
      },
      {
        id: 225,
        code: '131126',
        title: '故城县',
        p_code: '131100'
      },
      {
        id: 226,
        code: '131127',
        title: '景县',
        p_code: '131100'
      },
      {
        id: 227,
        code: '131128',
        title: '阜城县',
        p_code: '131100'
      },
      {
        id: 228,
        code: '131181',
        title: '冀州市',
        p_code: '131100'
      },
      {
        id: 229,
        code: '131182',
        title: '深州市',
        p_code: '131100'
      },

      {
        id: 231,
        code: '140100',
        title: '太原市',
        p_code: '140000'
      },
      {
        id: 232,
        code: '140101',
        title: '市辖区',
        p_code: '140100'
      },
      {
        id: 233,
        code: '140105',
        title: '小店区',
        p_code: '140101'
      },
      {
        id: 234,
        code: '140106',
        title: '迎泽区',
        p_code: '140101'
      },
      {
        id: 235,
        code: '140107',
        title: '杏花岭区',
        p_code: '140101'
      },
      {
        id: 236,
        code: '140108',
        title: '尖草坪区',
        p_code: '140101'
      },
      {
        id: 237,
        code: '140109',
        title: '万柏林区',
        p_code: '140101'
      },
      {
        id: 238,
        code: '140110',
        title: '晋源区',
        p_code: '140101'
      },
      {
        id: 239,
        code: '140121',
        title: '清徐县',
        p_code: '140100'
      },
      {
        id: 240,
        code: '140122',
        title: '阳曲县',
        p_code: '140100'
      },
      {
        id: 241,
        code: '140123',
        title: '娄烦县',
        p_code: '140100'
      },
      {
        id: 242,
        code: '140181',
        title: '古交市',
        p_code: '140100'
      },
      {
        id: 243,
        code: '140200',
        title: '大同市',
        p_code: '140000'
      },
      {
        id: 244,
        code: '140201',
        title: '市辖区',
        p_code: '140200'
      },
      {
        id: 245,
        code: '140202',
        title: '城区',
        p_code: '140201'
      },
      {
        id: 246,
        code: '140203',
        title: '矿区',
        p_code: '140201'
      },
      {
        id: 247,
        code: '140211',
        title: '南郊区',
        p_code: '140201'
      },
      {
        id: 248,
        code: '140212',
        title: '新荣区',
        p_code: '140201'
      },
      {
        id: 249,
        code: '140221',
        title: '阳高县',
        p_code: '140200'
      },
      {
        id: 250,
        code: '140222',
        title: '天镇县',
        p_code: '140200'
      },
      {
        id: 251,
        code: '140223',
        title: '广灵县',
        p_code: '140200'
      },
      {
        id: 252,
        code: '140224',
        title: '灵丘县',
        p_code: '140200'
      },
      {
        id: 253,
        code: '140225',
        title: '浑源县',
        p_code: '140200'
      },
      {
        id: 254,
        code: '140226',
        title: '左云县',
        p_code: '140200'
      },
      {
        id: 255,
        code: '140227',
        title: '大同县',
        p_code: '140200'
      },
      {
        id: 256,
        code: '140300',
        title: '阳泉市',
        p_code: '140000'
      },
      {
        id: 257,
        code: '140301',
        title: '市辖区',
        p_code: '140300'
      },
      {
        id: 258,
        code: '140302',
        title: '城区',
        p_code: '140301'
      },
      {
        id: 259,
        code: '140303',
        title: '矿区',
        p_code: '140301'
      },
      {
        id: 260,
        code: '140311',
        title: '郊区',
        p_code: '140301'
      },
      {
        id: 261,
        code: '140321',
        title: '平定县',
        p_code: '140300'
      },
      {
        id: 262,
        code: '140322',
        title: '盂县',
        p_code: '140300'
      },
      {
        id: 263,
        code: '140400',
        title: '长治市',
        p_code: '140000'
      },
      {
        id: 264,
        code: '140401',
        title: '市辖区',
        p_code: '140400'
      },
      {
        id: 265,
        code: '140402',
        title: '城区',
        p_code: '140401'
      },
      {
        id: 266,
        code: '140411',
        title: '郊区',
        p_code: '140401'
      },
      {
        id: 267,
        code: '140421',
        title: '长治县',
        p_code: '140400'
      },
      {
        id: 268,
        code: '140423',
        title: '襄垣县',
        p_code: '140400'
      },
      {
        id: 269,
        code: '140424',
        title: '屯留县',
        p_code: '140400'
      },
      {
        id: 270,
        code: '140425',
        title: '平顺县',
        p_code: '140400'
      },
      {
        id: 271,
        code: '140426',
        title: '黎城县',
        p_code: '140400'
      },
      {
        id: 272,
        code: '140427',
        title: '壶关县',
        p_code: '140400'
      },
      {
        id: 273,
        code: '140428',
        title: '长子县',
        p_code: '140400'
      },
      {
        id: 274,
        code: '140429',
        title: '武乡县',
        p_code: '140400'
      },
      {
        id: 275,
        code: '140430',
        title: '沁县',
        p_code: '140400'
      },
      {
        id: 276,
        code: '140431',
        title: '沁源县',
        p_code: '140400'
      },
      {
        id: 277,
        code: '140481',
        title: '潞城市',
        p_code: '140400'
      },
      {
        id: 278,
        code: '140500',
        title: '晋城市',
        p_code: '140000'
      },
      {
        id: 279,
        code: '140501',
        title: '市辖区',
        p_code: '140500'
      },
      {
        id: 280,
        code: '140502',
        title: '城区',
        p_code: '140501'
      },
      {
        id: 281,
        code: '140521',
        title: '沁水县',
        p_code: '140500'
      },
      {
        id: 282,
        code: '140522',
        title: '阳城县',
        p_code: '140500'
      },
      {
        id: 283,
        code: '140524',
        title: '陵川县',
        p_code: '140500'
      },
      {
        id: 284,
        code: '140525',
        title: '泽州县',
        p_code: '140500'
      },
      {
        id: 285,
        code: '140581',
        title: '高平市',
        p_code: '140500'
      },
      {
        id: 286,
        code: '140600',
        title: '朔州市',
        p_code: '140000'
      },
      {
        id: 287,
        code: '140601',
        title: '市辖区',
        p_code: '140600'
      },
      {
        id: 288,
        code: '140602',
        title: '朔城区',
        p_code: '140601'
      },
      {
        id: 289,
        code: '140603',
        title: '平鲁区',
        p_code: '140601'
      },
      {
        id: 290,
        code: '140621',
        title: '山阴县',
        p_code: '140600'
      },
      {
        id: 291,
        code: '140622',
        title: '应县',
        p_code: '140600'
      },
      {
        id: 292,
        code: '140623',
        title: '右玉县',
        p_code: '140600'
      },
      {
        id: 293,
        code: '140624',
        title: '怀仁县',
        p_code: '140600'
      },
      {
        id: 294,
        code: '140700',
        title: '晋中市',
        p_code: '140000'
      },
      {
        id: 295,
        code: '140701',
        title: '市辖区',
        p_code: '140700'
      },
      {
        id: 296,
        code: '140702',
        title: '榆次区',
        p_code: '140701'
      },
      {
        id: 297,
        code: '140721',
        title: '榆社县',
        p_code: '140700'
      },
      {
        id: 298,
        code: '140722',
        title: '左权县',
        p_code: '140700'
      },
      {
        id: 299,
        code: '140723',
        title: '和顺县',
        p_code: '140700'
      },
      {
        id: 300,
        code: '140724',
        title: '昔阳县',
        p_code: '140700'
      },
      {
        id: 301,
        code: '140725',
        title: '寿阳县',
        p_code: '140700'
      },
      {
        id: 302,
        code: '140726',
        title: '太谷县',
        p_code: '140700'
      },
      {
        id: 303,
        code: '140727',
        title: '祁县',
        p_code: '140700'
      },
      {
        id: 304,
        code: '140728',
        title: '平遥县',
        p_code: '140700'
      },
      {
        id: 305,
        code: '140729',
        title: '灵石县',
        p_code: '140700'
      },
      {
        id: 306,
        code: '140781',
        title: '介休市',
        p_code: '140700'
      },
      {
        id: 307,
        code: '140800',
        title: '运城市',
        p_code: '140000'
      },
      {
        id: 308,
        code: '140801',
        title: '市辖区',
        p_code: '140800'
      },
      {
        id: 309,
        code: '140802',
        title: '盐湖区',
        p_code: '140801'
      },
      {
        id: 310,
        code: '140821',
        title: '临猗县',
        p_code: '140800'
      },
      {
        id: 311,
        code: '140822',
        title: '万荣县',
        p_code: '140800'
      },
      {
        id: 312,
        code: '140823',
        title: '闻喜县',
        p_code: '140800'
      },
      {
        id: 313,
        code: '140824',
        title: '稷山县',
        p_code: '140800'
      },
      {
        id: 314,
        code: '140825',
        title: '新绛县',
        p_code: '140800'
      },
      {
        id: 315,
        code: '140826',
        title: '绛县',
        p_code: '140800'
      },
      {
        id: 316,
        code: '140827',
        title: '垣曲县',
        p_code: '140800'
      },
      {
        id: 317,
        code: '140828',
        title: '夏县',
        p_code: '140800'
      },
      {
        id: 318,
        code: '140829',
        title: '平陆县',
        p_code: '140800'
      },
      {
        id: 319,
        code: '140830',
        title: '芮城县',
        p_code: '140800'
      },
      {
        id: 320,
        code: '140881',
        title: '永济市',
        p_code: '140800'
      },
      {
        id: 321,
        code: '140882',
        title: '河津市',
        p_code: '140800'
      },
      {
        id: 322,
        code: '140900',
        title: '忻州市',
        p_code: '140000'
      },
      {
        id: 323,
        code: '140901',
        title: '忻府区',
        p_code: '140900'
      },
      {
        id: 324,
        code: '140921',
        title: '定襄县',
        p_code: '140900'
      },
      {
        id: 325,
        code: '140922',
        title: '五台县',
        p_code: '140900'
      },
      {
        id: 326,
        code: '140923',
        title: '代县',
        p_code: '140900'
      },
      {
        id: 327,
        code: '140924',
        title: '繁峙县',
        p_code: '140900'
      },
      {
        id: 328,
        code: '140925',
        title: '宁武县',
        p_code: '140900'
      },
      {
        id: 329,
        code: '140926',
        title: '静乐县',
        p_code: '140900'
      },
      {
        id: 330,
        code: '140927',
        title: '神池县',
        p_code: '140900'
      },
      {
        id: 331,
        code: '140928',
        title: '五寨县',
        p_code: '140900'
      },
      {
        id: 332,
        code: '140929',
        title: '岢岚县',
        p_code: '140900'
      },
      {
        id: 333,
        code: '140930',
        title: '河曲县',
        p_code: '140900'
      },
      {
        id: 334,
        code: '140931',
        title: '保德县',
        p_code: '140900'
      },
      {
        id: 335,
        code: '140932',
        title: '偏关县',
        p_code: '140900'
      },
      {
        id: 336,
        code: '140981',
        title: '原平市',
        p_code: '140900'
      },
      {
        id: 337,
        code: '141000',
        title: '临汾市',
        p_code: '140000'
      },
      {
        id: 338,
        code: '141001',
        title: '市辖区',
        p_code: '141000'
      },
      {
        id: 339,
        code: '141002',
        title: '尧都区',
        p_code: '141000'
      },
      {
        id: 340,
        code: '141021',
        title: '曲沃县',
        p_code: '141000'
      },
      {
        id: 341,
        code: '141022',
        title: '翼城县',
        p_code: '141000'
      },
      {
        id: 342,
        code: '141023',
        title: '襄汾县',
        p_code: '141000'
      },
      {
        id: 343,
        code: '141024',
        title: '洪洞县',
        p_code: '141000'
      },
      {
        id: 344,
        code: '141025',
        title: '古县',
        p_code: '141000'
      },
      {
        id: 345,
        code: '141026',
        title: '安泽县',
        p_code: '141000'
      },
      {
        id: 346,
        code: '141027',
        title: '浮山县',
        p_code: '141000'
      },
      {
        id: 347,
        code: '141028',
        title: '吉县',
        p_code: '141000'
      },
      {
        id: 348,
        code: '141029',
        title: '乡宁县',
        p_code: '141000'
      },
      {
        id: 349,
        code: '141030',
        title: '大宁县',
        p_code: '141000'
      },
      {
        id: 350,
        code: '141031',
        title: '隰县',
        p_code: '141000'
      },
      {
        id: 351,
        code: '141032',
        title: '永和县',
        p_code: '141000'
      },
      {
        id: 352,
        code: '141033',
        title: '蒲县',
        p_code: '141000'
      },
      {
        id: 353,
        code: '141034',
        title: '汾西县',
        p_code: '141000'
      },
      {
        id: 354,
        code: '141081',
        title: '侯马市',
        p_code: '141000'
      },
      {
        id: 355,
        code: '141082',
        title: '霍州市',
        p_code: '141000'
      },
      {
        id: 356,
        code: '141100',
        title: '吕梁市',
        p_code: '140000'
      },
      {
        id: 357,
        code: '141101',
        title: '市辖区',
        p_code: '141100'
      },
      {
        id: 358,
        code: '141102',
        title: '离石区',
        p_code: '141101'
      },
      {
        id: 359,
        code: '141121',
        title: '文水县',
        p_code: '141100'
      },
      {
        id: 360,
        code: '141122',
        title: '交城县',
        p_code: '141100'
      },
      {
        id: 361,
        code: '141123',
        title: '兴县',
        p_code: '141100'
      },
      {
        id: 362,
        code: '141124',
        title: '临县',
        p_code: '141100'
      },
      {
        id: 363,
        code: '141125',
        title: '柳林县',
        p_code: '141100'
      },
      {
        id: 364,
        code: '141126',
        title: '石楼县',
        p_code: '141100'
      },
      {
        id: 365,
        code: '141127',
        title: '岚县',
        p_code: '141100'
      },
      {
        id: 366,
        code: '141128',
        title: '方山县',
        p_code: '141100'
      },
      {
        id: 367,
        code: '141129',
        title: '中阳县',
        p_code: '141100'
      },
      {
        id: 368,
        code: '141130',
        title: '交口县',
        p_code: '141100'
      },
      {
        id: 369,
        code: '141181',
        title: '孝义市',
        p_code: '141100'
      },
      {
        id: 370,
        code: '141182',
        title: '汾阳市',
        p_code: '141100'
      },

      {
        id: 372,
        code: '150100',
        title: '呼和浩特市',
        p_code: '150000'
      },
      {
        id: 373,
        code: '150101',
        title: '市辖区',
        p_code: '150100'
      },
      {
        id: 374,
        code: '150102',
        title: '新城区',
        p_code: '150101'
      },
      {
        id: 375,
        code: '150103',
        title: '回民区',
        p_code: '150101'
      },
      {
        id: 376,
        code: '150104',
        title: '玉泉区',
        p_code: '150101'
      },
      {
        id: 377,
        code: '150105',
        title: '赛罕区',
        p_code: '150101'
      },
      {
        id: 378,
        code: '150121',
        title: '土默特左旗',
        p_code: '150100'
      },
      {
        id: 379,
        code: '150122',
        title: '托克托县',
        p_code: '150100'
      },
      {
        id: 380,
        code: '150123',
        title: '和林格尔县',
        p_code: '150100'
      },
      {
        id: 381,
        code: '150124',
        title: '清水河县',
        p_code: '150100'
      },
      {
        id: 382,
        code: '150125',
        title: '武川县',
        p_code: '150100'
      },
      {
        id: 383,
        code: '150200',
        title: '包头市',
        p_code: '150000'
      },
      {
        id: 384,
        code: '150201',
        title: '市辖区',
        p_code: '150200'
      },
      {
        id: 385,
        code: '150202',
        title: '东河区',
        p_code: '150201'
      },
      {
        id: 386,
        code: '150203',
        title: '昆都仑区',
        p_code: '150201'
      },
      {
        id: 387,
        code: '150204',
        title: '青山区',
        p_code: '150201'
      },
      {
        id: 388,
        code: '150205',
        title: '石拐区',
        p_code: '150201'
      },
      {
        id: 389,
        code: '150206',
        title: '白云矿区',
        p_code: '150201'
      },
      {
        id: 390,
        code: '150207',
        title: '九原区',
        p_code: '150201'
      },
      {
        id: 391,
        code: '150221',
        title: '土默特右旗',
        p_code: '150200'
      },
      {
        id: 392,
        code: '150222',
        title: '固阳县',
        p_code: '150200'
      },
      {
        id: 393,
        code: '150223',
        title: '达尔罕茂明安联合旗',
        p_code: '150200'
      },
      {
        id: 394,
        code: '150300',
        title: '乌海市',
        p_code: '150000'
      },
      {
        id: 395,
        code: '150301',
        title: '市辖区',
        p_code: '150300'
      },
      {
        id: 396,
        code: '150302',
        title: '海勃湾区',
        p_code: '150301'
      },
      {
        id: 397,
        code: '150303',
        title: '海南区',
        p_code: '150301'
      },
      {
        id: 398,
        code: '150304',
        title: '乌达区',
        p_code: '150301'
      },
      {
        id: 399,
        code: '150400',
        title: '赤峰市',
        p_code: '150000'
      },
      {
        id: 400,
        code: '150401',
        title: '市辖区',
        p_code: '150400'
      },
      {
        id: 401,
        code: '150402',
        title: '红山区',
        p_code: '150401'
      },
      {
        id: 402,
        code: '150403',
        title: '元宝山区',
        p_code: '150401'
      },
      {
        id: 403,
        code: '150404',
        title: '松山区',
        p_code: '150401'
      },
      {
        id: 404,
        code: '150421',
        title: '阿鲁科尔沁旗',
        p_code: '150400'
      },
      {
        id: 405,
        code: '150422',
        title: '巴林左旗',
        p_code: '150400'
      },
      {
        id: 406,
        code: '150423',
        title: '巴林右旗',
        p_code: '150400'
      },
      {
        id: 407,
        code: '150424',
        title: '林西县',
        p_code: '150400'
      },
      {
        id: 408,
        code: '150425',
        title: '克什克腾旗',
        p_code: '150400'
      },
      {
        id: 409,
        code: '150426',
        title: '翁牛特旗',
        p_code: '150400'
      },
      {
        id: 410,
        code: '150428',
        title: '喀喇沁旗',
        p_code: '150400'
      },
      {
        id: 411,
        code: '150429',
        title: '宁城县',
        p_code: '150400'
      },
      {
        id: 412,
        code: '150430',
        title: '敖汉旗',
        p_code: '150400'
      },
      {
        id: 413,
        code: '150500',
        title: '通辽市',
        p_code: '150000'
      },
      {
        id: 414,
        code: '150501',
        title: '市辖区',
        p_code: '150500'
      },
      {
        id: 415,
        code: '150502',
        title: '科尔沁区',
        p_code: '150501'
      },
      {
        id: 416,
        code: '150521',
        title: '科尔沁左翼中旗',
        p_code: '150500'
      },
      {
        id: 417,
        code: '150522',
        title: '科尔沁左翼后旗',
        p_code: '150500'
      },
      {
        id: 418,
        code: '150523',
        title: '开鲁县',
        p_code: '150500'
      },
      {
        id: 419,
        code: '150524',
        title: '库伦旗',
        p_code: '150500'
      },
      {
        id: 420,
        code: '150525',
        title: '奈曼旗',
        p_code: '150500'
      },
      {
        id: 421,
        code: '150526',
        title: '扎鲁特旗',
        p_code: '150500'
      },
      {
        id: 422,
        code: '150581',
        title: '霍林郭勒市',
        p_code: '150500'
      },
      {
        id: 423,
        code: '150600',
        title: '鄂尔多斯市',
        p_code: '150000'
      },
      {
        id: 424,
        code: '150602',
        title: '东胜区',
        p_code: '150600'
      },
      {
        id: 425,
        code: '150621',
        title: '达拉特旗',
        p_code: '150600'
      },
      {
        id: 426,
        code: '150622',
        title: '准格尔旗',
        p_code: '150600'
      },
      {
        id: 427,
        code: '150623',
        title: '鄂托克前旗',
        p_code: '150600'
      },
      {
        id: 428,
        code: '150624',
        title: '鄂托克旗',
        p_code: '150600'
      },
      {
        id: 429,
        code: '150625',
        title: '杭锦旗',
        p_code: '150600'
      },
      {
        id: 430,
        code: '150626',
        title: '乌审旗',
        p_code: '150600'
      },
      {
        id: 431,
        code: '150627',
        title: '伊金霍洛旗',
        p_code: '150600'
      },
      {
        id: 432,
        code: '150700',
        title: '呼伦贝尔市',
        p_code: '150000'
      },
      {
        id: 433,
        code: '150701',
        title: '市辖区',
        p_code: '150700'
      },
      {
        id: 434,
        code: '150702',
        title: '海拉尔区',
        p_code: '150701'
      },
      {
        id: 435,
        code: '150721',
        title: '阿荣旗',
        p_code: '150700'
      },
      {
        id: 436,
        code: '150722',
        title: '莫力达瓦达斡尔族自治旗',
        p_code: '150700'
      },
      {
        id: 437,
        code: '150723',
        title: '鄂伦春自治旗',
        p_code: '150700'
      },
      {
        id: 438,
        code: '150724',
        title: '鄂温克族自治旗',
        p_code: '150700'
      },
      {
        id: 439,
        code: '150725',
        title: '陈巴尔虎旗',
        p_code: '150700'
      },
      {
        id: 440,
        code: '150726',
        title: '新巴尔虎左旗',
        p_code: '150700'
      },
      {
        id: 441,
        code: '150727',
        title: '新巴尔虎右旗',
        p_code: '150700'
      },
      {
        id: 442,
        code: '150781',
        title: '满洲里市',
        p_code: '150700'
      },
      {
        id: 443,
        code: '150782',
        title: '牙克石市',
        p_code: '150700'
      },
      {
        id: 444,
        code: '150783',
        title: '扎兰屯市',
        p_code: '150700'
      },
      {
        id: 445,
        code: '150784',
        title: '额尔古纳市',
        p_code: '150700'
      },
      {
        id: 446,
        code: '150785',
        title: '根河市',
        p_code: '150700'
      },
      {
        id: 447,
        code: '150800',
        title: '巴彦淖尔市',
        p_code: '150000'
      },
      {
        id: 448,
        code: '150801',
        title: '市辖区',
        p_code: '150800'
      },
      {
        id: 449,
        code: '150802',
        title: '临河区',
        p_code: '150800'
      },
      {
        id: 450,
        code: '150821',
        title: '五原县',
        p_code: '150800'
      },
      {
        id: 451,
        code: '150822',
        title: '磴口县',
        p_code: '150800'
      },
      {
        id: 452,
        code: '150823',
        title: '乌拉特前旗',
        p_code: '150800'
      },
      {
        id: 453,
        code: '150824',
        title: '乌拉特中旗',
        p_code: '150800'
      },
      {
        id: 454,
        code: '150825',
        title: '乌拉特后旗',
        p_code: '150800'
      },
      {
        id: 455,
        code: '150826',
        title: '杭锦后旗',
        p_code: '150800'
      },
      {
        id: 456,
        code: '150900',
        title: '乌兰察布市',
        p_code: '150000'
      },
      {
        id: 457,
        code: '150901',
        title: '市辖区',
        p_code: '150900'
      },
      {
        id: 458,
        code: '150902',
        title: '集宁区',
        p_code: '150901'
      },
      {
        id: 459,
        code: '150921',
        title: '卓资县',
        p_code: '150900'
      },
      {
        id: 460,
        code: '150922',
        title: '化德县',
        p_code: '150900'
      },
      {
        id: 461,
        code: '150923',
        title: '商都县',
        p_code: '150900'
      },
      {
        id: 462,
        code: '150924',
        title: '兴和县',
        p_code: '150900'
      },
      {
        id: 463,
        code: '150925',
        title: '凉城县',
        p_code: '150900'
      },
      {
        id: 464,
        code: '150926',
        title: '察哈尔右翼前旗',
        p_code: '150900'
      },
      {
        id: 465,
        code: '150927',
        title: '察哈尔右翼中旗',
        p_code: '150900'
      },
      {
        id: 466,
        code: '150928',
        title: '察哈尔右翼后旗',
        p_code: '150900'
      },
      {
        id: 467,
        code: '150929',
        title: '四子王旗',
        p_code: '150900'
      },
      {
        id: 468,
        code: '150981',
        title: '丰镇市',
        p_code: '150900'
      },
      {
        id: 469,
        code: '152200',
        title: '兴安盟',
        p_code: '150000'
      },
      {
        id: 470,
        code: '152201',
        title: '乌兰浩特市',
        p_code: '152200'
      },
      {
        id: 471,
        code: '152202',
        title: '阿尔山市',
        p_code: '152200'
      },
      {
        id: 472,
        code: '152221',
        title: '科尔沁右翼前旗',
        p_code: '152200'
      },
      {
        id: 473,
        code: '152222',
        title: '科尔沁右翼中旗',
        p_code: '152200'
      },
      {
        id: 474,
        code: '152223',
        title: '扎赉特旗',
        p_code: '152200'
      },
      {
        id: 475,
        code: '152224',
        title: '突泉县',
        p_code: '152200'
      },
      {
        id: 476,
        code: '152500',
        title: '锡林郭勒盟',
        p_code: '150000'
      },
      {
        id: 477,
        code: '152501',
        title: '二连浩特市',
        p_code: '152500'
      },
      {
        id: 478,
        code: '152502',
        title: '锡林浩特市',
        p_code: '152500'
      },
      {
        id: 479,
        code: '152522',
        title: '阿巴嘎旗',
        p_code: '152500'
      },
      {
        id: 480,
        code: '152523',
        title: '苏尼特左旗',
        p_code: '152500'
      },
      {
        id: 481,
        code: '152524',
        title: '苏尼特右旗',
        p_code: '152500'
      },
      {
        id: 482,
        code: '152525',
        title: '东乌珠穆沁旗',
        p_code: '152500'
      },
      {
        id: 483,
        code: '152526',
        title: '西乌珠穆沁旗',
        p_code: '152500'
      },
      {
        id: 484,
        code: '152527',
        title: '太仆寺旗',
        p_code: '152500'
      },
      {
        id: 485,
        code: '152528',
        title: '镶黄旗',
        p_code: '152500'
      },
      {
        id: 486,
        code: '152529',
        title: '正镶白旗',
        p_code: '152500'
      },
      {
        id: 487,
        code: '152530',
        title: '正蓝旗',
        p_code: '152500'
      },
      {
        id: 488,
        code: '152531',
        title: '多伦县',
        p_code: '152500'
      },
      {
        id: 489,
        code: '152900',
        title: '阿拉善盟',
        p_code: '150000'
      },
      {
        id: 490,
        code: '152921',
        title: '阿拉善左旗',
        p_code: '152900'
      },
      {
        id: 491,
        code: '152922',
        title: '阿拉善右旗',
        p_code: '152900'
      },
      {
        id: 492,
        code: '152923',
        title: '额济纳旗',
        p_code: '152900'
      },

      {
        id: 494,
        code: '210100',
        title: '沈阳市',
        p_code: '210000'
      },
      {
        id: 495,
        code: '210101',
        title: '市辖区',
        p_code: '210100'
      },
      {
        id: 496,
        code: '210102',
        title: '和平区',
        p_code: '210101'
      },
      {
        id: 497,
        code: '210103',
        title: '沈河区',
        p_code: '210101'
      },
      {
        id: 498,
        code: '210104',
        title: '大东区',
        p_code: '210101'
      },
      {
        id: 499,
        code: '210105',
        title: '皇姑区',
        p_code: '210101'
      },
      {
        id: 500,
        code: '210106',
        title: '铁西区',
        p_code: '210101'
      },
      {
        id: 501,
        code: '210111',
        title: '苏家屯区',
        p_code: '210101'
      },
      {
        id: 502,
        code: '210112',
        title: '东陵区',
        p_code: '210101'
      },
      {
        id: 503,
        code: '210113',
        title: '新城子区',
        p_code: '210101'
      },
      {
        id: 504,
        code: '210114',
        title: '于洪区',
        p_code: '210101'
      },
      {
        id: 505,
        code: '210122',
        title: '辽中县',
        p_code: '210100'
      },
      {
        id: 506,
        code: '210123',
        title: '康平县',
        p_code: '210100'
      },
      {
        id: 507,
        code: '210124',
        title: '法库县',
        p_code: '210100'
      },
      {
        id: 508,
        code: '210181',
        title: '新民市',
        p_code: '210100'
      },
      {
        id: 509,
        code: '210182',
        title: '沈北新区',
        p_code: '210100'
      },
      {
        id: 510,
        code: '210200',
        title: '大连市',
        p_code: '210000'
      },
      {
        id: 511,
        code: '210201',
        title: '市辖区',
        p_code: '210200'
      },
      {
        id: 512,
        code: '210202',
        title: '中山区',
        p_code: '210201'
      },
      {
        id: 513,
        code: '210203',
        title: '西岗区',
        p_code: '210201'
      },
      {
        id: 514,
        code: '210204',
        title: '沙河口区',
        p_code: '210201'
      },
      {
        id: 515,
        code: '210211',
        title: '甘井子区',
        p_code: '210201'
      },
      {
        id: 516,
        code: '210212',
        title: '旅顺口区',
        p_code: '210201'
      },
      {
        id: 517,
        code: '210213',
        title: '金州区',
        p_code: '210201'
      },
      {
        id: 518,
        code: '210224',
        title: '长海县',
        p_code: '210200'
      },
      {
        id: 519,
        code: '210281',
        title: '瓦房店市',
        p_code: '210200'
      },
      {
        id: 520,
        code: '210282',
        title: '普兰店市',
        p_code: '210200'
      },
      {
        id: 521,
        code: '210283',
        title: '庄河市',
        p_code: '210200'
      },
      {
        id: 522,
        code: '210300',
        title: '鞍山市',
        p_code: '210000'
      },
      {
        id: 523,
        code: '210301',
        title: '市辖区',
        p_code: '210300'
      },
      {
        id: 524,
        code: '210302',
        title: '铁东区',
        p_code: '210301'
      },
      {
        id: 525,
        code: '210303',
        title: '铁西区',
        p_code: '210301'
      },
      {
        id: 526,
        code: '210304',
        title: '立山区',
        p_code: '210301'
      },
      {
        id: 527,
        code: '210311',
        title: '千山区',
        p_code: '210301'
      },
      {
        id: 528,
        code: '210321',
        title: '台安县',
        p_code: '210300'
      },
      {
        id: 529,
        code: '210323',
        title: '岫岩满族自治县',
        p_code: '210300'
      },
      {
        id: 530,
        code: '210381',
        title: '海城市',
        p_code: '210300'
      },
      {
        id: 531,
        code: '210400',
        title: '抚顺市',
        p_code: '210000'
      },
      {
        id: 532,
        code: '210401',
        title: '市辖区',
        p_code: '210400'
      },
      {
        id: 533,
        code: '210402',
        title: '新抚区',
        p_code: '210401'
      },
      {
        id: 534,
        code: '210403',
        title: '东洲区',
        p_code: '210401'
      },
      {
        id: 535,
        code: '210404',
        title: '望花区',
        p_code: '210401'
      },
      {
        id: 536,
        code: '210411',
        title: '顺城区',
        p_code: '210401'
      },
      {
        id: 537,
        code: '210421',
        title: '抚顺县',
        p_code: '210400'
      },
      {
        id: 538,
        code: '210422',
        title: '新宾满族自治县',
        p_code: '210400'
      },
      {
        id: 539,
        code: '210423',
        title: '清原满族自治县',
        p_code: '210400'
      },
      {
        id: 540,
        code: '210500',
        title: '本溪市',
        p_code: '210000'
      },
      {
        id: 541,
        code: '210501',
        title: '市辖区',
        p_code: '210500'
      },
      {
        id: 542,
        code: '210502',
        title: '平山区',
        p_code: '210501'
      },
      {
        id: 543,
        code: '210503',
        title: '溪湖??',
        p_code: '210501'
      },
      {
        id: 544,
        code: '210504',
        title: '明山区',
        p_code: '210501'
      },
      {
        id: 545,
        code: '210505',
        title: '南芬区',
        p_code: '210501'
      },
      {
        id: 546,
        code: '210521',
        title: '本溪满族自治县',
        p_code: '210500'
      },
      {
        id: 547,
        code: '210522',
        title: '桓仁满族自治县',
        p_code: '210500'
      },
      {
        id: 548,
        code: '210600',
        title: '丹东市',
        p_code: '210000'
      },
      {
        id: 549,
        code: '210601',
        title: '市辖区',
        p_code: '210600'
      },
      {
        id: 550,
        code: '210602',
        title: '元宝区',
        p_code: '210601'
      },
      {
        id: 551,
        code: '210603',
        title: '振兴区',
        p_code: '210601'
      },
      {
        id: 552,
        code: '210604',
        title: '振安区',
        p_code: '210601'
      },
      {
        id: 553,
        code: '210624',
        title: '宽甸满族自治县',
        p_code: '210600'
      },
      {
        id: 554,
        code: '210681',
        title: '东港市',
        p_code: '210600'
      },
      {
        id: 555,
        code: '210682',
        title: '凤城市',
        p_code: '210600'
      },
      {
        id: 556,
        code: '210700',
        title: '锦州市',
        p_code: '210000'
      },
      {
        id: 557,
        code: '210701',
        title: '市辖区',
        p_code: '210700'
      },
      {
        id: 558,
        code: '210702',
        title: '古塔区',
        p_code: '210701'
      },
      {
        id: 559,
        code: '210703',
        title: '凌河区',
        p_code: '210701'
      },
      {
        id: 560,
        code: '210711',
        title: '太和区',
        p_code: '210701'
      },
      {
        id: 561,
        code: '210726',
        title: '黑山县',
        p_code: '210700'
      },
      {
        id: 562,
        code: '210727',
        title: '义县',
        p_code: '210700'
      },
      {
        id: 563,
        code: '210781',
        title: '凌海市',
        p_code: '210700'
      },
      {
        id: 564,
        code: '210782',
        title: '北宁市',
        p_code: '210700'
      },
      {
        id: 565,
        code: '210800',
        title: '营口市',
        p_code: '210000'
      },
      {
        id: 566,
        code: '210801',
        title: '市辖区',
        p_code: '210800'
      },
      {
        id: 567,
        code: '210802',
        title: '站前区',
        p_code: '210801'
      },
      {
        id: 568,
        code: '210803',
        title: '西市区',
        p_code: '210801'
      },
      {
        id: 569,
        code: '210804',
        title: '鲅鱼圈区',
        p_code: '210801'
      },
      {
        id: 570,
        code: '210811',
        title: '老边区',
        p_code: '210801'
      },
      {
        id: 571,
        code: '210881',
        title: '盖州市',
        p_code: '210800'
      },
      {
        id: 572,
        code: '210882',
        title: '大石桥市',
        p_code: '210800'
      },
      {
        id: 573,
        code: '210900',
        title: '阜新市',
        p_code: '210000'
      },
      {
        id: 574,
        code: '210901',
        title: '市辖区',
        p_code: '210900'
      },
      {
        id: 575,
        code: '210902',
        title: '海州区',
        p_code: '210901'
      },
      {
        id: 576,
        code: '210903',
        title: '新邱区',
        p_code: '210901'
      },
      {
        id: 577,
        code: '210904',
        title: '太平区',
        p_code: '210901'
      },
      {
        id: 578,
        code: '210905',
        title: '清河门区',
        p_code: '210901'
      },
      {
        id: 579,
        code: '210911',
        title: '细河区',
        p_code: '210901'
      },
      {
        id: 580,
        code: '210921',
        title: '阜新蒙古族自治县',
        p_code: '210900'
      },
      {
        id: 581,
        code: '210922',
        title: '彰武县',
        p_code: '210900'
      },
      {
        id: 582,
        code: '211000',
        title: '辽阳市',
        p_code: '210000'
      },
      {
        id: 583,
        code: '211001',
        title: '市辖区',
        p_code: '211000'
      },
      {
        id: 584,
        code: '211002',
        title: '白塔区',
        p_code: '211001'
      },
      {
        id: 585,
        code: '211003',
        title: '文圣区',
        p_code: '211001'
      },
      {
        id: 586,
        code: '211004',
        title: '宏伟区',
        p_code: '211001'
      },
      {
        id: 587,
        code: '211005',
        title: '弓长岭区',
        p_code: '211001'
      },
      {
        id: 588,
        code: '211011',
        title: '太子河区',
        p_code: '211001'
      },
      {
        id: 589,
        code: '211021',
        title: '辽阳县',
        p_code: '211000'
      },
      {
        id: 590,
        code: '211081',
        title: '灯塔市',
        p_code: '211000'
      },
      {
        id: 591,
        code: '211100',
        title: '盘锦市',
        p_code: '210000'
      },
      {
        id: 592,
        code: '211101',
        title: '市辖区',
        p_code: '211100'
      },
      {
        id: 593,
        code: '211102',
        title: '双台子区',
        p_code: '211101'
      },
      {
        id: 594,
        code: '211103',
        title: '兴隆台区',
        p_code: '211101'
      },
      {
        id: 595,
        code: '211121',
        title: '大洼县',
        p_code: '211100'
      },
      {
        id: 596,
        code: '211122',
        title: '盘山县',
        p_code: '211100'
      },
      {
        id: 597,
        code: '211200',
        title: '铁岭市',
        p_code: '210000'
      },
      {
        id: 598,
        code: '211201',
        title: '市辖区',
        p_code: '211200'
      },
      {
        id: 599,
        code: '211202',
        title: '银州区',
        p_code: '211201'
      },
      {
        id: 600,
        code: '211204',
        title: '清河区',
        p_code: '211201'
      },
      {
        id: 601,
        code: '211221',
        title: '铁岭县',
        p_code: '211200'
      },
      {
        id: 602,
        code: '211223',
        title: '西丰县',
        p_code: '211200'
      },
      {
        id: 603,
        code: '211224',
        title: '昌图县',
        p_code: '211200'
      },
      {
        id: 604,
        code: '211281',
        title: '调兵山市',
        p_code: '211200'
      },
      {
        id: 605,
        code: '211282',
        title: '开原市',
        p_code: '211200'
      },
      {
        id: 606,
        code: '211300',
        title: '朝阳市',
        p_code: '210000'
      },
      {
        id: 607,
        code: '211301',
        title: '市辖区',
        p_code: '211300'
      },
      {
        id: 608,
        code: '211302',
        title: '双塔区',
        p_code: '211301'
      },
      {
        id: 609,
        code: '211303',
        title: '龙城区',
        p_code: '211301'
      },
      {
        id: 610,
        code: '211321',
        title: '朝阳县',
        p_code: '211300'
      },
      {
        id: 611,
        code: '211322',
        title: '建平县',
        p_code: '211300'
      },
      {
        id: 612,
        code: '211324',
        title: '喀喇沁左翼蒙古族自治县',
        p_code: '211300'
      },
      {
        id: 613,
        code: '211381',
        title: '北票市',
        p_code: '211300'
      },
      {
        id: 614,
        code: '211382',
        title: '凌源市',
        p_code: '211300'
      },
      {
        id: 615,
        code: '211400',
        title: '葫芦岛市',
        p_code: '210000'
      },
      {
        id: 616,
        code: '211401',
        title: '市辖区',
        p_code: '211400'
      },
      {
        id: 617,
        code: '211402',
        title: '连山区',
        p_code: '211401'
      },
      {
        id: 618,
        code: '211403',
        title: '龙港区',
        p_code: '211401'
      },
      {
        id: 619,
        code: '211404',
        title: '南票区',
        p_code: '211401'
      },
      {
        id: 620,
        code: '211421',
        title: '绥中县',
        p_code: '211400'
      },
      {
        id: 621,
        code: '211422',
        title: '建昌县',
        p_code: '211400'
      },
      {
        id: 622,
        code: '211481',
        title: '兴城市',
        p_code: '211400'
      },

      {
        id: 624,
        code: '220100',
        title: '长春市',
        p_code: '220000'
      },
      {
        id: 625,
        code: '220101',
        title: '市辖区',
        p_code: '220100'
      },
      {
        id: 626,
        code: '220102',
        title: '南关区',
        p_code: '220101'
      },
      {
        id: 627,
        code: '220103',
        title: '宽城区',
        p_code: '220101'
      },
      {
        id: 628,
        code: '220104',
        title: '朝阳区',
        p_code: '220101'
      },
      {
        id: 629,
        code: '220105',
        title: '二道区',
        p_code: '220101'
      },
      {
        id: 630,
        code: '220106',
        title: '绿园区',
        p_code: '220101'
      },
      {
        id: 631,
        code: '220112',
        title: '双阳区',
        p_code: '220101'
      },
      {
        id: 632,
        code: '220122',
        title: '农安县',
        p_code: '220100'
      },
      {
        id: 633,
        code: '220181',
        title: '九台市',
        p_code: '220100'
      },
      {
        id: 634,
        code: '220182',
        title: '榆树市',
        p_code: '220100'
      },
      {
        id: 635,
        code: '220183',
        title: '德惠市',
        p_code: '220100'
      },
      {
        id: 636,
        code: '220200',
        title: '吉林市',
        p_code: '220000'
      },
      {
        id: 637,
        code: '220201',
        title: '市辖区',
        p_code: '220200'
      },
      {
        id: 638,
        code: '220202',
        title: '昌邑区',
        p_code: '220201'
      },
      {
        id: 639,
        code: '220203',
        title: '龙潭区',
        p_code: '220201'
      },
      {
        id: 640,
        code: '220204',
        title: '船营区',
        p_code: '220201'
      },
      {
        id: 641,
        code: '220211',
        title: '丰满区',
        p_code: '220201'
      },
      {
        id: 642,
        code: '220221',
        title: '永吉县',
        p_code: '220200'
      },
      {
        id: 643,
        code: '220281',
        title: '蛟河市',
        p_code: '220200'
      },
      {
        id: 644,
        code: '220282',
        title: '桦甸市',
        p_code: '220200'
      },
      {
        id: 645,
        code: '220283',
        title: '舒兰市',
        p_code: '220200'
      },
      {
        id: 646,
        code: '220284',
        title: '磐石市',
        p_code: '220200'
      },
      {
        id: 647,
        code: '220300',
        title: '四平市',
        p_code: '220000'
      },
      {
        id: 648,
        code: '220301',
        title: '市辖区',
        p_code: '220300'
      },
      {
        id: 649,
        code: '220302',
        title: '铁西区',
        p_code: '220301'
      },
      {
        id: 650,
        code: '220303',
        title: '铁东区',
        p_code: '220301'
      },
      {
        id: 651,
        code: '220322',
        title: '梨树县',
        p_code: '220300'
      },
      {
        id: 652,
        code: '220323',
        title: '伊通满族自治县',
        p_code: '220300'
      },
      {
        id: 653,
        code: '220381',
        title: '公主岭市',
        p_code: '220300'
      },
      {
        id: 654,
        code: '220382',
        title: '双辽市',
        p_code: '220300'
      },
      {
        id: 655,
        code: '220400',
        title: '辽源市',
        p_code: '220000'
      },
      {
        id: 656,
        code: '220401',
        title: '市辖区',
        p_code: '220400'
      },
      {
        id: 657,
        code: '220402',
        title: '龙山区',
        p_code: '220401'
      },
      {
        id: 658,
        code: '220403',
        title: '西安区',
        p_code: '220401'
      },
      {
        id: 659,
        code: '220421',
        title: '东丰县',
        p_code: '220400'
      },
      {
        id: 660,
        code: '220422',
        title: '东辽县',
        p_code: '220400'
      },
      {
        id: 661,
        code: '220500',
        title: '通化市',
        p_code: '220000'
      },
      {
        id: 662,
        code: '220501',
        title: '市辖区',
        p_code: '220500'
      },
      {
        id: 663,
        code: '220502',
        title: '东昌区',
        p_code: '220501'
      },
      {
        id: 664,
        code: '220503',
        title: '二道江区',
        p_code: '220501'
      },
      {
        id: 665,
        code: '220521',
        title: '通化县',
        p_code: '220500'
      },
      {
        id: 666,
        code: '220523',
        title: '辉南县',
        p_code: '220500'
      },
      {
        id: 667,
        code: '220524',
        title: '柳河县',
        p_code: '220500'
      },
      {
        id: 668,
        code: '220581',
        title: '梅河口市',
        p_code: '220500'
      },
      {
        id: 669,
        code: '220582',
        title: '集安市',
        p_code: '220500'
      },
      {
        id: 670,
        code: '220600',
        title: '白山市',
        p_code: '220000'
      },
      {
        id: 671,
        code: '220601',
        title: '市辖区',
        p_code: '220600'
      },
      {
        id: 672,
        code: '220602',
        title: '八道江区',
        p_code: '220601'
      },
      {
        id: 673,
        code: '220621',
        title: '抚松县',
        p_code: '220600'
      },
      {
        id: 674,
        code: '220622',
        title: '靖宇县',
        p_code: '220600'
      },
      {
        id: 675,
        code: '220623',
        title: '长白朝鲜族自治县',
        p_code: '220600'
      },
      {
        id: 676,
        code: '220625',
        title: '江源区',
        p_code: '220600'
      },
      {
        id: 677,
        code: '220681',
        title: '临江市',
        p_code: '220600'
      },
      {
        id: 678,
        code: '220700',
        title: '松原市',
        p_code: '220000'
      },
      {
        id: 679,
        code: '220701',
        title: '市辖区',
        p_code: '220700'
      },
      {
        id: 680,
        code: '220702',
        title: '宁江区',
        p_code: '220701'
      },
      {
        id: 681,
        code: '220721',
        title: '前郭尔罗斯蒙古族自治县',
        p_code: '220700'
      },
      {
        id: 682,
        code: '220722',
        title: '长岭县',
        p_code: '220700'
      },
      {
        id: 683,
        code: '220723',
        title: '乾安县',
        p_code: '220700'
      },
      {
        id: 684,
        code: '220724',
        title: '扶余市',
        p_code: '220700'
      },
      {
        id: 685,
        code: '220800',
        title: '白城市',
        p_code: '220000'
      },
      {
        id: 686,
        code: '220801',
        title: '市辖区',
        p_code: '220800'
      },
      {
        id: 687,
        code: '220802',
        title: '洮北区',
        p_code: '220800'
      },
      {
        id: 688,
        code: '220821',
        title: '镇赉县',
        p_code: '220800'
      },
      {
        id: 689,
        code: '220822',
        title: '通榆县',
        p_code: '220800'
      },
      {
        id: 690,
        code: '220881',
        title: '洮南市',
        p_code: '220800'
      },
      {
        id: 691,
        code: '220882',
        title: '大安市',
        p_code: '220800'
      },
      {
        id: 692,
        code: '222400',
        title: '延边朝鲜族自治州',
        p_code: '220000'
      },
      {
        id: 693,
        code: '222401',
        title: '延吉市',
        p_code: '222400'
      },
      {
        id: 694,
        code: '222402',
        title: '图们市',
        p_code: '222400'
      },
      {
        id: 695,
        code: '222403',
        title: '敦化市',
        p_code: '222400'
      },
      {
        id: 696,
        code: '222404',
        title: '珲春市',
        p_code: '222400'
      },
      {
        id: 697,
        code: '222405',
        title: '龙井市',
        p_code: '222400'
      },
      {
        id: 698,
        code: '222406',
        title: '和龙市',
        p_code: '222400'
      },
      {
        id: 699,
        code: '222424',
        title: '汪清县',
        p_code: '222400'
      },
      {
        id: 700,
        code: '222426',
        title: '安图县',
        p_code: '222400'
      },

      {
        id: 702,
        code: '230100',
        title: '哈尔滨市',
        p_code: '230000'
      },
      {
        id: 703,
        code: '230101',
        title: '市辖区',
        p_code: '230100'
      },
      {
        id: 704,
        code: '230102',
        title: '道里区',
        p_code: '230101'
      },
      {
        id: 705,
        code: '230103',
        title: '南岗区',
        p_code: '230101'
      },
      {
        id: 706,
        code: '230104',
        title: '道外区',
        p_code: '230101'
      },
      {
        id: 707,
        code: '230106',
        title: '香坊区',
        p_code: '230101'
      },
      {
        id: 708,
        code: '230107',
        title: '动力区',
        p_code: '230101'
      },
      {
        id: 709,
        code: '230108',
        title: '平房区',
        p_code: '230101'
      },
      {
        id: 710,
        code: '230109',
        title: '松北区',
        p_code: '230101'
      },
      {
        id: 711,
        code: '230111',
        title: '呼兰区',
        p_code: '230101'
      },
      {
        id: 712,
        code: '230123',
        title: '依兰县',
        p_code: '230100'
      },
      {
        id: 713,
        code: '230124',
        title: '方正县',
        p_code: '230100'
      },
      {
        id: 714,
        code: '230125',
        title: '宾县',
        p_code: '230100'
      },
      {
        id: 715,
        code: '230126',
        title: '巴彦县',
        p_code: '230100'
      },
      {
        id: 716,
        code: '230127',
        title: '木兰县',
        p_code: '230100'
      },
      {
        id: 717,
        code: '230128',
        title: '通河县',
        p_code: '230100'
      },
      {
        id: 718,
        code: '230129',
        title: '延寿县',
        p_code: '230100'
      },
      {
        id: 719,
        code: '230181',
        title: '阿城市',
        p_code: '230100'
      },
      {
        id: 720,
        code: '230182',
        title: '双城市',
        p_code: '230100'
      },
      {
        id: 721,
        code: '230183',
        title: '尚志市',
        p_code: '230100'
      },
      {
        id: 722,
        code: '230184',
        title: '五常市',
        p_code: '230100'
      },
      {
        id: 723,
        code: '230200',
        title: '齐齐哈尔市',
        p_code: '230000'
      },
      {
        id: 724,
        code: '230201',
        title: '市辖区',
        p_code: '230200'
      },
      {
        id: 725,
        code: '230202',
        title: '龙沙区',
        p_code: '230201'
      },
      {
        id: 726,
        code: '230203',
        title: '建华区',
        p_code: '230201'
      },
      {
        id: 727,
        code: '230204',
        title: '铁锋区',
        p_code: '230201'
      },
      {
        id: 728,
        code: '230205',
        title: '昂昂溪区',
        p_code: '230201'
      },
      {
        id: 729,
        code: '230206',
        title: '富拉尔基区',
        p_code: '230201'
      },
      {
        id: 730,
        code: '230207',
        title: '碾子山区',
        p_code: '230201'
      },
      {
        id: 731,
        code: '230208',
        title: '梅里斯达斡尔族区',
        p_code: '230201'
      },
      {
        id: 732,
        code: '230221',
        title: '龙江县',
        p_code: '230200'
      },
      {
        id: 733,
        code: '230223',
        title: '依安县',
        p_code: '230200'
      },
      {
        id: 734,
        code: '230224',
        title: '泰来县',
        p_code: '230200'
      },
      {
        id: 735,
        code: '230225',
        title: '甘南县',
        p_code: '230200'
      },
      {
        id: 736,
        code: '230227',
        title: '富裕县',
        p_code: '230200'
      },
      {
        id: 737,
        code: '230229',
        title: '克山县',
        p_code: '230200'
      },
      {
        id: 738,
        code: '230230',
        title: '克东县',
        p_code: '230200'
      },
      {
        id: 739,
        code: '230231',
        title: '拜泉县',
        p_code: '230200'
      },
      {
        id: 740,
        code: '230281',
        title: '讷河市',
        p_code: '230200'
      },
      {
        id: 741,
        code: '230300',
        title: '鸡西市',
        p_code: '230000'
      },
      {
        id: 742,
        code: '230301',
        title: '市辖区',
        p_code: '230300'
      },
      {
        id: 743,
        code: '230302',
        title: '鸡冠区',
        p_code: '230301'
      },
      {
        id: 744,
        code: '230303',
        title: '恒山区',
        p_code: '230301'
      },
      {
        id: 745,
        code: '230304',
        title: '滴道区',
        p_code: '230301'
      },
      {
        id: 746,
        code: '230305',
        title: '梨树区',
        p_code: '230301'
      },
      {
        id: 747,
        code: '230306',
        title: '城子河区',
        p_code: '230301'
      },
      {
        id: 748,
        code: '230307',
        title: '麻山区',
        p_code: '230301'
      },
      {
        id: 749,
        code: '230321',
        title: '鸡东县',
        p_code: '230300'
      },
      {
        id: 750,
        code: '230381',
        title: '虎林市',
        p_code: '230300'
      },
      {
        id: 751,
        code: '230382',
        title: '密山市',
        p_code: '230300'
      },
      {
        id: 752,
        code: '230400',
        title: '鹤岗市',
        p_code: '230000'
      },
      {
        id: 753,
        code: '230401',
        title: '市辖区',
        p_code: '230400'
      },
      {
        id: 754,
        code: '230402',
        title: '向阳区',
        p_code: '230401'
      },
      {
        id: 755,
        code: '230403',
        title: '工农区',
        p_code: '230401'
      },
      {
        id: 756,
        code: '230404',
        title: '南山区',
        p_code: '230401'
      },
      {
        id: 757,
        code: '230405',
        title: '兴安区',
        p_code: '230401'
      },
      {
        id: 758,
        code: '230406',
        title: '东山区',
        p_code: '230401'
      },
      {
        id: 759,
        code: '230407',
        title: '兴山区',
        p_code: '230401'
      },
      {
        id: 760,
        code: '230421',
        title: '萝北县',
        p_code: '230400'
      },
      {
        id: 761,
        code: '230422',
        title: '绥滨县',
        p_code: '230400'
      },
      {
        id: 762,
        code: '230500',
        title: '双鸭山市',
        p_code: '230000'
      },
      {
        id: 763,
        code: '230501',
        title: '市辖区',
        p_code: '230500'
      },
      {
        id: 764,
        code: '230502',
        title: '尖山区',
        p_code: '230501'
      },
      {
        id: 765,
        code: '230503',
        title: '岭东区',
        p_code: '230501'
      },
      {
        id: 766,
        code: '230505',
        title: '四方台区',
        p_code: '230501'
      },
      {
        id: 767,
        code: '230506',
        title: '宝山区',
        p_code: '230501'
      },
      {
        id: 768,
        code: '230521',
        title: '集贤县',
        p_code: '230500'
      },
      {
        id: 769,
        code: '230522',
        title: '友谊县',
        p_code: '230500'
      },
      {
        id: 770,
        code: '230523',
        title: '宝清县',
        p_code: '230500'
      },
      {
        id: 771,
        code: '230524',
        title: '饶河县',
        p_code: '230500'
      },
      {
        id: 772,
        code: '230600',
        title: '大庆市',
        p_code: '230000'
      },
      {
        id: 773,
        code: '230601',
        title: '市辖区',
        p_code: '230600'
      },
      {
        id: 774,
        code: '230602',
        title: '萨尔图区',
        p_code: '230601'
      },
      {
        id: 775,
        code: '230603',
        title: '龙凤区',
        p_code: '230601'
      },
      {
        id: 776,
        code: '230604',
        title: '让胡路区',
        p_code: '230601'
      },
      {
        id: 777,
        code: '230605',
        title: '红岗区',
        p_code: '230601'
      },
      {
        id: 778,
        code: '230606',
        title: '大同区',
        p_code: '230601'
      },
      {
        id: 779,
        code: '230621',
        title: '肇州县',
        p_code: '230600'
      },
      {
        id: 780,
        code: '230622',
        title: '肇源县',
        p_code: '230600'
      },
      {
        id: 781,
        code: '230623',
        title: '林甸县',
        p_code: '230600'
      },
      {
        id: 782,
        code: '230624',
        title: '杜尔伯特蒙古族自治县',
        p_code: '230600'
      },
      {
        id: 783,
        code: '230700',
        title: '伊春市',
        p_code: '230000'
      },
      {
        id: 784,
        code: '230701',
        title: '市辖区',
        p_code: '230700'
      },
      {
        id: 785,
        code: '230702',
        title: '伊春区',
        p_code: '230701'
      },
      {
        id: 786,
        code: '230703',
        title: '南岔区',
        p_code: '230701'
      },
      {
        id: 787,
        code: '230704',
        title: '友好区',
        p_code: '230701'
      },
      {
        id: 788,
        code: '230705',
        title: '西林区',
        p_code: '230701'
      },
      {
        id: 789,
        code: '230706',
        title: '翠峦区',
        p_code: '230701'
      },
      {
        id: 790,
        code: '230707',
        title: '新青区',
        p_code: '230701'
      },
      {
        id: 791,
        code: '230708',
        title: '美溪区',
        p_code: '230701'
      },
      {
        id: 792,
        code: '230709',
        title: '金山屯区',
        p_code: '230701'
      },
      {
        id: 793,
        code: '230710',
        title: '五营区',
        p_code: '230701'
      },
      {
        id: 794,
        code: '230711',
        title: '乌马河区',
        p_code: '230701'
      },
      {
        id: 795,
        code: '230712',
        title: '汤旺河区',
        p_code: '230701'
      },
      {
        id: 796,
        code: '230713',
        title: '带岭区',
        p_code: '230701'
      },
      {
        id: 797,
        code: '230714',
        title: '乌伊岭区',
        p_code: '230701'
      },
      {
        id: 798,
        code: '230715',
        title: '红星区',
        p_code: '230701'
      },
      {
        id: 799,
        code: '230716',
        title: '上甘岭区',
        p_code: '230701'
      },
      {
        id: 800,
        code: '230722',
        title: '嘉荫县',
        p_code: '230700'
      },
      {
        id: 801,
        code: '230781',
        title: '铁力市',
        p_code: '230700'
      },
      {
        id: 802,
        code: '230800',
        title: '佳木斯市',
        p_code: '230000'
      },
      {
        id: 803,
        code: '230801',
        title: '市辖区',
        p_code: '230800'
      },
      {
        id: 804,
        code: '230803',
        title: '向阳区',
        p_code: '230801'
      },
      {
        id: 805,
        code: '230804',
        title: '前进区',
        p_code: '230801'
      },
      {
        id: 806,
        code: '230805',
        title: '东风区',
        p_code: '230801'
      },
      {
        id: 807,
        code: '230811',
        title: '郊区',
        p_code: '230801'
      },
      {
        id: 808,
        code: '230822',
        title: '桦南县',
        p_code: '230800'
      },
      {
        id: 809,
        code: '230826',
        title: '桦川县',
        p_code: '230800'
      },
      {
        id: 810,
        code: '230828',
        title: '汤原县',
        p_code: '230800'
      },
      {
        id: 811,
        code: '230833',
        title: '抚远县',
        p_code: '230800'
      },
      {
        id: 812,
        code: '230881',
        title: '同江市',
        p_code: '230800'
      },
      {
        id: 813,
        code: '230882',
        title: '富锦市',
        p_code: '230800'
      },
      {
        id: 814,
        code: '230900',
        title: '七台河市',
        p_code: '230000'
      },
      {
        id: 815,
        code: '230901',
        title: '市辖区',
        p_code: '230900'
      },
      {
        id: 816,
        code: '230902',
        title: '新兴区',
        p_code: '230901'
      },
      {
        id: 817,
        code: '230903',
        title: '桃山区',
        p_code: '230901'
      },
      {
        id: 818,
        code: '230904',
        title: '茄子河区',
        p_code: '230901'
      },
      {
        id: 819,
        code: '230921',
        title: '勃利县',
        p_code: '230900'
      },
      {
        id: 820,
        code: '231000',
        title: '牡丹江市',
        p_code: '230000'
      },
      {
        id: 821,
        code: '231001',
        title: '市辖区',
        p_code: '231000'
      },
      {
        id: 822,
        code: '231002',
        title: '东安区',
        p_code: '231001'
      },
      {
        id: 823,
        code: '231003',
        title: '阳明区',
        p_code: '231001'
      },
      {
        id: 824,
        code: '231004',
        title: '爱民区',
        p_code: '231001'
      },
      {
        id: 825,
        code: '231005',
        title: '西安区',
        p_code: '231001'
      },
      {
        id: 826,
        code: '231024',
        title: '东宁县',
        p_code: '231000'
      },
      {
        id: 827,
        code: '231025',
        title: '林口县',
        p_code: '231000'
      },
      {
        id: 828,
        code: '231081',
        title: '绥芬河市',
        p_code: '231000'
      },
      {
        id: 829,
        code: '231083',
        title: '海林市',
        p_code: '231000'
      },
      {
        id: 830,
        code: '231084',
        title: '宁安市',
        p_code: '231000'
      },
      {
        id: 831,
        code: '231085',
        title: '穆棱市',
        p_code: '231000'
      },
      {
        id: 832,
        code: '231100',
        title: '黑河市',
        p_code: '230000'
      },
      {
        id: 833,
        code: '231101',
        title: '市辖区',
        p_code: '231100'
      },
      {
        id: 834,
        code: '231102',
        title: '爱辉区',
        p_code: '231101'
      },
      {
        id: 835,
        code: '231121',
        title: '嫩江县',
        p_code: '231100'
      },
      {
        id: 836,
        code: '231123',
        title: '逊克县',
        p_code: '231100'
      },
      {
        id: 837,
        code: '231124',
        title: '孙吴县',
        p_code: '231100'
      },
      {
        id: 838,
        code: '231181',
        title: '北安市',
        p_code: '231100'
      },
      {
        id: 839,
        code: '231182',
        title: '五大连池市',
        p_code: '231100'
      },
      {
        id: 840,
        code: '231200',
        title: '绥化市',
        p_code: '230000'
      },
      {
        id: 841,
        code: '231201',
        title: '北林区',
        p_code: '231200'
      },
      {
        id: 842,
        code: '231221',
        title: '望奎县',
        p_code: '231200'
      },
      {
        id: 843,
        code: '231222',
        title: '兰西县',
        p_code: '231200'
      },
      {
        id: 844,
        code: '231223',
        title: '青冈县',
        p_code: '231200'
      },
      {
        id: 845,
        code: '231224',
        title: '庆安县',
        p_code: '231200'
      },
      {
        id: 846,
        code: '231225',
        title: '明水县',
        p_code: '231200'
      },
      {
        id: 847,
        code: '231226',
        title: '绥棱县',
        p_code: '231200'
      },
      {
        id: 848,
        code: '231281',
        title: '安达市',
        p_code: '231200'
      },
      {
        id: 849,
        code: '231282',
        title: '肇东市',
        p_code: '231200'
      },
      {
        id: 850,
        code: '231283',
        title: '海伦市',
        p_code: '231200'
      },
      {
        id: 851,
        code: '232700',
        title: '大兴安岭地区',
        p_code: '230000'
      },
      {
        id: 852,
        code: '232701',
        title: '加格达奇区',
        p_code: '232700'
      },
      {
        id: 853,
        code: '232702',
        title: '松岭区',
        p_code: '232700'
      },
      {
        id: 854,
        code: '232703',
        title: '新林区',
        p_code: '232700'
      },
      {
        id: 855,
        code: '232704',
        title: '呼中区',
        p_code: '232700'
      },
      {
        id: 856,
        code: '232721',
        title: '呼玛县',
        p_code: '232700'
      },
      {
        id: 857,
        code: '232722',
        title: '塔河县',
        p_code: '232700'
      },
      {
        id: 858,
        code: '232723',
        title: '漠河县',
        p_code: '232700'
      },

      {
        id: 860,
        code: '310100',
        title: '黄浦区',
        p_code: '310000'
      },
      {
        id: 861,
        code: '310300',
        title: '卢湾区',
        p_code: '310000'
      },
      {
        id: 862,
        code: '310400',
        title: '徐汇区',
        p_code: '310000'
      },
      {
        id: 863,
        code: '310500',
        title: '长宁区',
        p_code: '310000'
      },
      {
        id: 864,
        code: '310600',
        title: '静安区',
        p_code: '310000'
      },
      {
        id: 865,
        code: '310700',
        title: '普陀区',
        p_code: '310000'
      },
      {
        id: 866,
        code: '310800',
        title: '闸北区',
        p_code: '310000'
      },
      {
        id: 867,
        code: '310900',
        title: '虹口区',
        p_code: '310000'
      },
      {
        id: 868,
        code: '311000',
        title: '杨浦区',
        p_code: '310000'
      },
      {
        id: 869,
        code: '311200',
        title: '闵行区',
        p_code: '310000'
      },
      {
        id: 870,
        code: '311300',
        title: '宝山区',
        p_code: '310000'
      },
      {
        id: 871,
        code: '311400',
        title: '嘉定区',
        p_code: '310000'
      },
      {
        id: 872,
        code: '311500',
        title: '浦东新区',
        p_code: '310000'
      },
      {
        id: 873,
        code: '311600',
        title: '金山区',
        p_code: '310000'
      },
      {
        id: 874,
        code: '311700',
        title: '松江区',
        p_code: '310000'
      },
      {
        id: 875,
        code: '311800',
        title: '青浦区',
        p_code: '310000'
      },
      {
        id: 876,
        code: '311900',
        title: '南汇区',
        p_code: '310000'
      },
      {
        id: 877,
        code: '312000',
        title: '奉贤区',
        p_code: '310000'
      },
      {
        id: 878,
        code: '313000',
        title: '崇明县',
        p_code: '310000'
      },

      {
        id: 880,
        code: '320100',
        title: '南京市',
        p_code: '320000'
      },
      {
        id: 881,
        code: '320101',
        title: '市辖区',
        p_code: '320100'
      },
      {
        id: 882,
        code: '320102',
        title: '玄武区',
        p_code: '320101'
      },
      {
        id: 883,
        code: '320103',
        title: '白下区',
        p_code: '320101'
      },
      {
        id: 884,
        code: '320104',
        title: '秦淮区',
        p_code: '320101'
      },
      {
        id: 885,
        code: '320105',
        title: '建邺区',
        p_code: '320101'
      },
      {
        id: 886,
        code: '320106',
        title: '鼓楼区',
        p_code: '320101'
      },
      {
        id: 887,
        code: '320107',
        title: '下关区',
        p_code: '320101'
      },
      {
        id: 888,
        code: '320111',
        title: '浦口区',
        p_code: '320101'
      },
      {
        id: 889,
        code: '320113',
        title: '栖霞区',
        p_code: '320101'
      },
      {
        id: 890,
        code: '320114',
        title: '雨花台区',
        p_code: '320101'
      },
      {
        id: 891,
        code: '320115',
        title: '江宁区',
        p_code: '320101'
      },
      {
        id: 892,
        code: '320116',
        title: '六合区',
        p_code: '320101'
      },
      {
        id: 893,
        code: '320124',
        title: '溧水区',
        p_code: '320100'
      },
      {
        id: 894,
        code: '320125',
        title: '高淳区',
        p_code: '320100'
      },
      {
        id: 895,
        code: '320200',
        title: '无锡市',
        p_code: '320000'
      },
      {
        id: 896,
        code: '320201',
        title: '市辖区',
        p_code: '320200'
      },
      {
        id: 897,
        code: '320202',
        title: '崇安区',
        p_code: '320201'
      },
      {
        id: 898,
        code: '320203',
        title: '南长区',
        p_code: '320201'
      },
      {
        id: 899,
        code: '320204',
        title: '北塘区',
        p_code: '320201'
      },
      {
        id: 900,
        code: '320205',
        title: '锡山区',
        p_code: '320201'
      },
      {
        id: 901,
        code: '320206',
        title: '惠山区',
        p_code: '320201'
      },
      {
        id: 902,
        code: '320211',
        title: '滨湖区',
        p_code: '320201'
      },
      {
        id: 903,
        code: '320281',
        title: '江阴市',
        p_code: '320200'
      },
      {
        id: 904,
        code: '320282',
        title: '宜兴市',
        p_code: '320200'
      },
      {
        id: 905,
        code: '320300',
        title: '徐州市',
        p_code: '320000'
      },
      {
        id: 906,
        code: '320301',
        title: '泉山区',
        p_code: '320300'
      },
      {
        id: 907,
        code: '320302',
        title: '鼓楼区',
        p_code: '320300'
      },
      {
        id: 908,
        code: '320303',
        title: '云龙区',
        p_code: '320300'
      },
      {
        id: 909,
        code: '320304',
        title: '九里区',
        p_code: '320301'
      },
      {
        id: 910,
        code: '320305',
        title: '贾汪区',
        p_code: '320300'
      },
      {
        id: 911,
        code: '320321',
        title: '丰县',
        p_code: '320300'
      },
      {
        id: 912,
        code: '320322',
        title: '沛县',
        p_code: '320300'
      },
      {
        id: 913,
        code: '320323',
        title: '铜山县',
        p_code: '320300'
      },
      {
        id: 914,
        code: '320324',
        title: '睢宁县',
        p_code: '320300'
      },
      {
        id: 915,
        code: '320381',
        title: '新沂市',
        p_code: '320300'
      },
      {
        id: 916,
        code: '320382',
        title: '邳州市',
        p_code: '320300'
      },
      {
        id: 917,
        code: '320400',
        title: '常州市',
        p_code: '320000'
      },
      {
        id: 918,
        code: '320401',
        title: '市辖区',
        p_code: '320400'
      },
      {
        id: 919,
        code: '320402',
        title: '天宁区',
        p_code: '320401'
      },
      {
        id: 920,
        code: '320404',
        title: '钟楼区',
        p_code: '320401'
      },
      {
        id: 921,
        code: '320405',
        title: '戚墅堰区',
        p_code: '320401'
      },
      {
        id: 922,
        code: '320411',
        title: '新北区',
        p_code: '320401'
      },
      {
        id: 923,
        code: '320412',
        title: '武进区',
        p_code: '320401'
      },
      {
        id: 924,
        code: '320481',
        title: '溧阳市',
        p_code: '320400'
      },
      {
        id: 925,
        code: '320482',
        title: '金坛市',
        p_code: '320400'
      },
      {
        id: 926,
        code: '320500',
        title: '苏州市',
        p_code: '320000'
      },
      {
        id: 927,
        code: '320501',
        title: '市辖区',
        p_code: '320500'
      },
      {
        id: 928,
        code: '320502',
        title: '沧浪区',
        p_code: '320501'
      },
      {
        id: 929,
        code: '320503',
        title: '平江区',
        p_code: '320501'
      },
      {
        id: 930,
        code: '320504',
        title: '金阊区',
        p_code: '320501'
      },
      {
        id: 931,
        code: '320505',
        title: '虎丘区',
        p_code: '320501'
      },
      {
        id: 932,
        code: '320506',
        title: '吴中区',
        p_code: '320501'
      },
      {
        id: 933,
        code: '320507',
        title: '相城区',
        p_code: '320501'
      },
      {
        id: 934,
        code: '320581',
        title: '常熟市',
        p_code: '320500'
      },
      {
        id: 935,
        code: '320582',
        title: '张家港市',
        p_code: '320500'
      },
      {
        id: 936,
        code: '320583',
        title: '昆山市',
        p_code: '320500'
      },
      {
        id: 937,
        code: '320584',
        title: '吴江市',
        p_code: '320500'
      },
      {
        id: 938,
        code: '320585',
        title: '太仓市',
        p_code: '320500'
      },
      {
        id: 939,
        code: '320600',
        title: '南通市',
        p_code: '320000'
      },
      {
        id: 940,
        code: '320601',
        title: '市辖区',
        p_code: '320600'
      },
      {
        id: 941,
        code: '320602',
        title: '崇川区',
        p_code: '320601'
      },
      {
        id: 942,
        code: '320611',
        title: '港闸区',
        p_code: '320601'
      },
      {
        id: 943,
        code: '320621',
        title: '海安县',
        p_code: '320600'
      },
      {
        id: 944,
        code: '320623',
        title: '如东县',
        p_code: '320600'
      },
      {
        id: 945,
        code: '320681',
        title: '启东市',
        p_code: '320600'
      },
      {
        id: 946,
        code: '320682',
        title: '如皋市',
        p_code: '320600'
      },
      {
        id: 947,
        code: '320683',
        title: '通州市',
        p_code: '320600'
      },
      {
        id: 948,
        code: '320684',
        title: '海门市',
        p_code: '320600'
      },
      {
        id: 949,
        code: '320700',
        title: '连云港市',
        p_code: '320000'
      },
      {
        id: 950,
        code: '320701',
        title: '市辖区',
        p_code: '320700'
      },
      {
        id: 951,
        code: '320703',
        title: '连云区',
        p_code: '320701'
      },
      {
        id: 952,
        code: '320705',
        title: '新浦区',
        p_code: '320701'
      },
      {
        id: 953,
        code: '320706',
        title: '海州区',
        p_code: '320701'
      },
      {
        id: 954,
        code: '320721',
        title: '赣榆县',
        p_code: '320700'
      },
      {
        id: 955,
        code: '320722',
        title: '东海县',
        p_code: '320700'
      },
      {
        id: 956,
        code: '320723',
        title: '灌云县',
        p_code: '320700'
      },
      {
        id: 957,
        code: '320724',
        title: '灌南县',
        p_code: '320700'
      },
      {
        id: 958,
        code: '320800',
        title: '淮安市',
        p_code: '320000'
      },
      {
        id: 959,
        code: '320801',
        title: '市辖区',
        p_code: '320800'
      },
      {
        id: 960,
        code: '320802',
        title: '清河区',
        p_code: '320801'
      },
      {
        id: 961,
        code: '320803',
        title: '楚州区',
        p_code: '320801'
      },
      {
        id: 962,
        code: '320804',
        title: '淮阴区',
        p_code: '320801'
      },
      {
        id: 963,
        code: '320811',
        title: '清浦区',
        p_code: '320801'
      },
      {
        id: 964,
        code: '320826',
        title: '涟水县',
        p_code: '320800'
      },
      {
        id: 965,
        code: '320829',
        title: '洪泽县',
        p_code: '320800'
      },
      {
        id: 966,
        code: '320830',
        title: '盱眙县',
        p_code: '320800'
      },
      {
        id: 967,
        code: '320831',
        title: '金湖县',
        p_code: '320800'
      },
      {
        id: 968,
        code: '320900',
        title: '盐城市',
        p_code: '320000'
      },
      {
        id: 969,
        code: '320901',
        title: '市辖区',
        p_code: '320900'
      },
      {
        id: 970,
        code: '320902',
        title: '亭湖区',
        p_code: '320901'
      },
      {
        id: 971,
        code: '320903',
        title: '盐都区',
        p_code: '320901'
      },
      {
        id: 972,
        code: '320921',
        title: '响水县',
        p_code: '320900'
      },
      {
        id: 973,
        code: '320922',
        title: '滨海县',
        p_code: '320900'
      },
      {
        id: 974,
        code: '320923',
        title: '阜宁县',
        p_code: '320900'
      },
      {
        id: 975,
        code: '320924',
        title: '射阳县',
        p_code: '320900'
      },
      {
        id: 976,
        code: '320925',
        title: '建湖县',
        p_code: '320900'
      },
      {
        id: 977,
        code: '320981',
        title: '东台市',
        p_code: '320900'
      },
      {
        id: 978,
        code: '320982',
        title: '大丰市',
        p_code: '320900'
      },
      {
        id: 979,
        code: '321000',
        title: '扬州市',
        p_code: '320000'
      },
      {
        id: 980,
        code: '321001',
        title: '市辖区',
        p_code: '321000'
      },
      {
        id: 981,
        code: '321002',
        title: '广陵区',
        p_code: '321001'
      },
      {
        id: 982,
        code: '321003',
        title: '邗江区',
        p_code: '321001'
      },
      {
        id: 983,
        code: '321011',
        title: '维扬区',
        p_code: '321001'
      },
      {
        id: 984,
        code: '321023',
        title: '宝应县',
        p_code: '321000'
      },
      {
        id: 985,
        code: '321081',
        title: '仪征市',
        p_code: '321000'
      },
      {
        id: 986,
        code: '321084',
        title: '高邮市',
        p_code: '321000'
      },
      {
        id: 987,
        code: '321088',
        title: '江都市',
        p_code: '321000'
      },
      {
        id: 988,
        code: '321100',
        title: '镇江市',
        p_code: '320000'
      },
      {
        id: 989,
        code: '321101',
        title: '市辖区',
        p_code: '321100'
      },
      {
        id: 990,
        code: '321102',
        title: '京口区',
        p_code: '321101'
      },
      {
        id: 991,
        code: '321111',
        title: '润州区',
        p_code: '321101'
      },
      {
        id: 992,
        code: '321112',
        title: '丹徒区',
        p_code: '321101'
      },
      {
        id: 993,
        code: '321181',
        title: '丹阳市',
        p_code: '321100'
      },
      {
        id: 994,
        code: '321182',
        title: '扬中市',
        p_code: '321100'
      },
      {
        id: 995,
        code: '321183',
        title: '句容市',
        p_code: '321100'
      },
      {
        id: 996,
        code: '321200',
        title: '泰州市',
        p_code: '320000'
      },
      {
        id: 997,
        code: '321201',
        title: '市辖区',
        p_code: '321200'
      },
      {
        id: 998,
        code: '321202',
        title: '海陵区',
        p_code: '321201'
      },
      {
        id: 999,
        code: '321203',
        title: '高港区',
        p_code: '321201'
      },
      {
        id: 1000,
        code: '321281',
        title: '兴化市',
        p_code: '321200'
      },
      {
        id: 1001,
        code: '321282',
        title: '靖江市',
        p_code: '321200'
      },
      {
        id: 1002,
        code: '321283',
        title: '泰兴市',
        p_code: '321200'
      },
      {
        id: 1003,
        code: '321284',
        title: '姜堰市',
        p_code: '321200'
      },
      {
        id: 1004,
        code: '321300',
        title: '宿迁市',
        p_code: '320000'
      },
      {
        id: 1005,
        code: '321301',
        title: '市辖区',
        p_code: '321300'
      },
      {
        id: 1006,
        code: '321302',
        title: '宿城区',
        p_code: '321301'
      },
      {
        id: 1007,
        code: '321311',
        title: '宿豫区',
        p_code: '321301'
      },
      {
        id: 1008,
        code: '321322',
        title: '沭阳县',
        p_code: '321300'
      },
      {
        id: 1009,
        code: '321323',
        title: '泗阳县',
        p_code: '321300'
      },
      {
        id: 1010,
        code: '321324',
        title: '泗洪县',
        p_code: '321300'
      },

      {
        id: 1012,
        code: '330100',
        title: '杭州市',
        p_code: '330000'
      },
      {
        id: 1013,
        code: '330101',
        title: '市辖区',
        p_code: '330100'
      },
      {
        id: 1014,
        code: '330102',
        title: '上城区',
        p_code: '330101'
      },
      {
        id: 1015,
        code: '330103',
        title: '下城区',
        p_code: '330101'
      },
      {
        id: 1016,
        code: '330104',
        title: '江干区',
        p_code: '330101'
      },
      {
        id: 1017,
        code: '330105',
        title: '拱墅区',
        p_code: '330101'
      },
      {
        id: 1018,
        code: '330106',
        title: '西湖区',
        p_code: '330101'
      },
      {
        id: 1019,
        code: '330108',
        title: '滨江区',
        p_code: '330101'
      },
      {
        id: 1020,
        code: '330109',
        title: '萧山区',
        p_code: '330101'
      },
      {
        id: 1021,
        code: '330110',
        title: '余杭区',
        p_code: '330101'
      },
      {
        id: 1022,
        code: '330122',
        title: '桐庐县',
        p_code: '330100'
      },
      {
        id: 1023,
        code: '330127',
        title: '淳安县',
        p_code: '330100'
      },
      {
        id: 1024,
        code: '330182',
        title: '建德市',
        p_code: '330100'
      },
      {
        id: 1025,
        code: '330183',
        title: '富阳市',
        p_code: '330100'
      },
      {
        id: 1026,
        code: '330185',
        title: '临安市',
        p_code: '330100'
      },
      {
        id: 1027,
        code: '330200',
        title: '宁波市',
        p_code: '330000'
      },
      {
        id: 1028,
        code: '330201',
        title: '市辖区',
        p_code: '330200'
      },
      {
        id: 1029,
        code: '330203',
        title: '海曙区',
        p_code: '330201'
      },
      {
        id: 1030,
        code: '330204',
        title: '江东区',
        p_code: '330201'
      },
      {
        id: 1031,
        code: '330205',
        title: '江北区',
        p_code: '330201'
      },
      {
        id: 1032,
        code: '330206',
        title: '北仑区',
        p_code: '330201'
      },
      {
        id: 1033,
        code: '330211',
        title: '镇海区',
        p_code: '330201'
      },
      {
        id: 1034,
        code: '330212',
        title: '鄞州区',
        p_code: '330201'
      },
      {
        id: 1035,
        code: '330225',
        title: '象山县',
        p_code: '330200'
      },
      {
        id: 1036,
        code: '330226',
        title: '宁海县',
        p_code: '330200'
      },
      {
        id: 1037,
        code: '330281',
        title: '余姚市',
        p_code: '330200'
      },
      {
        id: 1038,
        code: '330282',
        title: '慈溪市',
        p_code: '330200'
      },
      {
        id: 1039,
        code: '330283',
        title: '奉化市',
        p_code: '330200'
      },
      {
        id: 1040,
        code: '330300',
        title: '温州市',
        p_code: '330000'
      },
      {
        id: 1041,
        code: '330301',
        title: '市辖区',
        p_code: '330300'
      },
      {
        id: 1042,
        code: '330302',
        title: '鹿城区',
        p_code: '330301'
      },
      {
        id: 1043,
        code: '330303',
        title: '龙湾区',
        p_code: '330301'
      },
      {
        id: 1044,
        code: '330304',
        title: '瓯海区',
        p_code: '330301'
      },
      {
        id: 1045,
        code: '330322',
        title: '洞头县',
        p_code: '330300'
      },
      {
        id: 1046,
        code: '330324',
        title: '永嘉县',
        p_code: '330300'
      },
      {
        id: 1047,
        code: '330326',
        title: '平阳县',
        p_code: '330300'
      },
      {
        id: 1048,
        code: '330327',
        title: '苍南县',
        p_code: '330300'
      },
      {
        id: 1049,
        code: '330328',
        title: '文成县',
        p_code: '330300'
      },
      {
        id: 1050,
        code: '330329',
        title: '泰顺县',
        p_code: '330300'
      },
      {
        id: 1051,
        code: '330381',
        title: '瑞安市',
        p_code: '330300'
      },
      {
        id: 1052,
        code: '330382',
        title: '乐清市',
        p_code: '330300'
      },
      {
        id: 1053,
        code: '330400',
        title: '嘉兴市',
        p_code: '330000'
      },
      {
        id: 1054,
        code: '330401',
        title: '市辖区',
        p_code: '330400'
      },
      {
        id: 1055,
        code: '330402',
        title: '南湖区',
        p_code: '330401'
      },
      {
        id: 1056,
        code: '330411',
        title: '秀洲区',
        p_code: '330401'
      },
      {
        id: 1057,
        code: '330421',
        title: '嘉善县',
        p_code: '330400'
      },
      {
        id: 1058,
        code: '330424',
        title: '海盐县',
        p_code: '330400'
      },
      {
        id: 1059,
        code: '330481',
        title: '海宁市',
        p_code: '330400'
      },
      {
        id: 1060,
        code: '330482',
        title: '平湖市',
        p_code: '330400'
      },
      {
        id: 1061,
        code: '330483',
        title: '桐乡市',
        p_code: '330400'
      },
      {
        id: 1062,
        code: '330500',
        title: '湖州市',
        p_code: '330000'
      },
      {
        id: 1063,
        code: '330501',
        title: '市辖区',
        p_code: '330500'
      },
      {
        id: 1064,
        code: '330502',
        title: '吴兴区',
        p_code: '330501'
      },
      {
        id: 1065,
        code: '330503',
        title: '南浔区',
        p_code: '330501'
      },
      {
        id: 1066,
        code: '330521',
        title: '德清县',
        p_code: '330500'
      },
      {
        id: 1067,
        code: '330522',
        title: '长兴县',
        p_code: '330500'
      },
      {
        id: 1068,
        code: '330523',
        title: '安吉县',
        p_code: '330500'
      },
      {
        id: 1069,
        code: '330600',
        title: '绍兴市',
        p_code: '330000'
      },
      {
        id: 1070,
        code: '330601',
        title: '市辖区',
        p_code: '330600'
      },
      {
        id: 1071,
        code: '330602',
        title: '越城区',
        p_code: '330601'
      },
      {
        id: 1072,
        code: '330621',
        title: '绍兴县',
        p_code: '330600'
      },
      {
        id: 1073,
        code: '330624',
        title: '新昌县',
        p_code: '330600'
      },
      {
        id: 1074,
        code: '330681',
        title: '诸暨市',
        p_code: '330600'
      },
      {
        id: 1075,
        code: '330682',
        title: '上虞市',
        p_code: '330600'
      },
      {
        id: 1076,
        code: '330683',
        title: '嵊州市',
        p_code: '330600'
      },
      {
        id: 1077,
        code: '330700',
        title: '金华市',
        p_code: '330000'
      },
      {
        id: 1078,
        code: '330701',
        title: '市辖区',
        p_code: '330700'
      },
      {
        id: 1079,
        code: '330702',
        title: '婺城区',
        p_code: '330701'
      },
      {
        id: 1080,
        code: '330703',
        title: '金东区',
        p_code: '330701'
      },
      {
        id: 1081,
        code: '330723',
        title: '武义县',
        p_code: '330700'
      },
      {
        id: 1082,
        code: '330726',
        title: '浦江县',
        p_code: '330700'
      },
      {
        id: 1083,
        code: '330727',
        title: '磐安县',
        p_code: '330700'
      },
      {
        id: 1084,
        code: '330781',
        title: '兰溪市',
        p_code: '330700'
      },
      {
        id: 1085,
        code: '330782',
        title: '义乌市',
        p_code: '330700'
      },
      {
        id: 1086,
        code: '330783',
        title: '东阳市',
        p_code: '330700'
      },
      {
        id: 1087,
        code: '330784',
        title: '永康市',
        p_code: '330700'
      },
      {
        id: 1088,
        code: '330800',
        title: '衢州市',
        p_code: '330000'
      },
      {
        id: 1089,
        code: '330801',
        title: '市辖区',
        p_code: '330800'
      },
      {
        id: 1090,
        code: '330802',
        title: '柯城区',
        p_code: '330801'
      },
      {
        id: 1091,
        code: '330803',
        title: '衢江区',
        p_code: '330801'
      },
      {
        id: 1092,
        code: '330822',
        title: '常山县',
        p_code: '330800'
      },
      {
        id: 1093,
        code: '330824',
        title: '开化县',
        p_code: '330800'
      },
      {
        id: 1094,
        code: '330825',
        title: '龙游县',
        p_code: '330800'
      },
      {
        id: 1095,
        code: '330881',
        title: '江山市',
        p_code: '330800'
      },
      {
        id: 1096,
        code: '330900',
        title: '舟山市',
        p_code: '330000'
      },
      {
        id: 1097,
        code: '330901',
        title: '市辖区',
        p_code: '330900'
      },
      {
        id: 1098,
        code: '330902',
        title: '定海区',
        p_code: '330901'
      },
      {
        id: 1099,
        code: '330903',
        title: '普陀区',
        p_code: '330901'
      },
      {
        id: 1100,
        code: '330921',
        title: '岱山县',
        p_code: '330900'
      },
      {
        id: 1101,
        code: '330922',
        title: '嵊泗县',
        p_code: '330900'
      },
      {
        id: 1102,
        code: '331000',
        title: '台州市',
        p_code: '330000'
      },
      {
        id: 1103,
        code: '331001',
        title: '市辖区',
        p_code: '331000'
      },
      {
        id: 1104,
        code: '331002',
        title: '椒江区',
        p_code: '331001'
      },
      {
        id: 1105,
        code: '331003',
        title: '黄岩区',
        p_code: '331001'
      },
      {
        id: 1106,
        code: '331004',
        title: '路桥区',
        p_code: '331001'
      },
      {
        id: 1107,
        code: '331021',
        title: '玉环县',
        p_code: '331000'
      },
      {
        id: 1108,
        code: '331022',
        title: '三门县',
        p_code: '331000'
      },
      {
        id: 1109,
        code: '331023',
        title: '天台县',
        p_code: '331000'
      },
      {
        id: 1110,
        code: '331024',
        title: '仙居县',
        p_code: '331000'
      },
      {
        id: 1111,
        code: '331081',
        title: '温岭市',
        p_code: '331000'
      },
      {
        id: 1112,
        code: '331082',
        title: '临海市',
        p_code: '331000'
      },
      {
        id: 1113,
        code: '331100',
        title: '丽水市',
        p_code: '330000'
      },
      {
        id: 1114,
        code: '331101',
        title: '市辖区',
        p_code: '331100'
      },
      {
        id: 1115,
        code: '331102',
        title: '莲都区',
        p_code: '331101'
      },
      {
        id: 1116,
        code: '331121',
        title: '青田县',
        p_code: '331100'
      },
      {
        id: 1117,
        code: '331122',
        title: '缙云县',
        p_code: '331100'
      },
      {
        id: 1118,
        code: '331123',
        title: '遂昌县',
        p_code: '331100'
      },
      {
        id: 1119,
        code: '331124',
        title: '松阳县',
        p_code: '331100'
      },
      {
        id: 1120,
        code: '331125',
        title: '云和县',
        p_code: '331100'
      },
      {
        id: 1121,
        code: '331126',
        title: '庆元县',
        p_code: '331100'
      },
      {
        id: 1122,
        code: '331127',
        title: '景宁畲族自治县',
        p_code: '331100'
      },
      {
        id: 1123,
        code: '331181',
        title: '龙泉市',
        p_code: '331100'
      },

      {
        id: 1125,
        code: '340100',
        title: '合肥市',
        p_code: '340000'
      },
      {
        id: 1126,
        code: '340101',
        title: '市辖区',
        p_code: '340100'
      },
      {
        id: 1127,
        code: '340102',
        title: '瑶海区',
        p_code: '340101'
      },
      {
        id: 1128,
        code: '340103',
        title: '庐阳区',
        p_code: '340101'
      },
      {
        id: 1129,
        code: '340104',
        title: '蜀山区',
        p_code: '340101'
      },
      {
        id: 1130,
        code: '340111',
        title: '包河区',
        p_code: '340101'
      },
      {
        id: 1131,
        code: '340121',
        title: '长丰县',
        p_code: '340100'
      },
      {
        id: 1132,
        code: '340122',
        title: '肥东县',
        p_code: '340100'
      },
      {
        id: 1133,
        code: '340123',
        title: '肥西县',
        p_code: '340100'
      },
      {
        id: 1134,
        code: '340124',
        title: '庐江县',
        p_code: '340100'
      },
      {
        id: 1135,
        code: '340181',
        title: '巢湖市',
        p_code: '340100'
      },
      {
        id: 1136,
        code: '340200',
        title: '芜湖市',
        p_code: '340000'
      },
      {
        id: 1137,
        code: '340201',
        title: '市辖区',
        p_code: '340200'
      },
      {
        id: 1138,
        code: '340202',
        title: '镜湖区',
        p_code: '340201'
      },
      {
        id: 1139,
        code: '340203',
        title: '马塘区',
        p_code: '340201'
      },
      {
        id: 1140,
        code: '340207',
        title: '鸠江区',
        p_code: '340201'
      },
      {
        id: 1141,
        code: '340221',
        title: '芜湖县',
        p_code: '340200'
      },
      {
        id: 1142,
        code: '340222',
        title: '繁昌县',
        p_code: '340200'
      },
      {
        id: 1143,
        code: '340223',
        title: '南陵县',
        p_code: '340200'
      },
      {
        id: 1144,
        code: '340225',
        title: '无为县',
        p_code: '340200'
      },
      {
        id: 1145,
        code: '340300',
        title: '蚌埠市',
        p_code: '340000'
      },
      {
        id: 1146,
        code: '340301',
        title: '市辖区',
        p_code: '340300'
      },
      {
        id: 1147,
        code: '340302',
        title: '龙子湖区',
        p_code: '340301'
      },
      {
        id: 1148,
        code: '340303',
        title: '蚌山区',
        p_code: '340301'
      },
      {
        id: 1149,
        code: '340304',
        title: '禹会区',
        p_code: '340301'
      },
      {
        id: 1150,
        code: '340311',
        title: '淮上区',
        p_code: '340301'
      },
      {
        id: 1151,
        code: '340321',
        title: '怀远县',
        p_code: '340300'
      },
      {
        id: 1152,
        code: '340322',
        title: '五河县',
        p_code: '340300'
      },
      {
        id: 1153,
        code: '340323',
        title: '固镇县',
        p_code: '340300'
      },
      {
        id: 1154,
        code: '340400',
        title: '淮南市',
        p_code: '340000'
      },
      {
        id: 1155,
        code: '340401',
        title: '市辖区',
        p_code: '340400'
      },
      {
        id: 1156,
        code: '340402',
        title: '大通区',
        p_code: '340401'
      },
      {
        id: 1157,
        code: '340403',
        title: '田家庵区',
        p_code: '340401'
      },
      {
        id: 1158,
        code: '340404',
        title: '谢家集区',
        p_code: '340401'
      },
      {
        id: 1159,
        code: '340405',
        title: '八公山区',
        p_code: '340401'
      },
      {
        id: 1160,
        code: '340406',
        title: '潘集区',
        p_code: '340401'
      },
      {
        id: 1161,
        code: '340421',
        title: '凤台县',
        p_code: '340400'
      },
      {
        id: 1162,
        code: '340500',
        title: '马鞍山市',
        p_code: '340000'
      },
      {
        id: 1163,
        code: '340501',
        title: '市辖区',
        p_code: '340500'
      },
      {
        id: 1164,
        code: '340502',
        title: '金家庄区',
        p_code: '340501'
      },
      {
        id: 1165,
        code: '340503',
        title: '花山区',
        p_code: '340501'
      },
      {
        id: 1166,
        code: '340504',
        title: '雨山区',
        p_code: '340501'
      },
      {
        id: 1167,
        code: '340521',
        title: '当涂县',
        p_code: '340500'
      },
      {
        id: 1168,
        code: '340522',
        title: '含山县',
        p_code: '340500'
      },
      {
        id: 1169,
        code: '340523',
        title: '和县',
        p_code: '340500'
      },
      {
        id: 1170,
        code: '340600',
        title: '淮北市',
        p_code: '340000'
      },
      {
        id: 1171,
        code: '340601',
        title: '市辖区',
        p_code: '340600'
      },
      {
        id: 1172,
        code: '340602',
        title: '杜集区',
        p_code: '340601'
      },
      {
        id: 1173,
        code: '340603',
        title: '相山区',
        p_code: '340601'
      },
      {
        id: 1174,
        code: '340604',
        title: '烈山区',
        p_code: '340601'
      },
      {
        id: 1175,
        code: '340621',
        title: '濉溪县',
        p_code: '340600'
      },
      {
        id: 1176,
        code: '340700',
        title: '铜陵市',
        p_code: '340000'
      },
      {
        id: 1177,
        code: '340701',
        title: '市辖区',
        p_code: '340700'
      },
      {
        id: 1178,
        code: '340702',
        title: '铜官山区',
        p_code: '340701'
      },
      {
        id: 1179,
        code: '340703',
        title: '狮子山区',
        p_code: '340701'
      },
      {
        id: 1180,
        code: '340711',
        title: '郊区',
        p_code: '340701'
      },
      {
        id: 1181,
        code: '340721',
        title: '铜陵县',
        p_code: '340700'
      },
      {
        id: 1182,
        code: '340800',
        title: '安庆市',
        p_code: '340000'
      },
      {
        id: 1183,
        code: '340801',
        title: '市辖区',
        p_code: '340800'
      },
      {
        id: 1184,
        code: '340802',
        title: '迎江区',
        p_code: '340801'
      },
      {
        id: 1185,
        code: '340803',
        title: '大观区',
        p_code: '340801'
      },
      {
        id: 1186,
        code: '340811',
        title: '宜秀区',
        p_code: '340801'
      },
      {
        id: 1187,
        code: '340822',
        title: '怀宁县',
        p_code: '340800'
      },
      {
        id: 1188,
        code: '340823',
        title: '枞阳县',
        p_code: '340800'
      },
      {
        id: 1189,
        code: '340824',
        title: '潜山县',
        p_code: '340800'
      },
      {
        id: 1190,
        code: '340825',
        title: '太湖县',
        p_code: '340800'
      },
      {
        id: 1191,
        code: '340826',
        title: '宿松县',
        p_code: '340800'
      },
      {
        id: 1192,
        code: '340827',
        title: '望江县',
        p_code: '340800'
      },
      {
        id: 1193,
        code: '340828',
        title: '岳西县',
        p_code: '340800'
      },
      {
        id: 1194,
        code: '340881',
        title: '桐城市',
        p_code: '340800'
      },
      {
        id: 1195,
        code: '341000',
        title: '黄山市',
        p_code: '340000'
      },
      {
        id: 1196,
        code: '341001',
        title: '黄山区',
        p_code: '341000'
      },
      {
        id: 1197,
        code: '341002',
        title: '屯溪区',
        p_code: '341000'
      },
      {
        id: 1198,
        code: '341004',
        title: '徽州区',
        p_code: '341000'
      },
      {
        id: 1199,
        code: '341021',
        title: '歙县',
        p_code: '341000'
      },
      {
        id: 1200,
        code: '341022',
        title: '休宁县',
        p_code: '341000'
      },
      {
        id: 1201,
        code: '341023',
        title: '黟县',
        p_code: '341000'
      },
      {
        id: 1202,
        code: '341024',
        title: '祁门县',
        p_code: '341000'
      },
      {
        id: 1203,
        code: '341091',
        title: '汤口镇',
        p_code: '341000'
      },
      {
        id: 1204,
        code: '341100',
        title: '滁州市',
        p_code: '340000'
      },
      {
        id: 1205,
        code: '341101',
        title: '市辖区',
        p_code: '341100'
      },
      {
        id: 1206,
        code: '341102',
        title: '琅琊区',
        p_code: '341101'
      },
      {
        id: 1207,
        code: '341103',
        title: '南谯区',
        p_code: '341101'
      },
      {
        id: 1208,
        code: '341122',
        title: '来安县',
        p_code: '341100'
      },
      {
        id: 1209,
        code: '341124',
        title: '全椒县',
        p_code: '341100'
      },
      {
        id: 1210,
        code: '341125',
        title: '定远县',
        p_code: '341100'
      },
      {
        id: 1211,
        code: '341126',
        title: '凤阳县',
        p_code: '341100'
      },
      {
        id: 1212,
        code: '341181',
        title: '天长市',
        p_code: '341100'
      },
      {
        id: 1213,
        code: '341182',
        title: '明光市',
        p_code: '341100'
      },
      {
        id: 1214,
        code: '341200',
        title: '阜阳市',
        p_code: '340000'
      },
      {
        id: 1215,
        code: '341201',
        title: '颍泉区',
        p_code: '341200'
      },
      {
        id: 1216,
        code: '341202',
        title: '颍州区',
        p_code: '341200'
      },
      {
        id: 1217,
        code: '341203',
        title: '颍东区',
        p_code: '341200'
      },
      {
        id: 1218,
        code: '341221',
        title: '临泉县',
        p_code: '341200'
      },
      {
        id: 1219,
        code: '341222',
        title: '太和县',
        p_code: '341200'
      },
      {
        id: 1220,
        code: '341225',
        title: '阜南县',
        p_code: '341200'
      },
      {
        id: 1221,
        code: '341226',
        title: '颍上县',
        p_code: '341200'
      },
      {
        id: 1222,
        code: '341282',
        title: '界首市',
        p_code: '341200'
      },
      {
        id: 1223,
        code: '341300',
        title: '宿州市',
        p_code: '340000'
      },
      {
        id: 1224,
        code: '341301',
        title: '市辖区',
        p_code: '341300'
      },
      {
        id: 1225,
        code: '341302',
        title: '埇桥区',
        p_code: '341301'
      },
      {
        id: 1226,
        code: '341321',
        title: '砀山县',
        p_code: '341300'
      },
      {
        id: 1227,
        code: '341322',
        title: '萧县',
        p_code: '341300'
      },
      {
        id: 1228,
        code: '341323',
        title: '灵璧县',
        p_code: '341300'
      },
      {
        id: 1229,
        code: '341324',
        title: '泗县',
        p_code: '341300'
      },
      {
        id: 1230,
        code: '341402',
        title: '居巢区',
        p_code: null
      },
      {
        id: 1231,
        code: '341500',
        title: '六安市',
        p_code: '340000'
      },
      {
        id: 1232,
        code: '341501',
        title: '市辖区',
        p_code: '341500'
      },
      {
        id: 1233,
        code: '341502',
        title: '金安区',
        p_code: '341501'
      },
      {
        id: 1234,
        code: '341503',
        title: '裕安区',
        p_code: '341501'
      },
      {
        id: 1235,
        code: '341521',
        title: '寿县',
        p_code: '341500'
      },
      {
        id: 1236,
        code: '341522',
        title: '霍邱县',
        p_code: '341500'
      },
      {
        id: 1237,
        code: '341523',
        title: '舒城县',
        p_code: '341500'
      },
      {
        id: 1238,
        code: '341524',
        title: '金寨县',
        p_code: '341500'
      },
      {
        id: 1239,
        code: '341525',
        title: '霍山县',
        p_code: '341500'
      },
      {
        id: 1240,
        code: '341600',
        title: '亳州市',
        p_code: '340000'
      },
      {
        id: 1241,
        code: '341601',
        title: '谯城区',
        p_code: '341600'
      },
      {
        id: 1242,
        code: '341621',
        title: '涡阳县',
        p_code: '341600'
      },
      {
        id: 1243,
        code: '341622',
        title: '蒙城县',
        p_code: '341600'
      },
      {
        id: 1244,
        code: '341623',
        title: '利辛县',
        p_code: '341600'
      },
      {
        id: 1245,
        code: '341700',
        title: '池州市',
        p_code: '340000'
      },
      {
        id: 1246,
        code: '341701',
        title: '市辖区',
        p_code: '341700'
      },
      {
        id: 1247,
        code: '341702',
        title: '贵池区',
        p_code: '341701'
      },
      {
        id: 1248,
        code: '341721',
        title: '东至县',
        p_code: '341700'
      },
      {
        id: 1249,
        code: '341722',
        title: '石台县',
        p_code: '341700'
      },
      {
        id: 1250,
        code: '341723',
        title: '青阳县',
        p_code: '341700'
      },
      {
        id: 1251,
        code: '341800',
        title: '宣城市',
        p_code: '340000'
      },
      {
        id: 1252,
        code: '341801',
        title: '市辖区',
        p_code: '341800'
      },
      {
        id: 1253,
        code: '341802',
        title: '宣州区',
        p_code: '341801'
      },
      {
        id: 1254,
        code: '341821',
        title: '郎溪县',
        p_code: '341800'
      },
      {
        id: 1255,
        code: '341822',
        title: '广德县',
        p_code: '341800'
      },
      {
        id: 1256,
        code: '341823',
        title: '泾县',
        p_code: '341800'
      },
      {
        id: 1257,
        code: '341824',
        title: '绩溪县',
        p_code: '341800'
      },
      {
        id: 1258,
        code: '341825',
        title: '旌德县',
        p_code: '341800'
      },
      {
        id: 1259,
        code: '341881',
        title: '宁国市',
        p_code: '341800'
      },

      {
        id: 1261,
        code: '350100',
        title: '福州市',
        p_code: '350000'
      },
      {
        id: 1262,
        code: '350101',
        title: '市辖区',
        p_code: '350100'
      },
      {
        id: 1263,
        code: '350102',
        title: '鼓楼区',
        p_code: '350101'
      },
      {
        id: 1264,
        code: '350103',
        title: '台江区',
        p_code: '350101'
      },
      {
        id: 1265,
        code: '350104',
        title: '仓山区',
        p_code: '350101'
      },
      {
        id: 1266,
        code: '350105',
        title: '马尾区',
        p_code: '350101'
      },
      {
        id: 1267,
        code: '350111',
        title: '晋安区',
        p_code: '350101'
      },
      {
        id: 1268,
        code: '350121',
        title: '闽侯县',
        p_code: '350100'
      },
      {
        id: 1269,
        code: '350122',
        title: '连江县',
        p_code: '350100'
      },
      {
        id: 1270,
        code: '350123',
        title: '罗源县',
        p_code: '350100'
      },
      {
        id: 1271,
        code: '350124',
        title: '闽清县',
        p_code: '350100'
      },
      {
        id: 1272,
        code: '350125',
        title: '永泰县',
        p_code: '350100'
      },
      {
        id: 1273,
        code: '350128',
        title: '平潭县',
        p_code: '350100'
      },
      {
        id: 1274,
        code: '350181',
        title: '福清市',
        p_code: '350100'
      },
      {
        id: 1275,
        code: '350182',
        title: '长乐市',
        p_code: '350100'
      },
      {
        id: 1276,
        code: '350200',
        title: '厦门市',
        p_code: '350000'
      },
      {
        id: 1277,
        code: '350201',
        title: '市辖区',
        p_code: '350200'
      },
      {
        id: 1278,
        code: '350203',
        title: '思明区',
        p_code: '350201'
      },
      {
        id: 1279,
        code: '350205',
        title: '海沧区',
        p_code: '350201'
      },
      {
        id: 1280,
        code: '350206',
        title: '湖里区',
        p_code: '350201'
      },
      {
        id: 1281,
        code: '350211',
        title: '集美区',
        p_code: '350201'
      },
      {
        id: 1282,
        code: '350212',
        title: '同安区',
        p_code: '350201'
      },
      {
        id: 1283,
        code: '350213',
        title: '翔安区',
        p_code: '350201'
      },
      {
        id: 1284,
        code: '350300',
        title: '莆田市',
        p_code: '350000'
      },
      {
        id: 1285,
        code: '350301',
        title: '市辖区',
        p_code: '350300'
      },
      {
        id: 1286,
        code: '350302',
        title: '城厢区',
        p_code: '350301'
      },
      {
        id: 1287,
        code: '350303',
        title: '涵江区',
        p_code: '350301'
      },
      {
        id: 1288,
        code: '350304',
        title: '荔城区',
        p_code: '350301'
      },
      {
        id: 1289,
        code: '350305',
        title: '秀屿区',
        p_code: '350301'
      },
      {
        id: 1290,
        code: '350322',
        title: '仙游县',
        p_code: '350300'
      },
      {
        id: 1291,
        code: '350400',
        title: '三明市',
        p_code: '350000'
      },
      {
        id: 1292,
        code: '350401',
        title: '市辖区',
        p_code: '350400'
      },
      {
        id: 1293,
        code: '350402',
        title: '梅列区',
        p_code: '350401'
      },
      {
        id: 1294,
        code: '350403',
        title: '三元区',
        p_code: '350401'
      },
      {
        id: 1295,
        code: '350421',
        title: '明溪县',
        p_code: '350400'
      },
      {
        id: 1296,
        code: '350423',
        title: '清流县',
        p_code: '350400'
      },
      {
        id: 1297,
        code: '350424',
        title: '宁化县',
        p_code: '350400'
      },
      {
        id: 1298,
        code: '350425',
        title: '大田县',
        p_code: '350400'
      },
      {
        id: 1299,
        code: '350426',
        title: '尤溪县',
        p_code: '350400'
      },
      {
        id: 1300,
        code: '350427',
        title: '沙县',
        p_code: '350400'
      },
      {
        id: 1301,
        code: '350428',
        title: '将乐县',
        p_code: '350400'
      },
      {
        id: 1302,
        code: '350429',
        title: '泰宁县',
        p_code: '350400'
      },
      {
        id: 1303,
        code: '350430',
        title: '建宁县',
        p_code: '350400'
      },
      {
        id: 1304,
        code: '350481',
        title: '永安市',
        p_code: '350400'
      },
      {
        id: 1305,
        code: '350500',
        title: '泉州市',
        p_code: '350000'
      },
      {
        id: 1306,
        code: '350501',
        title: '市辖区',
        p_code: '350500'
      },
      {
        id: 1307,
        code: '350502',
        title: '鲤城区',
        p_code: '350501'
      },
      {
        id: 1308,
        code: '350503',
        title: '丰泽区',
        p_code: '350501'
      },
      {
        id: 1309,
        code: '350504',
        title: '洛江区',
        p_code: '350501'
      },
      {
        id: 1310,
        code: '350505',
        title: '泉港区',
        p_code: '350501'
      },
      {
        id: 1311,
        code: '350521',
        title: '惠安县',
        p_code: '350500'
      },
      {
        id: 1312,
        code: '350524',
        title: '安溪县',
        p_code: '350500'
      },
      {
        id: 1313,
        code: '350525',
        title: '永春县',
        p_code: '350500'
      },
      {
        id: 1314,
        code: '350526',
        title: '德化县',
        p_code: '350500'
      },
      {
        id: 1315,
        code: '350527',
        title: '金门县',
        p_code: '350500'
      },
      {
        id: 1316,
        code: '350581',
        title: '石狮市',
        p_code: '350500'
      },
      {
        id: 1317,
        code: '350582',
        title: '晋江市',
        p_code: '350500'
      },
      {
        id: 1318,
        code: '350583',
        title: '南安市',
        p_code: '350500'
      },
      {
        id: 1319,
        code: '350600',
        title: '漳州市',
        p_code: '350000'
      },
      {
        id: 1320,
        code: '350601',
        title: '市辖区',
        p_code: '350600'
      },
      {
        id: 1321,
        code: '350602',
        title: '芗城区',
        p_code: '350601'
      },
      {
        id: 1322,
        code: '350603',
        title: '龙文区',
        p_code: '350601'
      },
      {
        id: 1323,
        code: '350622',
        title: '云霄县',
        p_code: '350600'
      },
      {
        id: 1324,
        code: '350623',
        title: '漳浦县',
        p_code: '350600'
      },
      {
        id: 1325,
        code: '350624',
        title: '诏安县',
        p_code: '350600'
      },
      {
        id: 1326,
        code: '350625',
        title: '长泰县',
        p_code: '350600'
      },
      {
        id: 1327,
        code: '350626',
        title: '东山县',
        p_code: '350600'
      },
      {
        id: 1328,
        code: '350627',
        title: '南靖县',
        p_code: '350600'
      },
      {
        id: 1329,
        code: '350628',
        title: '平和县',
        p_code: '350600'
      },
      {
        id: 1330,
        code: '350629',
        title: '华安县',
        p_code: '350600'
      },
      {
        id: 1331,
        code: '350681',
        title: '龙海市',
        p_code: '350600'
      },
      {
        id: 1332,
        code: '350700',
        title: '南平市',
        p_code: '350000'
      },
      {
        id: 1333,
        code: '350701',
        title: '市辖区',
        p_code: '350700'
      },
      {
        id: 1334,
        code: '350702',
        title: '延平区',
        p_code: '350701'
      },
      {
        id: 1335,
        code: '350721',
        title: '顺昌县',
        p_code: '350700'
      },
      {
        id: 1336,
        code: '350722',
        title: '浦城县',
        p_code: '350700'
      },
      {
        id: 1337,
        code: '350723',
        title: '光泽县',
        p_code: '350700'
      },
      {
        id: 1338,
        code: '350724',
        title: '松溪县',
        p_code: '350700'
      },
      {
        id: 1339,
        code: '350725',
        title: '政和县',
        p_code: '350700'
      },
      {
        id: 1340,
        code: '350781',
        title: '邵武市',
        p_code: '350700'
      },
      {
        id: 1341,
        code: '350782',
        title: '武夷山市',
        p_code: '350700'
      },
      {
        id: 1342,
        code: '350783',
        title: '建瓯市',
        p_code: '350700'
      },
      {
        id: 1343,
        code: '350784',
        title: '建阳市',
        p_code: '350700'
      },
      {
        id: 1344,
        code: '350800',
        title: '龙岩市',
        p_code: '350000'
      },
      {
        id: 1345,
        code: '350801',
        title: '市辖区',
        p_code: '350800'
      },
      {
        id: 1346,
        code: '350802',
        title: '新罗区',
        p_code: '350801'
      },
      {
        id: 1347,
        code: '350821',
        title: '长汀县',
        p_code: '350800'
      },
      {
        id: 1348,
        code: '350822',
        title: '永定县',
        p_code: '350800'
      },
      {
        id: 1349,
        code: '350823',
        title: '上杭县',
        p_code: '350800'
      },
      {
        id: 1350,
        code: '350824',
        title: '武平县',
        p_code: '350800'
      },
      {
        id: 1351,
        code: '350825',
        title: '连城县',
        p_code: '350800'
      },
      {
        id: 1352,
        code: '350881',
        title: '漳平市',
        p_code: '350800'
      },
      {
        id: 1353,
        code: '350900',
        title: '宁德市',
        p_code: '350000'
      },
      {
        id: 1354,
        code: '350901',
        title: '市辖区',
        p_code: '350900'
      },
      {
        id: 1355,
        code: '350902',
        title: '蕉城区',
        p_code: '350901'
      },
      {
        id: 1356,
        code: '350921',
        title: '霞浦县',
        p_code: '350900'
      },
      {
        id: 1357,
        code: '350922',
        title: '古田县',
        p_code: '350900'
      },
      {
        id: 1358,
        code: '350923',
        title: '屏南县',
        p_code: '350900'
      },
      {
        id: 1359,
        code: '350924',
        title: '寿宁县',
        p_code: '350900'
      },
      {
        id: 1360,
        code: '350925',
        title: '周宁县',
        p_code: '350900'
      },
      {
        id: 1361,
        code: '350926',
        title: '柘荣县',
        p_code: '350900'
      },
      {
        id: 1362,
        code: '350981',
        title: '福安市',
        p_code: '350900'
      },
      {
        id: 1363,
        code: '350982',
        title: '福鼎市',
        p_code: '350900'
      },

      {
        id: 1365,
        code: '360100',
        title: '南昌市',
        p_code: '360000'
      },
      {
        id: 1366,
        code: '360101',
        title: '市辖区',
        p_code: '360100'
      },
      {
        id: 1367,
        code: '360102',
        title: '东湖区',
        p_code: '360101'
      },
      {
        id: 1368,
        code: '360103',
        title: '西湖区',
        p_code: '360101'
      },
      {
        id: 1369,
        code: '360104',
        title: '青云谱区',
        p_code: '360101'
      },
      {
        id: 1370,
        code: '360105',
        title: '湾里区',
        p_code: '360101'
      },
      {
        id: 1371,
        code: '360111',
        title: '青山湖区',
        p_code: '360101'
      },
      {
        id: 1372,
        code: '360121',
        title: '南昌县',
        p_code: '360100'
      },
      {
        id: 1373,
        code: '360122',
        title: '新建县',
        p_code: '360100'
      },
      {
        id: 1374,
        code: '360123',
        title: '安义县',
        p_code: '360100'
      },
      {
        id: 1375,
        code: '360124',
        title: '进贤县',
        p_code: '360100'
      },
      {
        id: 1376,
        code: '360200',
        title: '景德镇市',
        p_code: '360000'
      },
      {
        id: 1377,
        code: '360201',
        title: '市辖区',
        p_code: '360200'
      },
      {
        id: 1378,
        code: '360202',
        title: '昌江区',
        p_code: '360201'
      },
      {
        id: 1379,
        code: '360203',
        title: '珠山区',
        p_code: '360201'
      },
      {
        id: 1380,
        code: '360222',
        title: '浮梁县',
        p_code: '360200'
      },
      {
        id: 1381,
        code: '360281',
        title: '乐平市',
        p_code: '360200'
      },
      {
        id: 1382,
        code: '360300',
        title: '萍乡市',
        p_code: '360000'
      },
      {
        id: 1383,
        code: '360301',
        title: '市辖区',
        p_code: '360300'
      },
      {
        id: 1384,
        code: '360302',
        title: '安源区',
        p_code: '360301'
      },
      {
        id: 1385,
        code: '360313',
        title: '湘东区',
        p_code: '360301'
      },
      {
        id: 1386,
        code: '360321',
        title: '莲花县',
        p_code: '360300'
      },
      {
        id: 1387,
        code: '360322',
        title: '上栗县',
        p_code: '360300'
      },
      {
        id: 1388,
        code: '360323',
        title: '芦溪县',
        p_code: '360300'
      },
      {
        id: 1389,
        code: '360400',
        title: '九江市',
        p_code: '360000'
      },
      {
        id: 1390,
        code: '360401',
        title: '市辖区',
        p_code: '360400'
      },
      {
        id: 1391,
        code: '360402',
        title: '庐山区',
        p_code: '360401'
      },
      {
        id: 1392,
        code: '360403',
        title: '浔阳区',
        p_code: '360401'
      },
      {
        id: 1393,
        code: '360421',
        title: '九江县',
        p_code: '360400'
      },
      {
        id: 1394,
        code: '360423',
        title: '武宁县',
        p_code: '360400'
      },
      {
        id: 1395,
        code: '360424',
        title: '修水县',
        p_code: '360400'
      },
      {
        id: 1396,
        code: '360425',
        title: '永修县',
        p_code: '360400'
      },
      {
        id: 1397,
        code: '360426',
        title: '德安县',
        p_code: '360400'
      },
      {
        id: 1398,
        code: '360427',
        title: '星子县',
        p_code: '360400'
      },
      {
        id: 1399,
        code: '360428',
        title: '都昌县',
        p_code: '360400'
      },
      {
        id: 1400,
        code: '360429',
        title: '湖口县',
        p_code: '360400'
      },
      {
        id: 1401,
        code: '360430',
        title: '彭泽县',
        p_code: '360400'
      },
      {
        id: 1402,
        code: '360481',
        title: '瑞昌市',
        p_code: '360400'
      },
      {
        id: 1403,
        code: '360482',
        title: '共青城市',
        p_code: '360400'
      },
      {
        id: 1404,
        code: '360500',
        title: '新余市',
        p_code: '360000'
      },
      {
        id: 1405,
        code: '360501',
        title: '市辖区',
        p_code: '360500'
      },
      {
        id: 1406,
        code: '360502',
        title: '渝水区',
        p_code: '360501'
      },
      {
        id: 1407,
        code: '360521',
        title: '分宜县',
        p_code: '360500'
      },
      {
        id: 1408,
        code: '360600',
        title: '鹰潭市',
        p_code: '360000'
      },
      {
        id: 1409,
        code: '360601',
        title: '市辖区',
        p_code: '360600'
      },
      {
        id: 1410,
        code: '360602',
        title: '月湖区',
        p_code: '360601'
      },
      {
        id: 1411,
        code: '360622',
        title: '余江县',
        p_code: '360600'
      },
      {
        id: 1412,
        code: '360681',
        title: '贵溪市',
        p_code: '360600'
      },
      {
        id: 1413,
        code: '360700',
        title: '赣州市',
        p_code: '360000'
      },
      {
        id: 1414,
        code: '360701',
        title: '市辖区',
        p_code: '360700'
      },
      {
        id: 1415,
        code: '360702',
        title: '章贡区',
        p_code: '360701'
      },
      {
        id: 1416,
        code: '360721',
        title: '赣县',
        p_code: '360700'
      },
      {
        id: 1417,
        code: '360722',
        title: '信丰县',
        p_code: '360700'
      },
      {
        id: 1418,
        code: '360723',
        title: '大余县',
        p_code: '360700'
      },
      {
        id: 1419,
        code: '360724',
        title: '上犹县',
        p_code: '360700'
      },
      {
        id: 1420,
        code: '360725',
        title: '崇义县',
        p_code: '360700'
      },
      {
        id: 1421,
        code: '360726',
        title: '安远县',
        p_code: '360700'
      },
      {
        id: 1422,
        code: '360727',
        title: '龙南县',
        p_code: '360700'
      },
      {
        id: 1423,
        code: '360728',
        title: '定南县',
        p_code: '360700'
      },
      {
        id: 1424,
        code: '360729',
        title: '全南县',
        p_code: '360700'
      },
      {
        id: 1425,
        code: '360730',
        title: '宁都县',
        p_code: '360700'
      },
      {
        id: 1426,
        code: '360731',
        title: '于都县',
        p_code: '360700'
      },
      {
        id: 1427,
        code: '360732',
        title: '兴国县',
        p_code: '360700'
      },
      {
        id: 1428,
        code: '360733',
        title: '会昌县',
        p_code: '360700'
      },
      {
        id: 1429,
        code: '360734',
        title: '寻乌县',
        p_code: '360700'
      },
      {
        id: 1430,
        code: '360735',
        title: '石城县',
        p_code: '360700'
      },
      {
        id: 1431,
        code: '360781',
        title: '瑞金市',
        p_code: '360700'
      },
      {
        id: 1432,
        code: '360782',
        title: '南康市',
        p_code: '360700'
      },
      {
        id: 1433,
        code: '360800',
        title: '吉安市',
        p_code: '360000'
      },
      {
        id: 1434,
        code: '360801',
        title: '市辖区',
        p_code: '360800'
      },
      {
        id: 1435,
        code: '360802',
        title: '吉州区',
        p_code: '360801'
      },
      {
        id: 1436,
        code: '360803',
        title: '青原区',
        p_code: '360801'
      },
      {
        id: 1437,
        code: '360821',
        title: '吉安县',
        p_code: '360800'
      },
      {
        id: 1438,
        code: '360822',
        title: '吉水县',
        p_code: '360800'
      },
      {
        id: 1439,
        code: '360823',
        title: '峡江县',
        p_code: '360800'
      },
      {
        id: 1440,
        code: '360824',
        title: '新干县',
        p_code: '360800'
      },
      {
        id: 1441,
        code: '360825',
        title: '永丰县',
        p_code: '360800'
      },
      {
        id: 1442,
        code: '360826',
        title: '泰和县',
        p_code: '360800'
      },
      {
        id: 1443,
        code: '360827',
        title: '遂川县',
        p_code: '360800'
      },
      {
        id: 1444,
        code: '360828',
        title: '万安县',
        p_code: '360800'
      },
      {
        id: 1445,
        code: '360829',
        title: '安福县',
        p_code: '360800'
      },
      {
        id: 1446,
        code: '360830',
        title: '永新县',
        p_code: '360800'
      },
      {
        id: 1447,
        code: '360881',
        title: '井冈山市',
        p_code: '360800'
      },
      {
        id: 1448,
        code: '360900',
        title: '宜春市',
        p_code: '360000'
      },
      {
        id: 1449,
        code: '360901',
        title: '市辖区',
        p_code: '360900'
      },
      {
        id: 1450,
        code: '360902',
        title: '袁州区',
        p_code: '360901'
      },
      {
        id: 1451,
        code: '360921',
        title: '奉新县',
        p_code: '360900'
      },
      {
        id: 1452,
        code: '360922',
        title: '万载县',
        p_code: '360900'
      },
      {
        id: 1453,
        code: '360923',
        title: '上高县',
        p_code: '360900'
      },
      {
        id: 1454,
        code: '360924',
        title: '宜丰县',
        p_code: '360900'
      },
      {
        id: 1455,
        code: '360925',
        title: '靖安县',
        p_code: '360900'
      },
      {
        id: 1456,
        code: '360926',
        title: '铜鼓县',
        p_code: '360900'
      },
      {
        id: 1457,
        code: '360981',
        title: '丰城市',
        p_code: '360900'
      },
      {
        id: 1458,
        code: '360982',
        title: '樟树市',
        p_code: '360900'
      },
      {
        id: 1459,
        code: '360983',
        title: '高安市',
        p_code: '360900'
      },
      {
        id: 1460,
        code: '361000',
        title: '抚州市',
        p_code: '360000'
      },
      {
        id: 1461,
        code: '361001',
        title: '市辖区',
        p_code: '361000'
      },
      {
        id: 1462,
        code: '361002',
        title: '临川区',
        p_code: '361001'
      },
      {
        id: 1463,
        code: '361021',
        title: '南城县',
        p_code: '361000'
      },
      {
        id: 1464,
        code: '361022',
        title: '黎川县',
        p_code: '361000'
      },
      {
        id: 1465,
        code: '361023',
        title: '南丰县',
        p_code: '361000'
      },
      {
        id: 1466,
        code: '361024',
        title: '崇仁县',
        p_code: '361000'
      },
      {
        id: 1467,
        code: '361025',
        title: '乐安县',
        p_code: '361000'
      },
      {
        id: 1468,
        code: '361026',
        title: '宜黄县',
        p_code: '361000'
      },
      {
        id: 1469,
        code: '361027',
        title: '金溪县',
        p_code: '361000'
      },
      {
        id: 1470,
        code: '361028',
        title: '资溪县',
        p_code: '361000'
      },
      {
        id: 1471,
        code: '361029',
        title: '东乡县',
        p_code: '361000'
      },
      {
        id: 1472,
        code: '361030',
        title: '广昌县',
        p_code: '361000'
      },
      {
        id: 1473,
        code: '361100',
        title: '上饶市',
        p_code: '360000'
      },
      {
        id: 1474,
        code: '361101',
        title: '市辖区',
        p_code: '361100'
      },
      {
        id: 1475,
        code: '361102',
        title: '信州区',
        p_code: '361101'
      },
      {
        id: 1476,
        code: '361121',
        title: '上饶县',
        p_code: '361100'
      },
      {
        id: 1477,
        code: '361122',
        title: '广丰县',
        p_code: '361100'
      },
      {
        id: 1478,
        code: '361123',
        title: '玉山县',
        p_code: '361100'
      },
      {
        id: 1479,
        code: '361124',
        title: '铅山县',
        p_code: '361100'
      },
      {
        id: 1480,
        code: '361125',
        title: '横峰县',
        p_code: '361100'
      },
      {
        id: 1481,
        code: '361126',
        title: '弋阳县',
        p_code: '361100'
      },
      {
        id: 1482,
        code: '361127',
        title: '余干县',
        p_code: '361100'
      },
      {
        id: 1483,
        code: '361128',
        title: '鄱阳县',
        p_code: '361100'
      },
      {
        id: 1484,
        code: '361129',
        title: '万年县',
        p_code: '361100'
      },
      {
        id: 1485,
        code: '361130',
        title: '婺源县',
        p_code: '361100'
      },
      {
        id: 1486,
        code: '361181',
        title: '德兴市',
        p_code: '361100'
      },

      {
        id: 1488,
        code: '370100',
        title: '济南市',
        p_code: '370000'
      },
      {
        id: 1489,
        code: '370101',
        title: '市辖区',
        p_code: '370100'
      },
      {
        id: 1490,
        code: '370102',
        title: '历下区',
        p_code: '370101'
      },
      {
        id: 1491,
        code: '370103',
        title: '市中区',
        p_code: '370101'
      },
      {
        id: 1492,
        code: '370104',
        title: '槐荫区',
        p_code: '370101'
      },
      {
        id: 1493,
        code: '370105',
        title: '天桥区',
        p_code: '370101'
      },
      {
        id: 1494,
        code: '370112',
        title: '历城区',
        p_code: '370101'
      },
      {
        id: 1495,
        code: '370113',
        title: '长清区',
        p_code: '370101'
      },
      {
        id: 1496,
        code: '370124',
        title: '平阴县',
        p_code: '370100'
      },
      {
        id: 1497,
        code: '370125',
        title: '济阳县',
        p_code: '370100'
      },
      {
        id: 1498,
        code: '370126',
        title: '商河县',
        p_code: '370100'
      },
      {
        id: 1499,
        code: '370181',
        title: '章丘市',
        p_code: '370100'
      },
      {
        id: 1500,
        code: '370200',
        title: '青岛市',
        p_code: '370000'
      },
      {
        id: 1501,
        code: '370201',
        title: '市辖区',
        p_code: '370200'
      },
      {
        id: 1502,
        code: '370202',
        title: '市南区',
        p_code: '370201'
      },
      {
        id: 1503,
        code: '370203',
        title: '市北区',
        p_code: '370201'
      },
      {
        id: 1504,
        code: '370205',
        title: '四方区',
        p_code: '370201'
      },
      {
        id: 1505,
        code: '370211',
        title: '黄岛区',
        p_code: '370201'
      },
      {
        id: 1506,
        code: '370212',
        title: '崂山区',
        p_code: '370201'
      },
      {
        id: 1507,
        code: '370213',
        title: '李沧区',
        p_code: '370201'
      },
      {
        id: 1508,
        code: '370214',
        title: '城阳区',
        p_code: '370201'
      },
      {
        id: 1509,
        code: '370281',
        title: '胶州市',
        p_code: '370200'
      },
      {
        id: 1510,
        code: '370282',
        title: '即墨市',
        p_code: '370200'
      },
      {
        id: 1511,
        code: '370283',
        title: '平度市',
        p_code: '370200'
      },
      {
        id: 1512,
        code: '370284',
        title: '胶南市',
        p_code: '370200'
      },
      {
        id: 1513,
        code: '370285',
        title: '莱西市',
        p_code: '370200'
      },
      {
        id: 1514,
        code: '370300',
        title: '淄博市',
        p_code: '370000'
      },
      {
        id: 1515,
        code: '370301',
        title: '市辖区',
        p_code: '370300'
      },
      {
        id: 1516,
        code: '370302',
        title: '淄川区',
        p_code: '370301'
      },
      {
        id: 1517,
        code: '370303',
        title: '张店区',
        p_code: '370301'
      },
      {
        id: 1518,
        code: '370304',
        title: '博山区',
        p_code: '370301'
      },
      {
        id: 1519,
        code: '370305',
        title: '临淄区',
        p_code: '370301'
      },
      {
        id: 1520,
        code: '370306',
        title: '周村区',
        p_code: '370301'
      },
      {
        id: 1521,
        code: '370321',
        title: '桓台县',
        p_code: '370300'
      },
      {
        id: 1522,
        code: '370322',
        title: '高青县',
        p_code: '370300'
      },
      {
        id: 1523,
        code: '370323',
        title: '沂源县',
        p_code: '370300'
      },
      {
        id: 1524,
        code: '370400',
        title: '枣庄市',
        p_code: '370000'
      },
      {
        id: 1525,
        code: '370401',
        title: '市辖区',
        p_code: '370400'
      },
      {
        id: 1526,
        code: '370402',
        title: '市中区',
        p_code: '370401'
      },
      {
        id: 1527,
        code: '370403',
        title: '薛城区',
        p_code: '370401'
      },
      {
        id: 1528,
        code: '370404',
        title: '峄城区',
        p_code: '370401'
      },
      {
        id: 1529,
        code: '370405',
        title: '台儿庄区',
        p_code: '370401'
      },
      {
        id: 1530,
        code: '370406',
        title: '山亭区',
        p_code: '370401'
      },
      {
        id: 1531,
        code: '370481',
        title: '滕州市',
        p_code: '370400'
      },
      {
        id: 1532,
        code: '370500',
        title: '东营市',
        p_code: '370000'
      },
      {
        id: 1533,
        code: '370501',
        title: '市辖区',
        p_code: '370500'
      },
      {
        id: 1534,
        code: '370502',
        title: '东营区',
        p_code: '370501'
      },
      {
        id: 1535,
        code: '370503',
        title: '河口区',
        p_code: '370501'
      },
      {
        id: 1536,
        code: '370521',
        title: '垦利县',
        p_code: '370500'
      },
      {
        id: 1537,
        code: '370522',
        title: '利津县',
        p_code: '370500'
      },
      {
        id: 1538,
        code: '370523',
        title: '广饶县',
        p_code: '370500'
      },
      {
        id: 1539,
        code: '370600',
        title: '烟台市',
        p_code: '370000'
      },
      {
        id: 1540,
        code: '370601',
        title: '市辖区',
        p_code: '370600'
      },
      {
        id: 1541,
        code: '370602',
        title: '芝罘区',
        p_code: '370601'
      },
      {
        id: 1542,
        code: '370611',
        title: '福山区',
        p_code: '370601'
      },
      {
        id: 1543,
        code: '370612',
        title: '牟平区',
        p_code: '370601'
      },
      {
        id: 1544,
        code: '370613',
        title: '莱山区',
        p_code: '370601'
      },
      {
        id: 1545,
        code: '370634',
        title: '长岛县',
        p_code: '370600'
      },
      {
        id: 1546,
        code: '370681',
        title: '龙口市',
        p_code: '370600'
      },
      {
        id: 1547,
        code: '370682',
        title: '莱阳市',
        p_code: '370600'
      },
      {
        id: 1548,
        code: '370683',
        title: '莱州市',
        p_code: '370600'
      },
      {
        id: 1549,
        code: '370684',
        title: '蓬莱市',
        p_code: '370600'
      },
      {
        id: 1550,
        code: '370685',
        title: '招远市',
        p_code: '370600'
      },
      {
        id: 1551,
        code: '370686',
        title: '栖霞市',
        p_code: '370600'
      },
      {
        id: 1552,
        code: '370687',
        title: '海阳市',
        p_code: '370600'
      },
      {
        id: 1553,
        code: '370700',
        title: '潍坊市',
        p_code: '370000'
      },
      {
        id: 1554,
        code: '370701',
        title: '市辖区',
        p_code: '370700'
      },
      {
        id: 1555,
        code: '370702',
        title: '潍城区',
        p_code: '370701'
      },
      {
        id: 1556,
        code: '370703',
        title: '寒亭区',
        p_code: '370701'
      },
      {
        id: 1557,
        code: '370704',
        title: '坊子区',
        p_code: '370701'
      },
      {
        id: 1558,
        code: '370705',
        title: '奎文区',
        p_code: '370701'
      },
      {
        id: 1559,
        code: '370724',
        title: '临朐县',
        p_code: '370700'
      },
      {
        id: 1560,
        code: '370725',
        title: '昌乐县',
        p_code: '370700'
      },
      {
        id: 1561,
        code: '370781',
        title: '青州市',
        p_code: '370700'
      },
      {
        id: 1562,
        code: '370782',
        title: '诸城市',
        p_code: '370700'
      },
      {
        id: 1563,
        code: '370783',
        title: '寿光市',
        p_code: '370700'
      },
      {
        id: 1564,
        code: '370784',
        title: '安丘市',
        p_code: '370700'
      },
      {
        id: 1565,
        code: '370785',
        title: '高密市',
        p_code: '370700'
      },
      {
        id: 1566,
        code: '370786',
        title: '昌邑市',
        p_code: '370700'
      },
      {
        id: 1567,
        code: '370800',
        title: '济宁市',
        p_code: '370000'
      },
      {
        id: 1568,
        code: '370801',
        title: '市辖区',
        p_code: '370800'
      },
      {
        id: 1569,
        code: '370802',
        title: '市中区',
        p_code: '370801'
      },
      {
        id: 1570,
        code: '370811',
        title: '任城区',
        p_code: '370801'
      },
      {
        id: 1571,
        code: '370826',
        title: '微山县',
        p_code: '370800'
      },
      {
        id: 1572,
        code: '370827',
        title: '鱼台县',
        p_code: '370800'
      },
      {
        id: 1573,
        code: '370828',
        title: '金乡县',
        p_code: '370800'
      },
      {
        id: 1574,
        code: '370829',
        title: '嘉祥县',
        p_code: '370800'
      },
      {
        id: 1575,
        code: '370830',
        title: '汶上县',
        p_code: '370800'
      },
      {
        id: 1576,
        code: '370831',
        title: '泗水县',
        p_code: '370800'
      },
      {
        id: 1577,
        code: '370832',
        title: '梁山县',
        p_code: '370800'
      },
      {
        id: 1578,
        code: '370881',
        title: '曲阜市',
        p_code: '370800'
      },
      {
        id: 1579,
        code: '370882',
        title: '兖州市',
        p_code: '370800'
      },
      {
        id: 1580,
        code: '370883',
        title: '邹城市',
        p_code: '370800'
      },
      {
        id: 1581,
        code: '370900',
        title: '泰安市',
        p_code: '370000'
      },
      {
        id: 1582,
        code: '370901',
        title: '岱岳区',
        p_code: '370900'
      },
      {
        id: 1583,
        code: '370902',
        title: '泰山区',
        p_code: '370900'
      },
      {
        id: 1584,
        code: '370921',
        title: '宁阳县',
        p_code: '370900'
      },
      {
        id: 1585,
        code: '370923',
        title: '东平县',
        p_code: '370900'
      },
      {
        id: 1586,
        code: '370982',
        title: '新泰市',
        p_code: '370900'
      },
      {
        id: 1587,
        code: '370983',
        title: '肥城市',
        p_code: '370900'
      },
      {
        id: 1588,
        code: '371000',
        title: '威海市',
        p_code: '370000'
      },
      {
        id: 1589,
        code: '371001',
        title: '市辖区',
        p_code: '371000'
      },
      {
        id: 1590,
        code: '371002',
        title: '环翠区',
        p_code: '371001'
      },
      {
        id: 1591,
        code: '371081',
        title: '文登市',
        p_code: '371000'
      },
      {
        id: 1592,
        code: '371082',
        title: '荣成市',
        p_code: '371000'
      },
      {
        id: 1593,
        code: '371083',
        title: '乳山市',
        p_code: '371000'
      },
      {
        id: 1594,
        code: '371100',
        title: '日照市',
        p_code: '370000'
      },
      {
        id: 1595,
        code: '371101',
        title: '市辖区',
        p_code: '371100'
      },
      {
        id: 1596,
        code: '371102',
        title: '东港区',
        p_code: '371101'
      },
      {
        id: 1597,
        code: '371103',
        title: '岚山区',
        p_code: '371101'
      },
      {
        id: 1598,
        code: '371121',
        title: '五莲县',
        p_code: '371100'
      },
      {
        id: 1599,
        code: '371122',
        title: '莒县',
        p_code: '371100'
      },
      {
        id: 1600,
        code: '371200',
        title: '莱芜市',
        p_code: '370000'
      },
      {
        id: 1601,
        code: '371201',
        title: '市辖区',
        p_code: '371200'
      },
      {
        id: 1602,
        code: '371202',
        title: '莱城区',
        p_code: '371201'
      },
      {
        id: 1603,
        code: '371203',
        title: '钢城区',
        p_code: '371201'
      },
      {
        id: 1604,
        code: '371300',
        title: '临沂市',
        p_code: '370000'
      },
      {
        id: 1605,
        code: '371301',
        title: '市辖区',
        p_code: '371300'
      },
      {
        id: 1606,
        code: '371302',
        title: '兰山区',
        p_code: '371301'
      },
      {
        id: 1607,
        code: '371311',
        title: '罗庄区',
        p_code: '371301'
      },
      {
        id: 1608,
        code: '371312',
        title: '河东区',
        p_code: '371301'
      },
      {
        id: 1609,
        code: '371321',
        title: '沂南县',
        p_code: '371300'
      },
      {
        id: 1610,
        code: '371322',
        title: '郯城县',
        p_code: '371300'
      },
      {
        id: 1611,
        code: '371323',
        title: '沂水县',
        p_code: '371300'
      },
      {
        id: 1612,
        code: '371324',
        title: '苍山县',
        p_code: '371300'
      },
      {
        id: 1613,
        code: '371325',
        title: '费县',
        p_code: '371300'
      },
      {
        id: 1614,
        code: '371326',
        title: '平邑县',
        p_code: '371300'
      },
      {
        id: 1615,
        code: '371327',
        title: '莒南县',
        p_code: '371300'
      },
      {
        id: 1616,
        code: '371328',
        title: '蒙阴县',
        p_code: '371300'
      },
      {
        id: 1617,
        code: '371329',
        title: '临沭县',
        p_code: '371300'
      },
      {
        id: 1618,
        code: '371400',
        title: '德州市',
        p_code: '370000'
      },
      {
        id: 1619,
        code: '371401',
        title: '市辖区',
        p_code: '371400'
      },
      {
        id: 1620,
        code: '371402',
        title: '德城区',
        p_code: '371401'
      },
      {
        id: 1621,
        code: '371421',
        title: '陵县',
        p_code: '371400'
      },
      {
        id: 1622,
        code: '371422',
        title: '宁津县',
        p_code: '371400'
      },
      {
        id: 1623,
        code: '371423',
        title: '庆云县',
        p_code: '371400'
      },
      {
        id: 1624,
        code: '371424',
        title: '临邑县',
        p_code: '371400'
      },
      {
        id: 1625,
        code: '371425',
        title: '齐河县',
        p_code: '371400'
      },
      {
        id: 1626,
        code: '371426',
        title: '平原县',
        p_code: '371400'
      },
      {
        id: 1627,
        code: '371427',
        title: '夏津县',
        p_code: '371400'
      },
      {
        id: 1628,
        code: '371428',
        title: '武城县',
        p_code: '371400'
      },
      {
        id: 1629,
        code: '371481',
        title: '乐陵市',
        p_code: '371400'
      },
      {
        id: 1630,
        code: '371482',
        title: '禹城市',
        p_code: '371400'
      },
      {
        id: 1631,
        code: '371500',
        title: '聊城市',
        p_code: '370000'
      },
      {
        id: 1632,
        code: '371501',
        title: '市辖区',
        p_code: '371500'
      },
      {
        id: 1633,
        code: '371502',
        title: '东昌府区',
        p_code: '371501'
      },
      {
        id: 1634,
        code: '371521',
        title: '阳谷县',
        p_code: '371500'
      },
      {
        id: 1635,
        code: '371522',
        title: '莘县',
        p_code: '371500'
      },
      {
        id: 1636,
        code: '371523',
        title: '茌平县',
        p_code: '371500'
      },
      {
        id: 1637,
        code: '371524',
        title: '东阿县',
        p_code: '371500'
      },
      {
        id: 1638,
        code: '371525',
        title: '冠县',
        p_code: '371500'
      },
      {
        id: 1639,
        code: '371526',
        title: '高唐县',
        p_code: '371500'
      },
      {
        id: 1640,
        code: '371581',
        title: '临清市',
        p_code: '371500'
      },
      {
        id: 1641,
        code: '371600',
        title: '滨州市',
        p_code: '370000'
      },
      {
        id: 1642,
        code: '371601',
        title: '市辖区',
        p_code: '371600'
      },
      {
        id: 1643,
        code: '371602',
        title: '滨城区',
        p_code: '371601'
      },
      {
        id: 1644,
        code: '371621',
        title: '惠民县',
        p_code: '371600'
      },
      {
        id: 1645,
        code: '371622',
        title: '阳信县',
        p_code: '371600'
      },
      {
        id: 1646,
        code: '371623',
        title: '无棣县',
        p_code: '371600'
      },
      {
        id: 1647,
        code: '371624',
        title: '沾化县',
        p_code: '371600'
      },
      {
        id: 1648,
        code: '371625',
        title: '博兴县',
        p_code: '371600'
      },
      {
        id: 1649,
        code: '371626',
        title: '邹平县',
        p_code: '371600'
      },
      {
        id: 1650,
        code: '371700',
        title: '菏泽市',
        p_code: '370000'
      },
      {
        id: 1651,
        code: '371701',
        title: '市辖区',
        p_code: '371700'
      },
      {
        id: 1652,
        code: '371702',
        title: '牡丹区',
        p_code: '371701'
      },
      {
        id: 1653,
        code: '371721',
        title: '曹县',
        p_code: '371700'
      },
      {
        id: 1654,
        code: '371722',
        title: '单县',
        p_code: '371700'
      },
      {
        id: 1655,
        code: '371723',
        title: '成武县',
        p_code: '371700'
      },
      {
        id: 1656,
        code: '371724',
        title: '巨野县',
        p_code: '371700'
      },
      {
        id: 1657,
        code: '371725',
        title: '郓城县',
        p_code: '371700'
      },
      {
        id: 1658,
        code: '371726',
        title: '鄄城县',
        p_code: '371700'
      },
      {
        id: 1659,
        code: '371727',
        title: '定陶县',
        p_code: '371700'
      },
      {
        id: 1660,
        code: '371728',
        title: '东明县',
        p_code: '371700'
      },

      {
        id: 1662,
        code: '410100',
        title: '郑州市',
        p_code: '410000'
      },
      {
        id: 1663,
        code: '410101',
        title: '金水区',
        p_code: '410100'
      },
      {
        id: 1664,
        code: '410102',
        title: '中原区',
        p_code: '410100'
      },
      {
        id: 1665,
        code: '410103',
        title: '二七区',
        p_code: '410100'
      },
      {
        id: 1666,
        code: '410104',
        title: '管城回族区',
        p_code: '410100'
      },
      {
        id: 1667,
        code: '410106',
        title: '上街区',
        p_code: '410100'
      },
      {
        id: 1668,
        code: '410108',
        title: '惠济区',
        p_code: '410100'
      },
      {
        id: 1669,
        code: '410122',
        title: '中牟县',
        p_code: '410100'
      },
      {
        id: 1670,
        code: '410181',
        title: '巩义市',
        p_code: '410100'
      },
      {
        id: 1671,
        code: '410182',
        title: '荥阳市',
        p_code: '410100'
      },
      {
        id: 1672,
        code: '410183',
        title: '新密市',
        p_code: '410100'
      },
      {
        id: 1673,
        code: '410184',
        title: '新郑市',
        p_code: '410100'
      },
      {
        id: 1674,
        code: '410185',
        title: '登封市',
        p_code: '410100'
      },
      {
        id: 1675,
        code: '410200',
        title: '开封市',
        p_code: '410000'
      },
      {
        id: 1676,
        code: '410201',
        title: '市辖区',
        p_code: '410200'
      },
      {
        id: 1677,
        code: '410202',
        title: '龙亭区',
        p_code: '410201'
      },
      {
        id: 1678,
        code: '410203',
        title: '顺河回族区',
        p_code: '410201'
      },
      {
        id: 1679,
        code: '410204',
        title: '鼓楼区',
        p_code: '410201'
      },
      {
        id: 1680,
        code: '410205',
        title: '禹王台区',
        p_code: '410201'
      },
      {
        id: 1681,
        code: '410211',
        title: '金明区',
        p_code: '410201'
      },
      {
        id: 1682,
        code: '410221',
        title: '杞县',
        p_code: '410200'
      },
      {
        id: 1683,
        code: '410222',
        title: '通许县',
        p_code: '410200'
      },
      {
        id: 1684,
        code: '410223',
        title: '尉氏县',
        p_code: '410200'
      },
      {
        id: 1685,
        code: '410224',
        title: '开封县',
        p_code: '410200'
      },
      {
        id: 1686,
        code: '410225',
        title: '兰考县',
        p_code: '410200'
      },
      {
        id: 1687,
        code: '410300',
        title: '洛阳市',
        p_code: '410000'
      },
      {
        id: 1688,
        code: '410301',
        title: '市辖区',
        p_code: '410300'
      },
      {
        id: 1689,
        code: '410302',
        title: '老城区',
        p_code: '410301'
      },
      {
        id: 1690,
        code: '410303',
        title: '西工区',
        p_code: '410301'
      },
      {
        id: 1691,
        code: '410304',
        title: '廛河回族区',
        p_code: '410301'
      },
      {
        id: 1692,
        code: '410305',
        title: '涧西区',
        p_code: '410301'
      },
      {
        id: 1693,
        code: '410306',
        title: '吉利区',
        p_code: '410301'
      },
      {
        id: 1694,
        code: '410307',
        title: '洛龙区',
        p_code: '410301'
      },
      {
        id: 1695,
        code: '410322',
        title: '孟津县',
        p_code: '410300'
      },
      {
        id: 1696,
        code: '410323',
        title: '新安县',
        p_code: '410300'
      },
      {
        id: 1697,
        code: '410324',
        title: '栾川县',
        p_code: '410300'
      },
      {
        id: 1698,
        code: '410325',
        title: '嵩县',
        p_code: '410300'
      },
      {
        id: 1699,
        code: '410326',
        title: '汝阳县',
        p_code: '410300'
      },
      {
        id: 1700,
        code: '410327',
        title: '宜阳县',
        p_code: '410300'
      },
      {
        id: 1701,
        code: '410328',
        title: '洛宁县',
        p_code: '410300'
      },
      {
        id: 1702,
        code: '410329',
        title: '伊川县',
        p_code: '410300'
      },
      {
        id: 1703,
        code: '410381',
        title: '偃师市',
        p_code: '410300'
      },
      {
        id: 1704,
        code: '410400',
        title: '平顶山市',
        p_code: '410000'
      },
      {
        id: 1705,
        code: '410401',
        title: '市辖区',
        p_code: '410400'
      },
      {
        id: 1706,
        code: '410402',
        title: '新华区',
        p_code: '410401'
      },
      {
        id: 1707,
        code: '410403',
        title: '卫东区',
        p_code: '410401'
      },
      {
        id: 1708,
        code: '410404',
        title: '石龙区',
        p_code: '410401'
      },
      {
        id: 1709,
        code: '410411',
        title: '湛河区',
        p_code: '410401'
      },
      {
        id: 1710,
        code: '410421',
        title: '宝丰县',
        p_code: '410400'
      },
      {
        id: 1711,
        code: '410422',
        title: '叶县',
        p_code: '410400'
      },
      {
        id: 1712,
        code: '410423',
        title: '鲁山县',
        p_code: '410400'
      },
      {
        id: 1713,
        code: '410425',
        title: '郏县',
        p_code: '410400'
      },
      {
        id: 1714,
        code: '410481',
        title: '舞钢市',
        p_code: '410400'
      },
      {
        id: 1715,
        code: '410482',
        title: '汝州市',
        p_code: '410400'
      },
      {
        id: 1716,
        code: '410500',
        title: '安阳市',
        p_code: '410000'
      },
      {
        id: 1717,
        code: '410501',
        title: '市辖区',
        p_code: '410500'
      },
      {
        id: 1718,
        code: '410502',
        title: '文峰区',
        p_code: '410501'
      },
      {
        id: 1719,
        code: '410503',
        title: '北关区',
        p_code: '410501'
      },
      {
        id: 1720,
        code: '410505',
        title: '殷都区',
        p_code: '410501'
      },
      {
        id: 1721,
        code: '410506',
        title: '龙安区',
        p_code: '410501'
      },
      {
        id: 1722,
        code: '410522',
        title: '安阳县',
        p_code: '410500'
      },
      {
        id: 1723,
        code: '410523',
        title: '汤阴县',
        p_code: '410500'
      },
      {
        id: 1724,
        code: '410526',
        title: '滑县',
        p_code: '410500'
      },
      {
        id: 1725,
        code: '410527',
        title: '内黄县',
        p_code: '410500'
      },
      {
        id: 1726,
        code: '410581',
        title: '林州市',
        p_code: '410500'
      },
      {
        id: 1727,
        code: '410600',
        title: '鹤壁市',
        p_code: '410000'
      },
      {
        id: 1728,
        code: '410601',
        title: '市辖区',
        p_code: '410600'
      },
      {
        id: 1729,
        code: '410602',
        title: '鹤山区',
        p_code: '410601'
      },
      {
        id: 1730,
        code: '410603',
        title: '山城区',
        p_code: '410601'
      },
      {
        id: 1731,
        code: '410611',
        title: '淇滨区',
        p_code: '410601'
      },
      {
        id: 1732,
        code: '410621',
        title: '浚县',
        p_code: '410600'
      },
      {
        id: 1733,
        code: '410622',
        title: '淇县',
        p_code: '410600'
      },
      {
        id: 1734,
        code: '410700',
        title: '新乡市',
        p_code: '410000'
      },
      {
        id: 1735,
        code: '410701',
        title: '市辖区',
        p_code: '410700'
      },
      {
        id: 1736,
        code: '410702',
        title: '红旗区',
        p_code: '410700'
      },
      {
        id: 1737,
        code: '410703',
        title: '卫滨区',
        p_code: '410700'
      },
      {
        id: 1738,
        code: '410704',
        title: '凤泉区',
        p_code: '410700'
      },
      {
        id: 1739,
        code: '410711',
        title: '牧野区',
        p_code: '410700'
      },
      {
        id: 1740,
        code: '410721',
        title: '新乡县',
        p_code: '410700'
      },
      {
        id: 1741,
        code: '410724',
        title: '获嘉县',
        p_code: '410700'
      },
      {
        id: 1742,
        code: '410725',
        title: '原阳县',
        p_code: '410700'
      },
      {
        id: 1743,
        code: '410726',
        title: '延津县',
        p_code: '410700'
      },
      {
        id: 1744,
        code: '410727',
        title: '封丘县',
        p_code: '410700'
      },
      {
        id: 1745,
        code: '410728',
        title: '长垣县',
        p_code: '410700'
      },
      {
        id: 1746,
        code: '410781',
        title: '卫辉市',
        p_code: '410700'
      },
      {
        id: 1747,
        code: '410782',
        title: '辉县市',
        p_code: '410700'
      },
      {
        id: 1748,
        code: '410800',
        title: '焦作市',
        p_code: '410000'
      },
      {
        id: 1749,
        code: '410801',
        title: '市辖区',
        p_code: '410800'
      },
      {
        id: 1750,
        code: '410802',
        title: '解放区',
        p_code: '410801'
      },
      {
        id: 1751,
        code: '410803',
        title: '中站区',
        p_code: '410801'
      },
      {
        id: 1752,
        code: '410804',
        title: '马村区',
        p_code: '410801'
      },
      {
        id: 1753,
        code: '410811',
        title: '山阳区',
        p_code: '410801'
      },
      {
        id: 1754,
        code: '410821',
        title: '修武县',
        p_code: '410800'
      },
      {
        id: 1755,
        code: '410822',
        title: '博爱县',
        p_code: '410800'
      },
      {
        id: 1756,
        code: '410823',
        title: '武陟县',
        p_code: '410800'
      },
      {
        id: 1757,
        code: '410825',
        title: '温县',
        p_code: '410800'
      },
      {
        id: 1758,
        code: '410882',
        title: '沁阳市',
        p_code: '410800'
      },
      {
        id: 1759,
        code: '410883',
        title: '孟州市',
        p_code: '410800'
      },
      {
        id: 1760,
        code: '410900',
        title: '濮阳市',
        p_code: '410000'
      },
      {
        id: 1761,
        code: '410901',
        title: '市辖区',
        p_code: '410900'
      },
      {
        id: 1762,
        code: '410902',
        title: '华龙区',
        p_code: '410901'
      },
      {
        id: 1763,
        code: '410922',
        title: '清丰县',
        p_code: '410900'
      },
      {
        id: 1764,
        code: '410923',
        title: '南乐县',
        p_code: '410900'
      },
      {
        id: 1765,
        code: '410926',
        title: '范县',
        p_code: '410900'
      },
      {
        id: 1766,
        code: '410927',
        title: '台前县',
        p_code: '410900'
      },
      {
        id: 1767,
        code: '410928',
        title: '濮阳县',
        p_code: '410900'
      },
      {
        id: 1768,
        code: '411000',
        title: '许昌市',
        p_code: '410000'
      },
      {
        id: 1769,
        code: '411001',
        title: '市辖区',
        p_code: '411000'
      },
      {
        id: 1770,
        code: '411002',
        title: '魏都区',
        p_code: '411001'
      },
      {
        id: 1771,
        code: '411023',
        title: '许昌县',
        p_code: '411000'
      },
      {
        id: 1772,
        code: '411024',
        title: '鄢陵县',
        p_code: '411000'
      },
      {
        id: 1773,
        code: '411025',
        title: '襄城县',
        p_code: '411000'
      },
      {
        id: 1774,
        code: '411081',
        title: '禹州市',
        p_code: '411000'
      },
      {
        id: 1775,
        code: '411082',
        title: '长葛市',
        p_code: '411000'
      },
      {
        id: 1776,
        code: '411100',
        title: '漯河市',
        p_code: '410000'
      },
      {
        id: 1777,
        code: '411101',
        title: '召陵区',
        p_code: '411100'
      },
      {
        id: 1778,
        code: '411102',
        title: '源汇区',
        p_code: '411100'
      },
      {
        id: 1779,
        code: '411103',
        title: '郾城区',
        p_code: '411100'
      },
      {
        id: 1780,
        code: '411121',
        title: '舞阳县',
        p_code: '411100'
      },
      {
        id: 1781,
        code: '411122',
        title: '临颍县',
        p_code: '411100'
      },
      {
        id: 1782,
        code: '411200',
        title: '三门峡市',
        p_code: '410000'
      },
      {
        id: 1783,
        code: '411201',
        title: '市辖区',
        p_code: '411200'
      },
      {
        id: 1784,
        code: '411202',
        title: '湖滨区',
        p_code: '411201'
      },
      {
        id: 1785,
        code: '411221',
        title: '渑池县',
        p_code: '411200'
      },
      {
        id: 1786,
        code: '411222',
        title: '陕县',
        p_code: '411200'
      },
      {
        id: 1787,
        code: '411224',
        title: '卢氏县',
        p_code: '411200'
      },
      {
        id: 1788,
        code: '411281',
        title: '义马市',
        p_code: '411200'
      },
      {
        id: 1789,
        code: '411282',
        title: '灵宝市',
        p_code: '411200'
      },
      {
        id: 1790,
        code: '411300',
        title: '南阳市',
        p_code: '410000'
      },
      {
        id: 1791,
        code: '411301',
        title: '市辖区',
        p_code: '411300'
      },
      {
        id: 1792,
        code: '411302',
        title: '宛城区',
        p_code: '411301'
      },
      {
        id: 1793,
        code: '411303',
        title: '卧龙区',
        p_code: '411301'
      },
      {
        id: 1794,
        code: '411321',
        title: '南召县',
        p_code: '411300'
      },
      {
        id: 1795,
        code: '411322',
        title: '方城县',
        p_code: '411300'
      },
      {
        id: 1796,
        code: '411323',
        title: '西峡县',
        p_code: '411300'
      },
      {
        id: 1797,
        code: '411324',
        title: '镇平县',
        p_code: '411300'
      },
      {
        id: 1798,
        code: '411325',
        title: '内乡县',
        p_code: '411300'
      },
      {
        id: 1799,
        code: '411326',
        title: '淅川县',
        p_code: '411300'
      },
      {
        id: 1800,
        code: '411327',
        title: '社旗县',
        p_code: '411300'
      },
      {
        id: 1801,
        code: '411328',
        title: '唐河县',
        p_code: '411300'
      },
      {
        id: 1802,
        code: '411329',
        title: '新野县',
        p_code: '411300'
      },
      {
        id: 1803,
        code: '411330',
        title: '桐柏县',
        p_code: '411300'
      },
      {
        id: 1804,
        code: '411381',
        title: '邓州市',
        p_code: '411300'
      },
      {
        id: 1805,
        code: '411400',
        title: '商丘市',
        p_code: '410000'
      },
      {
        id: 1806,
        code: '411401',
        title: '市辖区',
        p_code: '411400'
      },
      {
        id: 1807,
        code: '411402',
        title: '梁园区',
        p_code: '411400'
      },
      {
        id: 1808,
        code: '411403',
        title: '睢阳区',
        p_code: '411400'
      },
      {
        id: 1809,
        code: '411421',
        title: '民权县',
        p_code: '411400'
      },
      {
        id: 1810,
        code: '411422',
        title: '睢县',
        p_code: '411400'
      },
      {
        id: 1811,
        code: '411423',
        title: '宁陵县',
        p_code: '411400'
      },
      {
        id: 1812,
        code: '411424',
        title: '柘城县',
        p_code: '411400'
      },
      {
        id: 1813,
        code: '411425',
        title: '虞城县',
        p_code: '411400'
      },
      {
        id: 1814,
        code: '411426',
        title: '夏邑县',
        p_code: '411400'
      },
      {
        id: 1815,
        code: '411481',
        title: '永城市',
        p_code: '411400'
      },
      {
        id: 1816,
        code: '411482',
        title: '新区',
        p_code: '411400'
      },
      {
        id: 1817,
        code: '411500',
        title: '信阳市',
        p_code: '410000'
      },
      {
        id: 1818,
        code: '411501',
        title: '市辖区',
        p_code: '411500'
      },
      {
        id: 1819,
        code: '411502',
        title: '浉河区',
        p_code: '411501'
      },
      {
        id: 1820,
        code: '411503',
        title: '平桥区',
        p_code: '411501'
      },
      {
        id: 1821,
        code: '411521',
        title: '罗山县',
        p_code: '411500'
      },
      {
        id: 1822,
        code: '411522',
        title: '光山县',
        p_code: '411500'
      },
      {
        id: 1823,
        code: '411523',
        title: '新县',
        p_code: '411500'
      },
      {
        id: 1824,
        code: '411524',
        title: '商城县',
        p_code: '411500'
      },
      {
        id: 1825,
        code: '411525',
        title: '固始县',
        p_code: '411500'
      },
      {
        id: 1826,
        code: '411526',
        title: '潢川县',
        p_code: '411500'
      },
      {
        id: 1827,
        code: '411527',
        title: '淮滨县',
        p_code: '411500'
      },
      {
        id: 1828,
        code: '411528',
        title: '息县',
        p_code: '411500'
      },
      {
        id: 1829,
        code: '411600',
        title: '周口市',
        p_code: '410000'
      },
      {
        id: 1830,
        code: '411601',
        title: '市辖区',
        p_code: '411600'
      },
      {
        id: 1831,
        code: '411602',
        title: '川汇区',
        p_code: '411601'
      },
      {
        id: 1832,
        code: '411621',
        title: '扶沟县',
        p_code: '411600'
      },
      {
        id: 1833,
        code: '411622',
        title: '西华县',
        p_code: '411600'
      },
      {
        id: 1834,
        code: '411623',
        title: '商水县',
        p_code: '411600'
      },
      {
        id: 1835,
        code: '411624',
        title: '沈丘县',
        p_code: '411600'
      },
      {
        id: 1836,
        code: '411625',
        title: '郸城县',
        p_code: '411600'
      },
      {
        id: 1837,
        code: '411626',
        title: '淮阳县',
        p_code: '411600'
      },
      {
        id: 1838,
        code: '411627',
        title: '太康县',
        p_code: '411600'
      },
      {
        id: 1839,
        code: '411628',
        title: '鹿邑县',
        p_code: '411600'
      },
      {
        id: 1840,
        code: '411681',
        title: '项城市',
        p_code: '411600'
      },
      {
        id: 1841,
        code: '411700',
        title: '驻马店市',
        p_code: '410000'
      },
      {
        id: 1842,
        code: '411701',
        title: '市辖区',
        p_code: '411700'
      },
      {
        id: 1843,
        code: '411702',
        title: '驿城区',
        p_code: '411701'
      },
      {
        id: 1844,
        code: '411721',
        title: '西平县',
        p_code: '411700'
      },
      {
        id: 1845,
        code: '411722',
        title: '上蔡县',
        p_code: '411700'
      },
      {
        id: 1846,
        code: '411723',
        title: '平舆县',
        p_code: '411700'
      },
      {
        id: 1847,
        code: '411724',
        title: '正阳县',
        p_code: '411700'
      },
      {
        id: 1848,
        code: '411725',
        title: '确山县',
        p_code: '411700'
      },
      {
        id: 1849,
        code: '411726',
        title: '泌阳县',
        p_code: '411700'
      },
      {
        id: 1850,
        code: '411727',
        title: '汝南县',
        p_code: '411700'
      },
      {
        id: 1851,
        code: '411728',
        title: '遂平县',
        p_code: '411700'
      },
      {
        id: 1852,
        code: '411729',
        title: '新蔡县',
        p_code: '411700'
      },
      {
        id: 1853,
        code: '411800',
        title: '济源市',
        p_code: '410000'
      },
      {
        id: 1854,
        code: '411801',
        title: '市辖区',
        p_code: '411800'
      },

      {
        id: 1856,
        code: '420100',
        title: '武汉市',
        p_code: '420000'
      },
      {
        id: 1857,
        code: '420101',
        title: '市辖区',
        p_code: '420100'
      },
      {
        id: 1858,
        code: '420102',
        title: '江岸区',
        p_code: '420101'
      },
      {
        id: 1859,
        code: '420103',
        title: '江汉区',
        p_code: '420101'
      },
      {
        id: 1860,
        code: '420104',
        title: '硚口区',
        p_code: '420101'
      },
      {
        id: 1861,
        code: '420105',
        title: '汉阳区',
        p_code: '420101'
      },
      {
        id: 1862,
        code: '420106',
        title: '武昌区',
        p_code: '420101'
      },
      {
        id: 1863,
        code: '420107',
        title: '青山区',
        p_code: '420101'
      },
      {
        id: 1864,
        code: '420111',
        title: '洪山区',
        p_code: '420101'
      },
      {
        id: 1865,
        code: '420112',
        title: '东西湖区',
        p_code: '420101'
      },
      {
        id: 1866,
        code: '420113',
        title: '汉南区',
        p_code: '420101'
      },
      {
        id: 1867,
        code: '420114',
        title: '蔡甸区',
        p_code: '420101'
      },
      {
        id: 1868,
        code: '420115',
        title: '江夏区',
        p_code: '420101'
      },
      {
        id: 1869,
        code: '420116',
        title: '黄陂区',
        p_code: '420101'
      },
      {
        id: 1870,
        code: '420117',
        title: '新洲区',
        p_code: '420100'
      },
      {
        id: 1871,
        code: '420200',
        title: '黄石市',
        p_code: '420000'
      },
      {
        id: 1872,
        code: '420201',
        title: '市辖区',
        p_code: '420200'
      },
      {
        id: 1873,
        code: '420202',
        title: '黄石港区',
        p_code: '420201'
      },
      {
        id: 1874,
        code: '420203',
        title: '西塞山区',
        p_code: '420201'
      },
      {
        id: 1875,
        code: '420204',
        title: '下陆区',
        p_code: '420201'
      },
      {
        id: 1876,
        code: '420205',
        title: '铁山区',
        p_code: '420201'
      },
      {
        id: 1877,
        code: '420222',
        title: '阳新县',
        p_code: '420200'
      },
      {
        id: 1878,
        code: '420281',
        title: '大冶市',
        p_code: '420200'
      },
      {
        id: 1879,
        code: '420300',
        title: '十堰市',
        p_code: '420000'
      },
      {
        id: 1880,
        code: '420301',
        title: '市辖区',
        p_code: '420300'
      },
      {
        id: 1881,
        code: '420302',
        title: '茅箭区',
        p_code: '420301'
      },
      {
        id: 1882,
        code: '420303',
        title: '张湾区',
        p_code: '420301'
      },
      {
        id: 1883,
        code: '420321',
        title: '郧县',
        p_code: '420300'
      },
      {
        id: 1884,
        code: '420322',
        title: '郧西县',
        p_code: '420300'
      },
      {
        id: 1885,
        code: '420323',
        title: '竹山县',
        p_code: '420300'
      },
      {
        id: 1886,
        code: '420324',
        title: '竹溪县',
        p_code: '420300'
      },
      {
        id: 1887,
        code: '420325',
        title: '房县',
        p_code: '420300'
      },
      {
        id: 1888,
        code: '420381',
        title: '丹江口市',
        p_code: '420300'
      },
      {
        id: 1889,
        code: '420500',
        title: '宜昌市',
        p_code: '420000'
      },
      {
        id: 1890,
        code: '420501',
        title: '市辖区',
        p_code: '420500'
      },
      {
        id: 1891,
        code: '420502',
        title: '西陵区',
        p_code: '420501'
      },
      {
        id: 1892,
        code: '420503',
        title: '伍家岗区',
        p_code: '420501'
      },
      {
        id: 1893,
        code: '420504',
        title: '点军区',
        p_code: '420501'
      },
      {
        id: 1894,
        code: '420505',
        title: '猇亭区',
        p_code: '420501'
      },
      {
        id: 1895,
        code: '420506',
        title: '夷陵区',
        p_code: '420501'
      },
      {
        id: 1896,
        code: '420525',
        title: '远安县',
        p_code: '420500'
      },
      {
        id: 1897,
        code: '420526',
        title: '兴山县',
        p_code: '420500'
      },
      {
        id: 1898,
        code: '420527',
        title: '秭归县',
        p_code: '420500'
      },
      {
        id: 1899,
        code: '420528',
        title: '长阳土家族自治县',
        p_code: '420500'
      },
      {
        id: 1900,
        code: '420529',
        title: '五峰土家族自治县',
        p_code: '420500'
      },
      {
        id: 1901,
        code: '420581',
        title: '宜都市',
        p_code: '420500'
      },
      {
        id: 1902,
        code: '420582',
        title: '当阳市',
        p_code: '420500'
      },
      {
        id: 1903,
        code: '420583',
        title: '枝江市',
        p_code: '420500'
      },
      {
        id: 1904,
        code: '420600',
        title: '襄阳市',
        p_code: '420000'
      },
      {
        id: 1905,
        code: '420601',
        title: '市辖区',
        p_code: '420600'
      },
      {
        id: 1906,
        code: '420602',
        title: '襄城区',
        p_code: '420601'
      },
      {
        id: 1907,
        code: '420606',
        title: '樊城区',
        p_code: '420601'
      },
      {
        id: 1908,
        code: '420607',
        title: '襄州区',
        p_code: '420601'
      },
      {
        id: 1909,
        code: '420624',
        title: '南漳县',
        p_code: '420600'
      },
      {
        id: 1910,
        code: '420625',
        title: '谷城县',
        p_code: '420600'
      },
      {
        id: 1911,
        code: '420626',
        title: '保康县',
        p_code: '420600'
      },
      {
        id: 1912,
        code: '420682',
        title: '老河口市',
        p_code: '420600'
      },
      {
        id: 1913,
        code: '420683',
        title: '枣阳市',
        p_code: '420600'
      },
      {
        id: 1914,
        code: '420684',
        title: '宜城市',
        p_code: '420600'
      },
      {
        id: 1915,
        code: '420700',
        title: '鄂州市',
        p_code: '420000'
      },
      {
        id: 1916,
        code: '420701',
        title: '市辖区',
        p_code: '420700'
      },
      {
        id: 1917,
        code: '420702',
        title: '梁子湖区',
        p_code: '420701'
      },
      {
        id: 1918,
        code: '420703',
        title: '华容区',
        p_code: '420701'
      },
      {
        id: 1919,
        code: '420704',
        title: '鄂城区',
        p_code: '420701'
      },
      {
        id: 1920,
        code: '420800',
        title: '荆门市',
        p_code: '420000'
      },
      {
        id: 1921,
        code: '420801',
        title: '市辖区',
        p_code: '420800'
      },
      {
        id: 1922,
        code: '420802',
        title: '东宝区',
        p_code: '420801'
      },
      {
        id: 1923,
        code: '420804',
        title: '掇刀区',
        p_code: '420801'
      },
      {
        id: 1924,
        code: '420821',
        title: '京山县',
        p_code: '420800'
      },
      {
        id: 1925,
        code: '420822',
        title: '沙洋县',
        p_code: '420800'
      },
      {
        id: 1926,
        code: '420881',
        title: '钟祥市',
        p_code: '420800'
      },
      {
        id: 1927,
        code: '420900',
        title: '孝感市',
        p_code: '420000'
      },
      {
        id: 1928,
        code: '420901',
        title: '市辖区',
        p_code: '420900'
      },
      {
        id: 1929,
        code: '420902',
        title: '孝南区',
        p_code: '420901'
      },
      {
        id: 1930,
        code: '420921',
        title: '孝昌县',
        p_code: '420900'
      },
      {
        id: 1931,
        code: '420922',
        title: '大悟县',
        p_code: '420900'
      },
      {
        id: 1932,
        code: '420923',
        title: '云梦县',
        p_code: '420900'
      },
      {
        id: 1933,
        code: '420981',
        title: '应城市',
        p_code: '420900'
      },
      {
        id: 1934,
        code: '420982',
        title: '安陆市',
        p_code: '420900'
      },
      {
        id: 1935,
        code: '420984',
        title: '汉川市',
        p_code: '420900'
      },
      {
        id: 1936,
        code: '421000',
        title: '荆州市',
        p_code: '420000'
      },
      {
        id: 1937,
        code: '421001',
        title: '市辖区',
        p_code: '421000'
      },
      {
        id: 1938,
        code: '421002',
        title: '沙市区',
        p_code: '421001'
      },
      {
        id: 1939,
        code: '421003',
        title: '荆州区',
        p_code: '421001'
      },
      {
        id: 1940,
        code: '421022',
        title: '公安县',
        p_code: '421000'
      },
      {
        id: 1941,
        code: '421023',
        title: '监利县',
        p_code: '421000'
      },
      {
        id: 1942,
        code: '421024',
        title: '江陵县',
        p_code: '421000'
      },
      {
        id: 1943,
        code: '421081',
        title: '石首市',
        p_code: '421000'
      },
      {
        id: 1944,
        code: '421083',
        title: '洪湖市',
        p_code: '421000'
      },
      {
        id: 1945,
        code: '421087',
        title: '松滋市',
        p_code: '421000'
      },
      {
        id: 1946,
        code: '421100',
        title: '黄冈市',
        p_code: '420000'
      },
      {
        id: 1947,
        code: '421101',
        title: '市辖区',
        p_code: '421100'
      },
      {
        id: 1948,
        code: '421102',
        title: '黄州区',
        p_code: '421101'
      },
      {
        id: 1949,
        code: '421121',
        title: '团风县',
        p_code: '421100'
      },
      {
        id: 1950,
        code: '421122',
        title: '红安县',
        p_code: '421100'
      },
      {
        id: 1951,
        code: '421123',
        title: '罗田县',
        p_code: '421100'
      },
      {
        id: 1952,
        code: '421124',
        title: '英山县',
        p_code: '421100'
      },
      {
        id: 1953,
        code: '421125',
        title: '浠水县',
        p_code: '421100'
      },
      {
        id: 1954,
        code: '421126',
        title: '蕲春县',
        p_code: '421100'
      },
      {
        id: 1955,
        code: '421127',
        title: '黄梅县',
        p_code: '421100'
      },
      {
        id: 1956,
        code: '421181',
        title: '麻城市',
        p_code: '421100'
      },
      {
        id: 1957,
        code: '421182',
        title: '武穴市',
        p_code: '421100'
      },
      {
        id: 1958,
        code: '421200',
        title: '咸宁市',
        p_code: '420000'
      },
      {
        id: 1959,
        code: '421201',
        title: '市辖区',
        p_code: '421200'
      },
      {
        id: 1960,
        code: '421202',
        title: '咸安区',
        p_code: '421201'
      },
      {
        id: 1961,
        code: '421221',
        title: '嘉鱼县',
        p_code: '421200'
      },
      {
        id: 1962,
        code: '421222',
        title: '通城县',
        p_code: '421200'
      },
      {
        id: 1963,
        code: '421223',
        title: '崇阳县',
        p_code: '421200'
      },
      {
        id: 1964,
        code: '421224',
        title: '通山县',
        p_code: '421200'
      },
      {
        id: 1965,
        code: '421281',
        title: '赤壁市',
        p_code: '421200'
      },
      {
        id: 1966,
        code: '421300',
        title: '随州市',
        p_code: '420000'
      },
      {
        id: 1967,
        code: '421301',
        title: '市辖区',
        p_code: '421300'
      },
      {
        id: 1968,
        code: '421302',
        title: '曾都区',
        p_code: '421301'
      },
      {
        id: 1969,
        code: '421381',
        title: '广水市',
        p_code: '421300'
      },
      {
        id: 1970,
        code: '422800',
        title: '恩施土家族苗族自治州',
        p_code: '420000'
      },
      {
        id: 1971,
        code: '422801',
        title: '恩施市',
        p_code: '422800'
      },
      {
        id: 1972,
        code: '422802',
        title: '利川市',
        p_code: '422800'
      },
      {
        id: 1973,
        code: '422822',
        title: '建始县',
        p_code: '422800'
      },
      {
        id: 1974,
        code: '422823',
        title: '巴东县',
        p_code: '422800'
      },
      {
        id: 1975,
        code: '422825',
        title: '宣恩县',
        p_code: '422800'
      },
      {
        id: 1976,
        code: '422826',
        title: '咸丰县',
        p_code: '422800'
      },
      {
        id: 1977,
        code: '422827',
        title: '来凤县',
        p_code: '422800'
      },
      {
        id: 1978,
        code: '422828',
        title: '鹤峰县',
        p_code: '422800'
      },
      {
        id: 1979,
        code: '429000',
        title: '省直辖行政单位',
        p_code: '420000'
      },
      {
        id: 1980,
        code: '429004',
        title: '仙桃市',
        p_code: '429000'
      },
      {
        id: 1981,
        code: '429005',
        title: '潜江市',
        p_code: '429000'
      },
      {
        id: 1982,
        code: '429006',
        title: '天门市',
        p_code: '429000'
      },
      {
        id: 1983,
        code: '429021',
        title: '神农架林区',
        p_code: '429000'
      },

      {
        id: 1985,
        code: '430100',
        title: '长沙市',
        p_code: '430000'
      },
      {
        id: 1986,
        code: '430101',
        title: '市辖区',
        p_code: '430100'
      },
      {
        id: 1987,
        code: '430102',
        title: '芙蓉区',
        p_code: '430101'
      },
      {
        id: 1988,
        code: '430103',
        title: '天心区',
        p_code: '430101'
      },
      {
        id: 1989,
        code: '430104',
        title: '岳麓区',
        p_code: '430101'
      },
      {
        id: 1990,
        code: '430105',
        title: '开福区',
        p_code: '430101'
      },
      {
        id: 1991,
        code: '430111',
        title: '雨花区',
        p_code: '430101'
      },
      {
        id: 1992,
        code: '430121',
        title: '长沙县',
        p_code: '430100'
      },
      {
        id: 1993,
        code: '430122',
        title: '望城县',
        p_code: '430100'
      },
      {
        id: 1994,
        code: '430124',
        title: '宁乡县',
        p_code: '430100'
      },
      {
        id: 1995,
        code: '430181',
        title: '浏阳市',
        p_code: '430100'
      },
      {
        id: 1996,
        code: '430200',
        title: '株洲市',
        p_code: '430000'
      },
      {
        id: 1997,
        code: '430201',
        title: '市辖区',
        p_code: '430200'
      },
      {
        id: 1998,
        code: '430202',
        title: '荷塘区',
        p_code: '430201'
      },
      {
        id: 1999,
        code: '430203',
        title: '芦淞区',
        p_code: '430201'
      },
      {
        id: 2000,
        code: '430204',
        title: '石峰区',
        p_code: '430201'
      },
      {
        id: 2001,
        code: '430211',
        title: '天元区',
        p_code: '430201'
      },
      {
        id: 2002,
        code: '430221',
        title: '株洲县',
        p_code: '430200'
      },
      {
        id: 2003,
        code: '430223',
        title: '攸县',
        p_code: '430200'
      },
      {
        id: 2004,
        code: '430224',
        title: '茶陵县',
        p_code: '430200'
      },
      {
        id: 2005,
        code: '430225',
        title: '炎陵县',
        p_code: '430200'
      },
      {
        id: 2006,
        code: '430281',
        title: '醴陵市',
        p_code: '430200'
      },
      {
        id: 2007,
        code: '430300',
        title: '湘潭市',
        p_code: '430000'
      },
      {
        id: 2008,
        code: '430301',
        title: '市辖区',
        p_code: '430300'
      },
      {
        id: 2009,
        code: '430302',
        title: '雨湖区',
        p_code: '430301'
      },
      {
        id: 2010,
        code: '430304',
        title: '岳塘区',
        p_code: '430301'
      },
      {
        id: 2011,
        code: '430321',
        title: '湘潭县',
        p_code: '430300'
      },
      {
        id: 2012,
        code: '430381',
        title: '湘乡市',
        p_code: '430300'
      },
      {
        id: 2013,
        code: '430382',
        title: '韶山市',
        p_code: '430300'
      },
      {
        id: 2014,
        code: '430400',
        title: '衡阳市',
        p_code: '430000'
      },
      {
        id: 2015,
        code: '430401',
        title: '市辖区',
        p_code: '430400'
      },
      {
        id: 2016,
        code: '430405',
        title: '珠晖区',
        p_code: '430401'
      },
      {
        id: 2017,
        code: '430406',
        title: '雁峰区',
        p_code: '430401'
      },
      {
        id: 2018,
        code: '430407',
        title: '石鼓区',
        p_code: '430401'
      },
      {
        id: 2019,
        code: '430408',
        title: '蒸湘区',
        p_code: '430401'
      },
      {
        id: 2020,
        code: '430412',
        title: '南岳区',
        p_code: '430401'
      },
      {
        id: 2021,
        code: '430421',
        title: '衡阳县',
        p_code: '430400'
      },
      {
        id: 2022,
        code: '430422',
        title: '衡南县',
        p_code: '430400'
      },
      {
        id: 2023,
        code: '430423',
        title: '衡山县',
        p_code: '430400'
      },
      {
        id: 2024,
        code: '430424',
        title: '衡东县',
        p_code: '430400'
      },
      {
        id: 2025,
        code: '430426',
        title: '祁东县',
        p_code: '430400'
      },
      {
        id: 2026,
        code: '430481',
        title: '耒阳市',
        p_code: '430400'
      },
      {
        id: 2027,
        code: '430482',
        title: '常宁市',
        p_code: '430400'
      },
      {
        id: 2028,
        code: '430500',
        title: '邵阳市',
        p_code: '430000'
      },
      {
        id: 2029,
        code: '430501',
        title: '市辖区',
        p_code: '430500'
      },
      {
        id: 2030,
        code: '430502',
        title: '双清区',
        p_code: '430501'
      },
      {
        id: 2031,
        code: '430503',
        title: '大祥区',
        p_code: '430501'
      },
      {
        id: 2032,
        code: '430511',
        title: '北塔区',
        p_code: '430501'
      },
      {
        id: 2033,
        code: '430521',
        title: '邵东县',
        p_code: '430500'
      },
      {
        id: 2034,
        code: '430522',
        title: '新邵县',
        p_code: '430500'
      },
      {
        id: 2035,
        code: '430523',
        title: '邵阳县',
        p_code: '430500'
      },
      {
        id: 2036,
        code: '430524',
        title: '隆回县',
        p_code: '430500'
      },
      {
        id: 2037,
        code: '430525',
        title: '洞口县',
        p_code: '430500'
      },
      {
        id: 2038,
        code: '430527',
        title: '绥宁县',
        p_code: '430500'
      },
      {
        id: 2039,
        code: '430528',
        title: '新宁县',
        p_code: '430500'
      },
      {
        id: 2040,
        code: '430529',
        title: '城步苗族自治县',
        p_code: '430500'
      },
      {
        id: 2041,
        code: '430581',
        title: '武冈市',
        p_code: '430500'
      },
      {
        id: 2042,
        code: '430600',
        title: '岳阳市',
        p_code: '430000'
      },
      {
        id: 2043,
        code: '430601',
        title: '市辖区',
        p_code: '430600'
      },
      {
        id: 2044,
        code: '430602',
        title: '岳阳楼区',
        p_code: '430601'
      },
      {
        id: 2045,
        code: '430603',
        title: '云溪区',
        p_code: '430601'
      },
      {
        id: 2046,
        code: '430611',
        title: '君山区',
        p_code: '430601'
      },
      {
        id: 2047,
        code: '430621',
        title: '岳阳县',
        p_code: '430600'
      },
      {
        id: 2048,
        code: '430623',
        title: '华容县',
        p_code: '430600'
      },
      {
        id: 2049,
        code: '430624',
        title: '湘阴县',
        p_code: '430600'
      },
      {
        id: 2050,
        code: '430626',
        title: '平江县',
        p_code: '430600'
      },
      {
        id: 2051,
        code: '430681',
        title: '汨罗市',
        p_code: '430600'
      },
      {
        id: 2052,
        code: '430682',
        title: '临湘市',
        p_code: '430600'
      },
      {
        id: 2053,
        code: '430700',
        title: '常德市',
        p_code: '430000'
      },
      {
        id: 2054,
        code: '430701',
        title: '市辖区',
        p_code: '430700'
      },
      {
        id: 2055,
        code: '430702',
        title: '武陵区',
        p_code: '430701'
      },
      {
        id: 2056,
        code: '430703',
        title: '鼎城区',
        p_code: '430701'
      },
      {
        id: 2057,
        code: '430721',
        title: '安乡县',
        p_code: '430700'
      },
      {
        id: 2058,
        code: '430722',
        title: '汉寿县',
        p_code: '430700'
      },
      {
        id: 2059,
        code: '430723',
        title: '澧县',
        p_code: '430700'
      },
      {
        id: 2060,
        code: '430724',
        title: '临澧县',
        p_code: '430700'
      },
      {
        id: 2061,
        code: '430725',
        title: '桃源县',
        p_code: '430700'
      },
      {
        id: 2062,
        code: '430726',
        title: '石门县',
        p_code: '430700'
      },
      {
        id: 2063,
        code: '430781',
        title: '津市市',
        p_code: '430700'
      },
      {
        id: 2064,
        code: '430800',
        title: '张家界市',
        p_code: '430000'
      },
      {
        id: 2065,
        code: '430801',
        title: '市辖区',
        p_code: '430800'
      },
      {
        id: 2066,
        code: '430802',
        title: '永定区',
        p_code: '430801'
      },
      {
        id: 2067,
        code: '430811',
        title: '武陵源区',
        p_code: '430801'
      },
      {
        id: 2068,
        code: '430821',
        title: '慈利县',
        p_code: '430800'
      },
      {
        id: 2069,
        code: '430822',
        title: '桑植县',
        p_code: '430800'
      },
      {
        id: 2070,
        code: '430900',
        title: '益阳市',
        p_code: '430000'
      },
      {
        id: 2071,
        code: '430901',
        title: '市辖区',
        p_code: '430900'
      },
      {
        id: 2072,
        code: '430902',
        title: '资阳区',
        p_code: '430901'
      },
      {
        id: 2073,
        code: '430903',
        title: '赫山区',
        p_code: '430901'
      },
      {
        id: 2074,
        code: '430921',
        title: '南县',
        p_code: '430900'
      },
      {
        id: 2075,
        code: '430922',
        title: '桃江县',
        p_code: '430900'
      },
      {
        id: 2076,
        code: '430923',
        title: '安化县',
        p_code: '430900'
      },
      {
        id: 2077,
        code: '430981',
        title: '沅江市',
        p_code: '430900'
      },
      {
        id: 2078,
        code: '431000',
        title: '郴州市',
        p_code: '430000'
      },
      {
        id: 2079,
        code: '431001',
        title: '市辖区',
        p_code: '431000'
      },
      {
        id: 2080,
        code: '431002',
        title: '北湖区',
        p_code: '431001'
      },
      {
        id: 2081,
        code: '431003',
        title: '苏仙区',
        p_code: '431001'
      },
      {
        id: 2082,
        code: '431021',
        title: '桂阳县',
        p_code: '431000'
      },
      {
        id: 2083,
        code: '431022',
        title: '宜章县',
        p_code: '431000'
      },
      {
        id: 2084,
        code: '431023',
        title: '永兴县',
        p_code: '431000'
      },
      {
        id: 2085,
        code: '431024',
        title: '嘉禾县',
        p_code: '431000'
      },
      {
        id: 2086,
        code: '431025',
        title: '临武县',
        p_code: '431000'
      },
      {
        id: 2087,
        code: '431026',
        title: '汝城县',
        p_code: '431000'
      },
      {
        id: 2088,
        code: '431027',
        title: '桂东县',
        p_code: '431000'
      },
      {
        id: 2089,
        code: '431028',
        title: '安仁县',
        p_code: '431000'
      },
      {
        id: 2090,
        code: '431081',
        title: '资兴市',
        p_code: '431000'
      },
      {
        id: 2091,
        code: '431100',
        title: '永州市',
        p_code: '430000'
      },
      {
        id: 2092,
        code: '431101',
        title: '市辖区',
        p_code: '431100'
      },
      {
        id: 2093,
        code: '431102',
        title: '零陵区',
        p_code: '431101'
      },
      {
        id: 2094,
        code: '431103',
        title: '冷水滩区',
        p_code: '431101'
      },
      {
        id: 2095,
        code: '431121',
        title: '祁阳县',
        p_code: '431100'
      },
      {
        id: 2096,
        code: '431122',
        title: '东安县',
        p_code: '431100'
      },
      {
        id: 2097,
        code: '431123',
        title: '双牌县',
        p_code: '431100'
      },
      {
        id: 2098,
        code: '431124',
        title: '道县',
        p_code: '431100'
      },
      {
        id: 2099,
        code: '431125',
        title: '江永县',
        p_code: '431100'
      },
      {
        id: 2100,
        code: '431126',
        title: '宁远县',
        p_code: '431100'
      },
      {
        id: 2101,
        code: '431127',
        title: '蓝山县',
        p_code: '431100'
      },
      {
        id: 2102,
        code: '431128',
        title: '新田县',
        p_code: '431100'
      },
      {
        id: 2103,
        code: '431129',
        title: '江华瑶族自治县',
        p_code: '431100'
      },
      {
        id: 2104,
        code: '431200',
        title: '怀化市',
        p_code: '430000'
      },
      {
        id: 2105,
        code: '431201',
        title: '市辖区',
        p_code: '431200'
      },
      {
        id: 2106,
        code: '431202',
        title: '鹤城区',
        p_code: '431201'
      },
      {
        id: 2107,
        code: '431221',
        title: '中方县',
        p_code: '431200'
      },
      {
        id: 2108,
        code: '431222',
        title: '沅陵县',
        p_code: '431200'
      },
      {
        id: 2109,
        code: '431223',
        title: '辰溪县',
        p_code: '431200'
      },
      {
        id: 2110,
        code: '431224',
        title: '溆浦县',
        p_code: '431200'
      },
      {
        id: 2111,
        code: '431225',
        title: '会同县',
        p_code: '431200'
      },
      {
        id: 2112,
        code: '431226',
        title: '麻阳苗族自治县',
        p_code: '431200'
      },
      {
        id: 2113,
        code: '431227',
        title: '新晃侗族自治县',
        p_code: '431200'
      },
      {
        id: 2114,
        code: '431228',
        title: '芷江侗族自治县',
        p_code: '431200'
      },
      {
        id: 2115,
        code: '431229',
        title: '靖州苗族侗族自治县',
        p_code: '431200'
      },
      {
        id: 2116,
        code: '431230',
        title: '通道侗族自治县',
        p_code: '431200'
      },
      {
        id: 2117,
        code: '431281',
        title: '洪江市',
        p_code: '431200'
      },
      {
        id: 2118,
        code: '431300',
        title: '娄底市',
        p_code: '430000'
      },
      {
        id: 2119,
        code: '431301',
        title: '市辖区',
        p_code: '431300'
      },
      {
        id: 2120,
        code: '431302',
        title: '娄星区',
        p_code: '431301'
      },
      {
        id: 2121,
        code: '431321',
        title: '双峰县',
        p_code: '431300'
      },
      {
        id: 2122,
        code: '431322',
        title: '新化县',
        p_code: '431300'
      },
      {
        id: 2123,
        code: '431381',
        title: '冷水江市',
        p_code: '431300'
      },
      {
        id: 2124,
        code: '431382',
        title: '涟源市',
        p_code: '431300'
      },
      {
        id: 2125,
        code: '433100',
        title: '湘西土家族苗族自治州',
        p_code: '430000'
      },
      {
        id: 2126,
        code: '433101',
        title: '吉首市',
        p_code: '433100'
      },
      {
        id: 2127,
        code: '433122',
        title: '泸溪县',
        p_code: '433100'
      },
      {
        id: 2128,
        code: '433123',
        title: '凤凰县',
        p_code: '433100'
      },
      {
        id: 2129,
        code: '433124',
        title: '花垣县',
        p_code: '433100'
      },
      {
        id: 2130,
        code: '433125',
        title: '保靖县',
        p_code: '433100'
      },
      {
        id: 2131,
        code: '433126',
        title: '古丈县',
        p_code: '433100'
      },
      {
        id: 2132,
        code: '433127',
        title: '永顺县',
        p_code: '433100'
      },
      {
        id: 2133,
        code: '433130',
        title: '龙山县',
        p_code: '433100'
      },

      {
        id: 2135,
        code: '440100',
        title: '广州市',
        p_code: '440000'
      },
      {
        id: 2136,
        code: '440101',
        title: '市辖区',
        p_code: '440100'
      },
      {
        id: 2137,
        code: '440103',
        title: '荔湾区',
        p_code: '440101'
      },
      {
        id: 2138,
        code: '440104',
        title: '越秀区',
        p_code: '440101'
      },
      {
        id: 2139,
        code: '440105',
        title: '海珠区',
        p_code: '440101'
      },
      {
        id: 2140,
        code: '440106',
        title: '天河区',
        p_code: '440101'
      },
      {
        id: 2141,
        code: '440111',
        title: '白云区',
        p_code: '440101'
      },
      {
        id: 2142,
        code: '440112',
        title: '黄埔区',
        p_code: '440101'
      },
      {
        id: 2143,
        code: '440113',
        title: '番禺区',
        p_code: '440101'
      },
      {
        id: 2144,
        code: '440114',
        title: '花都区',
        p_code: '440101'
      },
      {
        id: 2145,
        code: '440115',
        title: '南沙区',
        p_code: '440101'
      },
      {
        id: 2146,
        code: '440116',
        title: '萝岗区',
        p_code: '440101'
      },
      {
        id: 2147,
        code: '440183',
        title: '增城市',
        p_code: '440100'
      },
      {
        id: 2148,
        code: '440184',
        title: '从化市',
        p_code: '440100'
      },
      {
        id: 2149,
        code: '440200',
        title: '韶关市',
        p_code: '440000'
      },
      {
        id: 2150,
        code: '440201',
        title: '市辖区',
        p_code: '440200'
      },
      {
        id: 2151,
        code: '440203',
        title: '武江区',
        p_code: '440201'
      },
      {
        id: 2152,
        code: '440204',
        title: '浈江区',
        p_code: '440201'
      },
      {
        id: 2153,
        code: '440205',
        title: '曲江区',
        p_code: '440201'
      },
      {
        id: 2154,
        code: '440222',
        title: '始兴县',
        p_code: '440200'
      },
      {
        id: 2155,
        code: '440224',
        title: '仁化县',
        p_code: '440200'
      },
      {
        id: 2156,
        code: '440229',
        title: '翁源县',
        p_code: '440200'
      },
      {
        id: 2157,
        code: '440232',
        title: '乳源瑶族自治县',
        p_code: '440200'
      },
      {
        id: 2158,
        code: '440233',
        title: '新丰县',
        p_code: '440200'
      },
      {
        id: 2159,
        code: '440281',
        title: '乐昌市',
        p_code: '440200'
      },
      {
        id: 2160,
        code: '440282',
        title: '南雄市',
        p_code: '440200'
      },
      {
        id: 2161,
        code: '440300',
        title: '深圳市',
        p_code: '440000'
      },
      {
        id: 2162,
        code: '440301',
        title: '市辖区',
        p_code: '440300'
      },
      {
        id: 2163,
        code: '440303',
        title: '罗湖区',
        p_code: '440301'
      },
      {
        id: 2164,
        code: '440304',
        title: '福田区',
        p_code: '440301'
      },
      {
        id: 2165,
        code: '440305',
        title: '南山区',
        p_code: '440301'
      },
      {
        id: 2166,
        code: '440306',
        title: '宝安区',
        p_code: '440301'
      },
      {
        id: 2167,
        code: '440307',
        title: '龙岗区',
        p_code: '440301'
      },
      {
        id: 2168,
        code: '440308',
        title: '盐田区',
        p_code: '440301'
      },
      {
        id: 2169,
        code: '440400',
        title: '珠海市',
        p_code: '440000'
      },
      {
        id: 2170,
        code: '440401',
        title: '市辖区',
        p_code: '440400'
      },
      {
        id: 2171,
        code: '440402',
        title: '香洲区',
        p_code: '440401'
      },
      {
        id: 2172,
        code: '440403',
        title: '斗门区',
        p_code: '440401'
      },
      {
        id: 2173,
        code: '440404',
        title: '金湾区',
        p_code: '440401'
      },
      {
        id: 2174,
        code: '440500',
        title: '汕头市',
        p_code: '440000'
      },
      {
        id: 2175,
        code: '440501',
        title: '市辖区',
        p_code: '440500'
      },
      {
        id: 2176,
        code: '440507',
        title: '龙湖区',
        p_code: '440501'
      },
      {
        id: 2177,
        code: '440511',
        title: '金平区',
        p_code: '440501'
      },
      {
        id: 2178,
        code: '440512',
        title: '濠江区',
        p_code: '440501'
      },
      {
        id: 2179,
        code: '440513',
        title: '潮阳区',
        p_code: '440501'
      },
      {
        id: 2180,
        code: '440514',
        title: '潮南区',
        p_code: '440501'
      },
      {
        id: 2181,
        code: '440515',
        title: '澄海区',
        p_code: '440501'
      },
      {
        id: 2182,
        code: '440523',
        title: '南澳县',
        p_code: '440500'
      },
      {
        id: 2183,
        code: '440600',
        title: '佛山市',
        p_code: '440000'
      },
      {
        id: 2184,
        code: '440601',
        title: '市辖区',
        p_code: '440600'
      },
      {
        id: 2185,
        code: '440604',
        title: '禅城区',
        p_code: '440600'
      },
      {
        id: 2186,
        code: '440605',
        title: '南海区',
        p_code: '440600'
      },
      {
        id: 2187,
        code: '440606',
        title: '顺德区',
        p_code: '440600'
      },
      {
        id: 2188,
        code: '440607',
        title: '三水区',
        p_code: '440600'
      },
      {
        id: 2189,
        code: '440608',
        title: '高明区',
        p_code: '440600'
      },
      {
        id: 2190,
        code: '440700',
        title: '江门市',
        p_code: '440000'
      },
      {
        id: 2191,
        code: '440701',
        title: '市辖区',
        p_code: '440700'
      },
      {
        id: 2192,
        code: '440703',
        title: '蓬江区',
        p_code: '440701'
      },
      {
        id: 2193,
        code: '440704',
        title: '江海区',
        p_code: '440701'
      },
      {
        id: 2194,
        code: '440705',
        title: '新会区',
        p_code: '440701'
      },
      {
        id: 2195,
        code: '440781',
        title: '台山市',
        p_code: '440700'
      },
      {
        id: 2196,
        code: '440783',
        title: '开平市',
        p_code: '440700'
      },
      {
        id: 2197,
        code: '440784',
        title: '鹤山市',
        p_code: '440700'
      },
      {
        id: 2198,
        code: '440785',
        title: '恩平市',
        p_code: '440700'
      },
      {
        id: 2199,
        code: '440800',
        title: '湛江市',
        p_code: '440000'
      },
      {
        id: 2200,
        code: '440801',
        title: '市辖区',
        p_code: '440800'
      },
      {
        id: 2201,
        code: '440802',
        title: '赤坎区',
        p_code: '440801'
      },
      {
        id: 2202,
        code: '440803',
        title: '霞山区',
        p_code: '440801'
      },
      {
        id: 2203,
        code: '440804',
        title: '坡头区',
        p_code: '440801'
      },
      {
        id: 2204,
        code: '440811',
        title: '麻章区',
        p_code: '440801'
      },
      {
        id: 2205,
        code: '440823',
        title: '遂溪县',
        p_code: '440800'
      },
      {
        id: 2206,
        code: '440825',
        title: '徐闻县',
        p_code: '440800'
      },
      {
        id: 2207,
        code: '440881',
        title: '廉江市',
        p_code: '440800'
      },
      {
        id: 2208,
        code: '440882',
        title: '雷州市',
        p_code: '440800'
      },
      {
        id: 2209,
        code: '440883',
        title: '吴川市',
        p_code: '440800'
      },
      {
        id: 2210,
        code: '440900',
        title: '茂名市',
        p_code: '440000'
      },
      {
        id: 2211,
        code: '440901',
        title: '市辖区',
        p_code: '440900'
      },
      {
        id: 2212,
        code: '440902',
        title: '茂南区',
        p_code: '440901'
      },
      {
        id: 2213,
        code: '440903',
        title: '茂港区',
        p_code: '440901'
      },
      {
        id: 2214,
        code: '440923',
        title: '电白县',
        p_code: '440900'
      },
      {
        id: 2215,
        code: '440981',
        title: '高州市',
        p_code: '440900'
      },
      {
        id: 2216,
        code: '440982',
        title: '化州市',
        p_code: '440900'
      },
      {
        id: 2217,
        code: '440983',
        title: '信宜市',
        p_code: '440900'
      },
      {
        id: 2218,
        code: '441200',
        title: '肇庆市',
        p_code: '440000'
      },
      {
        id: 2219,
        code: '441201',
        title: '市辖区',
        p_code: '441200'
      },
      {
        id: 2220,
        code: '441202',
        title: '端州区',
        p_code: '441201'
      },
      {
        id: 2221,
        code: '441203',
        title: '鼎湖区',
        p_code: '441201'
      },
      {
        id: 2222,
        code: '441223',
        title: '广宁县',
        p_code: '441200'
      },
      {
        id: 2223,
        code: '441224',
        title: '怀集县',
        p_code: '441200'
      },
      {
        id: 2224,
        code: '441225',
        title: '封开县',
        p_code: '441200'
      },
      {
        id: 2225,
        code: '441226',
        title: '德庆县',
        p_code: '441200'
      },
      {
        id: 2226,
        code: '441283',
        title: '高要市',
        p_code: '441200'
      },
      {
        id: 2227,
        code: '441284',
        title: '四会市',
        p_code: '441200'
      },
      {
        id: 2228,
        code: '441300',
        title: '惠州市',
        p_code: '440000'
      },
      {
        id: 2229,
        code: '441301',
        title: '市辖区',
        p_code: '441300'
      },
      {
        id: 2230,
        code: '441302',
        title: '惠城区',
        p_code: '441301'
      },
      {
        id: 2231,
        code: '441303',
        title: '惠阳区',
        p_code: '441301'
      },
      {
        id: 2232,
        code: '441322',
        title: '博罗县',
        p_code: '441300'
      },
      {
        id: 2233,
        code: '441323',
        title: '惠东县',
        p_code: '441300'
      },
      {
        id: 2234,
        code: '441324',
        title: '龙门县',
        p_code: '441300'
      },
      {
        id: 2235,
        code: '441400',
        title: '梅州市',
        p_code: '440000'
      },
      {
        id: 2236,
        code: '441401',
        title: '市辖区',
        p_code: '441400'
      },
      {
        id: 2237,
        code: '441402',
        title: '梅江区',
        p_code: '441401'
      },
      {
        id: 2238,
        code: '441421',
        title: '梅县',
        p_code: '441400'
      },
      {
        id: 2239,
        code: '441422',
        title: '大埔县',
        p_code: '441400'
      },
      {
        id: 2240,
        code: '441423',
        title: '丰顺县',
        p_code: '441400'
      },
      {
        id: 2241,
        code: '441424',
        title: '五华县',
        p_code: '441400'
      },
      {
        id: 2242,
        code: '441426',
        title: '平远县',
        p_code: '441400'
      },
      {
        id: 2243,
        code: '441427',
        title: '蕉岭县',
        p_code: '441400'
      },
      {
        id: 2244,
        code: '441481',
        title: '兴宁市',
        p_code: '441400'
      },
      {
        id: 2245,
        code: '441500',
        title: '汕尾市',
        p_code: '440000'
      },
      {
        id: 2246,
        code: '441501',
        title: '市辖区',
        p_code: '441500'
      },
      {
        id: 2247,
        code: '441502',
        title: '城区',
        p_code: '441501'
      },
      {
        id: 2248,
        code: '441521',
        title: '海丰县',
        p_code: '441500'
      },
      {
        id: 2249,
        code: '441523',
        title: '陆河县',
        p_code: '441500'
      },
      {
        id: 2250,
        code: '441581',
        title: '陆丰市',
        p_code: '441500'
      },
      {
        id: 2251,
        code: '441600',
        title: '河源市',
        p_code: '440000'
      },
      {
        id: 2252,
        code: '441601',
        title: '市辖区',
        p_code: '441600'
      },
      {
        id: 2253,
        code: '441602',
        title: '源城区',
        p_code: '441601'
      },
      {
        id: 2254,
        code: '441621',
        title: '紫金县',
        p_code: '441600'
      },
      {
        id: 2255,
        code: '441622',
        title: '龙川县',
        p_code: '441600'
      },
      {
        id: 2256,
        code: '441623',
        title: '连平县',
        p_code: '441600'
      },
      {
        id: 2257,
        code: '441624',
        title: '和平县',
        p_code: '441600'
      },
      {
        id: 2258,
        code: '441625',
        title: '东源县',
        p_code: '441600'
      },
      {
        id: 2259,
        code: '441700',
        title: '阳江市',
        p_code: '440000'
      },
      {
        id: 2260,
        code: '441701',
        title: '市辖区',
        p_code: '441700'
      },
      {
        id: 2261,
        code: '441702',
        title: '江城区',
        p_code: '441701'
      },
      {
        id: 2262,
        code: '441721',
        title: '阳西县',
        p_code: '441700'
      },
      {
        id: 2263,
        code: '441723',
        title: '阳东县',
        p_code: '441700'
      },
      {
        id: 2264,
        code: '441781',
        title: '阳春市',
        p_code: '441700'
      },
      {
        id: 2265,
        code: '441800',
        title: '清远市',
        p_code: '440000'
      },
      {
        id: 2266,
        code: '441801',
        title: '市辖区',
        p_code: '441800'
      },
      {
        id: 2267,
        code: '441802',
        title: '清城区',
        p_code: '441801'
      },
      {
        id: 2268,
        code: '441821',
        title: '佛冈县',
        p_code: '441800'
      },
      {
        id: 2269,
        code: '441823',
        title: '阳山县',
        p_code: '441800'
      },
      {
        id: 2270,
        code: '441825',
        title: '连山壮族瑶族自治县',
        p_code: '441800'
      },
      {
        id: 2271,
        code: '441826',
        title: '连南瑶族自治县',
        p_code: '441800'
      },
      {
        id: 2272,
        code: '441827',
        title: '清新县',
        p_code: '441800'
      },
      {
        id: 2273,
        code: '441881',
        title: '英德市',
        p_code: '441800'
      },
      {
        id: 2274,
        code: '441882',
        title: '连州市',
        p_code: '441800'
      },
      {
        id: 2275,
        code: '441900',
        title: '东莞市',
        p_code: '440000'
      },
      {
        id: 2276,
        code: '442000',
        title: '中山市',
        p_code: '440000'
      },
      {
        id: 2277,
        code: '445100',
        title: '潮州市',
        p_code: '440000'
      },
      {
        id: 2278,
        code: '445101',
        title: '市辖区',
        p_code: '445100'
      },
      {
        id: 2279,
        code: '445102',
        title: '湘桥区',
        p_code: '445101'
      },
      {
        id: 2280,
        code: '445121',
        title: '潮安区',
        p_code: '445100'
      },
      {
        id: 2281,
        code: '445122',
        title: '饶平县',
        p_code: '445100'
      },
      {
        id: 2282,
        code: '445200',
        title: '揭阳市',
        p_code: '440000'
      },
      {
        id: 2283,
        code: '445201',
        title: '市辖区',
        p_code: '445200'
      },
      {
        id: 2284,
        code: '445202',
        title: '榕城区',
        p_code: '445201'
      },
      {
        id: 2285,
        code: '445221',
        title: '揭东县',
        p_code: '445200'
      },
      {
        id: 2286,
        code: '445222',
        title: '揭西县',
        p_code: '445200'
      },
      {
        id: 2287,
        code: '445224',
        title: '惠来县',
        p_code: '445200'
      },
      {
        id: 2288,
        code: '445281',
        title: '普宁市',
        p_code: '445200'
      },
      {
        id: 2289,
        code: '445300',
        title: '云浮市',
        p_code: '440000'
      },
      {
        id: 2290,
        code: '445301',
        title: '市辖区',
        p_code: '445300'
      },
      {
        id: 2291,
        code: '445302',
        title: '云城区',
        p_code: '445301'
      },
      {
        id: 2292,
        code: '445321',
        title: '新兴县',
        p_code: '445300'
      },
      {
        id: 2293,
        code: '445322',
        title: '郁南县',
        p_code: '445300'
      },
      {
        id: 2294,
        code: '445323',
        title: '云安县',
        p_code: '445300'
      },
      {
        id: 2295,
        code: '445381',
        title: '罗定市',
        p_code: '445300'
      },

      {
        id: 2297,
        code: '450100',
        title: '南宁市',
        p_code: '450000'
      },
      {
        id: 2298,
        code: '450101',
        title: '市辖区',
        p_code: '450100'
      },
      {
        id: 2299,
        code: '450102',
        title: '兴宁区',
        p_code: '450101'
      },
      {
        id: 2300,
        code: '450103',
        title: '青秀区',
        p_code: '450101'
      },
      {
        id: 2301,
        code: '450105',
        title: '江南区',
        p_code: '450101'
      },
      {
        id: 2302,
        code: '450107',
        title: '西乡塘区',
        p_code: '450101'
      },
      {
        id: 2303,
        code: '450108',
        title: '良庆区',
        p_code: '450101'
      },
      {
        id: 2304,
        code: '450109',
        title: '邕宁区',
        p_code: '450101'
      },
      {
        id: 2305,
        code: '450122',
        title: '武鸣县',
        p_code: '450100'
      },
      {
        id: 2306,
        code: '450123',
        title: '隆安县',
        p_code: '450100'
      },
      {
        id: 2307,
        code: '450124',
        title: '马山县',
        p_code: '450100'
      },
      {
        id: 2308,
        code: '450125',
        title: '上林县',
        p_code: '450100'
      },
      {
        id: 2309,
        code: '450126',
        title: '宾阳县',
        p_code: '450100'
      },
      {
        id: 2310,
        code: '450127',
        title: '横县',
        p_code: '450100'
      },
      {
        id: 2311,
        code: '450200',
        title: '柳州市',
        p_code: '450000'
      },
      {
        id: 2312,
        code: '450201',
        title: '市辖区',
        p_code: '450200'
      },
      {
        id: 2313,
        code: '450202',
        title: '城中区',
        p_code: '450201'
      },
      {
        id: 2314,
        code: '450203',
        title: '鱼峰区',
        p_code: '450201'
      },
      {
        id: 2315,
        code: '450204',
        title: '柳南区',
        p_code: '450201'
      },
      {
        id: 2316,
        code: '450205',
        title: '柳北区',
        p_code: '450201'
      },
      {
        id: 2317,
        code: '450221',
        title: '柳江县',
        p_code: '450200'
      },
      {
        id: 2318,
        code: '450222',
        title: '柳城县',
        p_code: '450200'
      },
      {
        id: 2319,
        code: '450223',
        title: '鹿寨县',
        p_code: '450200'
      },
      {
        id: 2320,
        code: '450224',
        title: '融安县',
        p_code: '450200'
      },
      {
        id: 2321,
        code: '450225',
        title: '融水苗族自治县',
        p_code: '450200'
      },
      {
        id: 2322,
        code: '450226',
        title: '三江侗族自治县',
        p_code: '450200'
      },
      {
        id: 2323,
        code: '450300',
        title: '桂林市',
        p_code: '450000'
      },
      {
        id: 2324,
        code: '450301',
        title: '市辖区',
        p_code: '450300'
      },
      {
        id: 2325,
        code: '450302',
        title: '秀峰区',
        p_code: '450301'
      },
      {
        id: 2326,
        code: '450303',
        title: '叠彩区',
        p_code: '450301'
      },
      {
        id: 2327,
        code: '450304',
        title: '象山区',
        p_code: '450301'
      },
      {
        id: 2328,
        code: '450305',
        title: '七星区',
        p_code: '450301'
      },
      {
        id: 2329,
        code: '450311',
        title: '雁山区',
        p_code: '450301'
      },
      {
        id: 2330,
        code: '450321',
        title: '阳朔县',
        p_code: '450300'
      },
      {
        id: 2331,
        code: '450322',
        title: '临桂区',
        p_code: '450300'
      },
      {
        id: 2332,
        code: '450323',
        title: '灵川县',
        p_code: '450300'
      },
      {
        id: 2333,
        code: '450324',
        title: '全州县',
        p_code: '450300'
      },
      {
        id: 2334,
        code: '450325',
        title: '兴安县',
        p_code: '450300'
      },
      {
        id: 2335,
        code: '450326',
        title: '永福县',
        p_code: '450300'
      },
      {
        id: 2336,
        code: '450327',
        title: '灌阳县',
        p_code: '450300'
      },
      {
        id: 2337,
        code: '450328',
        title: '龙胜各族自治县',
        p_code: '450300'
      },
      {
        id: 2338,
        code: '450329',
        title: '资源县',
        p_code: '450300'
      },
      {
        id: 2339,
        code: '450330',
        title: '平乐县',
        p_code: '450300'
      },
      {
        id: 2340,
        code: '450331',
        title: '荔浦县',
        p_code: '450300'
      },
      {
        id: 2341,
        code: '450332',
        title: '恭城瑶族自治县',
        p_code: '450300'
      },
      {
        id: 2342,
        code: '450400',
        title: '梧州市',
        p_code: '450000'
      },
      {
        id: 2343,
        code: '450401',
        title: '市辖区',
        p_code: '450400'
      },
      {
        id: 2344,
        code: '450403',
        title: '万秀区',
        p_code: '450401'
      },
      {
        id: 2345,
        code: '450404',
        title: '蝶山区',
        p_code: '450401'
      },
      {
        id: 2346,
        code: '450405',
        title: '长洲区',
        p_code: '450401'
      },
      {
        id: 2347,
        code: '450421',
        title: '苍梧县',
        p_code: '450400'
      },
      {
        id: 2348,
        code: '450422',
        title: '藤县',
        p_code: '450400'
      },
      {
        id: 2349,
        code: '450423',
        title: '蒙山县',
        p_code: '450400'
      },
      {
        id: 2350,
        code: '450481',
        title: '岑溪市',
        p_code: '450400'
      },
      {
        id: 2351,
        code: '450500',
        title: '北海市',
        p_code: '450000'
      },
      {
        id: 2352,
        code: '450501',
        title: '市辖区',
        p_code: '450500'
      },
      {
        id: 2353,
        code: '450502',
        title: '海城区',
        p_code: '450501'
      },
      {
        id: 2354,
        code: '450503',
        title: '银海区',
        p_code: '450501'
      },
      {
        id: 2355,
        code: '450512',
        title: '铁山港区',
        p_code: '450501'
      },
      {
        id: 2356,
        code: '450521',
        title: '合浦县',
        p_code: '450500'
      },
      {
        id: 2357,
        code: '450600',
        title: '防城港市',
        p_code: '450000'
      },
      {
        id: 2358,
        code: '450601',
        title: '市辖区',
        p_code: '450600'
      },
      {
        id: 2359,
        code: '450602',
        title: '港口区',
        p_code: '450601'
      },
      {
        id: 2360,
        code: '450603',
        title: '防城区',
        p_code: '450601'
      },
      {
        id: 2361,
        code: '450621',
        title: '上思县',
        p_code: '450600'
      },
      {
        id: 2362,
        code: '450681',
        title: '东兴市',
        p_code: '450600'
      },
      {
        id: 2363,
        code: '450700',
        title: '钦州市',
        p_code: '450000'
      },
      {
        id: 2364,
        code: '450701',
        title: '市辖区',
        p_code: '450700'
      },
      {
        id: 2365,
        code: '450702',
        title: '钦南区',
        p_code: '450700'
      },
      {
        id: 2366,
        code: '450703',
        title: '钦北区',
        p_code: '450700'
      },
      {
        id: 2367,
        code: '450721',
        title: '灵山县',
        p_code: '450700'
      },
      {
        id: 2368,
        code: '450722',
        title: '浦北县',
        p_code: '450700'
      },
      {
        id: 2369,
        code: '450800',
        title: '贵港市',
        p_code: '450000'
      },
      {
        id: 2370,
        code: '450801',
        title: '市辖区',
        p_code: '450800'
      },
      {
        id: 2371,
        code: '450802',
        title: '港北区',
        p_code: '450801'
      },
      {
        id: 2372,
        code: '450803',
        title: '港南区',
        p_code: '450801'
      },
      {
        id: 2373,
        code: '450804',
        title: '覃塘区',
        p_code: '450801'
      },
      {
        id: 2374,
        code: '450821',
        title: '平南县',
        p_code: '450800'
      },
      {
        id: 2375,
        code: '450881',
        title: '桂平市',
        p_code: '450800'
      },
      {
        id: 2376,
        code: '450900',
        title: '玉林市',
        p_code: '450000'
      },
      {
        id: 2377,
        code: '450901',
        title: '市辖区',
        p_code: '450900'
      },
      {
        id: 2378,
        code: '450902',
        title: '玉州区',
        p_code: '450901'
      },
      {
        id: 2379,
        code: '450921',
        title: '容县',
        p_code: '450900'
      },
      {
        id: 2380,
        code: '450922',
        title: '陆川县',
        p_code: '450900'
      },
      {
        id: 2381,
        code: '450923',
        title: '博白县',
        p_code: '450900'
      },
      {
        id: 2382,
        code: '450924',
        title: '兴业县',
        p_code: '450900'
      },
      {
        id: 2383,
        code: '450981',
        title: '北流市',
        p_code: '450900'
      },
      {
        id: 2384,
        code: '451000',
        title: '百色市',
        p_code: '450000'
      },
      {
        id: 2385,
        code: '451001',
        title: '市辖区',
        p_code: '451000'
      },
      {
        id: 2386,
        code: '451002',
        title: '右江区',
        p_code: '451001'
      },
      {
        id: 2387,
        code: '451021',
        title: '田阳县',
        p_code: '451000'
      },
      {
        id: 2388,
        code: '451022',
        title: '田东县',
        p_code: '451000'
      },
      {
        id: 2389,
        code: '451023',
        title: '平果县',
        p_code: '451000'
      },
      {
        id: 2390,
        code: '451024',
        title: '德保县',
        p_code: '451000'
      },
      {
        id: 2391,
        code: '451025',
        title: '靖西县',
        p_code: '451000'
      },
      {
        id: 2392,
        code: '451026',
        title: '那坡县',
        p_code: '451000'
      },
      {
        id: 2393,
        code: '451027',
        title: '凌云县',
        p_code: '451000'
      },
      {
        id: 2394,
        code: '451028',
        title: '乐业县',
        p_code: '451000'
      },
      {
        id: 2395,
        code: '451029',
        title: '田林县',
        p_code: '451000'
      },
      {
        id: 2396,
        code: '451030',
        title: '西林县',
        p_code: '451000'
      },
      {
        id: 2397,
        code: '451031',
        title: '隆林各族自治县',
        p_code: '451000'
      },
      {
        id: 2398,
        code: '451100',
        title: '贺州市',
        p_code: '450000'
      },
      {
        id: 2399,
        code: '451101',
        title: '市辖区',
        p_code: '451100'
      },
      {
        id: 2400,
        code: '451102',
        title: '八步区',
        p_code: '451101'
      },
      {
        id: 2401,
        code: '451121',
        title: '昭平县',
        p_code: '451100'
      },
      {
        id: 2402,
        code: '451122',
        title: '钟山县',
        p_code: '451100'
      },
      {
        id: 2403,
        code: '451123',
        title: '富川瑶族自治县',
        p_code: '451100'
      },
      {
        id: 2404,
        code: '451200',
        title: '河池市',
        p_code: '450000'
      },
      {
        id: 2405,
        code: '451201',
        title: '市辖区',
        p_code: '451200'
      },
      {
        id: 2406,
        code: '451202',
        title: '金城江区',
        p_code: '451201'
      },
      {
        id: 2407,
        code: '451221',
        title: '南丹县',
        p_code: '451200'
      },
      {
        id: 2408,
        code: '451222',
        title: '天峨县',
        p_code: '451200'
      },
      {
        id: 2409,
        code: '451223',
        title: '凤山县',
        p_code: '451200'
      },
      {
        id: 2410,
        code: '451224',
        title: '东兰县',
        p_code: '451200'
      },
      {
        id: 2411,
        code: '451225',
        title: '罗城仫佬族自治县',
        p_code: '451200'
      },
      {
        id: 2412,
        code: '451226',
        title: '环江毛南族自治县',
        p_code: '451200'
      },
      {
        id: 2413,
        code: '451227',
        title: '巴马瑶族自治县',
        p_code: '451200'
      },
      {
        id: 2414,
        code: '451228',
        title: '都安瑶族自治县',
        p_code: '451200'
      },
      {
        id: 2415,
        code: '451229',
        title: '大化瑶族自治县',
        p_code: '451200'
      },
      {
        id: 2416,
        code: '451281',
        title: '宜州市',
        p_code: '451200'
      },
      {
        id: 2417,
        code: '451300',
        title: '来宾市',
        p_code: '450000'
      },
      {
        id: 2418,
        code: '451301',
        title: '市辖区',
        p_code: '451300'
      },
      {
        id: 2419,
        code: '451302',
        title: '兴宾区',
        p_code: '451301'
      },
      {
        id: 2420,
        code: '451321',
        title: '忻城县',
        p_code: '451300'
      },
      {
        id: 2421,
        code: '451322',
        title: '象州县',
        p_code: '451300'
      },
      {
        id: 2422,
        code: '451323',
        title: '武宣县',
        p_code: '451300'
      },
      {
        id: 2423,
        code: '451324',
        title: '金秀瑶族自治县',
        p_code: '451300'
      },
      {
        id: 2424,
        code: '451381',
        title: '合山市',
        p_code: '451300'
      },
      {
        id: 2425,
        code: '451400',
        title: '崇左市',
        p_code: '450000'
      },
      {
        id: 2426,
        code: '451401',
        title: '市辖区',
        p_code: '451400'
      },
      {
        id: 2427,
        code: '451402',
        title: '江洲区',
        p_code: '451401'
      },
      {
        id: 2428,
        code: '451421',
        title: '扶绥县',
        p_code: '451400'
      },
      {
        id: 2429,
        code: '451422',
        title: '宁明县',
        p_code: '451400'
      },
      {
        id: 2430,
        code: '451423',
        title: '龙州县',
        p_code: '451400'
      },
      {
        id: 2431,
        code: '451424',
        title: '大新县',
        p_code: '451400'
      },
      {
        id: 2432,
        code: '451425',
        title: '天等县',
        p_code: '451400'
      },
      {
        id: 2433,
        code: '451481',
        title: '凭祥市',
        p_code: '451400'
      },

      {
        id: 2435,
        code: '460100',
        title: '海口市',
        p_code: '460000'
      },
      {
        id: 2436,
        code: '460101',
        title: '市辖区',
        p_code: '460100'
      },
      {
        id: 2437,
        code: '460105',
        title: '秀英区',
        p_code: '460101'
      },
      {
        id: 2438,
        code: '460106',
        title: '龙华区',
        p_code: '460101'
      },
      {
        id: 2439,
        code: '460107',
        title: '琼山区',
        p_code: '460101'
      },
      {
        id: 2440,
        code: '460108',
        title: '美兰区',
        p_code: '460101'
      },
      {
        id: 2441,
        code: '460200',
        title: '三亚市',
        p_code: '460000'
      },
      {
        id: 2442,
        code: '460201',
        title: '市辖区',
        p_code: '460200'
      },
      {
        id: 2443,
        code: '469000',
        title: '省直辖县级行政单位',
        p_code: '460000'
      },
      {
        id: 2444,
        code: '469001',
        title: '五指山市',
        p_code: '469000'
      },
      {
        id: 2445,
        code: '469002',
        title: '琼海市',
        p_code: '469000'
      },
      {
        id: 2446,
        code: '469003',
        title: '儋州市',
        p_code: '469000'
      },
      {
        id: 2447,
        code: '469005',
        title: '文昌市',
        p_code: '469000'
      },
      {
        id: 2448,
        code: '469006',
        title: '万宁市',
        p_code: '469000'
      },
      {
        id: 2449,
        code: '469007',
        title: '东方市',
        p_code: '469000'
      },
      {
        id: 2450,
        code: '469025',
        title: '定安县',
        p_code: '469000'
      },
      {
        id: 2451,
        code: '469026',
        title: '屯昌县',
        p_code: '469000'
      },
      {
        id: 2452,
        code: '469027',
        title: '澄迈县',
        p_code: '469000'
      },
      {
        id: 2453,
        code: '469028',
        title: '临高县',
        p_code: '469000'
      },
      {
        id: 2454,
        code: '469030',
        title: '白沙黎族自治县',
        p_code: '469000'
      },
      {
        id: 2455,
        code: '469031',
        title: '昌江黎族自治县',
        p_code: '469000'
      },
      {
        id: 2456,
        code: '469033',
        title: '乐东黎族自治县',
        p_code: '469000'
      },
      {
        id: 2457,
        code: '469034',
        title: '陵水黎族自治县',
        p_code: '469000'
      },
      {
        id: 2458,
        code: '469035',
        title: '保亭黎族苗族自治县',
        p_code: '469000'
      },
      {
        id: 2459,
        code: '469036',
        title: '琼中黎族苗族自治县',
        p_code: '469000'
      },
      {
        id: 2460,
        code: '469037',
        title: '西沙群岛',
        p_code: '469000'
      },
      {
        id: 2461,
        code: '469038',
        title: '南沙群岛',
        p_code: '469000'
      },
      {
        id: 2462,
        code: '469039',
        title: '中沙群岛的岛礁及其海域',
        p_code: '469000'
      },

      {
        id: 2464,
        code: '500100',
        title: '万州区',
        p_code: '500000'
      },
      {
        id: 2465,
        code: '500101',
        title: '万州区',
        p_code: '500100'
      },
      {
        id: 2466,
        code: '500200',
        title: '涪陵区',
        p_code: '500000'
      },
      {
        id: 2467,
        code: '500201',
        title: '涪陵区',
        p_code: '500200'
      },
      {
        id: 2468,
        code: '500300',
        title: '渝中区',
        p_code: '500000'
      },
      {
        id: 2469,
        code: '500301',
        title: '渝中区',
        p_code: '500300'
      },
      {
        id: 2470,
        code: '500400',
        title: '大渡口区',
        p_code: '500000'
      },
      {
        id: 2471,
        code: '500401',
        title: '大渡口区',
        p_code: '500400'
      },
      {
        id: 2472,
        code: '500500',
        title: '江北区',
        p_code: '500000'
      },
      {
        id: 2473,
        code: '500501',
        title: '江北区',
        p_code: '500500'
      },
      {
        id: 2474,
        code: '500600',
        title: '沙坪坝区',
        p_code: '500000'
      },
      {
        id: 2475,
        code: '500601',
        title: '沙坪坝区',
        p_code: '500600'
      },
      {
        id: 2476,
        code: '500700',
        title: '九龙坡区',
        p_code: '500000'
      },
      {
        id: 2477,
        code: '500701',
        title: '九龙坡区',
        p_code: '500700'
      },
      {
        id: 2478,
        code: '500800',
        title: '南岸区',
        p_code: '500000'
      },
      {
        id: 2479,
        code: '500801',
        title: '南岸区',
        p_code: '500800'
      },
      {
        id: 2480,
        code: '500900',
        title: '北碚区',
        p_code: '500000'
      },
      {
        id: 2481,
        code: '500901',
        title: '北碚区',
        p_code: '500900'
      },
      {
        id: 2482,
        code: '501000',
        title: '万盛区',
        p_code: '500000'
      },
      {
        id: 2483,
        code: '501001',
        title: '万盛区',
        p_code: '501000'
      },
      {
        id: 2484,
        code: '501100',
        title: '双桥区',
        p_code: '500000'
      },
      {
        id: 2485,
        code: '501101',
        title: '双桥区',
        p_code: '501100'
      },
      {
        id: 2486,
        code: '501200',
        title: '渝北区',
        p_code: '500000'
      },
      {
        id: 2487,
        code: '501201',
        title: '渝北区',
        p_code: '501200'
      },
      {
        id: 2488,
        code: '501300',
        title: '巴南区',
        p_code: '500000'
      },
      {
        id: 2489,
        code: '501301',
        title: '巴南区',
        p_code: '501300'
      },
      {
        id: 2490,
        code: '501400',
        title: '黔江区',
        p_code: '500000'
      },
      {
        id: 2491,
        code: '501401',
        title: '黔江区',
        p_code: '501400'
      },
      {
        id: 2492,
        code: '501500',
        title: '长寿区',
        p_code: '500000'
      },
      {
        id: 2493,
        code: '501501',
        title: '长寿区',
        p_code: '501500'
      },
      {
        id: 2494,
        code: '502200',
        title: '綦江区',
        p_code: '500000'
      },
      {
        id: 2495,
        code: '502201',
        title: '綦江区',
        p_code: '502200'
      },
      {
        id: 2496,
        code: '502300',
        title: '潼南县',
        p_code: '500000'
      },
      {
        id: 2497,
        code: '502301',
        title: '潼南县',
        p_code: '502300'
      },
      {
        id: 2498,
        code: '502400',
        title: '铜梁县',
        p_code: '500000'
      },
      {
        id: 2499,
        code: '502401',
        title: '铜梁县',
        p_code: '502400'
      },
      {
        id: 2500,
        code: '502500',
        title: '大足区',
        p_code: '500000'
      },
      {
        id: 2501,
        code: '502501',
        title: '大足区',
        p_code: '502500'
      },
      {
        id: 2502,
        code: '502600',
        title: '荣昌县',
        p_code: '500000'
      },
      {
        id: 2503,
        code: '502601',
        title: '荣昌县',
        p_code: '502600'
      },
      {
        id: 2504,
        code: '502700',
        title: '璧山县',
        p_code: '500000'
      },
      {
        id: 2505,
        code: '502701',
        title: '璧山县',
        p_code: '502700'
      },
      {
        id: 2506,
        code: '502800',
        title: '梁平县',
        p_code: '500000'
      },
      {
        id: 2507,
        code: '502801',
        title: '梁平县',
        p_code: '502800'
      },
      {
        id: 2508,
        code: '502900',
        title: '城口县',
        p_code: '500000'
      },
      {
        id: 2509,
        code: '502901',
        title: '城口县',
        p_code: '502900'
      },
      {
        id: 2510,
        code: '503000',
        title: '丰都县',
        p_code: '500000'
      },
      {
        id: 2511,
        code: '503001',
        title: '丰都县',
        p_code: '503000'
      },
      {
        id: 2512,
        code: '503100',
        title: '垫江县',
        p_code: '500000'
      },
      {
        id: 2513,
        code: '503101',
        title: '垫江县',
        p_code: '503100'
      },
      {
        id: 2514,
        code: '503200',
        title: '武隆县',
        p_code: '500000'
      },
      {
        id: 2515,
        code: '503201',
        title: '武隆县',
        p_code: '503200'
      },
      {
        id: 2516,
        code: '503300',
        title: '忠县',
        p_code: '500000'
      },
      {
        id: 2517,
        code: '503301',
        title: '忠县',
        p_code: '503300'
      },
      {
        id: 2518,
        code: '503400',
        title: '开县',
        p_code: '500000'
      },
      {
        id: 2519,
        code: '503401',
        title: '开县',
        p_code: '503400'
      },
      {
        id: 2520,
        code: '503500',
        title: '云阳县',
        p_code: '500000'
      },
      {
        id: 2521,
        code: '503501',
        title: '云阳县',
        p_code: '503500'
      },
      {
        id: 2522,
        code: '503600',
        title: '奉节县',
        p_code: '500000'
      },
      {
        id: 2523,
        code: '503601',
        title: '奉节县',
        p_code: '503600'
      },
      {
        id: 2524,
        code: '503700',
        title: '巫山县',
        p_code: '500000'
      },
      {
        id: 2525,
        code: '503701',
        title: '巫山县',
        p_code: '503700'
      },
      {
        id: 2526,
        code: '503800',
        title: '巫溪县',
        p_code: '500000'
      },
      {
        id: 2527,
        code: '503801',
        title: '巫溪县',
        p_code: '503800'
      },
      {
        id: 2528,
        code: '504000',
        title: '石柱县',
        p_code: '500000'
      },
      {
        id: 2529,
        code: '504001',
        title: '石柱县',
        p_code: '504000'
      },
      {
        id: 2530,
        code: '504100',
        title: '秀山县',
        p_code: '500000'
      },
      {
        id: 2531,
        code: '504101',
        title: '秀山县',
        p_code: '504100'
      },
      {
        id: 2532,
        code: '504200',
        title: '酉阳县',
        p_code: '500000'
      },
      {
        id: 2533,
        code: '504201',
        title: '酉阳县',
        p_code: '504200'
      },
      {
        id: 2534,
        code: '504300',
        title: '彭水县',
        p_code: '500000'
      },
      {
        id: 2535,
        code: '504301',
        title: '彭水县',
        p_code: '504300'
      },
      {
        id: 2536,
        code: '508100',
        title: '江津区',
        p_code: '500000'
      },
      {
        id: 2537,
        code: '508101',
        title: '江津区',
        p_code: '508100'
      },
      {
        id: 2538,
        code: '508200',
        title: '合川区',
        p_code: '500000'
      },
      {
        id: 2539,
        code: '508201',
        title: '合川区',
        p_code: '508200'
      },
      {
        id: 2540,
        code: '508300',
        title: '永川区',
        p_code: '500000'
      },
      {
        id: 2541,
        code: '508301',
        title: '永川区',
        p_code: '508300'
      },
      {
        id: 2542,
        code: '508400',
        title: '南川区',
        p_code: '500000'
      },
      {
        id: 2543,
        code: '508401',
        title: '南川区',
        p_code: '508400'
      },

      {
        id: 2545,
        code: '510100',
        title: '成都市',
        p_code: '510000'
      },
      {
        id: 2546,
        code: '510101',
        title: '市辖区',
        p_code: '510100'
      },
      {
        id: 2547,
        code: '510104',
        title: '锦江区',
        p_code: '510101'
      },
      {
        id: 2548,
        code: '510105',
        title: '青羊区',
        p_code: '510101'
      },
      {
        id: 2549,
        code: '510106',
        title: '金牛区',
        p_code: '510101'
      },
      {
        id: 2550,
        code: '510107',
        title: '武侯区',
        p_code: '510101'
      },
      {
        id: 2551,
        code: '510108',
        title: '成华区',
        p_code: '510101'
      },
      {
        id: 2552,
        code: '510112',
        title: '龙泉驿区',
        p_code: '510100'
      },
      {
        id: 2553,
        code: '510113',
        title: '青白江区',
        p_code: '510101'
      },
      {
        id: 2554,
        code: '510114',
        title: '新都区',
        p_code: '510101'
      },
      {
        id: 2555,
        code: '510115',
        title: '温江区',
        p_code: '510101'
      },
      {
        id: 2556,
        code: '510121',
        title: '金堂县',
        p_code: '510100'
      },
      {
        id: 2557,
        code: '510122',
        title: '双流县',
        p_code: '510100'
      },
      {
        id: 2558,
        code: '510124',
        title: '郫县',
        p_code: '510100'
      },
      {
        id: 2559,
        code: '510129',
        title: '大邑县',
        p_code: '510100'
      },
      {
        id: 2560,
        code: '510131',
        title: '蒲江县',
        p_code: '510100'
      },
      {
        id: 2561,
        code: '510132',
        title: '新津县',
        p_code: '510100'
      },
      {
        id: 2562,
        code: '510181',
        title: '都江堰市',
        p_code: '510100'
      },
      {
        id: 2563,
        code: '510182',
        title: '彭州市',
        p_code: '510100'
      },
      {
        id: 2564,
        code: '510183',
        title: '邛崃市',
        p_code: '510100'
      },
      {
        id: 2565,
        code: '510184',
        title: '崇州市',
        p_code: '510100'
      },
      {
        id: 2566,
        code: '510300',
        title: '自贡市',
        p_code: '510000'
      },
      {
        id: 2567,
        code: '510301',
        title: '市辖区',
        p_code: '510300'
      },
      {
        id: 2568,
        code: '510302',
        title: '自流井区',
        p_code: '510301'
      },
      {
        id: 2569,
        code: '510303',
        title: '贡井区',
        p_code: '510301'
      },
      {
        id: 2570,
        code: '510304',
        title: '大安区',
        p_code: '510301'
      },
      {
        id: 2571,
        code: '510311',
        title: '沿滩区',
        p_code: '510301'
      },
      {
        id: 2572,
        code: '510321',
        title: '荣县',
        p_code: '510300'
      },
      {
        id: 2573,
        code: '510322',
        title: '富顺县',
        p_code: '510300'
      },
      {
        id: 2574,
        code: '510400',
        title: '攀枝花市',
        p_code: '510000'
      },
      {
        id: 2575,
        code: '510401',
        title: '市辖区',
        p_code: '510400'
      },
      {
        id: 2576,
        code: '510402',
        title: '东区',
        p_code: '510401'
      },
      {
        id: 2577,
        code: '510403',
        title: '西区',
        p_code: '510401'
      },
      {
        id: 2578,
        code: '510411',
        title: '仁和区',
        p_code: '510401'
      },
      {
        id: 2579,
        code: '510421',
        title: '米易县',
        p_code: '510400'
      },
      {
        id: 2580,
        code: '510422',
        title: '盐边县',
        p_code: '510400'
      },
      {
        id: 2581,
        code: '510500',
        title: '泸州市',
        p_code: '510000'
      },
      {
        id: 2582,
        code: '510501',
        title: '市辖区',
        p_code: '510500'
      },
      {
        id: 2583,
        code: '510502',
        title: '江阳区',
        p_code: '510501'
      },
      {
        id: 2584,
        code: '510503',
        title: '纳溪区',
        p_code: '510501'
      },
      {
        id: 2585,
        code: '510504',
        title: '龙马潭区',
        p_code: '510501'
      },
      {
        id: 2586,
        code: '510521',
        title: '泸县',
        p_code: '510500'
      },
      {
        id: 2587,
        code: '510522',
        title: '合江县',
        p_code: '510500'
      },
      {
        id: 2588,
        code: '510524',
        title: '叙永县',
        p_code: '510500'
      },
      {
        id: 2589,
        code: '510525',
        title: '古蔺县',
        p_code: '510500'
      },
      {
        id: 2590,
        code: '510600',
        title: '德阳市',
        p_code: '510000'
      },
      {
        id: 2591,
        code: '510601',
        title: '市辖区',
        p_code: '510600'
      },
      {
        id: 2592,
        code: '510603',
        title: '旌阳区',
        p_code: '510601'
      },
      {
        id: 2593,
        code: '510623',
        title: '中江县',
        p_code: '510600'
      },
      {
        id: 2594,
        code: '510626',
        title: '罗江县',
        p_code: '510600'
      },
      {
        id: 2595,
        code: '510681',
        title: '广汉市',
        p_code: '510600'
      },
      {
        id: 2596,
        code: '510682',
        title: '什邡市',
        p_code: '510600'
      },
      {
        id: 2597,
        code: '510683',
        title: '绵竹市',
        p_code: '510600'
      },
      {
        id: 2598,
        code: '510700',
        title: '绵阳市',
        p_code: '510000'
      },
      {
        id: 2599,
        code: '510701',
        title: '市辖区',
        p_code: '510700'
      },
      {
        id: 2600,
        code: '510703',
        title: '涪城区',
        p_code: '510701'
      },
      {
        id: 2601,
        code: '510704',
        title: '游仙区',
        p_code: '510701'
      },
      {
        id: 2602,
        code: '510722',
        title: '三台县',
        p_code: '510700'
      },
      {
        id: 2603,
        code: '510723',
        title: '盐亭县',
        p_code: '510700'
      },
      {
        id: 2604,
        code: '510724',
        title: '安县',
        p_code: '510700'
      },
      {
        id: 2605,
        code: '510725',
        title: '梓潼县',
        p_code: '510700'
      },
      {
        id: 2606,
        code: '510726',
        title: '北川羌族自治县',
        p_code: '510700'
      },
      {
        id: 2607,
        code: '510727',
        title: '平武县',
        p_code: '510700'
      },
      {
        id: 2608,
        code: '510781',
        title: '江油市',
        p_code: '510700'
      },
      {
        id: 2609,
        code: '510800',
        title: '广元市',
        p_code: '510000'
      },
      {
        id: 2610,
        code: '510801',
        title: '市辖区',
        p_code: '510800'
      },
      {
        id: 2611,
        code: '510802',
        title: '市中区',
        p_code: '510801'
      },
      {
        id: 2612,
        code: '510811',
        title: '元坝区',
        p_code: '510801'
      },
      {
        id: 2613,
        code: '510812',
        title: '朝天区',
        p_code: '510801'
      },
      {
        id: 2614,
        code: '510821',
        title: '旺苍县',
        p_code: '510800'
      },
      {
        id: 2615,
        code: '510822',
        title: '青川县',
        p_code: '510800'
      },
      {
        id: 2616,
        code: '510823',
        title: '剑阁县',
        p_code: '510800'
      },
      {
        id: 2617,
        code: '510824',
        title: '苍溪县',
        p_code: '510800'
      },
      {
        id: 2618,
        code: '510900',
        title: '遂宁市',
        p_code: '510000'
      },
      {
        id: 2619,
        code: '510901',
        title: '市辖区',
        p_code: '510900'
      },
      {
        id: 2620,
        code: '510903',
        title: '船山区',
        p_code: '510901'
      },
      {
        id: 2621,
        code: '510904',
        title: '安居区',
        p_code: '510901'
      },
      {
        id: 2622,
        code: '510921',
        title: '蓬溪县',
        p_code: '510900'
      },
      {
        id: 2623,
        code: '510922',
        title: '射洪县',
        p_code: '510900'
      },
      {
        id: 2624,
        code: '510923',
        title: '大英县',
        p_code: '510900'
      },
      {
        id: 2625,
        code: '511000',
        title: '内江市',
        p_code: '510000'
      },
      {
        id: 2626,
        code: '511001',
        title: '市辖区',
        p_code: '511000'
      },
      {
        id: 2627,
        code: '511002',
        title: '市中区',
        p_code: '511001'
      },
      {
        id: 2628,
        code: '511011',
        title: '东兴区',
        p_code: '511001'
      },
      {
        id: 2629,
        code: '511024',
        title: '威远县',
        p_code: '511000'
      },
      {
        id: 2630,
        code: '511025',
        title: '资中县',
        p_code: '511000'
      },
      {
        id: 2631,
        code: '511028',
        title: '隆昌县',
        p_code: '511000'
      },
      {
        id: 2632,
        code: '511100',
        title: '乐山市',
        p_code: '510000'
      },
      {
        id: 2633,
        code: '511101',
        title: '市辖区',
        p_code: '511100'
      },
      {
        id: 2634,
        code: '511102',
        title: '市中区',
        p_code: '511101'
      },
      {
        id: 2635,
        code: '511111',
        title: '沙湾区',
        p_code: '511101'
      },
      {
        id: 2636,
        code: '511112',
        title: '五通桥区',
        p_code: '511101'
      },
      {
        id: 2637,
        code: '511113',
        title: '金口河区',
        p_code: '511101'
      },
      {
        id: 2638,
        code: '511123',
        title: '犍为县',
        p_code: '511100'
      },
      {
        id: 2639,
        code: '511124',
        title: '井研县',
        p_code: '511100'
      },
      {
        id: 2640,
        code: '511126',
        title: '夹江县',
        p_code: '511100'
      },
      {
        id: 2641,
        code: '511129',
        title: '沐川县',
        p_code: '511100'
      },
      {
        id: 2642,
        code: '511132',
        title: '峨边彝族自治县',
        p_code: '511100'
      },
      {
        id: 2643,
        code: '511133',
        title: '马边彝族自治县',
        p_code: '511100'
      },
      {
        id: 2644,
        code: '511181',
        title: '峨眉山市',
        p_code: '511100'
      },
      {
        id: 2645,
        code: '511300',
        title: '南充市',
        p_code: '510000'
      },
      {
        id: 2646,
        code: '511301',
        title: '市辖区',
        p_code: '511300'
      },
      {
        id: 2647,
        code: '511302',
        title: '顺庆区',
        p_code: '511301'
      },
      {
        id: 2648,
        code: '511303',
        title: '高坪区',
        p_code: '511301'
      },
      {
        id: 2649,
        code: '511304',
        title: '嘉陵区',
        p_code: '511301'
      },
      {
        id: 2650,
        code: '511321',
        title: '南部县',
        p_code: '511300'
      },
      {
        id: 2651,
        code: '511322',
        title: '营山县',
        p_code: '511300'
      },
      {
        id: 2652,
        code: '511323',
        title: '蓬安县',
        p_code: '511300'
      },
      {
        id: 2653,
        code: '511324',
        title: '仪陇县',
        p_code: '511300'
      },
      {
        id: 2654,
        code: '511325',
        title: '西充县',
        p_code: '511300'
      },
      {
        id: 2655,
        code: '511381',
        title: '阆中市',
        p_code: '511300'
      },
      {
        id: 2656,
        code: '511400',
        title: '眉山市',
        p_code: '510000'
      },
      {
        id: 2657,
        code: '511401',
        title: '市辖区',
        p_code: '511400'
      },
      {
        id: 2658,
        code: '511402',
        title: '东坡区',
        p_code: '511401'
      },
      {
        id: 2659,
        code: '511421',
        title: '仁寿县',
        p_code: '511400'
      },
      {
        id: 2660,
        code: '511422',
        title: '彭山县',
        p_code: '511400'
      },
      {
        id: 2661,
        code: '511423',
        title: '洪雅县',
        p_code: '511400'
      },
      {
        id: 2662,
        code: '511424',
        title: '丹棱县',
        p_code: '511400'
      },
      {
        id: 2663,
        code: '511425',
        title: '青神县',
        p_code: '511400'
      },
      {
        id: 2664,
        code: '511500',
        title: '宜宾市',
        p_code: '510000'
      },
      {
        id: 2665,
        code: '511501',
        title: '市辖区',
        p_code: '511500'
      },
      {
        id: 2666,
        code: '511502',
        title: '翠屏区',
        p_code: '511500'
      },
      {
        id: 2667,
        code: '511521',
        title: '宜宾县',
        p_code: '511500'
      },
      {
        id: 2668,
        code: '511522',
        title: '南溪县',
        p_code: '511500'
      },
      {
        id: 2669,
        code: '511523',
        title: '江安县',
        p_code: '511500'
      },
      {
        id: 2670,
        code: '511524',
        title: '长宁县',
        p_code: '511500'
      },
      {
        id: 2671,
        code: '511525',
        title: '高县',
        p_code: '511500'
      },
      {
        id: 2672,
        code: '511526',
        title: '珙县',
        p_code: '511500'
      },
      {
        id: 2673,
        code: '511527',
        title: '筠连县',
        p_code: '511500'
      },
      {
        id: 2674,
        code: '511528',
        title: '兴文县',
        p_code: '511500'
      },
      {
        id: 2675,
        code: '511529',
        title: '屏山县',
        p_code: '511500'
      },
      {
        id: 2676,
        code: '511600',
        title: '广安市',
        p_code: '510000'
      },
      {
        id: 2677,
        code: '511601',
        title: '市辖区',
        p_code: '511600'
      },
      {
        id: 2678,
        code: '511602',
        title: '广安区',
        p_code: '511601'
      },
      {
        id: 2679,
        code: '511621',
        title: '岳池县',
        p_code: '511600'
      },
      {
        id: 2680,
        code: '511622',
        title: '武胜县',
        p_code: '511600'
      },
      {
        id: 2681,
        code: '511623',
        title: '邻水县',
        p_code: '511600'
      },
      {
        id: 2682,
        code: '511681',
        title: '华蓥市',
        p_code: '511600'
      },
      {
        id: 2683,
        code: '511682',
        title: '广安区',
        p_code: '511600'
      },
      {
        id: 2684,
        code: '511700',
        title: '达州市',
        p_code: '510000'
      },
      {
        id: 2685,
        code: '511701',
        title: '市辖区',
        p_code: '511700'
      },
      {
        id: 2686,
        code: '511702',
        title: '通川区',
        p_code: '511701'
      },
      {
        id: 2687,
        code: '511721',
        title: '达川区',
        p_code: '511700'
      },
      {
        id: 2688,
        code: '511722',
        title: '宣汉县',
        p_code: '511700'
      },
      {
        id: 2689,
        code: '511723',
        title: '开江县',
        p_code: '511700'
      },
      {
        id: 2690,
        code: '511724',
        title: '大竹县',
        p_code: '511700'
      },
      {
        id: 2691,
        code: '511725',
        title: '渠县',
        p_code: '511700'
      },
      {
        id: 2692,
        code: '511781',
        title: '万源市',
        p_code: '511700'
      },
      {
        id: 2693,
        code: '511800',
        title: '雅安市',
        p_code: '510000'
      },
      {
        id: 2694,
        code: '511801',
        title: '雨城区',
        p_code: '511800'
      },
      {
        id: 2695,
        code: '511802',
        title: '雨城区',
        p_code: '511801'
      },
      {
        id: 2696,
        code: '511821',
        title: '名山区',
        p_code: '511800'
      },
      {
        id: 2697,
        code: '511822',
        title: '荥经县',
        p_code: '511800'
      },
      {
        id: 2698,
        code: '511823',
        title: '汉源县',
        p_code: '511800'
      },
      {
        id: 2699,
        code: '511824',
        title: '石棉县',
        p_code: '511800'
      },
      {
        id: 2700,
        code: '511825',
        title: '天全县',
        p_code: '511800'
      },
      {
        id: 2701,
        code: '511826',
        title: '芦山县',
        p_code: '511800'
      },
      {
        id: 2702,
        code: '511827',
        title: '宝兴县',
        p_code: '511800'
      },
      {
        id: 2703,
        code: '511900',
        title: '巴中市',
        p_code: '510000'
      },
      {
        id: 2704,
        code: '511901',
        title: '市辖区',
        p_code: '511900'
      },
      {
        id: 2705,
        code: '511902',
        title: '巴州区',
        p_code: '511901'
      },
      {
        id: 2706,
        code: '511921',
        title: '通江县',
        p_code: '511900'
      },
      {
        id: 2707,
        code: '511922',
        title: '南江县',
        p_code: '511900'
      },
      {
        id: 2708,
        code: '511923',
        title: '平昌县',
        p_code: '511900'
      },
      {
        id: 2709,
        code: '512000',
        title: '资阳市',
        p_code: '510000'
      },
      {
        id: 2710,
        code: '512001',
        title: '市辖区',
        p_code: '512000'
      },
      {
        id: 2711,
        code: '512002',
        title: '雁江区',
        p_code: '512001'
      },
      {
        id: 2712,
        code: '512021',
        title: '安岳县',
        p_code: '512000'
      },
      {
        id: 2713,
        code: '512022',
        title: '乐至县',
        p_code: '512000'
      },
      {
        id: 2714,
        code: '512081',
        title: '简阳市',
        p_code: '512000'
      },
      {
        id: 2715,
        code: '513200',
        title: '阿坝藏族羌族自治州',
        p_code: '510000'
      },
      {
        id: 2716,
        code: '513221',
        title: '汶川县',
        p_code: '513200'
      },
      {
        id: 2717,
        code: '513222',
        title: '理县',
        p_code: '513200'
      },
      {
        id: 2718,
        code: '513223',
        title: '茂县',
        p_code: '513200'
      },
      {
        id: 2719,
        code: '513224',
        title: '松潘县',
        p_code: '513200'
      },
      {
        id: 2720,
        code: '513225',
        title: '九寨沟县',
        p_code: '513200'
      },
      {
        id: 2721,
        code: '513226',
        title: '金川县',
        p_code: '513200'
      },
      {
        id: 2722,
        code: '513227',
        title: '小金县',
        p_code: '513200'
      },
      {
        id: 2723,
        code: '513228',
        title: '黑水县',
        p_code: '513200'
      },
      {
        id: 2724,
        code: '513229',
        title: '马尔康县',
        p_code: '513200'
      },
      {
        id: 2725,
        code: '513230',
        title: '壤塘县',
        p_code: '513200'
      },
      {
        id: 2726,
        code: '513231',
        title: '阿坝县',
        p_code: '513200'
      },
      {
        id: 2727,
        code: '513232',
        title: '若尔盖县',
        p_code: '513200'
      },
      {
        id: 2728,
        code: '513233',
        title: '红原县',
        p_code: '513200'
      },
      {
        id: 2729,
        code: '513300',
        title: '甘孜藏族自治州',
        p_code: '510000'
      },
      {
        id: 2730,
        code: '513321',
        title: '康定县',
        p_code: '513300'
      },
      {
        id: 2731,
        code: '513322',
        title: '泸定县',
        p_code: '513300'
      },
      {
        id: 2732,
        code: '513323',
        title: '丹巴县',
        p_code: '513300'
      },
      {
        id: 2733,
        code: '513324',
        title: '九龙县',
        p_code: '513300'
      },
      {
        id: 2734,
        code: '513325',
        title: '雅江县',
        p_code: '513300'
      },
      {
        id: 2735,
        code: '513326',
        title: '道孚县',
        p_code: '513300'
      },
      {
        id: 2736,
        code: '513327',
        title: '炉霍县',
        p_code: '513300'
      },
      {
        id: 2737,
        code: '513328',
        title: '甘孜县',
        p_code: '513300'
      },
      {
        id: 2738,
        code: '513329',
        title: '新龙县',
        p_code: '513300'
      },
      {
        id: 2739,
        code: '513330',
        title: '德格县',
        p_code: '513300'
      },
      {
        id: 2740,
        code: '513331',
        title: '白玉县',
        p_code: '513300'
      },
      {
        id: 2741,
        code: '513332',
        title: '石渠县',
        p_code: '513300'
      },
      {
        id: 2742,
        code: '513333',
        title: '色达县',
        p_code: '513300'
      },
      {
        id: 2743,
        code: '513334',
        title: '理塘县',
        p_code: '513300'
      },
      {
        id: 2744,
        code: '513335',
        title: '巴塘县',
        p_code: '513300'
      },
      {
        id: 2745,
        code: '513336',
        title: '乡城县',
        p_code: '513300'
      },
      {
        id: 2746,
        code: '513337',
        title: '稻城县',
        p_code: '513300'
      },
      {
        id: 2747,
        code: '513338',
        title: '得荣县',
        p_code: '513300'
      },
      {
        id: 2748,
        code: '513400',
        title: '凉山彝族自治州',
        p_code: '510000'
      },
      {
        id: 2749,
        code: '513401',
        title: '西昌市',
        p_code: '513400'
      },
      {
        id: 2750,
        code: '513422',
        title: '木里藏族自治县',
        p_code: '513400'
      },
      {
        id: 2751,
        code: '513423',
        title: '盐源县',
        p_code: '513400'
      },
      {
        id: 2752,
        code: '513424',
        title: '德昌县',
        p_code: '513400'
      },
      {
        id: 2753,
        code: '513425',
        title: '会理县',
        p_code: '513400'
      },
      {
        id: 2754,
        code: '513426',
        title: '会东县',
        p_code: '513400'
      },
      {
        id: 2755,
        code: '513427',
        title: '宁南县',
        p_code: '513400'
      },
      {
        id: 2756,
        code: '513428',
        title: '普格县',
        p_code: '513400'
      },
      {
        id: 2757,
        code: '513429',
        title: '布拖县',
        p_code: '513400'
      },
      {
        id: 2758,
        code: '513430',
        title: '金阳县',
        p_code: '513400'
      },
      {
        id: 2759,
        code: '513431',
        title: '昭觉县',
        p_code: '513400'
      },
      {
        id: 2760,
        code: '513432',
        title: '喜德县',
        p_code: '513400'
      },
      {
        id: 2761,
        code: '513433',
        title: '冕宁县',
        p_code: '513400'
      },
      {
        id: 2762,
        code: '513434',
        title: '越西县',
        p_code: '513400'
      },
      {
        id: 2763,
        code: '513435',
        title: '甘洛县',
        p_code: '513400'
      },
      {
        id: 2764,
        code: '513436',
        title: '美姑县',
        p_code: '513400'
      },
      {
        id: 2765,
        code: '513437',
        title: '雷波县',
        p_code: '513400'
      },

      {
        id: 2767,
        code: '520100',
        title: '贵阳市',
        p_code: '520000'
      },
      {
        id: 2768,
        code: '520101',
        title: '市辖区',
        p_code: '520100'
      },
      {
        id: 2769,
        code: '520102',
        title: '南明区',
        p_code: '520101'
      },
      {
        id: 2770,
        code: '520103',
        title: '云岩区',
        p_code: '520101'
      },
      {
        id: 2771,
        code: '520111',
        title: '花溪区',
        p_code: '520101'
      },
      {
        id: 2772,
        code: '520112',
        title: '乌当区',
        p_code: '520100'
      },
      {
        id: 2773,
        code: '520113',
        title: '白云区',
        p_code: '520101'
      },
      {
        id: 2774,
        code: '520114',
        title: '小河区',
        p_code: '520101'
      },
      {
        id: 2775,
        code: '520121',
        title: '开阳县',
        p_code: '520100'
      },
      {
        id: 2776,
        code: '520122',
        title: '息烽县',
        p_code: '520100'
      },
      {
        id: 2777,
        code: '520123',
        title: '修文县',
        p_code: '520100'
      },
      {
        id: 2778,
        code: '520181',
        title: '清镇市',
        p_code: '520100'
      },
      {
        id: 2779,
        code: '520200',
        title: '六盘水市',
        p_code: '520000'
      },
      {
        id: 2780,
        code: '520201',
        title: '钟山区',
        p_code: '520200'
      },
      {
        id: 2781,
        code: '520203',
        title: '六枝特区',
        p_code: '520200'
      },
      {
        id: 2782,
        code: '520221',
        title: '水城县',
        p_code: '520200'
      },
      {
        id: 2783,
        code: '520222',
        title: '盘县',
        p_code: '520200'
      },
      {
        id: 2784,
        code: '520300',
        title: '遵义市',
        p_code: '520000'
      },
      {
        id: 2785,
        code: '520301',
        title: '市辖区',
        p_code: '520300'
      },
      {
        id: 2786,
        code: '520302',
        title: '红花岗区',
        p_code: '520301'
      },
      {
        id: 2787,
        code: '520303',
        title: '汇川区',
        p_code: '520301'
      },
      {
        id: 2788,
        code: '520321',
        title: '遵义县',
        p_code: '520300'
      },
      {
        id: 2789,
        code: '520322',
        title: '桐梓县',
        p_code: '520300'
      },
      {
        id: 2790,
        code: '520323',
        title: '绥阳县',
        p_code: '520300'
      },
      {
        id: 2791,
        code: '520324',
        title: '正安县',
        p_code: '520300'
      },
      {
        id: 2792,
        code: '520325',
        title: '道真仡佬族苗族自治县',
        p_code: '520300'
      },
      {
        id: 2793,
        code: '520326',
        title: '务川仡佬族苗族自治县',
        p_code: '520300'
      },
      {
        id: 2794,
        code: '520327',
        title: '凤冈县',
        p_code: '520300'
      },
      {
        id: 2795,
        code: '520328',
        title: '湄潭县',
        p_code: '520300'
      },
      {
        id: 2796,
        code: '520329',
        title: '余庆县',
        p_code: '520300'
      },
      {
        id: 2797,
        code: '520330',
        title: '习水县',
        p_code: '520300'
      },
      {
        id: 2798,
        code: '520381',
        title: '赤水市',
        p_code: '520300'
      },
      {
        id: 2799,
        code: '520382',
        title: '仁怀市',
        p_code: '520300'
      },
      {
        id: 2800,
        code: '520400',
        title: '安顺市',
        p_code: '520000'
      },
      {
        id: 2801,
        code: '520401',
        title: '市辖区',
        p_code: '520400'
      },
      {
        id: 2802,
        code: '520402',
        title: '西秀区',
        p_code: '520401'
      },
      {
        id: 2803,
        code: '520421',
        title: '平坝县',
        p_code: '520400'
      },
      {
        id: 2804,
        code: '520422',
        title: '普定县',
        p_code: '520400'
      },
      {
        id: 2805,
        code: '520423',
        title: '镇宁布依族苗族自治县',
        p_code: '520400'
      },
      {
        id: 2806,
        code: '520424',
        title: '关岭布依族苗族自治县',
        p_code: '520400'
      },
      {
        id: 2807,
        code: '520425',
        title: '紫云苗族布依族自治县',
        p_code: '520400'
      },
      {
        id: 2808,
        code: '522200',
        title: '铜仁市',
        p_code: '520000'
      },
      {
        id: 2809,
        code: '522201',
        title: '碧江区',
        p_code: '522200'
      },
      {
        id: 2810,
        code: '522222',
        title: '江口县',
        p_code: '522200'
      },
      {
        id: 2811,
        code: '522223',
        title: '玉屏侗族自治县',
        p_code: '522200'
      },
      {
        id: 2812,
        code: '522224',
        title: '石阡县',
        p_code: '522200'
      },
      {
        id: 2813,
        code: '522225',
        title: '思南县',
        p_code: '522200'
      },
      {
        id: 2814,
        code: '522226',
        title: '印江土家族苗族自治县',
        p_code: '522200'
      },
      {
        id: 2815,
        code: '522227',
        title: '德江县',
        p_code: '522200'
      },
      {
        id: 2816,
        code: '522228',
        title: '沿河土家族自治县',
        p_code: '522200'
      },
      {
        id: 2817,
        code: '522229',
        title: '松桃苗族自治县',
        p_code: '522200'
      },
      {
        id: 2818,
        code: '522230',
        title: '万山区',
        p_code: '522200'
      },
      {
        id: 2819,
        code: '522300',
        title: '黔西南布依族苗族自治州',
        p_code: '520000'
      },
      {
        id: 2820,
        code: '522301',
        title: '兴义市',
        p_code: '522300'
      },
      {
        id: 2821,
        code: '522322',
        title: '兴仁县',
        p_code: '522300'
      },
      {
        id: 2822,
        code: '522323',
        title: '普安县',
        p_code: '522300'
      },
      {
        id: 2823,
        code: '522324',
        title: '晴隆县',
        p_code: '522300'
      },
      {
        id: 2824,
        code: '522325',
        title: '贞丰县',
        p_code: '522300'
      },
      {
        id: 2825,
        code: '522326',
        title: '望谟县',
        p_code: '522300'
      },
      {
        id: 2826,
        code: '522327',
        title: '册亨县',
        p_code: '522300'
      },
      {
        id: 2827,
        code: '522328',
        title: '安龙县',
        p_code: '522300'
      },
      {
        id: 2828,
        code: '522400',
        title: '毕节市',
        p_code: '520000'
      },
      {
        id: 2829,
        code: '522401',
        title: '七星关区',
        p_code: '522400'
      },
      {
        id: 2830,
        code: '522422',
        title: '大方县',
        p_code: '522400'
      },
      {
        id: 2831,
        code: '522423',
        title: '黔西县',
        p_code: '522400'
      },
      {
        id: 2832,
        code: '522424',
        title: '金沙县',
        p_code: '522400'
      },
      {
        id: 2833,
        code: '522425',
        title: '织金县',
        p_code: '522400'
      },
      {
        id: 2834,
        code: '522426',
        title: '纳雍县',
        p_code: '522400'
      },
      {
        id: 2835,
        code: '522427',
        title: '威宁彝族回族苗族自治县',
        p_code: '522400'
      },
      {
        id: 2836,
        code: '522428',
        title: '赫章县',
        p_code: '522400'
      },
      {
        id: 2837,
        code: '522600',
        title: '黔东南苗族侗族自治州',
        p_code: '520000'
      },
      {
        id: 2838,
        code: '522601',
        title: '凯里市',
        p_code: '522600'
      },
      {
        id: 2839,
        code: '522622',
        title: '黄平县',
        p_code: '522600'
      },
      {
        id: 2840,
        code: '522623',
        title: '施秉县',
        p_code: '522600'
      },
      {
        id: 2841,
        code: '522624',
        title: '三穗县',
        p_code: '522600'
      },
      {
        id: 2842,
        code: '522625',
        title: '镇远县',
        p_code: '522600'
      },
      {
        id: 2843,
        code: '522626',
        title: '岑巩县',
        p_code: '522600'
      },
      {
        id: 2844,
        code: '522627',
        title: '天柱县',
        p_code: '522600'
      },
      {
        id: 2845,
        code: '522628',
        title: '锦屏县',
        p_code: '522600'
      },
      {
        id: 2846,
        code: '522629',
        title: '剑河县',
        p_code: '522600'
      },
      {
        id: 2847,
        code: '522630',
        title: '台江县',
        p_code: '522600'
      },
      {
        id: 2848,
        code: '522631',
        title: '黎平县',
        p_code: '522600'
      },
      {
        id: 2849,
        code: '522632',
        title: '榕江县',
        p_code: '522600'
      },
      {
        id: 2850,
        code: '522633',
        title: '从江县',
        p_code: '522600'
      },
      {
        id: 2851,
        code: '522634',
        title: '雷山县',
        p_code: '522600'
      },
      {
        id: 2852,
        code: '522635',
        title: '麻江县',
        p_code: '522600'
      },
      {
        id: 2853,
        code: '522636',
        title: '丹寨县',
        p_code: '522600'
      },
      {
        id: 2854,
        code: '522700',
        title: '黔南布依族苗族自治州',
        p_code: '520000'
      },
      {
        id: 2855,
        code: '522701',
        title: '都匀市',
        p_code: '522700'
      },
      {
        id: 2856,
        code: '522702',
        title: '福泉市',
        p_code: '522700'
      },
      {
        id: 2857,
        code: '522722',
        title: '荔波县',
        p_code: '522700'
      },
      {
        id: 2858,
        code: '522723',
        title: '贵定县',
        p_code: '522700'
      },
      {
        id: 2859,
        code: '522725',
        title: '瓮安县',
        p_code: '522700'
      },
      {
        id: 2860,
        code: '522726',
        title: '独山县',
        p_code: '522700'
      },
      {
        id: 2861,
        code: '522727',
        title: '平塘县',
        p_code: '522700'
      },
      {
        id: 2862,
        code: '522728',
        title: '罗甸县',
        p_code: '522700'
      },
      {
        id: 2863,
        code: '522729',
        title: '长顺县',
        p_code: '522700'
      },
      {
        id: 2864,
        code: '522730',
        title: '龙里县',
        p_code: '522700'
      },
      {
        id: 2865,
        code: '522731',
        title: '惠水县',
        p_code: '522700'
      },
      {
        id: 2866,
        code: '522732',
        title: '三都水族自治县',
        p_code: '522700'
      },

      {
        id: 2868,
        code: '530100',
        title: '昆明市',
        p_code: '530000'
      },
      {
        id: 2869,
        code: '530101',
        title: '市辖区',
        p_code: '530100'
      },
      {
        id: 2870,
        code: '530102',
        title: '五华区',
        p_code: '530101'
      },
      {
        id: 2871,
        code: '530103',
        title: '盘龙区',
        p_code: '530101'
      },
      {
        id: 2872,
        code: '530111',
        title: '官渡区',
        p_code: '530101'
      },
      {
        id: 2873,
        code: '530112',
        title: '西山区',
        p_code: '530101'
      },
      {
        id: 2874,
        code: '530113',
        title: '东川区',
        p_code: '530101'
      },
      {
        id: 2875,
        code: '530121',
        title: '呈贡县',
        p_code: '530100'
      },
      {
        id: 2876,
        code: '530122',
        title: '晋宁县',
        p_code: '530100'
      },
      {
        id: 2877,
        code: '530124',
        title: '富民县',
        p_code: '530100'
      },
      {
        id: 2878,
        code: '530125',
        title: '宜良县',
        p_code: '530100'
      },
      {
        id: 2879,
        code: '530126',
        title: '石林彝族自治县',
        p_code: '530100'
      },
      {
        id: 2880,
        code: '530127',
        title: '嵩明县',
        p_code: '530100'
      },
      {
        id: 2881,
        code: '530128',
        title: '禄劝彝族苗族自治县',
        p_code: '530100'
      },
      {
        id: 2882,
        code: '530129',
        title: '寻甸回族彝族自治县',
        p_code: '530100'
      },
      {
        id: 2883,
        code: '530181',
        title: '安宁市',
        p_code: '530100'
      },
      {
        id: 2884,
        code: '530300',
        title: '曲靖市',
        p_code: '530000'
      },
      {
        id: 2885,
        code: '530301',
        title: '市辖区',
        p_code: '530300'
      },
      {
        id: 2886,
        code: '530302',
        title: '麒麟区',
        p_code: '530301'
      },
      {
        id: 2887,
        code: '530321',
        title: '马龙县',
        p_code: '530300'
      },
      {
        id: 2888,
        code: '530322',
        title: '陆良县',
        p_code: '530300'
      },
      {
        id: 2889,
        code: '530323',
        title: '师宗县',
        p_code: '530300'
      },
      {
        id: 2890,
        code: '530324',
        title: '罗平县',
        p_code: '530300'
      },
      {
        id: 2891,
        code: '530325',
        title: '富源县',
        p_code: '530300'
      },
      {
        id: 2892,
        code: '530326',
        title: '会泽县',
        p_code: '530300'
      },
      {
        id: 2893,
        code: '530328',
        title: '沾益县',
        p_code: '530300'
      },
      {
        id: 2894,
        code: '530381',
        title: '宣威市',
        p_code: '530300'
      },
      {
        id: 2895,
        code: '530400',
        title: '玉溪市',
        p_code: '530000'
      },
      {
        id: 2896,
        code: '530401',
        title: '市辖区',
        p_code: '530400'
      },
      {
        id: 2897,
        code: '530402',
        title: '红塔区',
        p_code: '530401'
      },
      {
        id: 2898,
        code: '530421',
        title: '江川县',
        p_code: '530400'
      },
      {
        id: 2899,
        code: '530422',
        title: '澄江县',
        p_code: '530400'
      },
      {
        id: 2900,
        code: '530423',
        title: '通海县',
        p_code: '530400'
      },
      {
        id: 2901,
        code: '530424',
        title: '华宁县',
        p_code: '530400'
      },
      {
        id: 2902,
        code: '530425',
        title: '易门县',
        p_code: '530400'
      },
      {
        id: 2903,
        code: '530426',
        title: '峨山彝族自治县',
        p_code: '530400'
      },
      {
        id: 2904,
        code: '530427',
        title: '新平彝族傣族自治县',
        p_code: '530400'
      },
      {
        id: 2905,
        code: '530428',
        title: '元江哈尼族彝族傣族自治县',
        p_code: '530400'
      },
      {
        id: 2906,
        code: '530500',
        title: '保山市',
        p_code: '530000'
      },
      {
        id: 2907,
        code: '530501',
        title: '市辖区',
        p_code: '530500'
      },
      {
        id: 2908,
        code: '530502',
        title: '隆阳区',
        p_code: '530500'
      },
      {
        id: 2909,
        code: '530521',
        title: '施甸县',
        p_code: '530500'
      },
      {
        id: 2910,
        code: '530522',
        title: '腾冲县',
        p_code: '530500'
      },
      {
        id: 2911,
        code: '530523',
        title: '龙陵县',
        p_code: '530500'
      },
      {
        id: 2912,
        code: '530524',
        title: '昌宁县',
        p_code: '530500'
      },
      {
        id: 2913,
        code: '530600',
        title: '昭通市',
        p_code: '530000'
      },
      {
        id: 2914,
        code: '530601',
        title: '市辖区',
        p_code: '530600'
      },
      {
        id: 2915,
        code: '530602',
        title: '昭阳区',
        p_code: '530601'
      },
      {
        id: 2916,
        code: '530621',
        title: '鲁甸县',
        p_code: '530600'
      },
      {
        id: 2917,
        code: '530622',
        title: '巧家县',
        p_code: '530600'
      },
      {
        id: 2918,
        code: '530623',
        title: '盐津县',
        p_code: '530600'
      },
      {
        id: 2919,
        code: '530624',
        title: '大关县',
        p_code: '530600'
      },
      {
        id: 2920,
        code: '530625',
        title: '永善县',
        p_code: '530600'
      },
      {
        id: 2921,
        code: '530626',
        title: '绥江县',
        p_code: '530600'
      },
      {
        id: 2922,
        code: '530627',
        title: '镇雄县',
        p_code: '530600'
      },
      {
        id: 2923,
        code: '530628',
        title: '彝良县',
        p_code: '530600'
      },
      {
        id: 2924,
        code: '530629',
        title: '威信县',
        p_code: '530600'
      },
      {
        id: 2925,
        code: '530630',
        title: '水富县',
        p_code: '530600'
      },
      {
        id: 2926,
        code: '530700',
        title: '丽江市',
        p_code: '530000'
      },
      {
        id: 2927,
        code: '530701',
        title: '市辖区',
        p_code: '530700'
      },
      {
        id: 2928,
        code: '530702',
        title: '古城区',
        p_code: '530701'
      },
      {
        id: 2929,
        code: '530721',
        title: '玉龙纳西族自治县',
        p_code: '530700'
      },
      {
        id: 2930,
        code: '530722',
        title: '永胜县',
        p_code: '530700'
      },
      {
        id: 2931,
        code: '530723',
        title: '华坪县',
        p_code: '530700'
      },
      {
        id: 2932,
        code: '530724',
        title: '宁蒗彝族自治县',
        p_code: '530700'
      },
      {
        id: 2933,
        code: '530800',
        title: '普洱市',
        p_code: '530000'
      },
      {
        id: 2934,
        code: '530801',
        title: '市辖区',
        p_code: '530800'
      },
      {
        id: 2935,
        code: '530802',
        title: '翠云区',
        p_code: '530801'
      },
      {
        id: 2936,
        code: '530821',
        title: '宁洱哈尼族彝族自治县',
        p_code: '530800'
      },
      {
        id: 2937,
        code: '530822',
        title: '墨江哈尼族自治县',
        p_code: '530800'
      },
      {
        id: 2938,
        code: '530823',
        title: '景东彝族自治县',
        p_code: '530800'
      },
      {
        id: 2939,
        code: '530824',
        title: '景谷傣族彝族自治县',
        p_code: '530800'
      },
      {
        id: 2940,
        code: '530825',
        title: '镇沅彝族哈尼族拉祜族自治县',
        p_code: '530800'
      },
      {
        id: 2941,
        code: '530826',
        title: '江城哈尼族彝族自治县',
        p_code: '530800'
      },
      {
        id: 2942,
        code: '530827',
        title: '孟连傣族拉祜族佤族自治县',
        p_code: '530800'
      },
      {
        id: 2943,
        code: '530828',
        title: '澜沧拉祜族自治县',
        p_code: '530800'
      },
      {
        id: 2944,
        code: '530829',
        title: '西盟佤族自治县',
        p_code: '530800'
      },
      {
        id: 2945,
        code: '530900',
        title: '临沧市',
        p_code: '530000'
      },
      {
        id: 2946,
        code: '530901',
        title: '市辖区',
        p_code: '530900'
      },
      {
        id: 2947,
        code: '530902',
        title: '临翔区',
        p_code: '530901'
      },
      {
        id: 2948,
        code: '530921',
        title: '凤庆县',
        p_code: '530900'
      },
      {
        id: 2949,
        code: '530922',
        title: '云县',
        p_code: '530900'
      },
      {
        id: 2950,
        code: '530923',
        title: '永德县',
        p_code: '530900'
      },
      {
        id: 2951,
        code: '530924',
        title: '镇康县',
        p_code: '530900'
      },
      {
        id: 2952,
        code: '530925',
        title: '双江拉祜族佤族布朗族傣族自治县',
        p_code: '530900'
      },
      {
        id: 2953,
        code: '530926',
        title: '耿马傣族佤族自治县',
        p_code: '530900'
      },
      {
        id: 2954,
        code: '530927',
        title: '沧源佤族自治县',
        p_code: '530900'
      },
      {
        id: 2955,
        code: '532300',
        title: '楚雄彝族自治州',
        p_code: '530000'
      },
      {
        id: 2956,
        code: '532301',
        title: '楚雄市',
        p_code: '532300'
      },
      {
        id: 2957,
        code: '532322',
        title: '双柏县',
        p_code: '532300'
      },
      {
        id: 2958,
        code: '532323',
        title: '牟定县',
        p_code: '532300'
      },
      {
        id: 2959,
        code: '532324',
        title: '南华县',
        p_code: '532300'
      },
      {
        id: 2960,
        code: '532325',
        title: '姚安县',
        p_code: '532300'
      },
      {
        id: 2961,
        code: '532326',
        title: '大姚县',
        p_code: '532300'
      },
      {
        id: 2962,
        code: '532327',
        title: '永仁县',
        p_code: '532300'
      },
      {
        id: 2963,
        code: '532328',
        title: '元谋县',
        p_code: '532300'
      },
      {
        id: 2964,
        code: '532329',
        title: '武定县',
        p_code: '532300'
      },
      {
        id: 2965,
        code: '532331',
        title: '禄丰县',
        p_code: '532300'
      },
      {
        id: 2966,
        code: '532500',
        title: '红河哈尼族彝族自治州',
        p_code: '530000'
      },
      {
        id: 2967,
        code: '532501',
        title: '个旧市',
        p_code: '532500'
      },
      {
        id: 2968,
        code: '532502',
        title: '开远市',
        p_code: '532500'
      },
      {
        id: 2969,
        code: '532522',
        title: '蒙自市',
        p_code: '532500'
      },
      {
        id: 2970,
        code: '532523',
        title: '屏边苗族自治县',
        p_code: '532500'
      },
      {
        id: 2971,
        code: '532524',
        title: '建水县',
        p_code: '532500'
      },
      {
        id: 2972,
        code: '532525',
        title: '石屏县',
        p_code: '532500'
      },
      {
        id: 2973,
        code: '532526',
        title: '弥勒市',
        p_code: '532500'
      },
      {
        id: 2974,
        code: '532527',
        title: '泸西县',
        p_code: '532500'
      },
      {
        id: 2975,
        code: '532528',
        title: '元阳县',
        p_code: '532500'
      },
      {
        id: 2976,
        code: '532529',
        title: '红河县',
        p_code: '532500'
      },
      {
        id: 2977,
        code: '532530',
        title: '金平苗族瑶族傣族自治县',
        p_code: '532500'
      },
      {
        id: 2978,
        code: '532531',
        title: '绿春县',
        p_code: '532500'
      },
      {
        id: 2979,
        code: '532532',
        title: '河口瑶族自治县',
        p_code: '532500'
      },
      {
        id: 2980,
        code: '532600',
        title: '文山壮族苗族自治州',
        p_code: '530000'
      },
      {
        id: 2981,
        code: '532621',
        title: '文山市',
        p_code: '532600'
      },
      {
        id: 2982,
        code: '532622',
        title: '砚山县',
        p_code: '532600'
      },
      {
        id: 2983,
        code: '532623',
        title: '西畴县',
        p_code: '532600'
      },
      {
        id: 2984,
        code: '532624',
        title: '麻栗坡县',
        p_code: '532600'
      },
      {
        id: 2985,
        code: '532625',
        title: '马关县',
        p_code: '532600'
      },
      {
        id: 2986,
        code: '532626',
        title: '丘北县',
        p_code: '532600'
      },
      {
        id: 2987,
        code: '532627',
        title: '广南县',
        p_code: '532600'
      },
      {
        id: 2988,
        code: '532628',
        title: '富宁县',
        p_code: '532600'
      },
      {
        id: 2989,
        code: '532800',
        title: '西双版纳傣族自治州',
        p_code: '530000'
      },
      {
        id: 2990,
        code: '532801',
        title: '景洪市',
        p_code: '532800'
      },
      {
        id: 2991,
        code: '532822',
        title: '勐海县',
        p_code: '532800'
      },
      {
        id: 2992,
        code: '532823',
        title: '勐腊县',
        p_code: '532800'
      },
      {
        id: 2993,
        code: '532900',
        title: '大理白族自治州',
        p_code: '530000'
      },
      {
        id: 2994,
        code: '532901',
        title: '大理市',
        p_code: '532900'
      },
      {
        id: 2995,
        code: '532922',
        title: '漾濞彝族自治县',
        p_code: '532900'
      },
      {
        id: 2996,
        code: '532923',
        title: '祥云县',
        p_code: '532900'
      },
      {
        id: 2997,
        code: '532924',
        title: '宾川县',
        p_code: '532900'
      },
      {
        id: 2998,
        code: '532925',
        title: '弥渡县',
        p_code: '532900'
      },
      {
        id: 2999,
        code: '532926',
        title: '南涧彝族自治县',
        p_code: '532900'
      },
      {
        id: 3000,
        code: '532927',
        title: '巍山彝族回族自治县',
        p_code: '532900'
      },
      {
        id: 3001,
        code: '532928',
        title: '永平县',
        p_code: '532900'
      },
      {
        id: 3002,
        code: '532929',
        title: '云龙县',
        p_code: '532900'
      },
      {
        id: 3003,
        code: '532930',
        title: '洱源县',
        p_code: '532900'
      },
      {
        id: 3004,
        code: '532931',
        title: '剑川县',
        p_code: '532900'
      },
      {
        id: 3005,
        code: '532932',
        title: '鹤庆县',
        p_code: '532900'
      },
      {
        id: 3006,
        code: '533100',
        title: '德宏傣族景颇族自治州',
        p_code: '530000'
      },
      {
        id: 3007,
        code: '533102',
        title: '瑞丽市',
        p_code: '533100'
      },
      {
        id: 3008,
        code: '533103',
        title: '潞西市',
        p_code: '533100'
      },
      {
        id: 3009,
        code: '533122',
        title: '梁河县',
        p_code: '533100'
      },
      {
        id: 3010,
        code: '533123',
        title: '盈江县',
        p_code: '533100'
      },
      {
        id: 3011,
        code: '533124',
        title: '陇川县',
        p_code: '533100'
      },
      {
        id: 3012,
        code: '533300',
        title: '怒江傈僳族自治州',
        p_code: '530000'
      },
      {
        id: 3013,
        code: '533321',
        title: '泸水县',
        p_code: '533300'
      },
      {
        id: 3014,
        code: '533323',
        title: '福贡县',
        p_code: '533300'
      },
      {
        id: 3015,
        code: '533324',
        title: '贡山独龙族怒族自治县',
        p_code: '533300'
      },
      {
        id: 3016,
        code: '533325',
        title: '兰坪白族普米族自治县',
        p_code: '533300'
      },
      {
        id: 3017,
        code: '533400',
        title: '迪庆藏族自治州',
        p_code: '530000'
      },
      {
        id: 3018,
        code: '533421',
        title: '香格里拉县',
        p_code: '533400'
      },
      {
        id: 3019,
        code: '533422',
        title: '德钦县',
        p_code: '533400'
      },
      {
        id: 3020,
        code: '533423',
        title: '维西傈僳族自治县',
        p_code: '533400'
      },

      {
        id: 3022,
        code: '540100',
        title: '拉萨市',
        p_code: '540000'
      },
      {
        id: 3023,
        code: '540101',
        title: '市辖区',
        p_code: '540100'
      },
      {
        id: 3024,
        code: '540102',
        title: '城关区',
        p_code: '540101'
      },
      {
        id: 3025,
        code: '540121',
        title: '林周县',
        p_code: '540100'
      },
      {
        id: 3026,
        code: '540122',
        title: '当雄县',
        p_code: '540100'
      },
      {
        id: 3027,
        code: '540123',
        title: '尼木县',
        p_code: '540100'
      },
      {
        id: 3028,
        code: '540124',
        title: '曲水县',
        p_code: '540100'
      },
      {
        id: 3029,
        code: '540125',
        title: '堆龙德庆县',
        p_code: '540100'
      },
      {
        id: 3030,
        code: '540126',
        title: '达孜县',
        p_code: '540100'
      },
      {
        id: 3031,
        code: '540127',
        title: '墨竹工卡县',
        p_code: '540100'
      },
      {
        id: 3032,
        code: '542100',
        title: '昌都地区',
        p_code: '540000'
      },
      {
        id: 3033,
        code: '542121',
        title: '昌都县',
        p_code: '542100'
      },
      {
        id: 3034,
        code: '542122',
        title: '江达县',
        p_code: '542100'
      },
      {
        id: 3035,
        code: '542123',
        title: '贡觉县',
        p_code: '542100'
      },
      {
        id: 3036,
        code: '542124',
        title: '类乌齐县',
        p_code: '542100'
      },
      {
        id: 3037,
        code: '542125',
        title: '丁青县',
        p_code: '542100'
      },
      {
        id: 3038,
        code: '542126',
        title: '察雅县',
        p_code: '542100'
      },
      {
        id: 3039,
        code: '542127',
        title: '八宿县',
        p_code: '542100'
      },
      {
        id: 3040,
        code: '542128',
        title: '左贡县',
        p_code: '542100'
      },
      {
        id: 3041,
        code: '542129',
        title: '芒康县',
        p_code: '542100'
      },
      {
        id: 3042,
        code: '542132',
        title: '洛隆县',
        p_code: '542100'
      },
      {
        id: 3043,
        code: '542133',
        title: '边坝县',
        p_code: '542100'
      },
      {
        id: 3044,
        code: '542200',
        title: '山南地区',
        p_code: '540000'
      },
      {
        id: 3045,
        code: '542221',
        title: '乃东县',
        p_code: '542200'
      },
      {
        id: 3046,
        code: '542222',
        title: '扎囊县',
        p_code: '542200'
      },
      {
        id: 3047,
        code: '542223',
        title: '贡嘎县',
        p_code: '542200'
      },
      {
        id: 3048,
        code: '542224',
        title: '桑日县',
        p_code: '542200'
      },
      {
        id: 3049,
        code: '542225',
        title: '琼结县',
        p_code: '542200'
      },
      {
        id: 3050,
        code: '542226',
        title: '曲松县',
        p_code: '542200'
      },
      {
        id: 3051,
        code: '542227',
        title: '措美县',
        p_code: '542200'
      },
      {
        id: 3052,
        code: '542228',
        title: '洛扎县',
        p_code: '542200'
      },
      {
        id: 3053,
        code: '542229',
        title: '加查县',
        p_code: '542200'
      },
      {
        id: 3054,
        code: '542231',
        title: '隆子县',
        p_code: '542200'
      },
      {
        id: 3055,
        code: '542232',
        title: '错那县',
        p_code: '542200'
      },
      {
        id: 3056,
        code: '542233',
        title: '浪卡子县',
        p_code: '542200'
      },
      {
        id: 3057,
        code: '542300',
        title: '日喀则地区',
        p_code: '540000'
      },
      {
        id: 3058,
        code: '542301',
        title: '日喀则市',
        p_code: '542300'
      },
      {
        id: 3059,
        code: '542322',
        title: '南木林县',
        p_code: '542300'
      },
      {
        id: 3060,
        code: '542323',
        title: '江孜县',
        p_code: '542300'
      },
      {
        id: 3061,
        code: '542324',
        title: '定日县',
        p_code: '542300'
      },
      {
        id: 3062,
        code: '542325',
        title: '萨迦县',
        p_code: '542300'
      },
      {
        id: 3063,
        code: '542326',
        title: '拉孜县',
        p_code: '542300'
      },
      {
        id: 3064,
        code: '542327',
        title: '昂仁县',
        p_code: '542300'
      },
      {
        id: 3065,
        code: '542328',
        title: '谢通门县',
        p_code: '542300'
      },
      {
        id: 3066,
        code: '542329',
        title: '白朗县',
        p_code: '542300'
      },
      {
        id: 3067,
        code: '542330',
        title: '仁布县',
        p_code: '542300'
      },
      {
        id: 3068,
        code: '542331',
        title: '康马县',
        p_code: '542300'
      },
      {
        id: 3069,
        code: '542332',
        title: '定结县',
        p_code: '542300'
      },
      {
        id: 3070,
        code: '542333',
        title: '仲巴县',
        p_code: '542300'
      },
      {
        id: 3071,
        code: '542334',
        title: '亚东县',
        p_code: '542300'
      },
      {
        id: 3072,
        code: '542335',
        title: '吉隆县',
        p_code: '542300'
      },
      {
        id: 3073,
        code: '542336',
        title: '聂拉木县',
        p_code: '542300'
      },
      {
        id: 3074,
        code: '542337',
        title: '萨嘎县',
        p_code: '542300'
      },
      {
        id: 3075,
        code: '542338',
        title: '岗巴县',
        p_code: '542300'
      },
      {
        id: 3076,
        code: '542400',
        title: '那曲地区',
        p_code: '540000'
      },
      {
        id: 3077,
        code: '542421',
        title: '那曲县',
        p_code: '542400'
      },
      {
        id: 3078,
        code: '542422',
        title: '嘉黎县',
        p_code: '542400'
      },
      {
        id: 3079,
        code: '542423',
        title: '比如县',
        p_code: '542400'
      },
      {
        id: 3080,
        code: '542424',
        title: '聂荣县',
        p_code: '542400'
      },
      {
        id: 3081,
        code: '542425',
        title: '安多县',
        p_code: '542400'
      },
      {
        id: 3082,
        code: '542426',
        title: '申扎县',
        p_code: '542400'
      },
      {
        id: 3083,
        code: '542427',
        title: '索县',
        p_code: '542400'
      },
      {
        id: 3084,
        code: '542428',
        title: '班戈县',
        p_code: '542400'
      },
      {
        id: 3085,
        code: '542429',
        title: '巴青县',
        p_code: '542400'
      },
      {
        id: 3086,
        code: '542430',
        title: '尼玛县',
        p_code: '542400'
      },
      {
        id: 3087,
        code: '542500',
        title: '阿里地区',
        p_code: '540000'
      },
      {
        id: 3088,
        code: '542521',
        title: '普兰县',
        p_code: '542500'
      },
      {
        id: 3089,
        code: '542522',
        title: '札达县',
        p_code: '542500'
      },
      {
        id: 3090,
        code: '542523',
        title: '噶尔县',
        p_code: '542500'
      },
      {
        id: 3091,
        code: '542524',
        title: '日土县',
        p_code: '542500'
      },
      {
        id: 3092,
        code: '542525',
        title: '革吉县',
        p_code: '542500'
      },
      {
        id: 3093,
        code: '542526',
        title: '改则县',
        p_code: '542500'
      },
      {
        id: 3094,
        code: '542527',
        title: '措勤县',
        p_code: '542500'
      },
      {
        id: 3095,
        code: '542600',
        title: '林芝地区',
        p_code: '540000'
      },
      {
        id: 3096,
        code: '542621',
        title: '林芝县',
        p_code: '542600'
      },
      {
        id: 3097,
        code: '542622',
        title: '工布江达县',
        p_code: '542600'
      },
      {
        id: 3098,
        code: '542623',
        title: '米林县',
        p_code: '542600'
      },
      {
        id: 3099,
        code: '542624',
        title: '墨脱县',
        p_code: '542600'
      },
      {
        id: 3100,
        code: '542625',
        title: '波密县',
        p_code: '542600'
      },
      {
        id: 3101,
        code: '542626',
        title: '察隅县',
        p_code: '542600'
      },
      {
        id: 3102,
        code: '542627',
        title: '朗县',
        p_code: '542600'
      },

      {
        id: 3104,
        code: '610100',
        title: '西安市',
        p_code: '610000'
      },
      {
        id: 3105,
        code: '610101',
        title: '长安区',
        p_code: '610100'
      },
      {
        id: 3106,
        code: '610102',
        title: '新城区',
        p_code: '610100'
      },
      {
        id: 3107,
        code: '610103',
        title: '碑林区',
        p_code: '610100'
      },
      {
        id: 3108,
        code: '610104',
        title: '莲湖区',
        p_code: '610100'
      },
      {
        id: 3109,
        code: '610111',
        title: '灞桥区',
        p_code: '610100'
      },
      {
        id: 3110,
        code: '610112',
        title: '未央区',
        p_code: '610100'
      },
      {
        id: 3111,
        code: '610113',
        title: '雁塔区',
        p_code: '610100'
      },
      {
        id: 3112,
        code: '610114',
        title: '阎良区',
        p_code: '610100'
      },
      {
        id: 3113,
        code: '610115',
        title: '临潼区',
        p_code: '610100'
      },
      {
        id: 3114,
        code: '610122',
        title: '蓝田县',
        p_code: '610100'
      },
      {
        id: 3115,
        code: '610124',
        title: '周至县',
        p_code: '610100'
      },
      {
        id: 3116,
        code: '610125',
        title: '户县',
        p_code: '610100'
      },
      {
        id: 3117,
        code: '610126',
        title: '高陵县',
        p_code: '610100'
      },
      {
        id: 3118,
        code: '610200',
        title: '铜川市',
        p_code: '610000'
      },
      {
        id: 3119,
        code: '610201',
        title: '市辖区',
        p_code: '610200'
      },
      {
        id: 3120,
        code: '610202',
        title: '王益区',
        p_code: '610201'
      },
      {
        id: 3121,
        code: '610203',
        title: '印台区',
        p_code: '610201'
      },
      {
        id: 3122,
        code: '610204',
        title: '耀州区',
        p_code: '610201'
      },
      {
        id: 3123,
        code: '610222',
        title: '宜君县',
        p_code: '610200'
      },
      {
        id: 3124,
        code: '610300',
        title: '宝鸡市',
        p_code: '610000'
      },
      {
        id: 3125,
        code: '610301',
        title: '市辖区',
        p_code: '610300'
      },
      {
        id: 3126,
        code: '610302',
        title: '渭滨区',
        p_code: '610301'
      },
      {
        id: 3127,
        code: '610303',
        title: '金台区',
        p_code: '610301'
      },
      {
        id: 3128,
        code: '610304',
        title: '陈仓区',
        p_code: '610301'
      },
      {
        id: 3129,
        code: '610322',
        title: '凤翔县',
        p_code: '610300'
      },
      {
        id: 3130,
        code: '610323',
        title: '岐山县',
        p_code: '610300'
      },
      {
        id: 3131,
        code: '610324',
        title: '扶风县',
        p_code: '610300'
      },
      {
        id: 3132,
        code: '610326',
        title: '眉县',
        p_code: '610300'
      },
      {
        id: 3133,
        code: '610327',
        title: '陇县',
        p_code: '610300'
      },
      {
        id: 3134,
        code: '610328',
        title: '千阳县',
        p_code: '610300'
      },
      {
        id: 3135,
        code: '610329',
        title: '麟游县',
        p_code: '610300'
      },
      {
        id: 3136,
        code: '610330',
        title: '凤县',
        p_code: '610300'
      },
      {
        id: 3137,
        code: '610331',
        title: '太白县',
        p_code: '610300'
      },
      {
        id: 3138,
        code: '610400',
        title: '咸阳市',
        p_code: '610000'
      },
      {
        id: 3139,
        code: '610401',
        title: '市辖区',
        p_code: '610400'
      },
      {
        id: 3140,
        code: '610402',
        title: '秦都区',
        p_code: '610401'
      },
      {
        id: 3141,
        code: '610404',
        title: '渭城区',
        p_code: '610401'
      },
      {
        id: 3142,
        code: '610422',
        title: '三原县',
        p_code: '610400'
      },
      {
        id: 3143,
        code: '610423',
        title: '泾阳县',
        p_code: '610400'
      },
      {
        id: 3144,
        code: '610424',
        title: '乾县',
        p_code: '610400'
      },
      {
        id: 3145,
        code: '610425',
        title: '礼泉县',
        p_code: '610400'
      },
      {
        id: 3146,
        code: '610426',
        title: '永寿县',
        p_code: '610400'
      },
      {
        id: 3147,
        code: '610427',
        title: '彬县',
        p_code: '610400'
      },
      {
        id: 3148,
        code: '610428',
        title: '长武县',
        p_code: '610400'
      },
      {
        id: 3149,
        code: '610429',
        title: '旬邑县',
        p_code: '610400'
      },
      {
        id: 3150,
        code: '610430',
        title: '淳化县',
        p_code: '610400'
      },
      {
        id: 3151,
        code: '610431',
        title: '武功县',
        p_code: '610400'
      },
      {
        id: 3152,
        code: '610481',
        title: '兴平市',
        p_code: '610400'
      },
      {
        id: 3153,
        code: '610500',
        title: '渭南市',
        p_code: '610000'
      },
      {
        id: 3154,
        code: '610501',
        title: '市辖区',
        p_code: '610500'
      },
      {
        id: 3155,
        code: '610502',
        title: '临渭区',
        p_code: '610501'
      },
      {
        id: 3156,
        code: '610521',
        title: '华县',
        p_code: '610500'
      },
      {
        id: 3157,
        code: '610522',
        title: '潼关县',
        p_code: '610500'
      },
      {
        id: 3158,
        code: '610523',
        title: '大荔县',
        p_code: '610500'
      },
      {
        id: 3159,
        code: '610524',
        title: '合阳县',
        p_code: '610500'
      },
      {
        id: 3160,
        code: '610525',
        title: '澄城县',
        p_code: '610500'
      },
      {
        id: 3161,
        code: '610526',
        title: '蒲城县',
        p_code: '610500'
      },
      {
        id: 3162,
        code: '610527',
        title: '白水县',
        p_code: '610500'
      },
      {
        id: 3163,
        code: '610528',
        title: '富平县',
        p_code: '610500'
      },
      {
        id: 3164,
        code: '610581',
        title: '韩城市',
        p_code: '610500'
      },
      {
        id: 3165,
        code: '610582',
        title: '华阴市',
        p_code: '610500'
      },
      {
        id: 3166,
        code: '610600',
        title: '延安市',
        p_code: '610000'
      },
      {
        id: 3167,
        code: '610601',
        title: '市辖区',
        p_code: '610600'
      },
      {
        id: 3168,
        code: '610602',
        title: '宝塔区',
        p_code: '610601'
      },
      {
        id: 3169,
        code: '610621',
        title: '延长县',
        p_code: '610600'
      },
      {
        id: 3170,
        code: '610622',
        title: '延川县',
        p_code: '610600'
      },
      {
        id: 3171,
        code: '610623',
        title: '子长县',
        p_code: '610600'
      },
      {
        id: 3172,
        code: '610624',
        title: '安塞县',
        p_code: '610600'
      },
      {
        id: 3173,
        code: '610625',
        title: '志丹县',
        p_code: '610600'
      },
      {
        id: 3174,
        code: '610626',
        title: '吴起县',
        p_code: '610600'
      },
      {
        id: 3175,
        code: '610627',
        title: '甘泉县',
        p_code: '610600'
      },
      {
        id: 3176,
        code: '610628',
        title: '富县',
        p_code: '610600'
      },
      {
        id: 3177,
        code: '610629',
        title: '洛川县',
        p_code: '610600'
      },
      {
        id: 3178,
        code: '610630',
        title: '宜川县',
        p_code: '610600'
      },
      {
        id: 3179,
        code: '610631',
        title: '黄龙县',
        p_code: '610600'
      },
      {
        id: 3180,
        code: '610632',
        title: '黄陵县',
        p_code: '610600'
      },
      {
        id: 3181,
        code: '610700',
        title: '汉中市',
        p_code: '610000'
      },
      {
        id: 3182,
        code: '610701',
        title: '市辖区',
        p_code: '610700'
      },
      {
        id: 3183,
        code: '610702',
        title: '汉台区',
        p_code: '610701'
      },
      {
        id: 3184,
        code: '610721',
        title: '南郑县',
        p_code: '610700'
      },
      {
        id: 3185,
        code: '610722',
        title: '城固县',
        p_code: '610700'
      },
      {
        id: 3186,
        code: '610723',
        title: '洋县',
        p_code: '610700'
      },
      {
        id: 3187,
        code: '610724',
        title: '西乡县',
        p_code: '610700'
      },
      {
        id: 3188,
        code: '610725',
        title: '勉县',
        p_code: '610700'
      },
      {
        id: 3189,
        code: '610726',
        title: '宁强县',
        p_code: '610700'
      },
      {
        id: 3190,
        code: '610727',
        title: '略阳县',
        p_code: '610700'
      },
      {
        id: 3191,
        code: '610728',
        title: '镇巴县',
        p_code: '610700'
      },
      {
        id: 3192,
        code: '610729',
        title: '留坝县',
        p_code: '610700'
      },
      {
        id: 3193,
        code: '610730',
        title: '佛坪县',
        p_code: '610700'
      },
      {
        id: 3194,
        code: '610800',
        title: '榆林市',
        p_code: '610000'
      },
      {
        id: 3195,
        code: '610801',
        title: '市辖区',
        p_code: '610800'
      },
      {
        id: 3196,
        code: '610802',
        title: '榆阳区',
        p_code: '610800'
      },
      {
        id: 3197,
        code: '610821',
        title: '神木县',
        p_code: '610800'
      },
      {
        id: 3198,
        code: '610822',
        title: '府谷县',
        p_code: '610800'
      },
      {
        id: 3199,
        code: '610823',
        title: '横山县',
        p_code: '610800'
      },
      {
        id: 3200,
        code: '610824',
        title: '靖边县',
        p_code: '610800'
      },
      {
        id: 3201,
        code: '610825',
        title: '定边县',
        p_code: '610800'
      },
      {
        id: 3202,
        code: '610826',
        title: '绥德县',
        p_code: '610800'
      },
      {
        id: 3203,
        code: '610827',
        title: '米脂县',
        p_code: '610800'
      },
      {
        id: 3204,
        code: '610828',
        title: '佳县',
        p_code: '610800'
      },
      {
        id: 3205,
        code: '610829',
        title: '吴堡县',
        p_code: '610800'
      },
      {
        id: 3206,
        code: '610830',
        title: '清涧县',
        p_code: '610800'
      },
      {
        id: 3207,
        code: '610831',
        title: '子洲县',
        p_code: '610800'
      },
      {
        id: 3208,
        code: '610900',
        title: '安康市',
        p_code: '610000'
      },
      {
        id: 3209,
        code: '610901',
        title: '市辖区',
        p_code: '610900'
      },
      {
        id: 3210,
        code: '610902',
        title: '汉滨区',
        p_code: '610901'
      },
      {
        id: 3211,
        code: '610921',
        title: '汉阴县',
        p_code: '610900'
      },
      {
        id: 3212,
        code: '610922',
        title: '石泉县',
        p_code: '610900'
      },
      {
        id: 3213,
        code: '610923',
        title: '宁陕县',
        p_code: '610900'
      },
      {
        id: 3214,
        code: '610924',
        title: '紫阳县',
        p_code: '610900'
      },
      {
        id: 3215,
        code: '610925',
        title: '岚皋县',
        p_code: '610900'
      },
      {
        id: 3216,
        code: '610926',
        title: '平利县',
        p_code: '610900'
      },
      {
        id: 3217,
        code: '610927',
        title: '镇坪县',
        p_code: '610900'
      },
      {
        id: 3218,
        code: '610928',
        title: '旬阳县',
        p_code: '610900'
      },
      {
        id: 3219,
        code: '610929',
        title: '白河县',
        p_code: '610900'
      },
      {
        id: 3220,
        code: '611000',
        title: '商洛市',
        p_code: '610000'
      },
      {
        id: 3221,
        code: '611001',
        title: '市辖区',
        p_code: '611000'
      },
      {
        id: 3222,
        code: '611002',
        title: '商州区',
        p_code: '611001'
      },
      {
        id: 3223,
        code: '611021',
        title: '洛南县',
        p_code: '611000'
      },
      {
        id: 3224,
        code: '611022',
        title: '丹凤县',
        p_code: '611000'
      },
      {
        id: 3225,
        code: '611023',
        title: '商南县',
        p_code: '611000'
      },
      {
        id: 3226,
        code: '611024',
        title: '山阳县',
        p_code: '611000'
      },
      {
        id: 3227,
        code: '611025',
        title: '镇安县',
        p_code: '611000'
      },
      {
        id: 3228,
        code: '611026',
        title: '柞水县',
        p_code: '611000'
      },
      {
        id: 3229,
        code: '611100',
        title: '杨凌示范区',
        p_code: '610000'
      },
      {
        id: 3230,
        code: '611103',
        title: '杨凌区',
        p_code: '611100'
      },

      {
        id: 3232,
        code: '620100',
        title: '兰州市',
        p_code: '620000'
      },
      {
        id: 3233,
        code: '620101',
        title: '市辖区',
        p_code: '620100'
      },
      {
        id: 3234,
        code: '620102',
        title: '城关区',
        p_code: '620101'
      },
      {
        id: 3235,
        code: '620103',
        title: '七里河区',
        p_code: '620101'
      },
      {
        id: 3236,
        code: '620104',
        title: '西固区',
        p_code: '620101'
      },
      {
        id: 3237,
        code: '620105',
        title: '安宁区',
        p_code: '620101'
      },
      {
        id: 3238,
        code: '620111',
        title: '红古区',
        p_code: '620101'
      },
      {
        id: 3239,
        code: '620121',
        title: '永登县',
        p_code: '620100'
      },
      {
        id: 3240,
        code: '620122',
        title: '皋兰县',
        p_code: '620100'
      },
      {
        id: 3241,
        code: '620123',
        title: '榆中县',
        p_code: '620100'
      },
      {
        id: 3242,
        code: '620200',
        title: '嘉峪关市',
        p_code: '620000'
      },
      {
        id: 3243,
        code: '620201',
        title: '市辖区',
        p_code: '620200'
      },
      {
        id: 3244,
        code: '620300',
        title: '金昌市',
        p_code: '620000'
      },
      {
        id: 3245,
        code: '620301',
        title: '金川区',
        p_code: '620300'
      },
      {
        id: 3246,
        code: '620321',
        title: '永昌县',
        p_code: '620300'
      },
      {
        id: 3247,
        code: '620400',
        title: '白银市',
        p_code: '620000'
      },
      {
        id: 3248,
        code: '620401',
        title: '市辖区',
        p_code: '620400'
      },
      {
        id: 3249,
        code: '620402',
        title: '白银区',
        p_code: '620401'
      },
      {
        id: 3250,
        code: '620403',
        title: '平川区',
        p_code: '620401'
      },
      {
        id: 3251,
        code: '620421',
        title: '靖远县',
        p_code: '620400'
      },
      {
        id: 3252,
        code: '620422',
        title: '会宁县',
        p_code: '620400'
      },
      {
        id: 3253,
        code: '620423',
        title: '景泰县',
        p_code: '620400'
      },
      {
        id: 3254,
        code: '620500',
        title: '天水市',
        p_code: '620000'
      },
      {
        id: 3255,
        code: '620501',
        title: '麦积区',
        p_code: '620500'
      },
      {
        id: 3256,
        code: '620502',
        title: '秦州区',
        p_code: '620500'
      },
      {
        id: 3257,
        code: '620521',
        title: '清水县',
        p_code: '620500'
      },
      {
        id: 3258,
        code: '620522',
        title: '秦安县',
        p_code: '620500'
      },
      {
        id: 3259,
        code: '620523',
        title: '甘谷县',
        p_code: '620500'
      },
      {
        id: 3260,
        code: '620524',
        title: '武山县',
        p_code: '620500'
      },
      {
        id: 3261,
        code: '620525',
        title: '张家川回族自治县',
        p_code: '620500'
      },
      {
        id: 3262,
        code: '620600',
        title: '武威市',
        p_code: '620000'
      },
      {
        id: 3263,
        code: '620601',
        title: '市辖区',
        p_code: '620600'
      },
      {
        id: 3264,
        code: '620602',
        title: '凉州区',
        p_code: '620601'
      },
      {
        id: 3265,
        code: '620621',
        title: '民勤县',
        p_code: '620600'
      },
      {
        id: 3266,
        code: '620622',
        title: '古浪县',
        p_code: '620600'
      },
      {
        id: 3267,
        code: '620623',
        title: '天祝藏族自治县',
        p_code: '620600'
      },
      {
        id: 3268,
        code: '620700',
        title: '张掖市',
        p_code: '620000'
      },
      {
        id: 3269,
        code: '620701',
        title: '市辖区',
        p_code: '620700'
      },
      {
        id: 3270,
        code: '620702',
        title: '甘州区',
        p_code: '620701'
      },
      {
        id: 3271,
        code: '620721',
        title: '肃南裕固族自治县',
        p_code: '620700'
      },
      {
        id: 3272,
        code: '620722',
        title: '民乐县',
        p_code: '620700'
      },
      {
        id: 3273,
        code: '620723',
        title: '临泽县',
        p_code: '620700'
      },
      {
        id: 3274,
        code: '620724',
        title: '高台县',
        p_code: '620700'
      },
      {
        id: 3275,
        code: '620725',
        title: '山丹县',
        p_code: '620700'
      },
      {
        id: 3276,
        code: '620800',
        title: '平凉市',
        p_code: '620000'
      },
      {
        id: 3277,
        code: '620801',
        title: '市辖区',
        p_code: '620800'
      },
      {
        id: 3278,
        code: '620802',
        title: '崆峒区',
        p_code: '620801'
      },
      {
        id: 3279,
        code: '620821',
        title: '泾川县',
        p_code: '620800'
      },
      {
        id: 3280,
        code: '620822',
        title: '灵台县',
        p_code: '620800'
      },
      {
        id: 3281,
        code: '620823',
        title: '崇信县',
        p_code: '620800'
      },
      {
        id: 3282,
        code: '620824',
        title: '华亭县',
        p_code: '620800'
      },
      {
        id: 3283,
        code: '620825',
        title: '庄浪县',
        p_code: '620800'
      },
      {
        id: 3284,
        code: '620826',
        title: '静宁县',
        p_code: '620800'
      },
      {
        id: 3285,
        code: '620900',
        title: '酒泉市',
        p_code: '620000'
      },
      {
        id: 3286,
        code: '620901',
        title: '市辖区',
        p_code: '620900'
      },
      {
        id: 3287,
        code: '620902',
        title: '肃州区',
        p_code: '620901'
      },
      {
        id: 3288,
        code: '620921',
        title: '金塔县',
        p_code: '620900'
      },
      {
        id: 3289,
        code: '620922',
        title: '瓜洲县',
        p_code: '620900'
      },
      {
        id: 3290,
        code: '620923',
        title: '肃北蒙古族自治县',
        p_code: '620900'
      },
      {
        id: 3291,
        code: '620924',
        title: '阿克塞哈萨克族自治县',
        p_code: '620900'
      },
      {
        id: 3292,
        code: '620981',
        title: '玉门市',
        p_code: '620900'
      },
      {
        id: 3293,
        code: '620982',
        title: '敦煌市',
        p_code: '620900'
      },
      {
        id: 3294,
        code: '621000',
        title: '庆阳市',
        p_code: '620000'
      },
      {
        id: 3295,
        code: '621001',
        title: '市辖区',
        p_code: '621000'
      },
      {
        id: 3296,
        code: '621002',
        title: '西峰区',
        p_code: '621001'
      },
      {
        id: 3297,
        code: '621021',
        title: '庆城县',
        p_code: '621000'
      },
      {
        id: 3298,
        code: '621022',
        title: '环县',
        p_code: '621000'
      },
      {
        id: 3299,
        code: '621023',
        title: '华池县',
        p_code: '621000'
      },
      {
        id: 3300,
        code: '621024',
        title: '合水县',
        p_code: '621000'
      },
      {
        id: 3301,
        code: '621025',
        title: '正宁县',
        p_code: '621000'
      },
      {
        id: 3302,
        code: '621026',
        title: '宁县',
        p_code: '621000'
      },
      {
        id: 3303,
        code: '621027',
        title: '镇原县',
        p_code: '621000'
      },
      {
        id: 3304,
        code: '621100',
        title: '定西市',
        p_code: '620000'
      },
      {
        id: 3305,
        code: '621101',
        title: '市辖区',
        p_code: '621100'
      },
      {
        id: 3306,
        code: '621102',
        title: '安定区',
        p_code: '621101'
      },
      {
        id: 3307,
        code: '621121',
        title: '通渭县',
        p_code: '621100'
      },
      {
        id: 3308,
        code: '621122',
        title: '陇西县',
        p_code: '621100'
      },
      {
        id: 3309,
        code: '621123',
        title: '渭源县',
        p_code: '621100'
      },
      {
        id: 3310,
        code: '621124',
        title: '临洮县',
        p_code: '621100'
      },
      {
        id: 3311,
        code: '621125',
        title: '漳县',
        p_code: '621100'
      },
      {
        id: 3312,
        code: '621126',
        title: '岷县',
        p_code: '621100'
      },
      {
        id: 3313,
        code: '621200',
        title: '陇南市',
        p_code: '620000'
      },
      {
        id: 3314,
        code: '621201',
        title: '武都区',
        p_code: '621200'
      },
      {
        id: 3315,
        code: '621221',
        title: '成县',
        p_code: '621200'
      },
      {
        id: 3316,
        code: '621222',
        title: '文县',
        p_code: '621200'
      },
      {
        id: 3317,
        code: '621223',
        title: '宕昌县',
        p_code: '621200'
      },
      {
        id: 3318,
        code: '621224',
        title: '康县',
        p_code: '621200'
      },
      {
        id: 3319,
        code: '621225',
        title: '西和县',
        p_code: '621200'
      },
      {
        id: 3320,
        code: '621226',
        title: '礼县',
        p_code: '621200'
      },
      {
        id: 3321,
        code: '621227',
        title: '徽县',
        p_code: '621200'
      },
      {
        id: 3322,
        code: '621228',
        title: '两当县',
        p_code: '621200'
      },
      {
        id: 3323,
        code: '622900',
        title: '临夏回族自治州',
        p_code: '620000'
      },
      {
        id: 3324,
        code: '622901',
        title: '临夏市',
        p_code: '622900'
      },
      {
        id: 3325,
        code: '622921',
        title: '临夏县',
        p_code: '622900'
      },
      {
        id: 3326,
        code: '622922',
        title: '康乐县',
        p_code: '622900'
      },
      {
        id: 3327,
        code: '622923',
        title: '永靖县',
        p_code: '622900'
      },
      {
        id: 3328,
        code: '622924',
        title: '广河县',
        p_code: '622900'
      },
      {
        id: 3329,
        code: '622925',
        title: '和政县',
        p_code: '622900'
      },
      {
        id: 3330,
        code: '622926',
        title: '东乡族自治县',
        p_code: '622900'
      },
      {
        id: 3331,
        code: '622927',
        title: '积石山保安族东乡族撒拉族自治县',
        p_code: '622900'
      },
      {
        id: 3332,
        code: '623000',
        title: '甘南藏族自治州',
        p_code: '620000'
      },
      {
        id: 3333,
        code: '623001',
        title: '合作市',
        p_code: '623000'
      },
      {
        id: 3334,
        code: '623021',
        title: '临潭县',
        p_code: '623000'
      },
      {
        id: 3335,
        code: '623022',
        title: '卓尼县',
        p_code: '623000'
      },
      {
        id: 3336,
        code: '623023',
        title: '舟曲县',
        p_code: '623000'
      },
      {
        id: 3337,
        code: '623024',
        title: '迭部县',
        p_code: '623000'
      },
      {
        id: 3338,
        code: '623025',
        title: '玛曲县',
        p_code: '623000'
      },
      {
        id: 3339,
        code: '623026',
        title: '碌曲县',
        p_code: '623000'
      },
      {
        id: 3340,
        code: '623027',
        title: '夏河县',
        p_code: '623000'
      },

      {
        id: 3342,
        code: '630100',
        title: '西宁市',
        p_code: '630000'
      },
      {
        id: 3343,
        code: '630101',
        title: '市辖区',
        p_code: '630100'
      },
      {
        id: 3344,
        code: '630102',
        title: '城东区',
        p_code: '630101'
      },
      {
        id: 3345,
        code: '630103',
        title: '城中区',
        p_code: '630101'
      },
      {
        id: 3346,
        code: '630104',
        title: '城西区',
        p_code: '630101'
      },
      {
        id: 3347,
        code: '630105',
        title: '城北区',
        p_code: '630101'
      },
      {
        id: 3348,
        code: '630121',
        title: '大通回族土族自治县',
        p_code: '630100'
      },
      {
        id: 3349,
        code: '630122',
        title: '湟中县',
        p_code: '630100'
      },
      {
        id: 3350,
        code: '630123',
        title: '湟源县',
        p_code: '630100'
      },
      {
        id: 3351,
        code: '632100',
        title: '海东市',
        p_code: '630000'
      },
      {
        id: 3352,
        code: '632121',
        title: '平安县',
        p_code: '632100'
      },
      {
        id: 3353,
        code: '632122',
        title: '民和回族土族自治县',
        p_code: '632100'
      },
      {
        id: 3354,
        code: '632123',
        title: '乐都区',
        p_code: '632100'
      },
      {
        id: 3355,
        code: '632126',
        title: '互助土族自治县',
        p_code: '632100'
      },
      {
        id: 3356,
        code: '632127',
        title: '化隆回族自治县',
        p_code: '632100'
      },
      {
        id: 3357,
        code: '632128',
        title: '循化撒拉族自治县',
        p_code: '632100'
      },
      {
        id: 3358,
        code: '632200',
        title: '海北藏族自治州',
        p_code: '630000'
      },
      {
        id: 3359,
        code: '632221',
        title: '门源回族自治县',
        p_code: '632200'
      },
      {
        id: 3360,
        code: '632222',
        title: '祁连县',
        p_code: '632200'
      },
      {
        id: 3361,
        code: '632223',
        title: '海晏县',
        p_code: '632200'
      },
      {
        id: 3362,
        code: '632224',
        title: '刚察县',
        p_code: '632200'
      },
      {
        id: 3363,
        code: '632300',
        title: '黄南藏族自治州',
        p_code: '630000'
      },
      {
        id: 3364,
        code: '632321',
        title: '同仁县',
        p_code: '632300'
      },
      {
        id: 3365,
        code: '632322',
        title: '尖扎县',
        p_code: '632300'
      },
      {
        id: 3366,
        code: '632323',
        title: '泽库县',
        p_code: '632300'
      },
      {
        id: 3367,
        code: '632324',
        title: '河南蒙古族自治县',
        p_code: '632300'
      },
      {
        id: 3368,
        code: '632500',
        title: '海南藏族自治州',
        p_code: '630000'
      },
      {
        id: 3369,
        code: '632521',
        title: '共和县',
        p_code: '632500'
      },
      {
        id: 3370,
        code: '632522',
        title: '同德县',
        p_code: '632500'
      },
      {
        id: 3371,
        code: '632523',
        title: '贵德县',
        p_code: '632500'
      },
      {
        id: 3372,
        code: '632524',
        title: '兴海县',
        p_code: '632500'
      },
      {
        id: 3373,
        code: '632525',
        title: '贵南县',
        p_code: '632500'
      },
      {
        id: 3374,
        code: '632600',
        title: '果洛藏族自治州',
        p_code: '630000'
      },
      {
        id: 3375,
        code: '632621',
        title: '玛沁县',
        p_code: '632600'
      },
      {
        id: 3376,
        code: '632622',
        title: '班玛县',
        p_code: '632600'
      },
      {
        id: 3377,
        code: '632623',
        title: '甘德县',
        p_code: '632600'
      },
      {
        id: 3378,
        code: '632624',
        title: '达日县',
        p_code: '632600'
      },
      {
        id: 3379,
        code: '632625',
        title: '久治县',
        p_code: '632600'
      },
      {
        id: 3380,
        code: '632626',
        title: '玛多县',
        p_code: '632600'
      },
      {
        id: 3381,
        code: '632700',
        title: '玉树藏族自治州',
        p_code: '630000'
      },
      {
        id: 3382,
        code: '632721',
        title: '玉树县',
        p_code: '632700'
      },
      {
        id: 3383,
        code: '632722',
        title: '杂多县',
        p_code: '632700'
      },
      {
        id: 3384,
        code: '632723',
        title: '称多县',
        p_code: '632700'
      },
      {
        id: 3385,
        code: '632724',
        title: '治多县',
        p_code: '632700'
      },
      {
        id: 3386,
        code: '632725',
        title: '囊谦县',
        p_code: '632700'
      },
      {
        id: 3387,
        code: '632726',
        title: '曲麻莱县',
        p_code: '632700'
      },
      {
        id: 3388,
        code: '632800',
        title: '海西蒙古族藏族自治州',
        p_code: '630000'
      },
      {
        id: 3389,
        code: '632801',
        title: '格尔木市',
        p_code: '632800'
      },
      {
        id: 3390,
        code: '632802',
        title: '德令哈市',
        p_code: '632800'
      },
      {
        id: 3391,
        code: '632821',
        title: '乌兰县',
        p_code: '632800'
      },
      {
        id: 3392,
        code: '632822',
        title: '都兰县',
        p_code: '632800'
      },
      {
        id: 3393,
        code: '632823',
        title: '天峻县',
        p_code: '632800'
      },

      {
        id: 3395,
        code: '640100',
        title: '银川市',
        p_code: '640000'
      },
      {
        id: 3396,
        code: '640104',
        title: '兴庆区',
        p_code: '640100'
      },
      {
        id: 3397,
        code: '640105',
        title: '西夏区',
        p_code: '640100'
      },
      {
        id: 3398,
        code: '640106',
        title: '金凤区',
        p_code: '640100'
      },
      {
        id: 3399,
        code: '640121',
        title: '永宁县',
        p_code: '640100'
      },
      {
        id: 3400,
        code: '640122',
        title: '贺兰县',
        p_code: '640100'
      },
      {
        id: 3401,
        code: '640181',
        title: '灵武市',
        p_code: '640100'
      },
      {
        id: 3402,
        code: '640200',
        title: '石嘴山市',
        p_code: '640000'
      },
      {
        id: 3403,
        code: '640202',
        title: '大武口区',
        p_code: '640200'
      },
      {
        id: 3404,
        code: '640205',
        title: '惠农县',
        p_code: '640200'
      },
      {
        id: 3405,
        code: '640221',
        title: '平罗县',
        p_code: '640200'
      },
      {
        id: 3406,
        code: '640300',
        title: '吴忠市',
        p_code: '640000'
      },
      {
        id: 3407,
        code: '640301',
        title: '红寺堡区',
        p_code: '640300'
      },
      {
        id: 3408,
        code: '640302',
        title: '利通区',
        p_code: '640300'
      },
      {
        id: 3409,
        code: '640323',
        title: '盐池县',
        p_code: '640300'
      },
      {
        id: 3410,
        code: '640324',
        title: '同心县',
        p_code: '640300'
      },
      {
        id: 3411,
        code: '640381',
        title: '青铜峡市',
        p_code: '640300'
      },
      {
        id: 3412,
        code: '640400',
        title: '固原市',
        p_code: '640000'
      },
      {
        id: 3413,
        code: '640401',
        title: '市辖区',
        p_code: '640400'
      },
      {
        id: 3414,
        code: '640402',
        title: '原州区',
        p_code: '640400'
      },
      {
        id: 3415,
        code: '640422',
        title: '西吉县',
        p_code: '640400'
      },
      {
        id: 3416,
        code: '640423',
        title: '隆德县',
        p_code: '640400'
      },
      {
        id: 3417,
        code: '640424',
        title: '泾源县',
        p_code: '640400'
      },
      {
        id: 3418,
        code: '640425',
        title: '彭阳县',
        p_code: '640400'
      },
      {
        id: 3419,
        code: '640500',
        title: '中卫市',
        p_code: '640000'
      },
      {
        id: 3420,
        code: '640501',
        title: '市辖区',
        p_code: '640500'
      },
      {
        id: 3421,
        code: '640502',
        title: '沙坡头区',
        p_code: '640500'
      },
      {
        id: 3422,
        code: '640521',
        title: '中宁县',
        p_code: '640500'
      },
      {
        id: 3423,
        code: '640522',
        title: '海原县',
        p_code: '640500'
      },

      {
        id: 3425,
        code: '650100',
        title: '乌鲁木齐市',
        p_code: '650000'
      },
      {
        id: 3426,
        code: '650101',
        title: '市辖区',
        p_code: '650100'
      },
      {
        id: 3427,
        code: '650102',
        title: '天山区',
        p_code: '650101'
      },
      {
        id: 3428,
        code: '650103',
        title: '沙依巴克区',
        p_code: '650101'
      },
      {
        id: 3429,
        code: '650104',
        title: '新市区',
        p_code: '650101'
      },
      {
        id: 3430,
        code: '650105',
        title: '水磨沟区',
        p_code: '650101'
      },
      {
        id: 3431,
        code: '650106',
        title: '头屯河区',
        p_code: '650101'
      },
      {
        id: 3432,
        code: '650107',
        title: '达坂城区',
        p_code: '650101'
      },
      {
        id: 3433,
        code: '650108',
        title: '东山区',
        p_code: '650101'
      },
      {
        id: 3434,
        code: '650121',
        title: '乌鲁木齐县',
        p_code: '650100'
      },
      {
        id: 3435,
        code: '650200',
        title: '克拉玛依市',
        p_code: '650000'
      },
      {
        id: 3436,
        code: '650201',
        title: '市辖区',
        p_code: '650200'
      },
      {
        id: 3437,
        code: '650202',
        title: '独山子区',
        p_code: '650201'
      },
      {
        id: 3438,
        code: '650203',
        title: '克拉玛依区',
        p_code: '650201'
      },
      {
        id: 3439,
        code: '650204',
        title: '白碱滩区',
        p_code: '650201'
      },
      {
        id: 3440,
        code: '650205',
        title: '乌尔禾区',
        p_code: '650201'
      },
      {
        id: 3441,
        code: '652100',
        title: '吐鲁番地区',
        p_code: '650000'
      },
      {
        id: 3442,
        code: '652101',
        title: '吐鲁番市',
        p_code: '652100'
      },
      {
        id: 3443,
        code: '652122',
        title: '鄯善县',
        p_code: '652100'
      },
      {
        id: 3444,
        code: '652123',
        title: '托克逊县',
        p_code: '652100'
      },
      {
        id: 3445,
        code: '652200',
        title: '哈密地区',
        p_code: '650000'
      },
      {
        id: 3446,
        code: '652201',
        title: '哈密市',
        p_code: '652200'
      },
      {
        id: 3447,
        code: '652222',
        title: '巴里坤哈萨克自治县',
        p_code: '652200'
      },
      {
        id: 3448,
        code: '652223',
        title: '伊吾县',
        p_code: '652200'
      },
      {
        id: 3449,
        code: '652300',
        title: '昌吉回族自治州',
        p_code: '650000'
      },
      {
        id: 3450,
        code: '652301',
        title: '昌吉市',
        p_code: '652300'
      },
      {
        id: 3451,
        code: '652302',
        title: '阜康市',
        p_code: '652300'
      },
      {
        id: 3452,
        code: '652303',
        title: '米泉市',
        p_code: '652300'
      },
      {
        id: 3453,
        code: '652323',
        title: '呼图壁县',
        p_code: '652300'
      },
      {
        id: 3454,
        code: '652324',
        title: '玛纳斯县',
        p_code: '652300'
      },
      {
        id: 3455,
        code: '652325',
        title: '奇台县',
        p_code: '652300'
      },
      {
        id: 3456,
        code: '652327',
        title: '吉木萨尔县',
        p_code: '652300'
      },
      {
        id: 3457,
        code: '652328',
        title: '木垒哈萨克自治县',
        p_code: '652300'
      },
      {
        id: 3458,
        code: '652700',
        title: '博尔塔拉蒙古自治州',
        p_code: '650000'
      },
      {
        id: 3459,
        code: '652701',
        title: '博乐市',
        p_code: '652700'
      },
      {
        id: 3460,
        code: '652722',
        title: '精河县',
        p_code: '652700'
      },
      {
        id: 3461,
        code: '652723',
        title: '温泉县',
        p_code: '652700'
      },
      {
        id: 3462,
        code: '652800',
        title: '巴音郭楞蒙古自治州',
        p_code: '650000'
      },
      {
        id: 3463,
        code: '652801',
        title: '库尔勒市',
        p_code: '652800'
      },
      {
        id: 3464,
        code: '652822',
        title: '轮台县',
        p_code: '652800'
      },
      {
        id: 3465,
        code: '652823',
        title: '尉犁县',
        p_code: '652800'
      },
      {
        id: 3466,
        code: '652824',
        title: '若羌县',
        p_code: '652800'
      },
      {
        id: 3467,
        code: '652825',
        title: '且末县',
        p_code: '652800'
      },
      {
        id: 3468,
        code: '652826',
        title: '焉耆回族自治县',
        p_code: '652800'
      },
      {
        id: 3469,
        code: '652827',
        title: '和静县',
        p_code: '652800'
      },
      {
        id: 3470,
        code: '652828',
        title: '和硕县',
        p_code: '652800'
      },
      {
        id: 3471,
        code: '652829',
        title: '博湖县',
        p_code: '652800'
      },
      {
        id: 3472,
        code: '652900',
        title: '阿克苏地区',
        p_code: '650000'
      },
      {
        id: 3473,
        code: '652901',
        title: '阿克苏市',
        p_code: '652900'
      },
      {
        id: 3474,
        code: '652922',
        title: '温宿县',
        p_code: '652900'
      },
      {
        id: 3475,
        code: '652923',
        title: '库车县',
        p_code: '652900'
      },
      {
        id: 3476,
        code: '652924',
        title: '沙雅县',
        p_code: '652900'
      },
      {
        id: 3477,
        code: '652925',
        title: '新和县',
        p_code: '652900'
      },
      {
        id: 3478,
        code: '652926',
        title: '拜城县',
        p_code: '652900'
      },
      {
        id: 3479,
        code: '652927',
        title: '乌什县',
        p_code: '652900'
      },
      {
        id: 3480,
        code: '652928',
        title: '阿瓦提县',
        p_code: '652900'
      },
      {
        id: 3481,
        code: '652929',
        title: '柯坪县',
        p_code: '652900'
      },
      {
        id: 3482,
        code: '653000',
        title: '克孜勒苏柯尔克孜自治州',
        p_code: '650000'
      },
      {
        id: 3483,
        code: '653001',
        title: '阿图什市',
        p_code: '653000'
      },
      {
        id: 3484,
        code: '653022',
        title: '阿克陶县',
        p_code: '653000'
      },
      {
        id: 3485,
        code: '653023',
        title: '阿合奇县',
        p_code: '653000'
      },
      {
        id: 3486,
        code: '653024',
        title: '乌恰县',
        p_code: '653000'
      },
      {
        id: 3487,
        code: '653100',
        title: '喀什地区',
        p_code: '650000'
      },
      {
        id: 3488,
        code: '653101',
        title: '喀什市',
        p_code: '653100'
      },
      {
        id: 3489,
        code: '653121',
        title: '疏附县',
        p_code: '653100'
      },
      {
        id: 3490,
        code: '653122',
        title: '疏勒县',
        p_code: '653100'
      },
      {
        id: 3491,
        code: '653123',
        title: '英吉沙县',
        p_code: '653100'
      },
      {
        id: 3492,
        code: '653124',
        title: '泽普县',
        p_code: '653100'
      },
      {
        id: 3493,
        code: '653125',
        title: '莎车县',
        p_code: '653100'
      },
      {
        id: 3494,
        code: '653126',
        title: '叶城县',
        p_code: '653100'
      },
      {
        id: 3495,
        code: '653127',
        title: '麦盖提县',
        p_code: '653100'
      },
      {
        id: 3496,
        code: '653128',
        title: '岳普湖县',
        p_code: '653100'
      },
      {
        id: 3497,
        code: '653129',
        title: '伽师县',
        p_code: '653100'
      },
      {
        id: 3498,
        code: '653130',
        title: '巴楚县',
        p_code: '653100'
      },
      {
        id: 3499,
        code: '653131',
        title: '塔什库尔干塔吉克自治县',
        p_code: '653100'
      },
      {
        id: 3500,
        code: '653200',
        title: '和田地区',
        p_code: '650000'
      },
      {
        id: 3501,
        code: '653201',
        title: '和田市',
        p_code: '653200'
      },
      {
        id: 3502,
        code: '653221',
        title: '和田县',
        p_code: '653200'
      },
      {
        id: 3503,
        code: '653222',
        title: '墨玉县',
        p_code: '653200'
      },
      {
        id: 3504,
        code: '653223',
        title: '皮山县',
        p_code: '653200'
      },
      {
        id: 3505,
        code: '653224',
        title: '洛浦县',
        p_code: '653200'
      },
      {
        id: 3506,
        code: '653225',
        title: '策勒县',
        p_code: '653200'
      },
      {
        id: 3507,
        code: '653226',
        title: '于田县',
        p_code: '653200'
      },
      {
        id: 3508,
        code: '653227',
        title: '民丰县',
        p_code: '653200'
      },
      {
        id: 3509,
        code: '654000',
        title: '伊犁哈萨克自治州',
        p_code: '650000'
      },
      {
        id: 3510,
        code: '654002',
        title: '伊宁市',
        p_code: '654000'
      },
      {
        id: 3511,
        code: '654003',
        title: '奎屯市',
        p_code: '654000'
      },
      {
        id: 3512,
        code: '654021',
        title: '伊宁县',
        p_code: '654000'
      },
      {
        id: 3513,
        code: '654022',
        title: '察布查尔锡伯自治县',
        p_code: '654000'
      },
      {
        id: 3514,
        code: '654023',
        title: '霍城县',
        p_code: '654000'
      },
      {
        id: 3515,
        code: '654024',
        title: '巩留县',
        p_code: '654000'
      },
      {
        id: 3516,
        code: '654025',
        title: '新源县',
        p_code: '654000'
      },
      {
        id: 3517,
        code: '654026',
        title: '昭苏县',
        p_code: '654000'
      },
      {
        id: 3518,
        code: '654027',
        title: '特克斯县',
        p_code: '654000'
      },
      {
        id: 3519,
        code: '654028',
        title: '尼勒克县',
        p_code: '654000'
      },
      {
        id: 3520,
        code: '654200',
        title: '塔城地区',
        p_code: '650000'
      },
      {
        id: 3521,
        code: '654201',
        title: '塔城市',
        p_code: '654200'
      },
      {
        id: 3522,
        code: '654202',
        title: '乌苏市',
        p_code: '654200'
      },
      {
        id: 3523,
        code: '654221',
        title: '额敏县',
        p_code: '654200'
      },
      {
        id: 3524,
        code: '654223',
        title: '沙湾县',
        p_code: '654200'
      },
      {
        id: 3525,
        code: '654224',
        title: '托里县',
        p_code: '654200'
      },
      {
        id: 3526,
        code: '654225',
        title: '裕民县',
        p_code: '654200'
      },
      {
        id: 3527,
        code: '654226',
        title: '和布克赛尔蒙古自治县',
        p_code: '654200'
      },
      {
        id: 3528,
        code: '654300',
        title: '阿勒泰地区',
        p_code: '650000'
      },
      {
        id: 3529,
        code: '654301',
        title: '阿勒泰市',
        p_code: '654300'
      },
      {
        id: 3530,
        code: '654321',
        title: '布尔津县',
        p_code: '654300'
      },
      {
        id: 3531,
        code: '654322',
        title: '富蕴县',
        p_code: '654300'
      },
      {
        id: 3532,
        code: '654323',
        title: '福海县',
        p_code: '654300'
      },
      {
        id: 3533,
        code: '654324',
        title: '哈巴河县',
        p_code: '654300'
      },
      {
        id: 3534,
        code: '654325',
        title: '青河县',
        p_code: '654300'
      },
      {
        id: 3535,
        code: '654326',
        title: '吉木乃县',
        p_code: '654300'
      },
      {
        id: 3536,
        code: '659000',
        title: '省直辖行政单位',
        p_code: '650000'
      },
      {
        id: 3537,
        code: '659001',
        title: '石河子市',
        p_code: '659000'
      },
      {
        id: 3538,
        code: '659002',
        title: '阿拉尔市',
        p_code: '659000'
      },
      {
        id: 3539,
        code: '659003',
        title: '图木舒克市',
        p_code: '659000'
      },
      {
        id: 3540,
        code: '659004',
        title: '五家渠市',
        p_code: '659000'
      },

      {
        id: 3542,
        code: '990100',
        title: '第一师',
        p_code: '990000'
      },
      {
        id: 3543,
        code: '990200',
        title: '第二师',
        p_code: '990000'
      },
      {
        id: 3544,
        code: '990300',
        title: '第三师',
        p_code: '990000'
      },
      {
        id: 3545,
        code: '990400',
        title: '第四师',
        p_code: '990000'
      },
      {
        id: 3546,
        code: '990500',
        title: '第五师',
        p_code: '990000'
      },
      {
        id: 3547,
        code: '990600',
        title: '第六师',
        p_code: '990000'
      },
      {
        id: 3548,
        code: '990700',
        title: '第七师',
        p_code: '990000'
      },
      {
        id: 3549,
        code: '990800',
        title: '第八师',
        p_code: '990000'
      },
      {
        id: 3550,
        code: '990900',
        title: '第九师',
        p_code: '990000'
      },
      {
        id: 3551,
        code: '991000',
        title: '第十师',
        p_code: '990000'
      },
      {
        id: 3552,
        code: '991100',
        title: '建工师',
        p_code: '990000'
      },
      {
        id: 3553,
        code: '991200',
        title: '第十二师',
        p_code: '990000'
      },
      {
        id: 3554,
        code: '991300',
        title: '第十三师',
        p_code: '990000'
      },
      {
        id: 3555,
        code: '991400',
        title: '第十四师',
        p_code: '990000'
      }
    ],
    // 评审等级
    dict_audit_level: {
      1: '国家级',
      2: '省级',
      3: '校级'
    },
    // 评审阶段
    dict_audit_status: {
      0: '准备阶段',
      1: '通知阶段',
      2: '作品提交阶段',
      3: '网评阶段',
      4: '终审阶段',
      5: '公示阶段'
    },
    // 评审模式
    dict_audit_mode: {
      1: '传统模式',
      2: '随机分配模式'
    },
    // 教育层次
    dict_eduLevel: {
      1: '本科',
      2: '高职'
    },
    // 专业级别
    dict_subject_level: {
      1: '一级',
      2: '二级'
    },
    editorConfig: {
      UEDITOR_HOME_URL: '/UE/',
      serverUrl: rootUrl + '/ueditor/upload/',
      autoHeight: true,
      initialFrameWidth: '100%',
      initialFrameHeight: 300
    },
    notificationSystem: {
      options: {
        error: { // 错误提示
          position: 'topRight',
          messageColor: 'white',
          titleColor: 'white',
          iconColor: '#fff'
        },
        success: { // 成功提示
          position: 'topRight',
          messageColor: 'white',
          titleColor: 'white',
          iconColor: '#fff'
        }

      }
    },
    dict_result: {
      1: '通过',
      2: '待审',
      3: '不通过'
    }
  },
  mutations: {
    setUserInfo (state, userInfo) {
      this.state.userInfo = userInfo
    },
    setToken (state, token) {
      this.state.token = token
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({ storage: window.localStorage })]
})
