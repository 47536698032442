<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="4"
        :audit_status="audit_status"
      ></audit-tab>
      <span
        class="export-btn btn-export sprite_6"
        id="download"
        style="display: inline-block;"
        @click="exportResultMemberV2"
        :disabled="isLoading"
      >导出专家评审结果</span>
      <span
        class="export-btn btn-export sprite_7"
        id="download-stat"
        @click="exportMemberV2"
        :disabled="isLoading"
      >导出记录</span>
    </div>
    <div
      class="audit_content seendtime audit_cour clearfix"
      style="display: block;"
    >
      <div class="analisys-box clearfix">
        <div class="a-item">
          <div class="a-wrap">
            <p><span class="a-title">已签承诺书人数</span><span class="a-num">{{signData.expert_readed}}</span></p>
            <p><span class="a-title">未签承诺书人数</span><span class="a-num">{{signData.expert_all-signData.expert_readed}}</span></p>
            <p><span class="a-title">已签承诺书比例</span><span class="a-num">{{signedNum}}</span></p>
          </div>
        </div>
        <div class="a-item">
          <div class="a-wrap">
            <p><span class="a-title">已评审作品人数</span><span class="a-num">{{scoreData.expert_scored}}</span></p>
            <p><span class="a-title">未评审作品人数</span><span class="a-num">{{signData.expert_all-scoreData.expert_scored}}</span></p>
            <p><span class="a-title">已评审作品人数比例</span><span class="a-num">{{reviewNum}}</span></p>
          </div>
        </div>
        <div class="a-item">
          <div class="a-wrap">
            <p><span class="a-title">需评作品总数</span><span class="a-num">{{auditItemCount}}</span></p>
            <p><span class="a-title">已评作品数</span><span class="a-num">{{itemScoredData.item_scored}}</span></p>
            <p><span class="a-title">已评作品比例</span><span class="a-num">{{worksNum}}</span></p>
          </div>
        </div>
      </div>
      <div class="search-box">
        <transition name="fade">
          <form
            class="form-inline"
            v-if="isOpen"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="username"
                placeholder="账号"
                v-model="queryInfo.username"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="school"
                placeholder="专家学校"
                v-model="queryInfo.school_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="groupTitle"
                placeholder="分组"
                v-model="queryInfo.group_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="title"
                placeholder="项目名称"
                v-model="queryInfo.item_name"
              ></div>
            <div class="form-group"><select
                class="form-control input-sm"
                name="isagree"
                v-model="queryInfo.is_read"
              >
                <option value="">是否签订协议书</option>
                <option value="1">是</option>
                <option value="0">否</option>
              </select></div>
            <div class="form-group"><select
                class="form-control input-sm"
                name="normLog"
                v-model="queryInfo.status"
              >
                <option value="">是否评分</option>
                <option value="2">是</option>
                <option value="0,1">否</option>
              </select></div>
            <div class="form-group"><select
                class="form-control input-sm"
                name="isVeto"
                v-model="queryInfo.isVeto"
              >
                <option value="">是否一票否决</option>
                <option value="1">是</option>
                <option value="0">否</option>
              </select></div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索
              </button>
            </div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          @click="toggleQueryForm"
        >{{isOpen?'关闭':'展开'}}</span>
      </div>
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th>账号</th>
              <th>学校</th>
              <th>分组</th>
              <th>是否签订协议</th>
              <th>实验项目名称</th>
              <th>是否评分</th>
              <th>分数</th>
              <th>是否一票否决</th>
              <th width="90">操作</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td>{{item.account?item.account:'-'}}</td>
              <td>{{item.expertSchoolTitle?item.expertSchoolTitle:'-'}}</td>
              <td>{{item.groupTitle?item.groupTitle:'-'}}</td>
              <td>{{item.is_read === 1?'是':'否'}}</td>
              <td>{{item.projTitle?item.projTitle:'-'}}</td>
              <td>{{item.status == 2?'是':'否'}}</td>
<!--              <td>{{item.score?formatScore(item):"-"}}</td>-->
              <!--  查询的是某个专家的打分情况，不是作品的最终评分吧             -->
              <td>{{item.score?item.score:"-"}}</td>
              <td>{{item.isVeto?'是':'否'}}</td>
              <td><a
                  href="javascript:void(0)"
                  class="preview"
                  @click="viewDetail(item)"
                >查看详情</a></td>
            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        >没有数据</div>
      </div>
      <!-- 分页 -->
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      >
      </pagination>
    </div>
    <div
      class="form_overlay"
      id="expert_detail_overlay"
      :style="classShowDetailWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">评审详情</div>
        <div class="form_content">
          <div class="line">
            <span><strong>账号：</strong>{{detailInfo.account?detailInfo.account:'-'}}</span>
            <span><strong>专家学校：</strong>{{detailInfo.expertSchoolTitle?detailInfo.expertSchoolTitle:'-'}}</span>
            <span><strong>分组：</strong>{{detailInfo.groupTitle?detailInfo.groupTitle.title:'-'}}</span>
            <span><strong>签订承诺书：</strong>{{detailInfo.is_read && detailInfo.is_read === 1?'是':'否'}}</span>
          </div>
          <div class="line">
            <span><strong>作品编号：</strong>{{detailInfo.groupId?detailInfo.projId:'-'}}</span>
            <span><strong>作品名称：</strong>{{detailInfo.projTitle?detailInfo.projTitle:'-'}}</span>
<!--            <span><strong>分数：</strong>{{detailInfo.score?formatScore(detailInfo):"-"}}</span>-->
            <span><strong>分数：</strong>{{detailInfo.score?detailInfo.score:"-"}}</span>
          </div>
          <div class="line">
            <span><strong>评语：</strong>{{detailInfo.appraiseText?detailInfo.appraiseText:'-'}}</span>
          </div>
          <div class="line">
            <span><strong>否决性意见：</strong>{{detailInfo.vetoText?detailInfo.vetoText:'-'}}</span>
          </div>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hideDetailWindow"
          >关闭</a></div>
      </div>
    </div>
    <!-- 进度条 -->
    <div v-if="isLoading" class="xoverlay">
      <div class="xmodal">
        <h3>下载中... 请稍候</h3>
        <div class="progress-bar">
          <div class="progress-bar-inner" :style="{ width: progress + '%' }"></div>
        </div>
        <p>进度: {{ progress }}%</p>
        <button @click="()=>{cancelDownload()}">取消</button>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import outputTable from '../../../assets/js/outputTable.js'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab5Detail',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        audit_id: this.param_id,
        username: '',
        item_name: '',
        school_title: '',
        group_title: '',
        is_read: '',
        // status: '0,1,2',
        status: '',
        isVeto: ''
      },
      tableData: [],
      tableDataCount: 0,
      isOpen: false,
      audit_detail_id: '',
      veto_detail_id: '',
      exportData: [],
      exportArray: [],
      classShowDetailWindow: {},
      detailInfo: {},
      auditInfo: {},
      // 承诺书统计数据
      signData: {
        expert_readed: 0,
        expert_all: 0
      },
      scoreData: {
        expert_scored: 0
      },
      itemScoredData: {
        item_scored: 0
      },
      auditItemCount: 0,
      // 用来控制评审作品 导出进度条
      isLoading: false,
      progress: 0,
      error: null,
      cancelRequested: false
    }
  },
  methods: {
    formatScore (item) {
      var scoresArr, total, scoreCount, cutNum
      if (Number(item.auditItem.audit.calType) === 1) { // 算术平均分 数据中所有数据之和再除以数据个数
        scoresArr = item.score.split(',')
        total = scoresArr.length
        scoreCount = scoresArr.reduce(function (prev, curr, idx, arr) {
          return Number(prev) + Number(curr)
        })
        return total ? (Number(scoreCount) / total).toFixed(2) : Number(scoreCount).toFixed(2)
      } else if (Number(item.auditItem.audit.calType) === 2) { // 截尾平均分 去掉最高分和最低分求平均数
        scoresArr = item.score.split(',')

        scoresArr.sort(function (a, b) {
          return Number(b) - Number(a)
        })

        cutNum = scoresArr.slice(1, length - 1)
        scoreCount = scoresArr.reduce(function (prev, curr, idx, arr) {
          return Number(prev) + Number(curr)
        })
        return cutNum.length ? (Number(scoreCount) / cutNum.length).toFixed(2) : Number(scoreCount).toFixed(2)
      }
    },
    formatResult (val) {
      if (val) {
        return this.dict_result[val]
      } else {
        return '-'
      }
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    async getPersonNum () {
      const url = '/audit/' + this.param_id + '/'
      await this.$http.get(url).then((data) => {
        this.auditInfo = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 承诺书人数
    async getSignNum () {
      const url = '/promise_num/statistics/' + this.param_id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.signData = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 已评审作品人数
    async getScoredNum () {
      const url = '/score_num/statistics/' + this.param_id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.scoreData = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 参评作品总数
    async getAuditItemCount () {
      await this.$http.get('/audit-items/count/' + this.param_id + '/').then((data) => {
        this.auditItemCount = data.audit_item_count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },

    // 已评作品数
    async getItemScoredNum () {
      const url = '/item_num/statistics/' + this.param_id + '/'
      await this.$http.get(url).then((data) => {
        console.log(data)
        this.itemScoredData = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },

    // 搜索
    async search () {
      // await this.$http.get('/auditgroup_itemscore/', {
      //   params: this.queryInfo
      // }).then((data) => {
      //   this.tableData = data.results
      //   this.tableDataCount = data.count
      // }).catch(() => {
      //   this.$toast.error('获取数据失败！', '', this.error_tips)
      // })
      // 优化接口慢查询
      await this.$http.get('/auditgroup_itemscoreg/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    viewDetail (item) {
      console.log(item)
      this.detailInfo = item
      this.showDetailWindow()
    },
    // 导出查询列表
    async exportMember () {
      var exportArray, fileName
      fileName = '评审详情导出记录.csv'
      exportArray = [{
        username: '账号',
        item_name: '实验项目名称',
        school_title: '学校',
        group_title: '分组',
        is_read: '是否签订协议',
        status: '是否评分',
        isVeto: '是否一票否决',
        scores: '分数'
      }]

      const params = {
        page: 1,
        size: 100000,
        audit_id: this.param_id
      }
      await this.$http.get('/auditgroup_itemscore/', { params }).then((data) => {
        const exportData = data.results
        if (exportData.length === 0) {
          this.$toast.error('暂无信息可导出！', this.error_tips)
          return false
        } else {
          exportData.forEach(item => {
            exportArray.push({
              username: item.auditGroupExpert.expert ? item.auditGroupExpert.expert.username : '-',
              item_name: item.auditItem ? item.auditItem.title : '-',
              school_title: item.auditGroupExpert.expert.school ? item.auditGroupExpert.expert.school.title : '-',
              group_title: item.auditItem.auditGroup ? item.auditItem.auditGroup.title : '-',
              is_read: item.auditGroupExpert.is_read === 1 ? '是' : '否',
              status: item.status === 2 ? '是' : '否',
              isVeto: item.isVeto ? '是' : '否',
              scores: item.score
            })
          })

          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        }
      }).catch(() => {
        this.$toast.error('获取信息失败！', '', this.error_tips)
      })
    },
    // 导出全量评审记录
    async exportMemberV2 () {
      if (this.isLoading) return // 防止多次点击
      this.progress = 0
      // this.error = null;
      this.cancelRequested = false
      this.isLoading = true
      let page = 1
      const pageSize = 20
      let hasMoreData = true
      let loaded = 0
      const exportArray = [{
        username: '账号',
        item_name: '实验项目名称',
        school_title: '学校',
        group_title: '分组',
        is_read: '是否签订协议',
        status: '是否评分',
        isVeto: '是否一票否决',
        scores: '分数'
      }]
      try {
        while (hasMoreData && !this.cancelRequested) {
          const data = await this.$http.get('/auditgroup_itemscoreg/', {
            params: {
              page: page,
              size: pageSize,
              audit_id: this.param_id
            }
          })
          const results = data.results
          const next = data.next
          const count = data.count
          if (results.length) {
            results.forEach(item => {
              exportArray.push({
                username: item.account ? item.account : '-',
                item_name: item.projTitle ? item.projTitle : '-',
                school_title: item.expertSchoolTitle ? item.expertSchoolTitle : '-',
                group_title: item.groupTitle ? item.groupTitle : '-',
                is_read: item.is_read === 1 ? '是' : '否',
                status: item.status === 2 ? '是' : '否',
                isVeto: item.isVeto ? '是' : '否',
                scores: item.score
              })
            })
            // Increment page for the next iteration
            // Check if the number of results is less than the pageSize to determine if there's no more data
            // hasMoreData = results.length === pageSize
            loaded += results.length // 更新已加载记录数
            this.progress = Math.round((loaded / count) * 100)
            hasMoreData = !!next
            page++
          } else {
            hasMoreData = false // No more data to fetch
          }
        }
        this.progress = 100
      } catch (e) {
      } finally {
        this.isLoading = false
      }
      if (exportArray.length > 1) {
        if (!this.cancelRequested) {
          const fileName = '评审详情导出记录.csv'
          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        } else {
          this.$toast.success('已取消', '', this.success_tips)
        }
      } else {
        this.$toast.error('暂无信息可导出！', '', this.error_tips)
      }
    },
    // 取消下载
    cancelDownload () {
      this.cancelRequested = true
    },
    // 导出查询列表
    async exportResultMember () {
      var exportArray, fileName
      fileName = '专家评审结果.csv'
      exportArray = [{
        expertUsername: '专家账号',
        expertName: '专家姓名',
        expertSchool: '专家学校',
        isSign: '是否签订协议',
        expertGroup: '专家分组',
        expItemNum: '实验项目编号',
        expItemName: '实验项目名称',
        incharge: '实验负责人',
        schoolName: '学校',
        isVeto: '是否一票否决',
        isScore: '是否评分',
        score: '分数',
        appraiseText: '评语',
        vetoText: '一票否决评语'
      }]

      const params = {
        page: 1,
        size: 100000,
        audit_id: this.param_id
      }
      await this.$http.get('/auditgroup_itemscore/', { params }).then((data) => {
        const exportData = data.results
        if (exportData.length === 0) {
          this.$toast.error('暂无信息可导出！', this.error_tips)
          return false
        } else {
          exportData.forEach(item => {
            exportArray.push({
              expertUsername: item.auditGroupExpert.expert ? item.auditGroupExpert.expert.username : '-',
              expertName: item.auditGroupExpert.expert ? item.auditGroupExpert.expert.name : '-',
              expertSchool: item.auditGroupExpert.expert ? item.auditGroupExpert.expert.school.title : '-',
              isSign: item.auditGroupExpert.is_read === 1 ? '是' : '否',
              expertGroup: item.auditGroupExpert.auditGroup ? item.auditGroupExpert.auditGroup.title : '-',
              expItemNum: item.auditItem ? item.auditItem.projId : '-',
              expItemName: item.auditItem ? item.auditItem.title : '-',
              incharge: item.auditItem ? item.auditItem.incharge : '-',
              schoolName: item.auditItem ? item.auditItem.school.title : '-',
              isVeto: item.isVeto ? '是' : '否',
              isScore: item.status === 2 ? '是' : '否',
              score: item.score,
              appraiseText: item.appraiseText,
              vetoText: item.vetoText
            })
          })

          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        }
      }).catch(() => {
        this.$toast.error('获取信息失败！', '', this.error_tips)
      })
    },

    async exportResultMemberV2 () {
      if (this.isLoading) return // 防止多次点击
      this.progress = 0
      // this.error = null;
      this.cancelRequested = false
      this.isLoading = true
      let page = 1
      const pageSize = 20
      let hasMoreData = true
      let loaded = 0
      const exportArray = [{
        expertUsername: '专家账号',
        expertName: '专家姓名',
        expertSchool: '专家学校',
        isSign: '是否签订协议',
        expertGroup: '专家分组',
        expItemNum: '实验项目编号',
        expItemName: '实验项目名称',
        incharge: '实验负责人',
        schoolName: '学校',
        isVeto: '是否一票否决',
        isScore: '是否评分',
        score: '分数',
        appraiseText: '评语',
        vetoText: '一票否决评语'
      }]

      try {
        while (hasMoreData && !this.cancelRequested) {
          const data = await this.$http.get('/auditgroup_itemscoreg/', {
            // 每次循环后page++
            params: {
              page: page,
              size: pageSize,
              audit_id: this.param_id
            }
          })
          const results = data.results
          const next = data.next
          const count = data.count
          if (results.length) {
            results.forEach(item => {
              exportArray.push({
                expertUsername: item.account ? item.account : '-',
                expertName: item.account ? item.account : '-',
                expertSchool: item.expertSchoolTitle ? item.expertSchoolTitle : '-',
                isSign: item.is_read === 1 ? '是' : '否',
                expertGroup: item.groupTitle ? item.groupTitle : '-',
                expItemNum: item.projId ? item.projId : '-',
                expItemName: item.projTitle ? item.projTitle : '-',
                incharge: item.incharge ? item.incharge : '-',
                schoolName: item.projSchoolTitle ? item.projSchoolTitle : '-',
                isVeto: item.isVeto ? '是' : '否',
                isScore: item.status === 2 ? '是' : '否',
                score: item.score,
                appraiseText: item.appraiseText,
                vetoText: item.vetoText
              })
            })
            loaded += results.length // 更新已加载记录数
            this.progress = Math.round((loaded / count) * 100)
            hasMoreData = !!next
            page++
          } else {
            hasMoreData = false // No more data to fetch
          }
        }
      } catch (e) {
      } finally {
        this.isLoading = false
      }
      this.progress = 100
      if (exportArray.length > 1) {
        if (!this.cancelRequested) {
          const fileName = '专家评审结果.csv'
          this.$toast.success('导出成功', '', this.success_tips)
          outputTable(exportArray, fileName)
        } else {
          this.$toast.success('已取消', '', this.success_tips)
        }
      } else {
        this.$toast.error('暂无信息可导出！', '', this.error_tips)
      }
    },

    // 打开查看详情弹窗
    showDetailWindow () {
      this.classShowDetailWindow = {
        visibility: 'visible'
      }
    },
    // 关闭查看详情弹窗
    hideDetailWindow () {
      this.classShowDetailWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  // 计算属性
  computed: {
    // 已签承诺书比例
    signedNum () {
      return (this.signData.expert_readed / this.signData.expert_all).toFixed(2) * 100 + '%'
    },
    // 已评审作品人数比例
    reviewNum () {
      return (this.scoreData.expert_scored / this.signData.expert_all).toFixed(2) * 100 + '%'
    },
    // 已评作品比例
    worksNum () {
      return (this.itemScoredData.item_scored / this.auditItemCount).toFixed(2) * 100 + '%'
    },
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    },
    dict_result () {
      return this.$store.state.dict_result
    }
  },
  mounted () {
    this.search()
    // this.getPersonNum()
    this.getSignNum()
    this.getScoredNum()
    this.getItemScoredNum()
    this.getAuditItemCount()
  }
}
</script>

<style scoped>
#list {
  min-height: 400px;
}

.analisys-box {
  margin-bottom: 15px;
}

.a-item {
  width: 30%;
  margin-right: 5%;
  float: left;
}

.a-item:last-child {
  margin-right: 0;
}

.a-wrap {
  border: 1px solid #f0f0f0;
  padding: 15px;
  border-radius: 5px;
  line-height: 2em;
}

.a-item span {
  display: inline-block;
}

.a-item .a-title {
  width: 150px;
}

.a-item .a-num {
  float: right;
}

#expert_detail_overlay .line {
  margin-bottom: 20px;
}

#expert_detail_overlay .line span {
  display: inline-block;
  margin-right: 30px;
  font-size: 14px;
  color: #666;
}

#expert_detail_overlay .line span strong {
  color: #333;
  display: inline-block;
  /* min-width: 100px; */
}

.xoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.xmodal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.progress-bar {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 0;
}

.progress-bar-inner {
  height: 20px;
  background-color: #4caf50;
  text-align: center;
  color: white;
  line-height: 20px;
}
</style>
