<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="2"
        :audit_status="audit_status"
      ></audit-tab>
    </div>
    <div
      class="audit_content seendtime audit_group clearfix"
      style="display: block;"
    >
      <div class="group_head">
        <h3>{{auditgroupInfo.title}}</h3>

        <span>目前本组有专家<b>{{auditgroupInfo.expert_counts}}</b>名、参评作品有<b>{{auditgroupInfo.item_counts}}</b>个。</span>
        <router-link :to="'/admin/audit/maintain/tab3?id=' + param_id">返回分组列表</router-link>
      </div>
      <div class="group_title">
        <span class="item">专家</span>
        <span
          v-if="audit_status == 2"
          class="add-btn add-expert sprite_4"
          @click="getGroupLeaderInfo"
        >添加专家</span>
      </div>
      <div
        class="search-box"
        id="expert-search"
      >
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm"
            v-if="isOpen"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="username"
                placeholder="专家账号"
                v-model="expertQueryInfo.username"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="name"
                placeholder="专家姓名"
                v-model="expertQueryInfo.name"
              ></div>
            <div class="form-group"><button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          @click="isOpen=!isOpen"
        >{{isOpen?'关闭':'展开'}}</span>
      </div>
      <div
        class="list f_list"
        id="expert_list"
      >
        <table>
          <tbody>
            <tr class="l_header">
              <th>专家账号</th>
              <th>专家手机号</th>
              <th>专家姓名</th>
              <th>性别</th>
              <th>所属学校</th>
              <th>一级学科</th>
              <th>二级学科</th>
              <th
                width="160"
                v-if="audit_status == 2"
              >操作</th>
            </tr>
            <tr
              class="item"
              v-for="item in expertData"
              :key="item.id"
            >
              <td class="itemTitle">{{item.expert.username}}</td>
              <td class="itemTitle">{{item.expert.mobile?item.expert.mobile:'-'}}</td>
              <td class="itemTitle">{{item.expert.name}}</td>
              <td class="itemTitle">{{formatGender(item.expert.gender)}}</td>
              <td class="itemTitle">{{item.expert.school?item.expert.school.title:'-'}}</td>
              <td class="itemTitle">{{item.expert.subject01?item.expert.subject01.title:'-'}}</td>
              <td class="itemTitle">{{item.expert.subject02?item.expert.subject02.title:'-'}}</td>
              <td
                class=""
                v-if="audit_status == 2"
              >
                <a
                  class="btn delete"
                  href="javascript:void(0)"
                  @click="delExperItem(item.id)"
                >移除</a>
              </td>
            </tr>
          </tbody>

        </table>
        <div
          class="noitems"
          v-if="!expertData.length"
        >没有数据</div>
        <!-- 分页 -->
        <pagination
          v-if="expertData.length"
          :cur='pageInfo.page'
          :all='pageInfo.all'
          :totalData="pageInfo.totalData"
          :callback="pageCallback"
        ></pagination>
      </div>

      <div class="group_title">
        <span class="item">参评作品</span>
        <span
          v-if="audit_status == 2"
          class="add-btn add-works sprite_4"
          @click="showWorksWindow"
        >添加作品</span>
      </div>
      <div
        class="search-box"
        id="work-search"
      >
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm2"
            v-if="isShow"
            style="display: block; height: auto;"
          >
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="projId"
                placeholder="作品编码"
                v-model="queryJoinWorksInfo.projId"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="title"
                placeholder="项目名称"
                value=""
                v-model="queryJoinWorksInfo.title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="school"
                placeholder="学校名称"
                v-model="queryJoinWorksInfo.school_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="specialtySubject"
                placeholder="所属专业大类"
                v-model="queryJoinWorksInfo.category01"
              ></div>
            <div class="form-group"><button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageJoinWorksCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          data-reset=""
          @click="isShow=!isShow"
        >{{isShow?'关闭':'展开'}}</span>
      </div>
      <div
        class="list f_list"
        id="works_list"
      >
        <table>
          <tbody>
            <tr class="l_header">
              <th>作品名称</th>
              <th>学校</th>
              <th>负责人</th>
              <th>所属专业类</th>
              <th
                width="120"
                v-if="audit_status == 2"
              >操作</th>
            </tr>
            <tr
              v-for="item in joinWorksTableData"
              :key="item.id"
            >
              <td><a
                  href="javascript:void(0)"
                  class="a-link"
                ><span>{{item.title}}</span></a></td>
              <td>{{item.schoolTitle?item.schoolTitle:'-'}}</td>
              <td>{{item.incharge}}</td>
              <td>{{item.category02}}</td>
              <td
                class="action"
                v-if="audit_status == 2"
              >
                <a
                  class="btn delete"
                  href="javascript:void(0)"
                  @click="delItem(item.id)"
                >移除</a>
              </td>
            </tr>

          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!joinWorksTableData.length"
        >没有数据</div>
        <!-- 分页 -->
        <pagination
          v-if="joinWorksTableData.length"
          :cur='pageJoinWorksInfo.page'
          :all='pageJoinWorksInfo.all'
          :totalData="pageJoinWorksInfo.totalData"
          :callback="pageJoinWorksCallback"
        ></pagination>
      </div>

    </div>
    <!-- 选择组长弹窗 -->
    <div
      id="c-panel"
      :style="classShowGroupleaderWindow"
    >
      <div id="c-panel-bg"></div>
      <div class="c-content">
        <form
          id="search"
          class="search"
          onsubmit="return false;"
        >
          <select
            name="subject01"
            class="s-text searchSelect "
            id="subject01"
            v-model="queryGroupLeaderInfo.subject01_id"
            @change="getSsecondLevelInfo(queryGroupLeaderInfo.subject01_id)"
          >
            <option value="">请选择一级学科</option>
            <option
              v-for="item in categoryData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <select
            name="subject02"
            class="s-text searchSelect "
            id="subject02"
            v-model="queryGroupLeaderInfo.subject02_id"
          >
            <option value="">请选择二级学科</option>
            <option
              v-for="item in secondLevelData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <input
            type="text"
            name="name"
            class="s-text short"
            placeholder="姓名"
            v-model="queryGroupLeaderInfo.name"
          >
          <input
            type="text"
            name="referral"
            class="s-text short"
            placeholder="推荐来源"
            v-model="queryGroupLeaderInfo.source"
          >
          <input
            type="button"
            class="s-btn1"
            value="查询"
            @click="pageGroupLeaderCallback(1)"
          >
          <span
            class="close-cpanel"
            @click="hideGroupLeaderWindow"
          ><i class="iconfont"></i>

          </span>
        </form>
        <div id="uList">
          <table>
            <tbody>
              <tr class="l_header">
                <th class="itemTitle">用户名</th>
                <th class="itemTitle">手机号</th>
                <th class="itemCategory">姓名</th>
                <th class="itemCategory">性别</th>
                <th class="itemCategory">学校</th>
                <th class="itemCategory">一级学科</th>
                <th class="itemCategory">二级学科</th>
                <th class="itemCategory">推荐来源</th>
                <th class="action">操作</th>
              </tr>
              <tr
                v-for="item in groupLeaderData"
                :key="item.id"
              >
                <td>{{item.username}}</td>
                <td>{{item.mobile?item.mobile:'-'}}</td>
                <td>{{item.name}}</td>
                <td>{{formatGender(item.gender)}}</td>
                <td>{{item.school?item.school.title:'-'}}</td>
                <td>{{item.subject01?item.subject01.title:'-'}}</td>
                <td>{{item.subject02?item.subject02.title:'-'}}</td>
                <td>{{item.source}}</td>

                <td v-if="item.isSelect">-</td>
                <td v-else><a
                    href="javascript:void(0)"
                    @click="selectItem(item)"
                  >选择</a></td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!groupLeaderData.length"
          >没有数据</div>
        </div>
        <!-- 分页 -->
        <pagination
          v-if="groupLeaderData.length"
          :cur='pageGroupLeaderInfo.page'
          :all='pageGroupLeaderInfo.all'
          :totalData="pageGroupLeaderInfo.totalData"
          :callback="pageGroupLeaderCallback"
        ></pagination>
      </div>
    </div>
    <!-- 添加作品弹窗 -->
    <div
      id="w-panel"
      :style="classShowWorksWindow"
    >
      <div id="w-panel-bg"></div>
      <div class="c-content">
        <form
          id="wSearch"
          onsubmit="return false;"
        >
          <input
            type="text"
            name="specialtySubject"
            class="s-text short"
            placeholder="所属专业"
            v-model="queryWorksInfo.category01"
          >
          <input
            type="text"
            name="name"
            class="s-text"
            placeholder="作品名称"
            v-model="queryWorksInfo.title"
          >
          <input
            type="text"
            name="remarks"
            class="s-text short"
            placeholder="备注"
            v-model="queryWorksInfo.remarks"
          >
          <input
            type="button"
            class="s-btn1"
            value="查询"
            @click="pageWorksCallback(1)"
          >
          <span class="close-cpanel"><i
              class="iconfont"
              @click="hideWorksWindow"
            ></i></span>
        </form>
        <div id="wList">
          <table>
            <tbody>
              <tr class="l_header">
                <th><a
                    href="javascript:;"
                    class="selectAllPro"
                    @click="ckAll"
                  >全选</a></th>
                <th>作品名称</th>
                <th>隶属学校</th>
                <th>负责人</th>
                <th>所属专业类</th>
                <th>备注</th>
                <th>操作</th>
              </tr>
              <tr
                v-for="item in worksTableData"
                :key="item.id"
              >
                <td><input
                    type="checkbox"
                    name="chose"
                    :value="item.id"
                    v-model="ckUser"
                  ></td>
                <td><span title="测试">{{formatTitle(item.title)}}</span></td>
                <td>{{item.schoolTitle?item.schoolTitle:'-'}}</td>
                <td>{{item.incharge}}</td>
                <td>{{item.category02}}</td>
                <td>{{item.remarks}}</td>
                <td><a
                    class="a-button"
                    href="javascript:void(0)"
                    @click="changeGroup(item.id)"
                  >选择</a></td>
              </tr>
            </tbody>
          </table>
          <div
            class="noitems"
            v-if="!worksTableData.length"
          >没有数据</div>
        </div>
        <input
          type="button"
          class="add-btn"
          id="batchSelectPro"
          value="批量选择"
          @click="batchSelection"
        >
        <!-- 分页 -->
        <pagination
          v-if="worksTableData.length"
          :cur='pageWorksInfo.page'
          :all='pageWorksInfo.all'
          :totalData="pageWorksInfo.totalData"
          :callback="pageWorksCallback"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab3Maintain',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      tableData: [],
      tableDataCount: 0,
      groupLeaderData: [],
      groupLeaderDataCount: 0,
      expertData: [],
      expertDataCount: 0,
      worksTableData: [],
      worksTableDataCount: 0,
      joinWorksTableData: [],
      joinWorksTableDataCount: 0,
      expertQueryInfo: {
        page: 1,
        size: 10,
        username: '',
        name: '',
        auditGroup_id: this.$route.query.groupId
      },
      queryGroupLeaderInfo: {
        page: 1,
        size: 10,
        subject01_id: '',
        subject02_id: '',
        name: '',
        source: '',
        role: 1
      },
      queryWorksInfo: {
        page: 1,
        size: 10,
        category01: '',
        title: '',
        remarks: '',
        auditGroup_id: '',
        audit_id: this.param_id
      },
      queryJoinWorksInfo: {
        page: 1,
        size: 10,
        auditGroup_id: this.$route.query.groupId,
        projId: '',
        title: '',
        school_title: '',
        category01: ''
      },
      classShowGroupleaderWindow: {},
      classShowWorksWindow: {},
      ckUser: [],
      isOpen: false,
      isShow: false,
      auditgroupInfo: {},
      categoryData: [],
      secondLevelData: [],
      auditExpertData: []
    }
  },
  methods: {
    formatTitle (val) {
      if (val.length > 20) {
        return val.substring(0, 20) + '...'
      } else {
        return val
      }
    },
    // 全选
    ckAll () {
      if (this.ckUser.length === this.worksTableData.length) {
        this.ckUser = []
      } else {
        for (let i = 0; i < this.worksTableData.length; i++) {
          this.ckUser.push(this.worksTableData[i].id)
        }
      }
    },
    // 批量选择
    async  batchSelection () {
      if (this.ckUser.length === 0) {
        this.$toast.error('请先选择记录！', '', this.error_tips)
      } else {
        for (let i = 0; i < this.worksTableData.length; i++) {
          if (this.ckUser.indexOf(this.worksTableData[i].id) >= 0) {
            await this.changeGroup(this.worksTableData[i].id)
          }
        }
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
        this.hideWorksWindow()
        this.ckUser = []
      }
    },
    formatGender (val) {
      if (val !== '') {
        return val === '1' ? '男' : '女'
      } else {
        return '-'
      }
    },
    // 获取一级专业
    async getCategoryInfo () {
      const params = {
        page: 1,
        size: 10000,
        level: 1
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取二级专业
    async getSsecondLevelInfo (id) {
      const params = {
        page: 1,
        size: 10000,
        level: 2,
        parent_id: id
      }
      this.secondLevelData = []
      this.queryGroupLeaderInfo.subject02_id = ''
      if (id) {
        await this.$http.get('/subject/', { params }).then((data) => {
          this.secondLevelData = data.results
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        this.secondLevelData = []
      }
    },
    async getAuditgroupInfo () {
      // 使用查询快的接口代替auditgroup
      const url = '/auditgroup_search/' + this.$route.query.groupId + '/'
      await this.$http.get(url).then((data) => {
        this.auditgroupInfo = data
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async selectItem (item) {
      console.log(item)
      debugger
      await this.getAuditGroupExpert(item.id)
      if (!this.auditExpertData.length) {
        item.isSelect = true
        await this.$http.post('/auditgroup_expert/', {
          expert_id: item.id,
          auditGroup_id: this.$route.query.groupId
        }).then((data) => {
          this.search()
          this.getAuditgroupInfo()
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        this.$toast.error('该专家已被其它分组使用', '', this.error_tips)
      }
    },
    // 为作品分配组
    async changeGroup (id) {
      const url = '/audititem/' + id + '/'

      await this.$http.put(url, {
        auditGroup_id: this.$route.query.groupId,
        // 分配组的时候同时分配组名 冗余优化
        groupTitle: this.auditgroupInfo.title
      }).then((data) => {
        this.getWorksInfo()
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getAuditGroupExpert (id) {
      const params = {
        page: 1,
        size: 100000,
        username: '',
        name: '',
        auditGroup_id: '',
        expert_id: id,
        audit_id: this.param_id
      }
      await this.$http.get('/auditgroup_expert/', { params }).then((data) => {
        this.auditExpertData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async search () {
      await this.$http.get('/auditgroup_expert/', {
        params: this.expertQueryInfo
      }).then((data) => {
        this.expertData = data.results
        this.expertDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async getGroupLeaderInfo () {
      // 这个方法中 原开发人员本意是先获取所有已经分配的人员
      // 如果人员已经分配 选择按钮就会变成 "-",但是忽略了性能 这个是bug会把服务器弄崩需要优化
      // 索性不如全放开，如果选中了已经添加的专家就给出提示，提示专家已经在当前评审项目中
      // await this.getAuditGroupExpert()
      await this.$http.get('/userprofile/', {
        params: this.queryGroupLeaderInfo
      }).then((data) => {
        // for (let i = 0; i < data.results.length; i++) {
        //   data.results[i].isSelect = false
        //   for (let j = 0; j < this.auditExpertData.length; j++) {
        //     if (this.auditExpertData[j].expert_id === data.results[i].id) {
        //       data.results[i].isSelect = true
        //     }
        //   }
        // }
        this.groupLeaderData = data.results
        this.groupLeaderDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
      this.showGroupLeaderWindow()
    },
    async getWorksInfo () {
      // 优化查询作品
      await this.$http.get('/audititem_search/', {
        params: this.queryWorksInfo
      }).then((data) => {
        this.worksTableData = data.results
        this.worksTableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 查询本组的参评作品列表，需要进行优化
    async getJoinGroupWorks () {
      await this.$http.get('/audititem_search/', {
        params: this.queryJoinWorksInfo
      }).then((data) => {
        this.joinWorksTableData = data.results
        this.joinWorksTableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    delExperItem (id) {
      const url = '/auditgroup_expert/' + id + '/'
      this.$http.delete(url).then((data) => {
        this.search()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    delItem (id) {
      const url = '/audititem/' + id + '/'
      this.$http.put(url, {
        auditGroup_id: null
      }).then((data) => {
        this.getJoinGroupWorks()
        this.getAuditgroupInfo()
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 打开分组管理弹窗
    showGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {
        display: 'block'
      }
    },
    // 关闭分组管理弹窗
    hideGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {}
    },
    // 打开添加作品弹窗
    showWorksWindow () {
      this.classShowWorksWindow = {
        display: 'block'
      }
      this.queryWorksInfo = {
        page: 1,
        size: 10,
        category01: '',
        title: '',
        remarks: '',
        auditGroup_id: '',
        audit_id: this.param_id
      }
      this.getWorksInfo()
    },
    // 关闭添加作品弹窗
    hideWorksWindow () {
      this.classShowWorksWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.expertQueryInfo.page = data
      await this.search()
    },
    // 分页
    async pageJoinWorksCallback (data) {
      this.queryJoinWorksInfo.page = data
      await this.getJoinGroupWorks()
    },
    // 分页
    async pageWorksCallback (data) {
      this.queryWorksInfo.page = data
      await this.getWorksInfo()
    },
    // 分页
    async pageGroupLeaderCallback (data) {
      this.queryGroupLeaderInfo.page = data
      await this.getGroupLeaderInfo()
    }
  },
  mounted () {
    this.getAuditgroupInfo()
    this.search()
    this.getJoinGroupWorks()
    this.getCategoryInfo()
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.expertQueryInfo.page,
        all: Math.ceil(this.expertDataCount / this.expertQueryInfo.size),
        totalData: this.expertDataCount
      }
    },
    pageJoinWorksInfo () {
      return {
        page: this.queryJoinWorksInfo.page,
        all: Math.ceil(this.joinWorksTableDataCount / this.queryJoinWorksInfo.size),
        totalData: this.joinWorksTableDataCount
      }
    },
    pageWorksInfo () {
      return {
        page: this.queryWorksInfo.page,
        all: Math.ceil(this.worksTableDataCount / this.queryWorksInfo.size),
        totalData: this.worksTableDataCount
      }
    },
    pageGroupLeaderInfo () {
      return {
        page: this.queryGroupLeaderInfo.page,
        all: Math.ceil(this.groupLeaderDataCount / this.queryGroupLeaderInfo.size),
        totalData: this.groupLeaderDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.list {
  overflow: hidden;
}
</style>
