<template>
  <div
    class="container"
    style="min-height: 668px;"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">会评评审分组</span>
      <div class="btns">
        <span
          class="btn import"
          @click="$router.go(-1)"
        ><i class="iconfont"></i>返回上一级</span>
      </div>
    </div>
    <div class="search-box"></div>
    <div
      id="list"
      class="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>分组名称</th>
            <th>作品数</th>
            <th width="120">操作</th>
          </tr>
          <tr
            v-for="item in audits"
            :key="item.id"
          >
            <td>{{item.title}}</td>
            <td id="w_num_17">{{item.item_count}}</td>
            <td><span
                class="btn import"
                @click="$router.push({path:'/admin/my_meeting_item',query: {id:item.id}})"
              >评审详情</span></td>
          </tr>
        </tbody>
      </table>
      <pagination
        v-if="audits.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>

  </div>
</template>

<script>
import Pagination from '../../components/Pager'
import { mapState } from 'vuex'
export default {
  name: 'MyMeetingGroup',
  data () {
    return {
      audits_count: 0,
      audits: [],
      queryInfo: {
        page: 1,
        size: 10
      }
    }
  },
  mounted () {
    this.getAudits()
  },
  components: {
    Pagination
  },
  computed: {
    ...mapState(['userInfo']),
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.audits_count / this.queryInfo.size),
        totalData: this.audits_count
      }
    }
  },
  methods: {
    pageCallback (data) {
      this.queryInfo.page = data
      this.getAudits()
    },
    async getAudits () {
      try {
        this.queryInfo.expert_id = this.userInfo.profile_id
        const { results } = await this.$http.get('/auditmetinggroup/', { params: this.queryInfo })
        this.audits = results
        this.audits_count = results.length
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.cN-pagination {
  font-size: 0.92em;
  padding: 0.75em 0;
  text-align: right;
  margin-top: 30px;
}
</style>
