<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="3"
        :audit_status="audit_status"
      ></audit-tab>

      <span
        class="import-course-btn btn-import hide sprite_4"
        @click="showFilesWindow(3)"
      >导入相关课程</span>

      <span
        class="import-veto-btn btn-import hide sprite_4"
        style="display: inline-block;"
        @click="showFilesWindow(2)"
      >导入否决性指标</span>
      <span
        class="import-proj-btn btn-import sprite_4"
        @click="showFilesWindow(1)"
      >导入作品</span>
      <span
        class="add-proj-btn btn-add sprite_4"
        @click="showWindow('add')"
      >新建作品</span>
      <span
        class="export-btn btn-export sprite_6"
        id="download"
        style="display: none;"
      >导出评审详情</span>
      <span
        class="export-btn btn-export sprite_7"
        data="1"
        id="download2"
        style="display: none;"
      >导出专家评审记录</span>
      <span
        class="export-btn btn-import sprite_6"
        id="save-storage"
        style="display: none;"
      >保存数据详情</span>
      <span
        class="export-btn btn-export sprite_7"
        id="showDetail"
        style="display: none;"
      >查看评审详情</span>
    </div>
    <div
      class="audit_content seendtime audit_cour clearfix"
      style="display: block;"
    >
      <div class="search-box">
        <transition name="fade">
          <form
            class="form-inline"
            id="filterForm"
            v-if="isOpen"
            style="display: block; height: auto;"
          >

            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="projId"
                placeholder="作品编码"
                v-model="queryInfo.projId"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="title"
                placeholder="项目名称"
                v-model="queryInfo.title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="school"
                placeholder="学校名称"
                v-model="queryInfo.school_title"
              ></div>
            <div class="form-group"><input
                type="text"
                class="form-control input-sm"
                name="specialtySubject"
                placeholder="所属专业类"
                v-model="queryInfo.category02"
              ></div>
            <div class="form-group">
              <select
                class="form-control input-sm"
                name="isZhen"
                v-model="queryInfo.isZhen"
              >
                <option value="">一流专业</option>
                <option value="1">是</option>
                <option value="2">否</option>
              </select>
            </div>
            <div class="form-group">
              <button
                type="button"
                class="btn btn-primary btn-rounded btn-search"
                @click="pageCallback(1)"
              ><i class="fa fa-search text-xs m-r-xs"></i>搜索
              </button>
            </div>

          </form>
        </transition>
        <span
          class="btn btn-danger btn-open"
          @click="toggleQueryForm"
        >{{isOpen?'关闭':'展开'}}</span>
      </div>
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th>作品名称</th>
              <th>作品编码</th>
              <th>所属专业类</th>
              <th>学校</th>
              <th>负责人</th>
              <th>一流专业建设点</th>
              <th>分组</th>
              <th width="220">操作</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td><a
                  class="a-link"
                  href="javascript:void(0)"
                ><span title="">{{item.title}}</span></a></td>
              <td>{{item.projId}}</td>
              <td>{{item.category02}}</td>

              <td>{{item.schoolTitle?item.schoolTitle:'-'}}</td>
              <td>{{item.incharge}}</td>
              <td>{{item.isZhen==1?'是':'否'}}</td>
              <td>{{item.groupTitle?item.groupTitle:'-'}}</td>
              <td><a
                  href="javascript:void(0)"
                  class="btn delete"
                  @click="delInfo(item.id)"
                >移除</a><a
                  href="javascript:void(0)"
                  class="btn edit"
                  @click="querySingle(item.id)"
                >编辑</a><a
                  href="javascript:void(0)"
                  class="btn manage"
                  @click="showVetoWindow(item.id)"
                >否决性指标</a><a
                  href="javascript:void(0)"
                  class="btn preview"
                  @click="getAudititemCourse(item.id)"
                >相关课程</a>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        >没有数据</div>
      </div>
      <!-- 分页 -->
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      ></pagination>
    </div>
    <!-- 新增/编辑弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay_proj"
      :style="classShowWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">作品管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="itemFormProj"
          >
            <div class="item">
              <span class="label"><i class="require">*</i>作品编码:</span>
              <input
                type="text"
                name="projId"
                placeholder="作品编码如：1000"
                v-model="formInfo.projId"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>作品名称:</span>
              <input
                type="text"
                name="title"
                placeholder="申报作品名称"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>申报年份:</span>
              <input
                type="text"
                name="declareYear"
                placeholder="作品申报年份，如：2019"
                v-model="formInfo.declareYear"
              >
            </div>
            <div class="item">
              <span class="check-label"><i class="require"></i>一流专业:</span>
              <label class="check-label">
                <input
                  class="elevel"
                  name="isZhen"
                  type="radio"
                  value="1"
                  v-model="formInfo.isZhen"
                >是</label>
              <label class="check-label">
                <input
                  class="elevel"
                  name="isZhen"
                  type="radio"
                  value="2"
                  v-model="formInfo.isZhen"
                >否</label>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专业大类:</span>
              <input
                type="text"
                name="specialtySubject"
                placeholder="填写申报专业大类"
                v-model="formInfo.category01"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>专业分类:</span>
              <input
                type="text"
                name="specialtySubject2"
                placeholder="填写申报专业分类"
                v-model="formInfo.category02"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>作品链接:</span>
              <input
                type="text"
                name="projUrl"
                placeholder="填写申报作品地址，如：http://www.ilab-x.com/details/2020?id=6362"
                v-model="formInfo.projUrl"
              >
            </div>
            <div class="item">
              <div
                id="distpicker"
                class="distpicker"
              >
                <span class="label"><i class="require">*</i>省份:</span>
                <span class="choice">
                  <select
                    name="province"
                    data-province="请选择省"
                    v-model="formInfo.province"
                    @change="getSchoolInfo(formInfo.province)"
                  >
                    <option
                      value=""
                      data-code=""
                    >请选择省</option>
                    <option
                      v-for="item in dict_province"
                      :key="item.code"
                      :value="item.code"
                    >{{item.title}}</option>
                  </select>
                </span>
              </div>
            </div>

            <div class="item">
              <span class="label"><i class="require">*</i>申报作品所属院校:</span>
              <div class="choose">
                <span class="choice">
                  <select
                    name="school"
                    v-model="formInfo.school_id"
                    @change="(e)=>{SchoolChange(e,formInfo.school_id)}"
                  >
                    <option value="">请选择学校</option>
                    <option
                      v-for="item in schoolData"
                      :key="item.id"
                      :value="item.id"
                    >{{item.title}}</option>
                  </select>
                </span>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>院校名称:</span>
              <input
                type="text"
                readonly
                name="schoolTitle"
                placeholder="填写院校名称"
                v-model="formInfo.schoolTitle"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>作品负责人:</span>
              <input
                type="text"
                name="incharge"
                placeholder="填写申报作品负责人"
                v-model="formInfo.incharge"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require"></i>备注:</span>
              <input
                type="text"
                name="remarks"
                placeholder=""
                v-model="formInfo.remarks"
              >
            </div>
            <!-- <div class="item hide">
              <span class="label"><i class="require"></i>形式审查其他内容:</span>

              <div class="k-container formal-box clearfix"></div>
              <div class="add-one add-one-formal">添加一条</div>
            </div> -->

          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit_proj"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 导入弹窗 -->
    <div
      class="form_overlay"
      id="form_upload_overlay we"
      :style="showFileWindow.bigFile ? 'visibility: visible' : ''"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideFilesWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div
              class="item"
              v-if="showFileWindow.worksUpload"
            >
              <label
                for="name"
                class="control-label col-xs-3 font-normal text-muted"
              ><i class="require">*</i>模板创建规则:</label>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：projId（作品编码）、title（作品名称）、declareYear（申报年份）、province（省份）、specialtySubject（专业大类）、specialtySubject2（专业分类）、projUrl（作品链接）、schoolCode（学校代码）、incharge（作品负责人）、remarks（备注）、isZhen（是否一流专业）；</p>
                  <p>3. 其中作品编码、作品名称、申报年份、省份、专业大类、专业分类、作品链接、学校代码、作品负责人为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_items_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div
              class="item"
              v-if="showFileWindow.vetoUpload"
            >
              <label
                for="name"
                class="control-label col-xs-3 font-normal text-muted"
              ><i class="require">*</i>模板创建规则:</label>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：projId（作品编码）、vetoId（否决性指标编码）、isVeto（否决 1-否决 2-不否决）、desc（详情）、markNum（标记 1-标红 2-正常）；</p>
                  <p>3. 其中作品编码、否决性指标编码、否决为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_veto_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div
              class="item"
              v-if="showFileWindow.courseUpload"
            >
              <label
                for="name"
                class="control-label col-xs-3 font-normal text-muted"
              ><i class="require">*</i>模板创建规则:</label>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：projId（作品编码）、courseId（课程ID）、title（课程名称）、url（课程链接）、type（课程类型，1-负责人以往认定；2-同专业类课程）、incharge(负责人)、mainTeam(团队主要成员)、schoolTitle(学校)、declareYear(认定年份)；</p>
                  <p>3. 其中作品编码、课程ID、课程名称、课程链接、课程类型、负责人、团队主要成员、学校、认定年份为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_course_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div class="item">
              <label
                for="name"
                class="label font-normal text-muted"
              ><i class="require">*</i>导入文件:</label>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="itemUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="itemUpload"
                    ref="xlsFile"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideFilesWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>

    <!-- 否决性指标列表弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowVetoWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel large-editor">
        <div class="form_title">否决性指标</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideVetoWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content clearfix">
          <div
            class="list"
            id="auditList"
          >
            <table>
              <tbody>
                <tr class="l_header">
                  <th width="100">指标编码</th>
                  <th width="100">指标名称</th>
                  <th>详情</th>
                  <th width="100">否决</th>
                  <th width="90">操作</th>
                </tr>

                <tr
                  class="veto_item_14808"
                  v-for="item in vetoData"
                  :key="item.id"
                >
                  <td>{{item.id}}</td>
                  <td>{{item.title}}</td>
                  <td :style="item.markNum==1?'color:red':''"><span v-html="item.desc"></span></td>
                  <td>{{item.isVeto==1?'是':'否'}}</td>
                  <td><a
                      href="javascript:void(0)"
                      class="btn delete"
                      v-if="item.delType"
                      @click="delAudititemVeto(item)"
                    >删除</a>
                    <a
                      href="javascript:void(0)"
                      v-else
                    >-</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
        <div
          class="form_control"
          style="padding-left:20px"
        >

          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="hideVetoWindow"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 相关课程列表弹窗 -->
    <div
      class="form_overlay"
      id="course_detail_overlay"
      :style="classShowCourseListWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">相关课程</div>
        <div class="form_content">
          <div class="list">
<!--            去掉负责人往年认定课程-->
<!--            <table>-->
<!--              <tbody>-->
<!--                <tr>-->
<!--                  <td colspan="6"><strong>负责人往年认定课程，供专家比对</strong></td>-->
<!--                </tr>-->
<!--                <tr class="l_header">-->
<!--                  <th>课程名称</th>-->
<!--                  <th width="90">负责人</th>-->
<!--                  <th width="120">团队主要成员</th>-->
<!--                  <th width="120">学校</th>-->
<!--                  <th width="70">认定年份</th>-->
<!--                  <th width="60">操作</th>-->
<!--                </tr>-->
<!--                <tr-->
<!--                  v-for="item in courseData"-->
<!--                  :key="item.id"-->
<!--                >-->
<!--                  <template v-if="item.type==1">-->
<!--                    <td>{{item.title}}</td>-->
<!--                    <td>{{item.incharge}}</td>-->
<!--                    <td>{{item.mainTeam}}</td>-->
<!--                    <td>{{item.schoolTitle}}</td>-->
<!--                    <td>{{item.declareYear}}</td>-->
<!--                    <td><a-->
<!--                        href="javascript:void(0)"-->
<!--                        class="btn preview"-->
<!--                        @click="jumpToIlabx(item)"-->
<!--                      >查看</a></td>-->
<!--                  </template>-->
<!--                </tr>-->
<!--                <tr>-->
<!--                  <td-->
<!--                    colspan="6"-->
<!--                    v-if="!courseData.length"-->
<!--                  >-->
<!--                    <div class="noitems">无相关课程</div>-->
<!--                  </td>-->
<!--                </tr>-->
<!--              </tbody>-->
<!--            </table>-->
            <table>
              <tbody>
                <tr>
                  <td colspan="6"><strong>同专业类认定课程，供考虑课程结构布局时参考</strong></td>
                </tr>
                <tr class="l_header">
                  <th>课程名称</th>
                  <th width="90">负责人</th>
                  <th width="120">团队主要成员</th>
                  <th width="120">学校</th>
                  <th width="70">认定年份</th>
                  <th width="60">操作</th>
                </tr>
                <tr
                  v-for="item in courseData"
                  :key="item.id"
                >
                  <template v-if="item.type==2">
                    <td>{{item.title}}</td>
                    <td>{{item.incharge}}</td>
                    <td>{{item.mainTeam}}</td>
                    <td>{{item.schoolTitle}}</td>
                    <td>{{item.declareYear}}</td>
                    <td><a
                        href="javascript:void(0)"
                        class="btn preview"
                        @click="jumpToIlabx(item)"
                      >查看</a></td>
                  </template>
                </tr>
                <tr>
                  <td
                    colspan="6"
                    v-if="!courseData.length"
                  >
                    <div class="noitems">无相关课程</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hideCourseListWindow"
          >关闭</a></div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'
import { getUrlParams, openPage } from '../../../util/utils'

export default {
  name: 'MaintainTab4',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status'],
  data () {
    return {
      tableData: [],
      tableDataCount: 0,
      vetoData: [],
      vetoDataCount: 0,
      courseData: [],
      courseDataCount: 0,
      classShowWindow: {},
      classShowVetoUploadWindow: {},
      classShowVetoWindow: {},
      classShowWorksWindow: {},
      classShowCourseWindow: {},
      classShowCourseListWindow: {},
      classShowProcessWindow: {},
      queryInfo: {
        page: 1,
        size: 10,
        projId: '',
        title: '',
        school_title: '',
        category02: '',
        isZhen: '',
        audit_id: this.param_id
      },
      formInfo: {
        projId: '',
        title: '',
        declareYear: '',
        isZhen: 2,
        category01: '',
        category02: '',
        projUrl: '',
        province: '',
        incharge: '',
        remarks: '',
        result: null,
        school_id: '',
        // 增加院校名称 为了展示优化
        schoolTitle: '',
        audit_id: this.param_id
      },
      schoolData: [],
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
      audititemInfo: [],
      isOpen: false,
      showFileWindow: {
        bigFile: false,
        worksUpload: false,
        vetoUpload: false,
        courseUpload: false,
        bigFileOption: {
          showProgress: false,
          progressValue: 0
        }
      },
      importVetoInfo: []
    }
  },
  methods: {
    async jumpToIlabx (item) {
      debugger
      // 获取token
      console.log(item)
      await this.$http.post('/xjwt/', {}).then((res) => {
        if (res.token) {
          // 获取token成功 拼接url进行跳转
          const url = item.url
          const ps = getUrlParams(url)
          const data = []
          for (const k in ps) {
            data.push({
              paramName: k,
              paramValue: ps[k]
            })
          }
          data.push({
            paramName: 'token',
            paramValue: res.token
          })
          openPage(url.split('?')[0], 'GET', data, '_blank')
        } else {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        }
      })
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    async getVetoInfo () {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id
      }
      await this.$http.get('/normveto/', { params }).then(async (data) => {
        this.vetoData = data.results
        this.vetoDataCount = data.count
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 相关课程列表
    async getAudititemCourse (id) {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        auditItem_id: id
      }
      await this.$http.get('/audititem_course/', { params }).then(async (data) => {
        this.courseData = data.results
        this.courseDataCount = data.count
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
      this.showCourseListWindow()
    },
    // 否决性指标列表
    async getAudititemVeto (id) {
      const params = {
        page: 1,
        size: 10000,
        audit_id: this.param_id,
        auditItem_id: id
      }
      await this.$http.get('/audititem_veto/', { params }).then(async (data) => {
        debugger
        this.importVetoInfo = data.results
        if (this.importVetoInfo.length) {
          const fix = JSON.parse(JSON.stringify(this.vetoData))
          for (let j = 0; j < this.importVetoInfo.length; j++) {
            for (let i = 0; i < fix.length; i++) {
              if (fix[i].id === this.importVetoInfo[j].normVeto) {
                fix[i].isVeto = this.importVetoInfo[j].isVeto
                fix[i].desc = this.importVetoInfo[j].desc
                fix[i].markNum = this.importVetoInfo[j].markNum
                fix[i].audititemId = this.importVetoInfo[j].id
                fix[i].delType = true
              }
            }
          }
          this.vetoData = fix
        } else {
          this.getVetoInfo()
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    async delAudititemVeto (item) {
      const url = '/audititem_veto/' + item.audititemId + '/'
      await this.$http.put(url, {
        isdel: 1
      }).then(async (data) => {
        await this.getVetoInfo()
        await this.getAudititemVeto(data.auditItem)
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 编辑时查询
    async querySingle (id) {
      const url = '/audititem/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        this.formInfo.id = id
        this.formInfo.projId = data.projId
        this.formInfo.title = data.title
        this.formInfo.declareYear = data.declareYear
        this.formInfo.isZhen = data.isZhen
        this.formInfo.category01 = data.category01
        this.formInfo.category02 = data.category02
        this.formInfo.projUrl = data.projUrl
        this.formInfo.province = data.province
        await this.getSchoolInfo(this.formInfo.province)
        this.formInfo.incharge = data.incharge
        this.formInfo.remarks = data.remarks
        this.formInfo.result = data.result
        this.formInfo.school_id = data.school_id
        // 增加一个学校名称 优化冗余字段
        this.formInfo.schoolTitle = data.schoolTitle
        this.showWindow()
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    async search () {
      // 优化一个简单的接口  /audititem 接口太重，这个阶段还不需要查询过重的数据
      await this.$http.get('/audititem_search/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 检查作品编码是否重复
    async checkProjId (val) {
      const params = {
        page: 1,
        size: 100000,
        eq_projId: val
      }
      await this.$http.get('/audititem/', { params }).then((data) => {
        this.audititemInfo = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取学校信息
    async getSchoolInfo (province, code) {
      const params = {
        page: 1,
        size: 100000,
        province: province,
        code: code
      }
      await this.$http.get('/school/', { params }).then((data) => {
        this.schoolData = data.results
        this.formInfo.school = ''
        // 同时将学校名称清空
        this.formInfo.schoolTitle = ''
      }).catch(() => {
        this.$toast.error('获取学校数据失败！', '', this.error_tips)
      })
    },
    async saveInfo () {
      if (!this.formInfo.projId) {
        this.$toast.error('作品编码不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.id) {
        await this.checkProjId(this.formInfo.projId)
        if (this.audititemInfo.length > 0) {
          this.$toast.error('作品编码不能重复', '', this.error_tips)
          return false
        }
      }

      if (!this.formInfo.title) {
        this.$toast.error('作品名称不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.category01) {
        this.$toast.error('专业大类不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.category02) {
        this.$toast.error('专业分类不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.projUrl) {
        this.$toast.error('作品链接不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.province) {
        this.$toast.error('请选择省份', '', this.error_tips)
        return false
      }
      if (!this.formInfo.school_id) {
        this.$toast.error('请选择学校', '', this.error_tips)
        return false
      }
      if (!this.formInfo.incharge) {
        this.$toast.error('作品负责人不能为空', '', this.error_tips)
        return false
      }
      if (this.formInfo.id) {
        const url = '/audititem/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('修改失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/audititem/', this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('添加失败！', '', this.error_tips)
        })
      }
    },
    delInfo (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/audititem/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },

    SchoolChange (e, schoolId) {
      if (schoolId) {
        this.formInfo.schoolTitle = e.target.selectedOptions[0].text
        return
      }
      this.formInfo.schoolTitle = ''
    },
    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$parent.showMsg('上传失败！')
      })
    },
    // 上传表格
    async submitCsv () {
      const uploadUrl = '/get_xls/'
      var params = {
        file_name: this.uploadedCsv
      }
      await this.$http.get(uploadUrl, { params }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }
        data.splice(0, 1)
        this.uploadedCscData = data
        this.hideFilesWindow()
        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    // 插入表格数据
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }

      const row = this.uploadedCscData[this.curCsvIndex]
      let addForm, url, audititemInfo
      // region 数据检查开始

      // if ((this.showFileWindow.vetoUpload || this.showFileWindow.courseUpload) && String(row[0]).trim().length !== 0) {
      await this.$http.get('/audititem/', {
        params: {
          page: 1,
          size: 100000,
          eq_projId: row[0],
          audit_id: this.param_id
        }
      }).then((data) => {
        audititemInfo = data.results
      })
      // }

      if (this.showFileWindow.worksUpload) {
        url = '/audititem/'
        addForm = {
          projId: row[0],
          title: row[1],
          declareYear: row[2],
          isZhen: row[10] === '是' ? '1' : '2',
          category01: row[4],
          category02: row[5],
          projUrl: row[6],
          province: row[3],
          incharge: row[8],
          remarks: row[9],
          result: null,
          school_id: '',
          audit_id: this.param_id
        }
        // 检查编码是否重复
        // 因为是管理员导入作品，所以这里面就不校验了，直接导入作品，减少查询 增加导入速度
        // await this.$http.get('/audititem/', {
        //   params: {
        //     page: 1,
        //     size: 100000,
        //     eq_projId: row[0],
        //     audit_id: this.param_id
        //   }
        // }).then((data) => {
        //   checkRepeat = data.results
        // })
        // if (checkRepeat.length > 0) {
        //   this.errorList.push({ code: 0, text: '[' + row[0] + ']' + '作品编码重复！' })
        //   this.curCsvIndex++
        //   await this.insertOne()
        //   return
        // }
        for (let i = 0; i < this.dict_province.length; i++) {
          if (this.dict_province[i].title === row[3]) {
            addForm.province = this.dict_province[i].code
          }
        }
        let schoolData
        await this.$http.get('/school/', {
          params: {
            page: 1,
            size: 100000,
            province: addForm.province,
            code: row[7]
          }
        }).then((data) => {
          schoolData = data.results
        })
        if (schoolData.length === 0) {
          this.errorList.push({ code: 0, text: '[' + row[7] + ']' + '学校编码与省份不匹配,请确保是该省份下的学校！' })
          this.curCsvIndex++
          await this.insertOne()
          return
        }
        addForm.school_id = schoolData[0].id
        // sunyj 增加的以后作品的学校名称导入之后就冗余保存 增大查询速度
        addForm.schoolTitle = schoolData[0].title
      } else if (this.showFileWindow.vetoUpload) {
        url = '/audititem_veto/'
        console.log(audititemInfo)
        if (audititemInfo.length === 0) {
          this.errorList.push({ code: 0, text: '[作品编码：' + row[0] + ']' + '找不到对应的作品！' })
          this.curCsvIndex++
          await this.insertOne()
          return
        }
        addForm = {
          auditItem: audititemInfo[0].id,
          normVeto: row[1],
          isVeto: row[2],
          markNum: row[3],
          desc: row[4]
        }
        let queryVetoData
        await this.$http.get('/audititem_veto/', {
          params: {
            page: 1,
            size: 10000,
            auditItem_id: audititemInfo[0].id,
            projId: row[0]

          }
        }).then(async (data) => {
          queryVetoData = data.results
        })
        if (queryVetoData.length) {
          debugger
          for (let i = 0; i < queryVetoData.length; i++) {
            if (audititemInfo[0].id === queryVetoData[i].auditItem && queryVetoData[i].normVeto === row[1]) {
              url = '/audititem_veto/' + queryVetoData[i].id + '/'
              await this.$http.put(url, addForm).then(async (data) => {
                this.errorList.push({ code: 1, text: `第${this.curCsvIndex + 1}条，导入成功！` })
                this.curCsvIndex++
                this.insertOne()
              }).catch((error) => {
                console.dir(error)
                this.errorList.push({ code: 0, text: error.response.data[0] })
                this.curCsvIndex++
                this.insertOne()
              })
            }
          }
        }
      } else if (this.showFileWindow.courseUpload) {
        url = '/audititem_course/'
        addForm = {
          courseId: row[2],
          title: row[3],
          url: row[4],
          type: row[1],
          desc: '',
          incharge: row[5],
          mainTeam: row[6],
          schoolTitle: row[7],
          declareYear: row[8],
          auditItem: audititemInfo[0].id
        }
      }
      await this.$http.post(url, addForm).then(async (data) => {
        this.errorList.push({ code: 1, text: `第${this.curCsvIndex + 1}条，导入成功！` })
        this.curCsvIndex++
        this.insertOne()
      }).catch((error) => {
        console.dir(error)
        this.errorList.push({ code: 0, text: error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    },
    // 打开添加/编辑弹窗
    showWindow (val) {
      this.classShowWindow = {
        visibility: 'visible'
      }
      if (val === 'add') {
        this.formInfo = {
          projId: '',
          title: '',
          declareYear: '',
          isZhen: 2,
          category01: '',
          category02: '',
          projUrl: '',
          province: '',
          incharge: '',
          remarks: '',
          result: null,
          school_id: '',
          audit_id: this.param_id
        }
      }
    },
    // 关闭添加/编辑弹窗
    hideWindow () {
      this.classShowWindow = {}
    },
    // 打开否决性指标列表弹窗
    showVetoWindow (id) {
      this.classShowVetoWindow = {
        visibility: 'visible'
      }
      this.getAudititemVeto(id)
    },
    // 关闭否决性指标列表弹窗
    hideVetoWindow () {
      this.classShowVetoWindow = {}
      this.vetoData = []
      this.getVetoInfo()
    },
    // 上传文件
    showFilesWindow (val) {
      this.showFileWindow.bigFile = true
      this.uploadedCsvName = ''
      this.uploadedCsv = ''
      if (val === 1) {
        this.showFileWindow.worksUpload = true
        this.showFileWindow.vetoUpload = false
        this.showFileWindow.courseUpload = false
      } else if (val === 2) {
        this.showFileWindow.vetoUpload = true
        this.showFileWindow.worksUpload = false
        this.showFileWindow.courseUpload = false
      } else if (val === 3) {
        this.showFileWindow.courseUpload = true
        this.showFileWindow.worksUpload = false
        this.showFileWindow.vetoUpload = false
      }
    },
    hideFilesWindow () {
      this.showFileWindow.bigFile = false
    },

    // 打开相关课程列表弹窗
    showCourseListWindow () {
      this.classShowCourseListWindow = {
        visibility: 'visible'
      }
    },
    // 关闭相关课程列表弹窗
    hideCourseListWindow () {
      this.classShowCourseListWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  mounted () {
    this.search()
    this.getVetoInfo()
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    dict_province () {
      return this.$store.state.dict_province
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.audit .attachment {
  border-top: none;
  padding-bottom: 0;
}
.form_content .item {
  overflow: hidden;
}
#dialog_content2 {
  padding: 10px;
}
#dialog_content2 ul li {
  line-height: 20px;
}
</style>
