<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">学校管理</span>
      <div class="btns">
        <span
          class="btn import"
          @click="showUploadWindow"
        ><i class="iconfont"></i>导入学校</span>
        <span
          class="btn add"
          @click="showWindow('add')"
        ><i class="iconfont"></i>新增学校</span>
      </div>
    </div>
    <div class="search-box">
      <transition name="fade">
        <form
          class="form-inline open"
          v-if="isOpen"
          style="display: block; height: auto;"
        >
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="title"
              placeholder="学校名称"
              v-model="queryInfo.title"
            ></div>
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="code"
              placeholder="学校编码"
              v-model="queryInfo.code"
            ></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="eduLevel"
              v-model="queryInfo.eduLevel"
            >
              <option value="">全部层次</option>
              <option
                v-for="(item,keys) in dict_eduLevel"
                :key="keys"
                :value="keys"
              >{{item}}</option>
            </select></div>
          <div class="form-group distpicker"><select
              class="form-control input-sm"
              name="province"
              placeholder="请选择省"
              v-model="queryInfo.province"
            >
              <option value="">请选择省</option>
              <option
                v-for="item in dict_province"
                :key="item.id"
                :value="item.code"
              >{{item.title}}</option>
            </select></div>
          <div class="form-group"><button
              type="button"
              class="btn btn-primary btn-rounded btn-search"
              @click="pageCallback(1)"
            ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
        </form>
      </transition>
      <span
        class="btn btn-danger btn-open"
        @click="toggleQueryForm"
      >{{isOpen?'关闭':'展开'}}</span>
    </div>
    <div
      class="list"
      id="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>学校名称</th>
            <th>院校代码</th>
            <th>省份</th>
            <th>所在地</th>
            <th>教育层次</th>
            <th>备注</th>
            <th width="120">操作</th>
          </tr>

          <tr
            v-for="item in tableData"
            :key="item.id"
          >
            <td>{{item.title}}</td>
            <td>{{item.code}}</td>
            <td>{{formatProvince(item.province)}}</td>
            <td>{{ parseInt(item.city) in dict_city_table ? dict_city_table[item.city] : ''}}</td>
            <td>{{dict_eduLevel[item.eduLevel]}}</td>
            <td>{{formatRemarks(item.remarks)}}</td>
            <td><a
                href="javascript:void(0)"
                class="btn edit"
                @click="querySingle(item.id)"
              >编辑</a><a
                href="javascript:void(0)"
                class="btn delete"
                @click="delInfo(item.id)"
              >删除</a></td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!tableData.length"
      >没有数据</div>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="tableData.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>
    <!-- 添加/编辑弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">学校管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideWindow"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>学校名称:</span>
              <input
                type="text"
                name="title"
                placeholder="填写学校名称"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>院校代码:</span>
              <input
                type="text"
                name="code"
                placeholder="填写院校代码"
                v-model="formInfo.code"
                @blur="checkCode(formInfo.code)"
              >
            </div>
            <div class="distpicker">
              <div class="item">
                <span class="label"><i class="require">*</i>省:</span>
                <span class="choice">
                  <select
                    name="province"
                    placeholder="请选择省"
                    v-model="formInfo.province"
                    @change="getCityInfo(formInfo.province)"
                  >
                    <option value="">请选择省</option>
                    <option
                      v-for="item in dict_province"
                      :key="item.id"
                      :value="item.code"
                    >{{item.title}}</option>
                  </select>
                </span>
              </div>
              <div class="item">
                <span class="label"><i class="require">*</i>市:</span>
                <span class="choice">
                  <select
                    name="city"
                    placeholder="请选择市"
                    v-model="formInfo.city"
                  >
                    <option
                      value=""
                      data-code=""
                    >请选择市</option>
                    <option
                      v-for="item in cityData"
                      :key="item.id"
                      :value="item.code"
                    >{{item.title}}</option>
                  </select>
                </span>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>教育层次:</span>
              <select
                name="eduLevel"
                v-model="formInfo.eduLevel"
              >
                <option value="">选择教育层次</option>
                <option
                  v-for="(item,keys) in dict_eduLevel"
                  :key="keys"
                  :value="keys"
                >{{item}}</option>
              </select>
            </div>
            <div class="item">
              <span class="label">备注:</span>
              <textarea
                class="simple"
                name="remarks"
                v-model="formInfo.remarks"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            @click="saveInfo"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 上传弹窗 -->
    <div
      class="form_overlay"
      id="form_upload_overlay"
      :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量上传</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideUploadWindow"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <label
                for="name"
                class="control-label col-xs-3 font-normal text-muted"
              ><i class="require">*</i>模板创建规则:</label>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：title（学校名称）、code（院校代码）、province（省份）、city（市）、level（教育层次）、remarks（备注）；</p>
                  <p>3. 其中学校名称、学校标识码、省份、市、教育层次为必填项。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_school_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>
            <div class="item">
              <label
                for="name"
                class="label font-normal text-muted"
              ><i class="require">*</i>导入文件:</label>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="fileUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="fileUpload"
                    ref="xlsFile"
                    accept=".csv,.xls,.xlsx"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideUploadWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'
export default {
  name: 'School',
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        title: '',
        code: '',
        eduLevel: '',
        province: ''
      },
      tableData: [],
      tableDataCount: 0,
      classShowWindow: {},
      classShowUploadWindow: {},
      formInfo: {
        title: '',
        code: '',
        province: '',
        city: '',
        eduLevel: '',
        remarks: ''
      },
      isOpen: false,
      provinceData: [],
      cityData: [],
      checkRes: false,
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
      classShowProcessWindow: {}
    }
  },
  methods: {
    formatProvince (val) {
      for (let i = 0; i < this.dict_province.length; i++) {
        if (this.dict_province[i].code === val) {
          return this.dict_province[i].title
        }
      }
    },
    formatCity (val) {
      for (let i = 0; i < this.dict_city.length; i++) {
        if (this.dict_city[i].code === val) {
          return this.dict_city[i].title
        }
      }
    },
    formatRemarks (val) {
      if (val.length > 20) {
        return val.substring(0, 20) + '...'
      } else {
        return val || '-'
      }
    },
    async checkCode (val) {
      const params = {
        page: 1,
        size: 100000,
        code: val
      }
      await this.$http.get('/school/', { params }).then((data) => {
        if (data.results.length) {
          this.checkRes = true
        } else {
          this.checkRes = false
        }
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    // 搜索
    async search () {
      await this.$http.get('/school/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },

    getCityInfo (val) {
      this.cityData = []
      this.formInfo.city = ''
      for (let i = 0; i < this.dict_city.length; i++) {
        if (val === this.dict_city[i].p_code) {
          this.cityData.push(this.dict_city[i])
        }
      }
    },
    // 编辑时查询
    async querySingle (id) {
      const url = '/school/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        this.formInfo.id = id
        this.formInfo.title = data.title
        this.formInfo.code = data.code
        this.formInfo.province = data.province
        await this.getCityInfo(this.formInfo.province)
        this.formInfo.city = data.city
        this.formInfo.eduLevel = data.eduLevel
        this.formInfo.remarks = data.remarks

        this.showWindow('edit')
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 保存
    async saveInfo () {
      if (!this.formInfo.title) {
        this.$toast.error('学校名称不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.code) {
        this.$toast.error('院校代码不能为空', '', this.error_tips)
        return false
      }
      if (this.checkRes) {
        this.$toast.error('院校代码不能重复', '', this.error_tips)
        return false
      }
      if (!this.formInfo.province) {
        this.$toast.error('请选择所属省份', '', this.error_tips)
        return false
      }
      if (!this.formInfo.city) {
        this.$toast.error('请选择所属城市', '', this.error_tips)
        return false
      }
      if (!this.formInfo.eduLevel) {
        this.$toast.error('请选择教育层次', '', this.error_tips)
        return false
      }
      if (this.formInfo.remarks.length >= 200) {
        this.$toast.error('备注字数不能超多200个', '', this.error_tips)
        return false
      }

      if (this.formInfo.id) {
        const url = '/school/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('修改失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/school/', this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('添加失败！', '', this.error_tips)
        })
      }
    },
    delInfo (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/school/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },
    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$toast.error('上传失败！', '', this.error_tips)
      })
    },

    async submitCsv () {
      const uploadUrl = '/get_xls/'
      await this.$http.get(uploadUrl, {
        params: {
          file_name: this.uploadedCsv
        }
      }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }

        data.splice(0, 1)

        this.uploadedCscData = data

        this.hideUploadWindow()

        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }

      const row = this.uploadedCscData[this.curCsvIndex]

      // region 数据检查开始
      const uploadForm = {
        title: row[0],
        code: row[1],
        province: row[2],
        city: row[3],
        eduLevel: row[4],
        remarks: row[5]
      }
      if (row[0].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入学校名称！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (('' + row[1]).length === 0) {
        this.errorList.push({ code: 0, text: '请输入院校代码！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      let checkRes = false
      await this.$http.get('/school/', {
        params: {
          page: 1,
          size: 100000,
          code_exact: row[1]
        }
      }).then((data) => {
        if (data.results.length) {
          checkRes = true
        } else {
          checkRes = false
        }
      })
      if (checkRes) {
        this.errorList.push({ code: 0, text: '[' + row[1] + ']' + '院校代码不能重复' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (row[2].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入省份！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      for (let i = 0; i < this.dict_province.length; i++) {
        if (this.dict_province[i].title === row[2]) {
          uploadForm.province = this.dict_province[i].code
        }
      }
      // if (row[3].trim().length === 0) {
      //   this.errorList.push({ code: 0, text: '请输入所在地城市！' })
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return
      // }
      // for (let i = 0; i < this.dict_city.length; i++) {
      //   if (this.dict_city[i].title === row[3]) {
      //     uploadForm.city = this.dict_city[i].code
      //   }
      // }
      if (row[4].trim().length === 0) {
        this.errorList.push({ code: 0, text: '请输入教育层次！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      if (row[4] === '本科') {
        uploadForm.eduLevel = '1'
      } else if (row[4] === '高职') {
        uploadForm.eduLevel = '2'
      } else if (row[4] === '') {
        uploadForm.eduLevel = ''
      }
      await this.$http.post('/school/', uploadForm).then(async (profileData) => {
        this.errorList.push({ code: 1, text: '[' + uploadForm.title + ']' + '导入成功！' })
        this.curCsvIndex++
        await this.insertOne()
      }).catch(async (error) => {
        console.log(error)
        this.errorList.push({ code: 0, text: '[' + uploadForm.title + ']' + error.response.data[0] })
        this.curCsvIndex++
        await this.insertOne()
      })
    },
    // 打开添加/编辑弹窗
    showWindow (value) {
      if (value === 'add') {
        this.formInfo = {
          id: 0,
          title: '',
          code: '',
          province: '',
          city: '',
          eduLevel: '',
          remarks: ''
        }
      }
      this.classShowWindow = {
        visibility: 'visible'
      }
    },
    // 关闭添加/编辑弹窗
    hideWindow () {
      this.classShowWindow = {}
      this.cityData = []
    },
    // 打开上传弹窗
    showUploadWindow () {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传弹窗
    hideUploadWindow () {
      this.classShowUploadWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    dict_eduLevel () {
      return this.$store.state.dict_eduLevel
    },
    dict_province () {
      return this.$store.state.dict_province
    },
    dict_city () {
      return this.$store.state.dict_city
    },
    dict_city_table () {
      const tempDict = {}
      this.$store.state.dict_city.forEach(function (m) { tempDict['' + m.code] = m.title })
      return tempDict
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  async mounted () {
    await this.search()
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
