<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">我的评审</span>
    </div>
    <div class="search-box"></div>
    <div
      id="list"
      class="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>评审项目</th>
            <th>开始时间</th>
            <th>截止时间</th>
            <th width="120">操作</th>
          </tr>
          <tr
            v-for="(item, index) in auditList"
            :key="index"
          >
            <td>{{ item.title }}</td>
            <td class="red">{{ formatDate(item.time4) }}</td>
            <td class="red">{{ formatDate(item.time5) }}</td>
            <td>
              <a
                @click="toScoreV2(item)"
                class="btn-enter btn"
              >进入</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!auditList.length"
      >没有数据</div>
    </div>
    <pagination
      v-if="auditList.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>

    <div
      class="form_overlay"
      id="form_overlay_info"
      :style="showReadWindow ? 'visibility: visible' : ''"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel editor">
        <div class="form_title">评审须知</div>
        <a
          href="javascript:void(0)"
          @click="showReadWindow = false"
          class="overlay-close"
        ><i class="iconfont"></i></a>
        <div class="form_content">
          <div
            class="auth-content"
            v-html="readWindowOption.authtxt"
          ></div>
          <div class="audit-agree-box">
            <label class="agree-label">
              <input
                type="checkbox"
                name="agree"
                v-model="readWindowOption.checked"
              >
              <span>以上承诺，经本人同意后生效并将切实遵照执行。如有违反，本人将承担相应责任。</span>
            </label>
          </div>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            @click="showReadWindow = false"
            class="button info_close close"
          >取消</a>
          <a
            href="javascript:void(0)"
            id="form_submit"
            @click="doRead"
            :class="readWindowOption.agreeTime > 0 ? 'disabled button': 'button'"
          >已阅读{{ readWindowOption.agreeTime > 0 ? '(' + readWindowOption.agreeTime + '秒)' : '' }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'

export default {
  name: 'MyAudit',
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 1
        // expert_id: this.$store.state.userInfo.profile_id
      },
      auditgroup: [],
      auditgroup_count: 0,
      showReadWindow: false,
      readWindowOption: {
        id: 0,
        item: null,
        agreeTime: 0,
        authtxt: '',
        checked: false,
        intval: null
      },
      auditList: [],
      auditCount: 0
    }
  },
  computed: {
    // pageInfo () {
    //   return {
    //     page: this.queryInfo.page,
    //     all: Math.ceil(this.auditgroup_count / this.queryInfo.size),
    //     totalData: this.auditgroup_count
    //   }
    // },
    // 分页重新计算
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.auditCount / this.queryInfo.size),
        totalData: this.auditCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  methods: {
    // 格式化评审说明
    formatDate (value) {
      if (value) {
        return value.substring(0, value.indexOf('T'))
      } else {
        return '暂无'
      }
    },
    async getAuditGroups () {
      await this.$http.get('/auditgroup/', {
        params: this.queryInfo
      }).then((data) => {
        this.auditgroup = data.results
        this.auditgroup_count = data.count
      })
    },

    async getAudits () {
      await this.$http.get('/audit_search_expert/', {
        params: this.queryInfo
      }).then((data) => {
        debugger
        this.auditList = data.results
        this.auditCount = data.count
      })
    },

    pageCallback (data) {
      this.queryInfo.page = data
      // this.getAuditGroups()
      this.getAudits()
    },
    toScore (item) {
      // 由于查询的主表进行了优化 用的是getAudits，这里面的取值也要进行调整
      this.$http.get('/auditgroup_expert/', {
        params: {
          // auditGroup_id: item.id,
          // 查询当前评审专家在当前评审项目中分配到哪个组
          // audit_id: item.audit.id,
          audit_id: item.id,
          expert_id: this.$store.state.userInfo.profile_id
        }
      }).then((data) => {
        console.log(data)
        if (data.results.length === 0) {
          console.log('数据错误')
        } else {
          if (data.results[0].auditGroup.audit.isauth !== 1) {
            this.$router.push('/admin/my_audit_group?audit_id=' + item.id)
          } else if (data.results[0].is_read === 1) {
            this.$router.push('/admin/my_audit_group?audit_id=' + item.id)
          } else {
            this.readWindowOption.id = data.results[0].id
            this.readWindowOption.item = item
            this.readWindowOption.agreeTime = data.results[0].auditGroup.audit.agreeTime
            this.readWindowOption.authtxt = data.results[0].auditGroup.audit.authtxt
            this.showReadWindow = true
            this.readWindowOption.intval = setInterval(() => {
              this.readWindowOption.agreeTime -= 1
              if (this.readWindowOption.agreeTime === 0) {
                clearInterval(this.readWindowOption.intval)
              }
            }, 1000)
          }
        }
      })
    },
    toScoreV2 (item) {
      // 查询是否已经阅读了当前项目的评审须知，防止越权不提供专家ID
      this.$http.get('/auditgroup_expert_search/', {
        params: {
          audit_id: item.id
        }
      }).then((data) => {
        console.log(data)
        if (data.length === 0) {
          console.log('数据错误')
        } else {
          if (item.isauth !== 1) {
            // 如果当前项目不需要评审须知，这接进去评分
            this.$router.push('/admin/my_audit_group?audit_id=' + item.id)
          } else if (data[0].is_read === 1) {
            // 如果当前项目已经阅读了评审须知
            this.$router.push('/admin/my_audit_group?audit_id=' + item.id)
          } else {
            this.readWindowOption.id = data[0].id
            this.readWindowOption.item = item
            this.readWindowOption.agreeTime = item.agreeTime
            this.readWindowOption.authtxt = item.authtxt
            this.showReadWindow = true
            this.readWindowOption.intval = setInterval(() => {
              this.readWindowOption.agreeTime -= 1
              if (this.readWindowOption.agreeTime === 0) {
                clearInterval(this.readWindowOption.intval)
              }
            }, 1000)
          }
        }
      })
    },
    doRead () {
      if (this.readWindowOption.agreeTime > 0) {
        return false
      }
      if (!this.readWindowOption.checked) {
        this.$toast.error('请勾选同意承诺书！', '', this.error_tips)
        return false
      }
      this.$http.put('/auditgroup_expert/' + this.readWindowOption.id + '/', {
        is_read: 1
      }).then((data) => {
        this.$router.push('/admin/my_audit_group?audit_id=' + this.readWindowOption.item.id)
      })
      return false
    }
  },
  mounted () {
    // this.getAuditGroups()
    // 先注释上，还没想好如何切换
    this.getAudits()
  }
}
</script>

<style scoped>

#form_overlay_info .form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
