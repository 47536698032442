import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'

import Login from '../views/www/Login'
import ChangePwd from '../views/www/ChangePwd'

import Layout from '../views/Layout'
import School from '../views/admin/School'
import User from '../views/admin/User'
import Subject from '../views/admin/Subject'
import Site from '../views/admin/Site'
import Audit from '../views/admin/Audit'

import Maintain from '../views/admin/audit/Maintain'
// 评审信息
import MaintainTab1 from '../views/admin/audit/MaintainTab1'
// 评审指标
import MaintainTab2 from '../views/admin/audit/MaintainTab2'

import MaintainTab3 from '../views/admin/audit/MaintainTab3'

import MaintainTab3Maintain from '../views/admin/audit/MaintainTab3Maintain'

import MaintainTab4 from '../views/admin/audit/MaintainTab4'

import MaintainTab5 from '../views/admin/audit/MaintainTab5'

import MaintainTab5Maintain from '../views/admin/audit/MaintainTab5Maintain'

import MaintainTab5Detail from '../views/admin/audit/MaintainTab5Detail'

import MaintainTab6 from '../views/admin/audit/MaintainTab6'

import MaintainTab6Maintain from '../views/admin/audit/MaintainTab6Maintain'

import MyAudit from '../views/admin/MyAudit'

import MyAuditGroup from '../views/admin/MyAuditGroup'

import MyAuditScore from '../views/admin/MyAuditScore'

// import MyMeeting from '../views/admin/MyMeeting'

import MyMeetingGroup from '../views/admin/MyMeetingGroup'

import MyMeetingItem from '../views/admin/MyMeetingItem'

// import MyAuditGroupItem from '../views/admin/MyAuditGroupItem'

import MyAuditItemIndex from '../views/admin/MyAuditItemIndex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    meta: {
      title: '首页'
    },
    redirect: '/admin'
  },
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登陆'
    }
  },
  {
    path: '/change_pwd',
    name: 'ChangePwd',
    component: ChangePwd,
    meta: {
      title: '登陆'
    }
  },
  {
    path: '/admin',
    name: 'Layout',
    component: Layout,
    meta: {
      title: '后台管理'
    },
    redirect: '/admin/user',
    children: [
      {
        path: '/admin/school',
        name: 'School',
        component: School,
        meta: {
          title: '学校管理',
          auth: ['系统管理员']
        }
      },
      {
        path: '/admin/user',
        name: 'User',
        component: User,
        meta: {
          title: '用户管理',
          auth: ['系统管理员']
        }
      },
      {
        path: '/admin/subject',
        name: 'Subject',
        component: Subject,
        meta: {
          title: '专业管理',
          auth: ['系统管理员']
        }
      },
      {
        path: '/admin/site',
        name: 'Site',
        component: Site,
        meta: {
          title: '接入平台管理',
          auth: ['系统管理员']
        }
      },
      {
        path: '/admin/audit',
        name: 'Audit',
        component: Audit,
        meta: {
          title: '评审管理',
          auth: ['系统管理员']
        }
      },
      {
        path: '/admin/audit/maintain',
        name: 'Maintain',
        component: Maintain,
        meta: {
          title: '评审管理 - 维护',
          auth: ['系统管理员']
        },
        redirect: '/admin/audit/maintain/tab1',
        children: [
          {
            path: '/admin/audit/maintain/tab1',
            name: 'MaintainTab1',
            component: MaintainTab1,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab2',
            name: 'MaintainTab2',
            component: MaintainTab2,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab3',
            name: 'MaintainTab3',
            component: MaintainTab3,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab3_maintain',
            name: 'MaintainTab3Maintain',
            component: MaintainTab3Maintain,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab4',
            name: 'MaintainTab4',
            component: MaintainTab4,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab5',
            name: 'MaintainTab5',
            component: MaintainTab5,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab5_maintain',
            name: 'MaintainTab5Maintain',
            component: MaintainTab5Maintain,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab5_detail',
            name: 'MaintainTab5Detail',
            component: MaintainTab5Detail,
            meta: {
              title: '评审管理 - 明细',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab6',
            name: 'MaintainTab6',
            component: MaintainTab6,
            meta: {
              title: '评审管理 - 维护',
              auth: ['系统管理员']
            }
          },
          {
            path: '/admin/audit/maintain/tab6_maintain',
            name: 'MaintainTab6Maintain',
            component: MaintainTab6Maintain,
            meta: {
              title: '评审管理 - 维护'
            }
          }
        ]
      },
      {
        path: '/admin/my_audit',
        name: 'MyAudit',
        component: MyAudit,
        meta: {
          title: '我的评审',
          auth: ['专家']
        }
      },
      {
        path: '/admin/my_audit_group',
        name: 'MyAuditGroup',
        component: MyAuditGroup,
        meta: {
          title: '我的评审',
          auth: ['专家']
        }
      },
      {
        path: '/admin/my_audit_score',
        name: 'MyAuditScore',
        component: MyAuditScore,
        meta: {
          title: '我的评审',
          auth: ['专家']
        }
      },
      // {
      //   path: '/admin/my_meeting',
      //   name: 'MyMeeting',
      //   component: MyMeeting,
      //   meta: {
      //     title: '会评评审',
      //     auth: ['专家']
      //   }
      // },
      {
        path: '/admin/my_meeting_group',
        name: 'MyMeetingGroup',
        component: MyMeetingGroup,
        meta: {
          title: '会评评审分组',
          auth: ['专家']
        }
      },
      {
        path: '/admin/my_meeting_item',
        name: 'MyMeetingItem',
        component: MyMeetingItem,
        meta: {
          title: '会评评审详情',
          auth: ['专家']
        }
      },
      // 组长功能先禁用 sunyj 本次评审没有组长
      // {
      //   path: '/admin/my_group',
      //   name: 'MyAuditGroupItem',
      //   component: MyAuditGroupItem,
      //   meta: {
      //     title: '组长评审分组',
      //     auth: ['专家']
      //   }
      // },
      {
        path: '/admin/my_audit_item_index',
        name: 'MyAuditItemIndex',
        component: MyAuditItemIndex,
        meta: {
          title: '评审详情',
          auth: ['专家']
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // }
  // if (to.path === '/login') return next()
  // const tokenStr = store.state.token
  // if (!tokenStr) return next('/login')
  // next()
  // 增加设置页面标题
  if (to.meta && to.meta.title) {
    document.title = to.meta.title // 设置页面标题
  }
  const userInfo = store.state.userInfo
  const dictRole = store.state.dict_role
  const currentRole = userInfo.role
  if (currentRole + '') {
    const requiredRoles = to.meta.auth
    if (requiredRoles) {
      if (requiredRoles.includes(dictRole[currentRole])) {
        return next()
      } else {
        if (currentRole === 0) {
          return next('/')
        } else {
          return next('/admin/my_audit')
        }
      }
    } else {
      if (to.path === '/login') {
        if (currentRole === 0) {
          return next('/')
        } else {
          return next('/admin/my_audit')
        }
      }
      return next()
    }
  } else {
    if (to.path === '/login') {
      return next()
    } else {
      return next('/login')
    }
  }
})

export default router
