<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">用户管理</span>
      <div class="btns">
        <span
          class="btn import"
          @click="showUploadWindow"
        ><i class="iconfont"></i>导入用户</span>
        <span
          class="btn add"
          @click="showWindow('add')"
        ><i class="iconfont"></i>新增用户</span>
      </div>
    </div>
    <div class="search-box">
      <transition name="fade">
        <form
          class="form-inline open"
          id="filterForm"
          v-if="isOpen"
          style="display: block; height: auto;"
        >
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="username"
              placeholder="账号"
              v-model="queryInfo.username"
            ></div>
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="name"
              placeholder="姓名"
              v-model="queryInfo.name"
            ></div>
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="email"
              placeholder="邮箱"
              v-model="queryInfo.emailInfo"
            ></div>
          <div class="form-group"><input
              type="text"
              class="form-control input-sm"
              name="mobile"
              placeholder="手机号"
              v-model="queryInfo.mobile"
            ></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="role"
              v-model="queryInfo.role"
            >
              <option value="">全部角色</option>
              <option value="0">系统管理员</option>
              <option value="1">专家</option>
            </select></div>
          <div class="form-group distpicker"><select
              class="form-control input-sm"
              name="province"
              placeholder="请选择省"
              v-model="queryInfo.province"
              @change="getSchoolInfo(queryInfo.province,'1')"
            >
              <option value="">请选择省</option>
              <option
                v-for="item in dict_province"
                :key="item.code"
                :value="item.code"
              >{{item.title}}</option>

            </select></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="school"
              v-model="queryInfo.school_id"
            >
              <option value="">选择学校</option>
              <option
                v-for="item in querySchoolData"
                :key="item.id"
                :value="item.id"
              >{{item.title}}</option>
            </select></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="subject01"
              v-model="queryInfo.subject01_id"
              @change="getSubject(queryInfo.subject01_id,'1')"
            >
              <option value="">一级学科</option>
              <option
                v-for="item in categoryData"
                :key="item.id"
                :value="item.id"
              >{{item.title}}</option>
            </select></div>
          <div class="form-group"><select
              class="form-control input-sm"
              name="subject02"
              v-model="queryInfo.subject02_id"
            >
              <option value="">二级学科</option>
              <option
                v-for="item in querySubjectData"
                :key="item.id"
                :value="item.id"
              >{{item.title}}</option>
            </select></div>
          <div class="form-group"><label
              class="sr-only"
              for="groupLeader-s"
            >组长</label><input
              type="checkbox"
              name="groupLeader"
              v-model="queryInfo.group_leader"
            ></div>
          <div class="form-group"><button
              type="button"
              class="btn btn-primary btn-rounded btn-search"
              @click="pageCallback(1)"
            ><i class="fa fa-search text-xs m-r-xs"></i>搜索</button></div>
        </form>
      </transition>
      <span
        class="btn btn-danger btn-open"
        @click="toggleQueryForm"
      >{{isOpen?'关闭':'展开'}}</span>
    </div>
    <div
      class="list"
      id="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>角色</th>
            <th>账号</th>
            <th>姓名</th>
            <th>性别</th>
            <th>职称</th>
            <th>职务</th>
            <th>邮箱</th>
            <th>手机号</th>
            <th>学校</th>
            <th width="280">操作</th>
          </tr>

          <tr
            v-for="item in tableData"
            :key="item.id"
          >
            <td>{{formatRole(item.role)}}</td>
            <td>{{item.username}}<span
                v-if="item.group_leader"
                class="leader-icon"
              >组长<span></span></span></td>
            <td>{{item.name?item.name:'-'}}</td>
            <td>{{formatGender(item.gender)}}</td>
            <td>{{item.position_title?item.position_title:'-'}}</td>
            <td>{{item.duty?item.duty:'-'}}</td>
            <td>{{item.emailInfo?item.emailInfo:'-'}}</td>
            <td>{{item.mobile?item.mobile:'-'}}</td>
            <td>{{item.school?item.school.title:'-'}}</td>
            <td>
              <a
                href="javascript:void(0)"
                class="btn edit"
                @click="setGroupLeade(item)"
              >{{item.group_leader?'取消组长':'设为组长'}}</a>
              <a
                href="javascript:void(0)"
                class="btn edit"
                @click="querySingle(item.id)"
              >编辑</a>
              <a
                href="javascript:void(0)"
                class="btn reset"
                @click="showPasswordWindow(item.id)"
              >重置密码</a>
              <a
                href="javascript:void(0)"
                class="btn delete"
                @click="delInfo(item.id)"
              >删除</a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!tableData.length"
      >没有数据</div>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="tableData.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>
    <!-- 添加/编辑弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">用户管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="itemForm">
            <div class="item">
              <span class="label"><i class="require">*</i>账号:</span>
              <input
                type="text"
                name="username"
                placeholder="用户名为4-64位数字、字母组合"
                v-model="formInfo.username"
              >
            </div>
            <div
              class="item new-item"
              v-if="formType=='add'"
            >
              <span class="label"><i class="require">*</i>密码:</span>
              <input
                type="password"
                name="password"
                placeholder="密码允许长度8-64位"
                v-model="formInfo.password"
              >
            </div>
            <div
              class="item new-item"
              v-if="formType=='add'"
            >
              <span class="label"><i class="require">*</i>确认密码:</span>
              <input
                type="password"
                name="password2"
                placeholder="再次输入密码"
                v-model="formInfo.password1"
              >
            </div>
            <div class="item">
              <p>1、密码长度8-64位</p>
              <p>2、须同时包含大写字母、小写字母、数字、特殊字符中的任意三种</p>
              <p>3、密码不能包含邮箱</p>
            </div>
            <div class="item checkbox-item">
              <span class="label"><i class="require"></i>强制修改密码:</span>
              <input
                type="checkbox"
                name="isInit"
                value="1"
                v-model="formInfo.force_pass"
              >
            </div>
            <div class="item checkbox-item">
              <span class="label"><i class="require"></i>隐藏评审菜单:</span>
              <input
                type="checkbox"
                name="hideMenu1"
                value="1"
                v-model="formInfo.hide_audit_menu"
              >
            </div>
            <div class="item new-item">
              <span class="label"><i class="require">*</i>邮箱:</span>
              <input
                type="text"
                name="email"
                placeholder="找回密码唯一途径"
                v-model="formInfo.emailInfo"
                @blur="checkEmail(formInfo.emailInfo)"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>姓名:</span>
              <input
                type="text"
                name="name"
                placeholder="2-10位中文或字母数字"
                v-model="formInfo.name"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>性别:</span>
              <div class="k-container">
                <label class="check-label">
                  <input
                    type="radio"
                    value="1"
                    name="gender"
                    checked="checked"
                    v-model="formInfo.gender"
                  >男</label>
                <label class="check-label">
                  <input
                    type="radio"
                    value="0"
                    name="gender"
                    v-model="formInfo.gender"
                  >女</label>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>角色:</span>
              <div class="k-container">
                <label class="check-label">
                  <input
                    type="radio"
                    value="0"
                    name="role"
                    v-model="formInfo.role"
                  >系统管理员</label>
                <label class="check-label">
                  <input
                    type="radio"
                    value="2"
                    name="role"
                    v-model="formInfo.role"
                  >省级管理员</label>
                <label class="check-label">
                  <input
                    type="radio"
                    value="1"
                    name="role"
                    v-model="formInfo.role"
                  >专家</label>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职称:</span>
              <input
                type="text"
                name="title"
                placeholder="获得的职称"
                v-model="formInfo.position_title"
              >
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>职务:</span>
              <input
                type="text"
                name="post"
                placeholder="目前担任职务"
                v-model="formInfo.duty"
              >
            </div>
            <div class="item mobile-item">
              <span class="label"><i class="require"></i>手机号:</span>
              <input
                type="text"
                name="mobile"
                placeholder="手机号可作为登录使用，全平台唯一"
                v-model="formInfo.mobile"
              >
            </div>
            <div
              id="distpicker"
              class="distpicker"
            >
              <div class="item">
                <span class="label"><i class="require">*</i>省份:</span>
                <span class="choice">
                  <select
                    name="province"
                    placeholder="请选择省"
                    v-model="formInfo.province"
                    @change="getSchoolInfo(formInfo.province,'2')"
                  >
                    <option value="">请选择省</option>
                    <option
                      v-for="item in dict_province"
                      :key="item.code"
                      :value="item.code"
                    >{{item.title}}</option>

                  </select>
                </span>
              </div>
            </div>
            <div class="item">
              <span class="label"><i class="require">*</i>学校:</span>
              <div class="choose">
                <span class="choice">
                  <select
                    name="school"
                    v-model="formInfo.school_id"
                  >
                    <option value="">请选择学校</option>
                    <option
                      v-for="item in schoolData"
                      :key="item.id"
                      :value="item.id"
                    >{{item.title}}</option>
                  </select>
                </span>
              </div>
            </div>
            <div
              class="item e-item"
              v-if="formInfo.role==1"
            >
              <span class="label"><i class="require"></i>一级学科:</span>
              <select
                name="subject01"
                v-model="formInfo.subject01_id"
                @change="getSubject(formInfo.subject01_id,'2')"
              >
                <option value="">请选择</option>
                <option
                  v-for="item in categoryData"
                  :key="item.id"
                  :value="item.id"
                >{{item.title}}</option>

              </select>
            </div>
            <div
              class="item e-item"
              v-if="formInfo.role==1"
            >
              <span class="label"><i class="require"></i>二级学科:</span>
              <select
                name="subject02"
                v-model="formInfo.subject02_id"
              >
                <option value="">请选择</option>
                <option
                  v-for="item in subjectData"
                  :key="item.id"
                  :value="item.id"
                >{{item.title}}</option>
              </select>
            </div>
            <div
              class="item e-item"
              v-if="formInfo.role==1"
            >
              <span class="label"><i class="require"></i>推荐来源:</span>
              <input
                type="text"
                name="referral"
                v-model="formInfo.source"
              >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >确定</a>
        </div>
      </div>
    </div>
    <!-- 上传弹窗 -->

    <div
      class="form_overlay"
      id="form_upload_overlay"
      :style="classShowUploadWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">批量导入</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideUploadWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form id="uploadForm">
            <div class="item">
              <span class="label"><i class="require"></i>规则:</span>
              <div class="item-box divLabel gray">
                <div class="normal-width">
                  <p>1. 文件格式为Excel文件；</p>
                  <p>2. 表格头说明：username（账号）、password（密码）、name（姓名）、gender（性别）、title（职称）、post（职务）、email（邮箱）、mobile（手机号）、schoolCode（学校代码）、subject01（一级学科）、subject02（二级学科）、referral（推荐来源）</p>
                  <p>3. 其中账号、姓名、邮箱、学校代码为必填项；</p>
                  <p>4. 如未指定密码，用户默认密码为：Aa123456。</p>
                </div>
              </div>
              <div class="clear"></div>
              <div class="attachment">
                <a
                  href="/static/upload_template/batch_upload_user_demo.xlsx"
                  target="_blank"
                >模版下载</a>
              </div>
            </div>

            <div class="item">
              <span class="label"><i class="require">*</i>选择文件:</span>
              <div class="item-box">
                <input
                  name="file"
                  type="text"
                  id="fileUrl"
                  v-model="uploadedCsvName"
                >
                <span class="button">选择文件
                  <input
                    type="file"
                    id="fileUpload"
                    ref="xlsFile"
                    accept=".csv,.xls,.xlsx"
                    class="upload-derict"
                    @change="xlsChange"
                  >
                </span>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            id="form_upload_close"
            @click="hideUploadWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_upload_submit"
            @click="submitCsv"
          >提交</a>
        </div>
      </div>
    </div>
    <div
      class="form_overlay"
      id="password_overlay"
      :style="classShowPasswordWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">重置密码</div>
        <div class="form_content">
          <form id="passwordForm">
            <div class="item"><span class="label"><i class="require">*</i>输入密码:</span><input
                type="password"
                name="password"
                placeholder="8-64位"
                v-model="passwordInfo.password1"
              ></div>
            <div class="item"><span class="label"><i class="require">*</i>确认密码:</span><input
                type="password"
                name="password2"
                v-model="passwordInfo.password2"
              ></div>
            <div class="item">
              <p>1、密码长度8-64位</p>
              <p>2、须同时包含大写字母、小写字母、数字、特殊字符中的任意三种</p>
              <p>3、密码不能包含邮箱</p>
            </div>
          </form>
        </div>
        <div class="form_control"><a
            href="javascript:void(0)"
            class="button close"
            @click="hidePasswordWindow"
          >取消</a><a
            href="javascript:void(0)"
            class="button"
            id="form_password_submit"
            @click="resetPassword"
          >提交</a></div>
      </div>
    </div>
    <!-- 导入提示弹窗 -->
    <div
      id="dialog_overlay"
      :style="classShowProcessWindow"
    >
      <div id="dialog_bg"></div>
      <div
        id="dialog_panel"
        ref="refErrorList"
        style="margin-left: -136px; margin-top: -54px;max-width: 300px;"
      >
        <div id="dialog_panel_bg"></div>
        <div id="dialog_title">提示</div>
        <div id="dialog_content">
          ({{ curCsvIndex }} / {{ uploadedCscData.length }})
          <span style="color: #219d2b">
            {{ ' ' + csvFinishText }}
          </span>
        </div>
        <div id="dialog_content2">
          <ul>
            <li
              v-for="(item, index) in errorList"
              v-bind:key="index"
            >
              <span :class="item.code == 1 ? 'success' : 'fail'">{{ item.text }}</span>
            </li>
          </ul>

        </div>
        <div id="dialog_control">
          <a
            href="javascript:void(0)"
            id="dialog_ok"
            class="button col2"
            @click="classShowProcessWindow={}"
            :style="isFinishUpsert ? 'display: inline-block' : 'display:none'"
          >确认</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'
export default {
  name: 'User',
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        username: '',
        name: '',
        emailInfo: '',
        mobile: '',
        role: '',
        province: '',
        school_id: '',
        subject01_id: '',
        subject02_id: '',
        group_leader: ''
      },
      uploadedCsv: '',
      uploadedCsvName: '',
      uploadedCscData: [],
      isFinishUpsert: false,
      curCsvIndex: 0,
      csvFinishText: '',
      errorList: [],
      tableData: [],
      tableDataCount: 0,
      classShowWindow: {},
      classShowUploadWindow: {},
      classShowPasswordWindow: {},
      formInfo: {
        name: '',
        gender: 1,
        role: 1,
        position_title: '',
        duty: '',
        mobile: '',
        province: '',
        source: '',
        group_leader: 0,
        emailInfo: '',
        hide_audit_menu: 0,
        force_pass: 0,
        school_id: '',
        subject01_id: null,
        subject02_id: null,
        username: ''
      },
      passwordInfo: {
        password: '',
        password1: '',
        password2: ''
      },
      isOpen: false,
      schoolData: [],
      querySchoolData: [],
      categoryData: [],
      subjectData: [],
      querySubjectData: [],
      formType: 'add',
      isEmailRepeat: false,
      classShowProcessWindow: {}
    }
  },
  created () {
    this.getMajorCategory()
  },
  methods: {

    async checkEmail (val) {
      const params = {
        page: 1,
        size: 100000,
        emailInfo: val
      }
      await this.$http.get('/userprofile/', { params }).then((data) => {
        if (data.results.length) {
          console.log('有数据')
          this.isEmailRepeat = true
        } else {
          this.isEmailRepeat = false
        }
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    formatRole (val) {
      if (val === '') {
        return '系统管理员'
      } else {
        return this.dict_role[val]
      }
    },
    formatGender (val) {
      if (val !== '') {
        return val === '1' ? '男' : '女'
      } else {
        return '-'
      }
    },
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    async resetPassword () {
      const url = '/userprofile/' + this.passwordInfo.id + '/'
      const params = {
        password1: this.passwordInfo.password1,
        password2: this.passwordInfo.password2
      }
      if (!this.passwordInfo.password1) {
        this.$toast.error('密码不能为空', '', this.error_tips)
        return false
      }
      if (!this.passwordValidate(this.passwordInfo.password1)) {
        return false
      }
      if (!this.passwordInfo.password2) {
        this.$toast.error('请确认密码', '', this.error_tips)
        return false
      }
      if (this.passwordInfo.password1 !== this.passwordInfo.password2) {
        this.$toast.error('两次输入的密码不一致', '', this.error_tips)
        return false
      }
      await this.$http.put(url, params).then((data) => {
        if (data.id) {
          this.$toast.success('重置密码成功！', '', this.success_tips)
          this.hidePasswordWindow()
          this.search()
        } else {
          this.$toast.error('重置密码失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('重置密码失败！', '', this.error_tips)
      })
    },
    // 密码校验方法
    passwordValidate (str) {
      var rC = {
        lW: '[a-z]', // 小写字母
        uW: '[A-Z]', // 大写字母
        nW: '[0-9]', // 数字
        sW: '[\\u0020-\\u002F\\u003A-\\u0040\\u005B-\\u0060\\u007B-\\u007E]'// 特殊字符
      }
      function Reg (str, rStr) {
        var reg = new RegExp(rStr)
        if (reg.test(str)) return true
        else return false
      }
      if (str.length < 8 || str.length > 64) {
        this.$toast.error('密码长度需保证为8-64位', '', this.error_tips)
        return false
      } else {
        var tR = {
          l: Reg(str, rC.lW),
          u: Reg(str, rC.uW),
          n: Reg(str, rC.nW),
          s: Reg(str, rC.sW)
        }
        if ((tR.l && tR.u && tR.n) || (tR.l && tR.u && tR.s) || (tR.s && tR.u && tR.n) || (tR.s && tR.l && tR.n)) {
          // 密码符合要求
          return true
        } else {
          this.$toast.error('您的密码必须含有“小写字母”、“大写字母”、“数字”、“特殊符号”中的任意三种', '', this.error_tips)
          return false
        }
      }
    },
    // 获取学校信息
    async getSchoolInfo (val, category) {
      const params = {
        page: 1,
        size: 100000,
        province: val
      }
      if (val) {
        await this.$http.get('/school/', { params }).then((data) => {
          console.log(data)
          if (category === '1') {
            this.querySchoolData = data.results
            this.queryInfo.school_id = ''
          } else if (category === '2') {
            this.schoolData = data.results
            this.formInfo.school_id = ''
          }
        }).catch(() => {
          this.$toast.error('获取学校数据失败！', '', this.error_tips)
        })
      } else {
        this.querySchoolData = []
        this.queryInfo.school_id = ''
        this.schoolData = []
        this.formInfo.school_id = ''
      }
    },
    // 获取一级学科信息
    async getMajorCategory () {
      const params = {
        page: 1,
        size: 100000,
        level: 1
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取一级学科数据失败！', '', this.error_tips)
      })
    },
    // 获取二级学科信息
    async getSubject (id, category) {
      const params = {
        page: 1,
        size: 100000,
        parent_id: id,
        level: 2
      }
      if (id) {
        await this.$http.get('/subject/', { params }).then((data) => {
          if (category === '1') {
            this.querySubjectData = data.results
            this.queryInfo.subject02_id = ''
          } else if (category === '2') {
            this.subjectData = data.results
            this.formInfo.subject02_id = ''
          }
        }).catch(() => {
          this.$toast.error('获取学校数据失败！', '', this.error_tips)
        })
      } else {
        this.querySubjectData = []
        this.queryInfo.subject02_id = ''
        this.subjectData = []
        this.formInfo.subject02_id = ''
      }
    },
    // 搜索
    async search () {
      this.queryInfo.group_leader = this.queryInfo.group_leader ? 1 : ''
      await this.$http.get('/userprofile/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 编辑时查询
    async querySingle (id) {
      const url = '/userprofile/' + id + '/'
      await this.$http.get(url).then(async (data) => {
        this.formInfo.id = id
        this.formInfo.name = data.name
        this.formInfo.gender = data.gender
        this.formInfo.role = data.role
        this.formInfo.position_title = data.position_title
        this.formInfo.duty = data.duty
        this.formInfo.mobile = data.mobile
        this.formInfo.province = data.province
        this.formInfo.source = data.source
        this.formInfo.group_leader = data.group_leader
        this.formInfo.emailInfo = data.emailInfo
        this.formInfo.hide_audit_menu = data.hide_audit_menu
        this.formInfo.force_pass = data.force_pass
        await this.getSchoolInfo(this.formInfo.province, '2')
        this.formInfo.school_id = data.school_id
        this.formInfo.subject01_id = data.subject01_id ? data.subject01_id : ''
        await this.getSubject(this.formInfo.subject01_id, '2')
        this.formInfo.subject02_id = data.subject02_id ? data.subject02_id : ''

        this.formInfo.username = data.username

        this.showWindow('edit')
      }).catch((error) => {
        console.log(error)
        this.$toast.error('数据获取失败！', '', this.error_tips)
      })
    },
    // 保存
    async saveInfo () {
      // var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z_]{4,64}$/
      var emailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.\w+)+$/
      var phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

      if (this.formInfo.username.length < 4 || this.formInfo.username.length > 64) {
        this.$toast.error('用户名为4-64位数字、字母组合', '', this.error_tips)
        return
      }

      if (this.formType === 'add' && !this.formInfo.password) {
        this.$toast.error('密码不能为空', '', this.error_tips)
        return false
      }
      if (this.formType === 'add' && !this.passwordValidate(this.formInfo.password)) {
        return false
      }
      if (this.formType === 'add' && !this.formInfo.password1) {
        this.$toast.error('请确认密码', '', this.error_tips)
        return false
      }
      if (this.formType === 'add' && this.formInfo.password !== this.formInfo.password1) {
        this.$toast.error('两次输入的密码不一致', '', this.error_tips)
        return false
      }
      if (!emailReg.test(this.formInfo.emailInfo)) {
        this.$toast.error('邮箱格式不正确', '', this.error_tips)
        return false
      }
      if (this.isEmailRepeat) {
        this.$toast.error('邮箱已被注册，请更换', '', this.error_tips)
        return false
      }

      if (this.formInfo.name.length < 2 || this.formInfo.name.length > 20) {
        this.$toast.error('请输入正确的姓名（2-20位）', '', this.error_tips)
        return false
      }
      if (!this.formInfo.position_title) {
        this.$toast.error('职称不能为空', '', this.error_tips)
        return false
      }
      if (!this.formInfo.duty) {
        this.$toast.error('职务不能为空', '', this.error_tips)
        return false
      }
      if (this.formInfo.mobile.length !== 0 && !phoneReg.test(this.formInfo.mobile)) {
        this.$toast.error('手机号格式不正确', '', this.error_tips)
        return false
      }
      if (!this.formInfo.province) {
        this.$toast.error('请选择省份', '', this.error_tips)
        return false
      }
      if (!this.formInfo.school_id) {
        this.$toast.error('请选择学校', '', this.error_tips)
        return false
      }

      this.formInfo.subject01_id = this.formInfo.subject01_id ? this.formInfo.subject01_id : null
      this.formInfo.subject02_id = this.formInfo.subject02_id ? this.formInfo.subject02_id : null
      this.formInfo.force_pass = this.formInfo.force_pass ? 1 : 0
      this.formInfo.hide_audit_menu = this.formInfo.hide_audit_menu ? 1 : 0
      if (this.formInfo.id) {
        const url = '/userprofile/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('修改失败！', '', this.error_tips)
        })
      } else {
        this.formInfo.password2 = this.formInfo.password1
        await this.$http.post('/userprofile/', this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('添加失败！', '', this.error_tips)
        })
      }
    },
    delInfo (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/userprofile/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },
    setGroupLeade (item) {
      var that = this
      this.$toast.question(`确认${item.group_leader ? '取消成为组长' : '设为组长'}吗?`, ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.changeGroupLeade(item)
          }
        }

      })
    },

    changeGroupLeade (item) {
      const url = '/userprofile/' + item.id + '/'
      var groupLeader = item.group_leader ? 0 : 1
      this.$http.put(url, {
        group_leader: groupLeader
      }).then((data) => {
        if (data.id) {
          this.$toast.success('操作成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('操作失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('操作失败！', '', this.error_tips)
      })
    },
    // 打开添加/编辑弹窗
    showWindow (value) {
      this.formType = value
      if (value === 'add') {
        this.formInfo = {
          name: '',
          gender: 1,
          role: '1',
          position_title: '',
          duty: '',
          mobile: '',
          province: '',
          source: '',
          group_leader: 0,
          emailInfo: '',
          hide_audit_menu: 0,
          force_pass: 0,
          school_id: '',
          subject01_id: '',
          subject02_id: '',
          username: ''
        }
      }
      this.classShowWindow = {
        visibility: 'visible'
      }
    },
    // 关闭添加/编辑弹窗
    hideWindow () {
      this.classShowWindow = {}
      this.cityData = []
    },
    // 打开上传弹窗
    showUploadWindow () {
      this.classShowUploadWindow = {
        visibility: 'visible'
      }
    },
    // 关闭上传弹窗
    hideUploadWindow () {
      this.classShowUploadWindow = {}
      this.uploadedCsv = ''
      this.uploadedCsvName = ''
    },
    // 打开重置密码弹窗
    showPasswordWindow (id) {
      this.classShowPasswordWindow = {
        visibility: 'visible'
      }
      this.passwordInfo = {
        id: id,
        password: '',
        password1: '',
        password2: ''
      }
    },
    // 关闭重置密码弹窗
    hidePasswordWindow () {
      this.classShowPasswordWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    },

    async xlsChange () {
      const uploadUrl = '/files/' + this.$refs.xlsFile.files[0].name
      await this.$http.put(uploadUrl, this.$refs.xlsFile.files[0]).then((data) => {
        this.uploadedCsvName = this.$refs.xlsFile.files[0].name
        this.uploadedCsv = data
      }).catch(() => {
        this.$toast.error('上传失败！', '', this.error_tips)
      })
    },
    async submitCsv () {
      const uploadUrl = '/get_xls/'
      await this.$http.get(uploadUrl, {
        params: {
          file_name: this.uploadedCsv
        }
      }).then((data) => {
        if (data.length <= 1) {
          this.$toast.error('表格中无数据！', '', this.error_tips)
          return
        }
        data.splice(0, 1)
        this.uploadedCscData = data
        this.hideUploadWindow()
        this.classShowProcessWindow = {
          visibility: 'visible'
        }
        this.isFinishUpsert = false
        this.curCsvIndex = 0
        this.csvFinishText = ''
        this.errorList = []
        this.insertOne()
      }).catch(() => {
        this.$toast.error('读取失败！', '', this.error_tips)
      })
    },
    async insertOne () {
      if (this.curCsvIndex >= this.uploadedCscData.length) {
        this.csvFinishText = '上传完成'
        this.isFinishUpsert = true
        this.search()
        return
      }
      const row = this.uploadedCscData[this.curCsvIndex]
      // region 数据检查开始
      const uploadForm = {
        name: row[2],
        role: 1,
        position_title: row[5],
        duty: row[6],
        mobile: row[8],
        source: row[12],
        group_leader: 0,
        emailInfo: row[4],
        hide_audit_menu: 0,
        force_pass: 1,
        school_id: row[9],
        username: row[0]
      }
      if (row[0].trim().length === 0) {
        this.$toast.error('请输入帐号！', '', this.error_tips)
        this.errorList.push({ code: 0, text: '请输入帐号！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      // 用户名查重
      let userNameRepeat = false
      await this.$http.get('/userprofile/', {
        params: {
          page: 1,
          size: 100000,
          username: row[0]
        }
      }).then((data) => {
        if (data.results.length) {
          userNameRepeat = true
        } else {
          userNameRepeat = false
        }
      })
      if (userNameRepeat) {
        this.errorList.push({ code: 0, text: '用户名重复！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }

      if (String(row[1]).trim().length === 0) {
        uploadForm.password = 'Aa123456'
        uploadForm.password1 = 'Aa123456'
        uploadForm.password2 = 'Aa123456'
      } else {
        uploadForm.password = row[1]
        uploadForm.password1 = row[1]
        uploadForm.password2 = row[1]
      }
      // if (row[2].trim().length === 0) {
      //   this.errorList.push({ code: 0, text: '请输入姓名！' })
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return
      // }
      if (row[3] === '男' || String(row[3]) === '1') {
        uploadForm.gender = '1'
      } else if (row[3] === '女' || String(row[3]) === '0') {
        uploadForm.gender = '0'
      } else {
        uploadForm.gender = ''
      }
      // if (row[4].trim().length === 0) {
      //   this.errorList.push({ code: 0, text: '请输入邮箱！' })
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return
      // }
      // 邮箱查重
      // let isEmailRepeat = false
      // await this.$http.get('/userprofile/', {
      //   params: {
      //     page: 1,
      //     size: 100000,
      //     emailInfo: row[4]
      //   }
      // }).then((data) => {
      //   if (data.results.length) {
      //     isEmailRepeat = true
      //   } else {
      //     isEmailRepeat = false
      //   }
      // })
      // if (isEmailRepeat) {
      //   this.errorList.push({ code: 0, text: '邮箱已被注册，请更换' })
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return
      // }
      // if (row[7].trim().length === 0) {
      //   this.errorList.push({ code: 0, text: '请输入省份！' })
      //   this.curCsvIndex++
      //   await this.insertOne()
      //   return
      // }
      // for (let i = 0; i < this.dict_province.length; i++) {
      //   if (this.dict_province[i].title === row[7]) {
      //     uploadForm.province = this.dict_province[i].code
      //   }
      // }
      if (String(row[9]).length === 0) {
        this.errorList.push({ code: 0, text: '请输入学校代码！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      let schoolImportData = []
      const params = {
        page: 1,
        size: 100000,
        code_exact: row[9],
        province: uploadForm.province
      }
      await this.$http.get('/school/', { params }).then((data) => {
        schoolImportData = data.results
      })
      if (schoolImportData.length === 0) {
        this.errorList.push({ code: 0, text: '[学校代码：' + row[9] + ']' + '找不到对应的学校编码！' })
        this.curCsvIndex++
        await this.insertOne()
        return
      }
      uploadForm.school_id = schoolImportData[0].id
      if (String(row[10]).length) {
        for (let i = 0; i < this.categoryData.length; i++) {
          if (String(this.categoryData[i].code) === String(row[10])) {
            uploadForm.subject01_id = this.categoryData[i].id
          } else {
            uploadForm.subject01_id = null
          }
        }
      }
      if (String(row[10]).length) {
        await this.$http.get('/subject/', {
          params: {
            page: 1,
            size: 100000,
            parent_id: uploadForm.subject01_id,
            level: 2,
            code: row[11]
          }
        }).then((data) => {
          if (data.results.length) {
            uploadForm.subject02_id = data.results[0].id
          } else {
            uploadForm.subject02_id = null
          }
        })
      }
      // console.log(uploadForm)
      await this.$http.post('/userprofile/', uploadForm).then((profileData) => {
        this.errorList.push({ code: 1, text: '[' + uploadForm.username + ']' + '导入成功！' })
        this.curCsvIndex++
        this.insertOne()
      }).catch((error) => {
        console.log(error)
        this.errorList.push({ code: 0, text: '[' + uploadForm.username + ']' + error.response.data[0] })
        this.curCsvIndex++
        this.insertOne()
      })
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    dict_province () {
      return this.$store.state.dict_province
    },
    dict_role () {
      return this.$store.state.dict_role
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  mounted () {
    this.search()
  }
}
</script>

<style scoped>
#dialog_content2 {
  padding: 10px;
}
#dialog_content2 ul li {
  line-height: 20px;
}
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
