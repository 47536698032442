export function getUrlParams (url) {
  const queryString = {}
  if (!url) {
    return queryString
  }
  url = url.split('?')
  if (url.length <= 1) {
    return queryString
  }
  var query = url[1]
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    // If first entry with this name
    if (typeof queryString[pair[0]] === 'undefined') {
      queryString[pair[0]] = pair[1]
      // If second entry with this name
    } else if (typeof queryString[pair[0]] === 'string') {
      queryString[pair[0]] = [queryString[pair[0]], pair[1]]
      // If third or later entry with this name
    } else {
      queryString[pair[0]].push(pair[1])
    }
  }
  return queryString
}

export function openPage (url, method, params, target) {
  target = target || '_self'
  var dynamicForm = document.createElement('form')
  dynamicForm.setAttribute('method', method)
  dynamicForm.setAttribute('action', url)
  dynamicForm.setAttribute('target', target)
  dynamicForm.innerHTML = ''
  document.body.appendChild(dynamicForm)
  for (var i = 0; i < params.length; i++) {
    var input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', params[i].paramName)
    input.setAttribute('value', params[i].paramValue)
    dynamicForm.appendChild(input)
  }
  dynamicForm.submit()
}
