<template>
  <div>
    <div
      class="tab1 auditnone"
      style="display: block;"
    >
      <audit-tab
        :param_id="param_id"
        :active_index="2"
        :audit_status="audit_status"
      ></audit-tab>
      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab1?id=' + param_id">评审信息</router-link>-->
      <!--      <router-link class="item" :to="'/admin/audit/maintain/tab2?id=' + param_id">评审指标</router-link>-->

      <!--      <span id="more-btn">-->
      <!--        <router-link class="item on" :to="'/admin/audit/maintain/tab3?id=' + param_id">评审分组</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab4?id=' + param_id">评审详情</router-link>-->
      <!--        <router-link class="item" :to="'/admin/audit/maintain/tab5?id=' + param_id">会评分组</router-link>-->
      <!--      </span>-->
      <span
        class="add-btn sprite_4"
        @click="showGroupWindow('add')"
        v-if="audit_status==2"
      >添加分组</span>
    </div>
    <div
      class="audit_content seendtime audit_group clearfix"
      style="display: block;"
    >
      <div id="list">
        <table>
          <tbody>
            <tr class="l_header">
              <th>分组名称</th>
              <th>专家数</th>
              <th>作品数</th>
              <th>组长</th>
              <th
                width="180"
                v-if="audit_status==2"
              >操作</th>
              <th
                width="180"
                v-if="audit_status > 2"
              >操作</th>
            </tr>
            <tr
              v-for="item in tableData"
              :key="item.id"
            >
              <td>{{item.title}}</td>
<!--              <td id="e_num_150">{{item.expert_count}}</td>-->
<!--              <td id="w_num_150">{{item.item_count}}</td>-->
<!--              <td>{{item.leader?item.leader.name:'-'}}</td>-->
              <td id="e_num_150">{{item.expert_counts}}</td>
              <td id="w_num_150">{{item.item_counts}}</td>
              <td>{{item.leader_name?item.leader_name:'-'}}</td>
              <td v-if="audit_status==2">
                <router-link
                  class="btn import"
                  :to="'/admin/audit/maintain/tab3_maintain?id=' + param_id+'&groupId='+item.id"
                >维护</router-link>
                <a
                  class="btn edit"
                  href="javascript:void(0)"
                  @click="editItem(item)"
                >编辑</a>
                <a
                  class="btn delete hide"
                  href="javascript:void(0)"
                  @click="delItem(item.id)"
                >删除</a>
                <a
                  class="btn import hide"
                  href="javascript:void(0)"
                  @click="flushGroup(item.id)"
                >刷新</a>
              </td>
              <td v-if="audit_status > 2">
                <router-link
                  class="btn import"
                  :to="'/admin/audit/maintain/tab3_maintain?id=' + param_id+'&groupId='+item.id"
                >查看</router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="noitems"
          v-if="!tableData.length"
        >没有数据</div>
      </div>
      <!-- 分页 -->
      <pagination
        v-if="tableData.length"
        :cur='pageInfo.page'
        :all='pageInfo.all'
        :totalData="pageInfo.totalData"
        :callback="pageCallback"
      >
      </pagination>
    </div>
    <!-- 分组管理弹窗 -->
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowGroupWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">分组管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideGroupWindow"
        ><i class="iconfont"></i>

        </a>
        <div class="form_content">
          <form
            class="itemForm"
            id="itemForm"
          >
            <div class="item">
              <span class="label"><i class="require">*</i>组名称:</span>
              <input
                name="title"
                type="text"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label">组长:</span>
              <div class="k-container group-item clearfix">
                <input
                  name="groupLeader"
                  type="text"
                  value=""
                  readonly="readonly"
                  data-username=""
                  @click="getGroupLeaderInfo"
                  v-model="formInfo.leader_name"
                >
                <span class="del"><i
                    class="iconfont"
                    @click="delLeader"
                  ></i>

                </span>
              </div>
            </div>
            <div class="item" v-if="mode==2">
              <span class="label"><i class="require">*</i>每个项目最低被评次数:</span>
              <input v-model="formInfo.max_time" name="projMinNum" type="number" value="" min="0" >
            </div>
            <div class="item" v-if="mode==2">
              <span class="label"><i class="require">*</i>专家评分项目最大值:</span>
              <input v-model="formInfo.max_score" name="expMaxNum" type="number" value="" min="0" >
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideGroupWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            id="form_submit"
            @click="saveInfo"
          >提交</a>
        </div>
      </div>
    </div>
    <!-- 选择组长弹窗 -->
    <div
      id="c-panel"
      :style="classShowGroupleaderWindow"
    >
      <div id="c-panel-bg"></div>
      <div class="c-content">
        <form
          id="search"
          class="search"
          onsubmit="return false;"
        >
          <select
            name="subject01"
            class="s-text searchSelect short"
            id="subject01"
            v-model="queryGroupLeaderInfo.subject01_id"
            @change="getSsecondLevelInfo(queryGroupLeaderInfo.subject01_id)"
          >
            <option value="">一级学科</option>
            <option
              v-for="item in categoryData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <select
            name="subject02"
            class="s-text searchSelect short"
            id="subject02"
            v-model="queryGroupLeaderInfo.subject02_id"
          >
            <option value="">二级学科</option>
            <option
              v-for="item in secondLevelData"
              :key="item.id"
              :value="item.id"
            >{{item.title}}</option>
          </select>
          <input
            type="text"
            name="name"
            class="s-text short"
            placeholder="姓名"
            v-model="queryGroupLeaderInfo.name"
          >
          <input
            type="text"
            name="referral"
            class="s-text short"
            placeholder="推荐来源"
            v-model="queryGroupLeaderInfo.source"
          >
          <input
            type="button"
            class="s-btn1"
            value="查询"
            @click="pageGroupLeaderCallback(1)"
          >
          <span
            class="close-cpanel"
            @click="hideGroupLeaderWindow"
          ><i class="iconfont"></i>

          </span>
        </form>
        <div id="uList">
          <table>
            <tbody>
              <tr class="l_header">
                <th class="itemTitle">用户名</th>
                <th class="itemTitle">手机号</th>
                <th class="itemCategory">姓名</th>
                <th class="itemCategory">性别</th>
                <th class="itemCategory">学校</th>
                <th class="itemCategory">一级学科</th>
                <th class="itemCategory">二级学科</th>
                <th class="itemCategory">推荐来源</th>
                <th class="action">操作</th>
              </tr>
              <tr
                v-for="item in groupLeaderData"
                :key="item.id"
              >
                <td>{{item.username}}</td>
                <td>{{item.mobile?item.mobile:'-'}}</td>
                <td>{{item.name}}</td>
                <td>{{formatGender(item.gender)}}</td>
                <td>{{item.school?item.school.title:'-'}}</td>
                <td>{{item.subject01?item.subject01.title:'-'}}</td>
                <td>{{item.subject02?item.subject02.title:'-'}}</td>
                <td>{{item.source}}</td>
                <td><a
                    class="a-button"
                    href="javascript:void(0)"
                    @click="selectItem(item)"
                  >选择</a></td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 分页 -->
        <pagination
          v-if="groupLeaderData.length"
          :cur='pageGroupLeaderInfo.page'
          :all='pageGroupLeaderInfo.all'
          :totalData="pageGroupLeaderInfo.totalData"
          :callback="pageGroupLeaderCallback"
        ></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../../components/Pager'
import AuditTab from '../../../components/AuditTab'

export default {
  name: 'MaintainTab3',
  components: {
    AuditTab,
    Pagination
  },
  props: ['param_id', 'audit_status', 'mode'],
  data () {
    return {
      tableData: [],
      tableDataCount: 0,
      groupLeaderData: [],
      groupLeaderDataCount: 0,
      queryInfo: {
        page: 1,
        size: 10,
        audit_id: this.param_id,
        expert_id: ''
      },
      queryGroupLeaderInfo: {
        page: 1,
        size: 10,
        subject01_id: '',
        subject02_id: '',
        name: '',
        source: '',
        group_leader: 1
      },
      formInfo: {
        id: null,
        leader_id: null,
        leader_name: '',
        audit_id: '',
        title: '',
        max_time: null,
        max_score: null
      },
      classShowGroupWindow: {},
      classShowGroupleaderWindow: {},
      categoryData: [],
      secondLevelData: []
    }
  },
  methods: {
    formatGender (val) {
      if (val !== '') {
        return val === '1' ? '男' : '女'
      } else {
        return '-'
      }
    },
    // 获取一级专业
    async getCategoryInfo () {
      const params = {
        page: 1,
        size: 10000,
        level: 1
      }
      await this.$http.get('/subject/', { params }).then((data) => {
        this.categoryData = data.results
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 获取二级专业
    async getSsecondLevelInfo (id) {
      const params = {
        page: 1,
        size: 10000,
        level: 2,
        parent_id: id
      }
      this.secondLevelData = []
      this.queryGroupLeaderInfo.subject02_id = ''
      if (id) {
        await this.$http.get('/subject/', { params }).then((data) => {
          this.secondLevelData = data.results
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        this.secondLevelData = []
      }
    },
    async search () {
      // 优化下使用auditgroup_search 代替原来的/auditgroup
      await this.$http.get('/auditgroup_search/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    async editItem (data) {
      // 优化不需要查询后台
      this.formInfo.id = data.id
      this.formInfo.leader_id = data.leader_id
      this.formInfo.leader_name = data.leader_name ? data.leader_name : ''
      this.formInfo.audit_id = this.param_id
      this.formInfo.title = data.title
      this.formInfo.max_time = data.max_time
      this.formInfo.max_score = data.max_score
      this.showGroupWindow('edit')
    },
    async saveInfo () {
      const params = {
        leader_id: this.formInfo.leader_id ? this.formInfo.leader_id : null,
        leader_name: this.formInfo.leader_name ? this.formInfo.leader_name : null,
        audit_id: this.param_id,
        title: this.formInfo.title
      }
      if (!this.formInfo.title) {
        this.$toast.error('组名称不能为空！', '', this.error_tips)
        return
      }
      if (this.mode === 2) {
        params.max_time = this.formInfo.max_time
        params.max_score = this.formInfo.max_score

        if (!params.max_time) {
          this.$toast.error('每个项目最低被评次数不能为空！', '', this.error_tips)
          return
        }
        if (!params.max_score) {
          this.$toast.error('专家评分项目最大值不能为空！', '', this.error_tips)
          return
        }
      }

      if (this.formInfo.id) {
        const url = '/auditgroup/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideGroupWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/auditgroup/', params).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideGroupWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch(() => {
          this.$toast.error('获取数据失败！', '', this.error_tips)
        })
      }
    },
    flushGroup (id) {
      // 刷新数据
      const url = '/auditgroup_flush/' + id + '/'
      this.$http.put(url, {}).then((data) => {
        if (data.code === '200') {
          this.$toast.success('刷新成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('刷新失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('刷新失败！', '', this.error_tips)
      })
    },
    delItem (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/auditgroup/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },
    async getGroupLeaderInfo () {
      await this.$http.get('/userprofile/', {
        params: this.queryGroupLeaderInfo
      }).then((data) => {
        this.groupLeaderData = data.results
        this.groupLeaderDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
      this.showGroupLeaderWindow()
    },
    selectItem (item) {
      this.formInfo.leader_id = item.id
      // 因为评审没有姓名信息 所以可以使用账号如果没有
      this.formInfo.leader_name = item.name || item.username
      this.hideGroupLeaderWindow()
    },
    // 清除leader
    delLeader () {
      // this.formInfo.id = null
      this.formInfo.leader_id = null
      this.formInfo.leader_name = ''
      // this.formInfo.audit_id = null
      // bug修改 清除leader名字不能一同清空
      // this.formInfo.title = ''
      this.formInfo.max_time = null
      this.formInfo.max_score = null
    },
    // 打开分组管理弹窗
    showGroupWindow (val) {
      this.classShowGroupWindow = {
        visibility: 'visible'
      }
      if (val === 'add') {
        this.formInfo.id = null
        this.formInfo.title = ''
        this.formInfo.leader_id = null
        this.formInfo.leader_name = ''
        this.formInfo.title = ''
        this.formInfo.max_time = null
        this.formInfo.max_score = null
      }
    },
    // 关闭分组管理弹窗
    hideGroupWindow () {
      this.classShowGroupWindow = {}
    },
    // 打开分组管理弹窗
    showGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {
        display: 'block'
      }
    },
    // 关闭分组管理弹窗
    hideGroupLeaderWindow () {
      this.classShowGroupleaderWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    },
    // 分页
    async pageGroupLeaderCallback (data) {
      this.queryGroupLeaderInfo.page = data
      await this.getGroupLeaderInfo()
    }
  },
  mounted () {
    this.search()
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    pageGroupLeaderInfo () {
      return {
        page: this.queryGroupLeaderInfo.page,
        all: Math.ceil(this.groupLeaderDataCount / this.queryGroupLeaderInfo.size),
        totalData: this.groupLeaderDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.c-content {
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
