<template>
  <div id="layout">
    <div
      id="north"
      class="north"
    >
      <Header></Header>
    </div>
    <div
      id="center"
      style="min-height:88vh"
    >
      <div
        id="left"
        class="left"
      >
        <div class="left-menu">
          <ul class="clearfix">
            <li
              @click="updateMenuSelected"
              v-if="localUser.role!=1"
            >
              <router-link
                :class="menuSelected == '/admin/user' ? 'on' : ''"
                to="/admin/user"
              ><i class="iconfont"></i>用户管理
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role!=1"
            >
              <router-link
                :class="menuSelected == '/admin/school' ? 'on' : ''"
                to="/admin/school"
              ><i class="iconfont"></i>学校管理
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role!=1"
            >
              <router-link
                :class="menuSelected == '/admin/subject' ? 'on' : ''"
                to="/admin/subject"
              ><i class="iconfont"></i>专业管理
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role!=1"
            >
              <router-link
                :class="menuSelected == '/admin/site' ? 'on' : ''"
                to="/admin/site"
              ><i class="iconfont"></i>接入平台管理
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role!=1"
            >
              <router-link
                :class="menuSelected == '/admin/audit' ? 'on' : ''"
                to="/admin/audit"
              ><i class="iconfont"></i>评审管理
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role==1"
            >
              <router-link
                :class="menuSelected.substr(0, 15) == '/admin/my_audit' ? 'on' : ''"
                to="/admin/my_audit"
              ><i class="iconfont"></i>我的评审
              </router-link>
            </li>
            <li
              @click="updateMenuSelected"
              v-if="localUser.role==1 && localUser.group_leader==1"
            >
              <router-link
                :class="menuSelected.substr(0, 21) == '/admin/my_group' ? 'on' : ''"
                to="/admin/my_group"
              ><i class="iconfont"></i>分组详情
              </router-link>
            </li>
<!--            暂时去掉会评评审-->
<!--            <li-->
<!--              @click="updateMenuSelected"-->
<!--              v-if="localUser.role==1"-->
<!--            >-->
<!--              <router-link-->
<!--                :class="menuSelected.substr(0, 17) == '/admin/my_meeting' ? 'on' : ''"-->
<!--                to="/admin/my_meeting"-->
<!--              ><i class="iconfont"></i>会评评审-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </div>
      </div>
      <div
        id="content"
        class="content"
        style="min-width: 960px;"
      >
        <router-view></router-view>
      </div>
    </div>
    <div id="south">
      <div class="footer">
        <p class="center-p">京ICP备18004542号-1</p>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../components/Header'

export default {
  name: 'Layout',
  components: {
    Header
  },
  data () {
    return {
      menuSelected: ''
    }
  },
  methods: {
    updateMenuSelected () {
      const url = window.location.href
      const arrUrl = url.split('//')
      const start = arrUrl[1].indexOf('/')
      let relUrl = arrUrl[1].substring(start)

      if (relUrl.indexOf('?') !== -1) {
        relUrl = relUrl.split('?')[0]
      }
      relUrl = relUrl.replace('#', '')
      this.menuSelected = relUrl
    }
  },
  // 计算属性
  computed: {
    localUser () {
      return this.$store.state.userInfo
    }
  },
  mounted () {
    this.updateMenuSelected()
  }
}
</script>
<style scoped src="../assets/css/styles.css"></style>
<style scoped>
@import "../assets/css/dialog.css";

@import "../assets/css/fonts.css";
@import "../assets/css/common.css";
@import "../assets/css/form.css";
@import "../assets/css/table.css";
@import "../assets/css/footer.css";
@import "../assets/css/styles.css";
@import "../assets/css/chunkUpload.css";
@import "../assets/css/norm.css";
@import "../assets/css/select-user.css";
/*@import "../assets/css/items.css";*/
.header_container .logo {
  width: auto;
}
.logo span {
  margin-left: 30px;
  line-height: 60px;
  font-weight: bold;
  font-size: 20px;
}
</style>
