<template>
  <div
    class="container"
    style="min-height:820px"
  >
    <div class="set-wrap clearfix">
      <span class="title-text">接入平台管理</span>
      <div
        class="btns"
        @click="showWindow('add')"
      >
        <span class="btn add"><i class="iconfont"></i>新增接入点</span>
      </div>
    </div>
    <div class="search-box">
      <transition name="fade">
        <form
          class="form-inline open"
          v-if="isOpen"
          style="display: block; height: auto;"
        >
          <div class="form-group">
            <input
              type="text"
              class="form-control input-sm"
              name="title"
              placeholder="接入点名称"
              v-model="queryInfo.title"
            >
          </div>
          <div class="form-group">
            <button
              type="button"
              class="btn btn-primary btn-rounded btn-search"
              @click="search"
            ><i class="fa fa-search text-xs m-r-xs"></i>搜索
            </button>
          </div>
        </form>
      </transition>
      <span
        class="btn btn-danger btn-open"
        @click="toggleQueryForm"
      >{{isOpen?'关闭':'展开'}}</span>
    </div>
    <div
      class="list"
      id="list"
    >
      <table>
        <tbody>
          <tr class="l_header">
            <th>接入点编码</th>
            <th>接入点名称</th>
            <th>备注</th>
            <th width="120">操作</th>
          </tr>

          <tr
            v-for="item in tableData"
            :key="item.id"
          >
            <td>{{item.id}}</td>
            <td>{{item.title}}</td>
            <td>{{item.remarks?item.remarks:'-'}}</td>
            <td><a
                href="javascript:void(0)"
                class="btn edit"
                @click="querySingle(item.id)"
              >编辑</a><a
                href="javascript:void(0)"
                class="btn delete"
                @click="delInfo(item.id)"
              >删除</a></td>
          </tr>
        </tbody>
      </table>
      <div
        class="noitems"
        v-if="!tableData.length"
      >没有数据</div>
    </div>
    <!-- 分页 -->
    <pagination
      v-if="tableData.length"
      :cur='pageInfo.page'
      :all='pageInfo.all'
      :totalData="pageInfo.totalData"
      :callback="pageCallback"
    ></pagination>
    <div
      class="form_overlay"
      id="form_overlay"
      :style="classShowWindow"
    >
      <div class="form_overlay_bg"></div>
      <div class="form_panel">
        <div class="form_title">接入平台管理</div>
        <a
          href="javascript:void(0)"
          class="overlay-close"
          @click="hideWindow"
        ><i class="iconfont"></i>
        </a>
        <div class="form_content">
          <form id="itemForm">

            <div class="item">
              <span class="label"><i class="require">*</i>接入平台名称:</span>
              <input
                type="text"
                name="title"
                placeholder="填写接入平台名称"
                v-model="formInfo.title"
              >
            </div>
            <div class="item">
              <span class="label">备注:</span>
              <textarea
                class="simple"
                name="remarks"
                v-model="formInfo.remarks"
              ></textarea>
            </div>
          </form>
        </div>
        <div class="form_control">
          <a
            href="javascript:void(0)"
            class="button close"
            @click="hideWindow"
          >取消</a>
          <a
            href="javascript:void(0)"
            class="button"
            @click="saveInfo"
          >确定</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '../../components/Pager'
export default {
  name: 'Site',
  components: {
    Pagination
  },
  data () {
    return {
      queryInfo: {
        page: 1,
        size: 10,
        title: ''
      },
      tableData: [],
      tableDataCount: 0,
      classShowWindow: {},
      formInfo: {
        title: '',
        remarks: ''
      },
      isOpen: false
    }
  },
  methods: {
    toggleQueryForm () {
      this.isOpen = !this.isOpen
    },
    // 搜索
    async search () {
      await this.$http.get('/sites/', {
        params: this.queryInfo
      }).then((data) => {
        this.tableData = data.results
        this.tableDataCount = data.count
      }).catch(() => {
        this.$toast.error('获取数据失败！', '', this.error_tips)
      })
    },
    // 编辑时查询
    async querySingle (id) {
      const url = '/sites/' + id + '/'
      await this.$http.get(url).then((data) => {
        this.formInfo = {
          id: id,
          title: data.title,
          remarks: data.remarks
        }
        this.showWindow('edit')
      }).catch((error) => {
        console.log(error)
        this.$parent.showMsg('数据获取失败！')
      })
    },
    // 保存
    async saveInfo () {
      if (!this.formInfo.title) {
        this.$toast.error('接入点名称不能为空', '', this.error_tips)
        return false
      }

      if (this.formInfo.id) {
        const url = '/sites/' + this.formInfo.id + '/'
        await this.$http.put(url, this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('修改成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('修改失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('修改失败！', '', this.error_tips)
        })
      } else {
        await this.$http.post('/sites/', this.formInfo).then((data) => {
          if (data.id) {
            this.$toast.success('添加成功！', '', this.success_tips)
            this.hideWindow()
            this.search()
          } else {
            this.$toast.error('添加失败！', '', this.error_tips)
          }
        }).catch((error) => {
          console.log(error)
          this.$toast.error('添加失败！', '', this.error_tips)
        })
      }
    },
    delInfo (id) {
      var that = this
      this.$toast.question('确认删除此条数据吗?', ' ', {
        timeout: 20000,
        close: false,
        overlay: true,
        toastOnce: true,
        id: 'question',
        zindex: 999,
        position: 'center',
        buttons: [
          ['<button><b>是</b></button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'yes')
          }, true],
          ['<button>否</button>', function (instance, toast) {
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'no')
          }]
        ],
        onClosing: function (instance, toast, closedBy) {
          if (closedBy === 'yes') {
            that.del(id)
          }
        }

      })
    },
    del (id) {
      const url = '/sites/' + id + '/'
      this.$http.put(url, {
        isdel: 1
      }).then((data) => {
        if (data.id) {
          this.$toast.success('删除成功！', '', this.success_tips)
          this.search()
        } else {
          this.$toast.error('删除失败！', '', this.error_tips)
        }
      }).catch((error) => {
        console.log(error)
        this.$toast.error('删除失败！', '', this.error_tips)
      })
    },
    // 打开添加/编辑弹窗
    showWindow (value) {
      if (value === 'add') {
        this.formInfo = {
          id: 0,
          title: '',
          remarks: ''
        }
      }
      this.classShowWindow = {
        visibility: 'visible'
      }
    },
    // 关闭添加/编辑弹窗
    hideWindow () {
      this.classShowWindow = {}
    },
    // 分页
    async pageCallback (data) {
      this.queryInfo.page = data
      await this.search()
    }
  },
  // 计算属性
  computed: {
    pageInfo () {
      return {
        page: this.queryInfo.page,
        all: Math.ceil(this.tableDataCount / this.queryInfo.size),
        totalData: this.tableDataCount
      }
    },
    error_tips () {
      return this.$store.state.notificationSystem.options.error
    },
    success_tips () {
      return this.$store.state.notificationSystem.options.success
    }
  },
  mounted () {
    this.search()
  }
}
</script>

<style scoped>
.form_panel {
  margin-left: 0;
  transform: translate(-50%, -50%);
}
</style>
